import PropTypes from 'prop-types';
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Dropdown, DropdownToggle, DropdownMenu, Button, Row, Col } from "reactstrap";
import SimpleBar from "simplebar-react";

import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import "firebase/database"
import "firebase/storage"

import * as moment from 'moment';
//Import images
import avatar3 from "../../../assets/images/users/avatar-3.jpg";
import avatar4 from "../../../assets/images/users/avatar-4.jpg";

//i18n
import { withTranslation } from "react-i18next";

class NotificationDropdown extends Component {
  constructor(props) {
    super(props)
    this.state = {
      user_id:"",
      menu: false,
      notifs: [],
      confirm_both: false,
      viewMore: false,
    }
    this.toggle = this.toggle.bind(this)
    this.toggleViewMore = this.toggleViewMore.bind(this)
  }
  componentDidMount() {
    const { notifs } = this.state

    if (notifs && !notifs.length) {
      this.getNotifs()
    }
    this.setState({ notifs })
  }
  componentDidUpdate() {
    const { menu, notifs } = this.state
    var t = []
    if (menu) {
      notifs.map(function (item) {
        if (item.status === "new") {
          const n = {
            id: item.id,
            viewed:true
          }
          t.push(n)
        }
      })
      t.forEach(element => {
        this.updateNotif(element)
      });
    }

  }

  getNotifs = async () => {
    this.setState({ loading: true });

    // Unsubscribe previous listeners to prevent memory leaks
    if (this.unsubscribeNotifs) {
      this.unsubscribeNotifs();
    }

    this.unsubscribeNotifs = firebase.auth().onAuthStateChanged(async user => {
      if (user) {
        const userId = user.uid;
        const usersCollection = firebase.firestore().collection("users")
        const userDocRef = usersCollection.doc(userId);

        try {
          const userDoc = await userDocRef.get();
          if (userDoc.exists) {
            const userId = userDoc.data().id;
            const userNotifsCollectionRef = usersCollection.doc(userId).collection("notifications");
            this.setState({
              user_id:userId
            })
            this.unsubscribeNotifs = userNotifsCollectionRef.onSnapshot(snapshot => {
              let n = [];
              snapshot.docs.forEach(doc => {
                n.push(doc.data());
              });

              this.setState({
                loading: false,
                notifs: n
              });
            });

            localStorage.setItem("authUser", JSON.stringify(user));
          } else {
            // User document not found
            localStorage.removeItem("authUser");
            
            this.setState({
                loading: false,
                notifs: []
              });
            
          }
        } catch (error) {
          console.error("Error fetching user document:", error);
          // Handle error
        }
      } else {
        localStorage.removeItem("authUser");
        // Clear the reservations if user is not authenticated
        this.setState({
                loading: false,
                notifs: []
              });
      }
    });
  }
  notifConfirmed = (notif) => {
    //console.log(notif)
    const n = {
      id: notif.id, status: "old"
    }
    if (notif.type === "ReservationDone") {
      const reser = {
        id: notif.r_id, status: "Done"
      }

      this.updateReservation(reser)
      this.updateNotif(n)
    }
    if (notif.type === "ReservationStart") {
      const reser = {
        id: notif.r_id, status: "Rental"
      }
      this.updateReservation(reser)
      this.updateNotif(n)
    }
    if (notif.type === "RepairEnd") {
      const rep = {
        id: notif.r_id, status: "done"
      }
      this.updateRepair(rep)
      this.updateNotif(n)
    }
    if (notif.type === "RepairStart") {
      const rep = {
        id: notif.r_id, status: "repair"
      }
      this.updateRepair(rep)
      this.updateNotif(n)
    }
  }
  notifCancelled = (notif) => {
    // //console.log(notif)
  }
  updateReservation = async (reservation) => {
    // //console.log(reservation.status)
    const collection = firebase.firestore().collection("users").doc(this.state.user_id).collection("reservations")
    collection.doc(reservation.id).update({
      status: reservation.status,
    }).then(() => {
      //console.log("reservation  Updated!");
    }).catch((error) => {
      console.error("Error updating reservation: ", error);
    });
  }
  updateNotif = async (n) => {
    // //console.log(reservation.status)
    const collection = firebase.firestore().collection("users").doc(this.state.user_id).collection("notifications")
    collection.doc(n.id).update({
      viewed:n.viewed
    }).then(() => {
      //console.log("notif  Updated!");
    }).catch((error) => {
      console.error("Error updating notif: ", error);
    });
  }
  updateRepair = async (repair) => {
    // //console.log(reservation.status)
    const collection = firebase.firestore().collection("users").doc(this.state.user_id).collection("repairs")
    collection.doc(repair.id).update({
      status: repair.status,
    }).then(() => {
      //console.log("repair  Updated!");
    }).catch((error) => {
      console.error("Error updating repair: ", error);
    });
  }
  toggle() {

    this.setState(prevState => ({
      menu: !prevState.menu,
    }))

  }
  toggleViewMore() {
    ////console.log(this.state.viewMore)
    this.setState(prevState => ({
      viewMore: !prevState.viewMore,
    }))

  }
  render() {
    const { notifs } = this.state

    //console.log(notifs)
    let n = []
    let c = []
    let not = []
    notifs.map(function (item) {
      if (item.status === "new") {
        c.push(item)
      }
      if (!item.viewed) {
        not.push(item)
      }
      var span = ""
      var ic = ""
      var link = ""
      if (item.type === "PPError") {
        span = "avatar-title bg-danger rounded-circle font-size-16"
        ic = "bx bx-user"
        link = "/profile"
      }
      if (item.type === "RepairEsoon") {
        span = "avatar-title bg-warning rounded-circle font-size-16"
        ic = "bx bx-wrench"
        link = "/maintenance"
      }
      if (item.type === "RepairSsoon") {
        ic = "bx bx-wrench"
        span = "avatar-title bg-warning rounded-circle font-size-16"
        link = "/maintenance"
      }
      if (item.type === "ReservationPsoon") {
        span = "avatar-title bg-warning rounded-circle font-size-16"
        ic = "bx bx-car"
        link = "/reservations"
      }
      if (item.type === "ReservationRsoon") {
        ic = "bx bx-car"
        span = "avatar-title bg-warning rounded-circle font-size-16"
        link = "/reservations"
      }
      if (item.type === "RepairEnd") {
        ic = "bx bx-wrench"
        span = "avatar-title bg-danger rounded-circle font-size-16"
        link = "/maintenance"
      }
      if (item.type === "RepairStart") {
        ic = "bx bx-wrench"
        span = "avatar-title bg-danger rounded-circle font-size-16"
        link = "/maintenance"
      }
      if (item.type === "ReservationStart") {
        ic = "bx bx-car"
        span = "avatar-title bg-danger rounded-circle font-size-16"
        link = "/reservations"
      }
      if (item.type === "ReservationDone") {
        ic = "bx bx-car"
        span = "avatar-title bg-danger rounded-circle font-size-16"
        link = "/reservations"
      }
      if(item.type==="RequestArrived"){
        span="avatar-title bg-success rounded-circle font-size-16"
        ic="bx bx-message-square-check"
        link="/booking_requests"
      }
      if(item.type==="RequestExpired"){
        span="avatar-title bg-danger rounded-circle font-size-16"
        ic="bx bx-message-square-check"
        link="/booking_requests"
      }
      if(item.type==="RequestEsoon"){
        span="avatar-title bg-warning rounded-circle font-size-16"
        ic="bx bx-message-square-check"
        link="/booking_requests"
      }
      if (item.type === "InssuranceSoon") {
        ic = "bx bx-paperclip"
        span = "avatar-title bg-warning rounded-circle font-size-16"
        link = "/inssurance"
      }
      if (item.type === "InssuranceExpired") {
        ic = "bx bx-paperclip"
        span = "avatar-title bg-danger rounded-circle font-size-16"
        link = "/inssurance"
      }
      if (item.type === "TaxSoon") {
        ic = "bx bx-money"
        span = "avatar-title bg-warning rounded-circle font-size-16"
        link = "/vignette"
      }
      if (item.type === "TaxExpired") {
        ic = "bx bx-money"
        span = "avatar-title bg-danger rounded-circle font-size-16"
        link = "/vignette"
      }
      if (item.type === "OilChangeSoon") {
        ic = "fas fa-oil-can"
        span = "avatar-title bg-warning rounded-circle font-size-16"
        link = "/maintenance"
      }
      if (item.type === "OilChangeToday") {
        ic = "fas fa-oil-can"
        span = "avatar-title bg-danger rounded-circle font-size-16"
        link = "/mantenance"
      }
      n.push({ desc: item.desc,data: item.data, status: item.status, time: item.time, title: item.title, type: item.type, ic: ic, span: span, link: link })
      //}
    })
    n.sort(function (a, b) {
      return b.time - a.time;
    });
    ////console.log(n)

    const count = not.length
    var kra = 0
    if (!this.state.viewMore) {
      kra = 5
    } else {
      kra = n.length
    }
    // //console.log(n)
    return (
      <React.Fragment>
        <Dropdown
          isOpen={this.state.menu}
          toggle={this.toggle}
          className="dropdown d-inline-block"
          tag="li"
        >
          {count == 0 ? <DropdownToggle
            className="btn header-item noti-icon"
            tag="button"
            id="page-header-notifications-dropdown"
          >
            <i className="bx bx-bell" />

          </DropdownToggle> :
            <DropdownToggle
              className="btn header-item noti-icon"
              tag="button"
              id="page-header-notifications-dropdown"
            >
              <i className="bx bx-bell bx-tada" />
              <span className="badge bg-danger rounded-pill">{count}</span>
            </DropdownToggle>
          }


          <DropdownMenu className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0">
            <div className="p-3">
              <Row className="align-items-center">
                <Col>
                  <h6 className="m-0"> {this.props.t("Notifications")} </h6>
                </Col>
                <div className="col-auto">
                  <a href="/notifications" >
                    {" "}
                    {this.props.t("View All")}
                  </a>
                </div>
              </Row>
            </div>

            <SimpleBar style={{ height: "230px" }} >
              {n && n.map((item, i) => (
                i < kra ?
                  <Link key={i} className="text-reset notification-item" to={item.link}>
                    <div className="media">

                      <div className="avatar-xs me-3">
                        <span className={item.span}>
                          <i className={item.ic} />
                        </span>
                      </div>

                      <div className="media-body">
                        <h6 className="mt-0 mb-1">
                          {this.props.t(item.title)}
                        </h6>
                        <div className="font-size-12 text-muted">
                          <p className="mb-0">
                            {this.props.t(item.desc)||""}
                          </p>
                          <p className="mb-1">
                            {item.data||""}
                          </p>
                          {/* <button
                          onClick={() => this.notifConfirmed(item)}
                          className="btn btn-success btn-sm"
                        >
                          Confirm
                        </button>
                        {"         "}
                        <button
                          onClick={() => this.notifCancelled(item)}
                          className="btn btn-danger save-event btn-sm"
                        >
                          Cancel
                        </button> */}
                          <p className="mb-0">
                            <i className="mdi mdi-clock-outline" />{" "}
                            {moment(item.time).fromNow()}{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                  </Link>
                  : null
              ))}
              {n == 0 ?
                <div className="media">



                  <div className="media-body center">

                    <div className="font-size-12 text-muted">
                      <p className="mb-1">
                        {this.props.t("No Notifications")}
                      </p>

                    </div>
                  </div>
                </div>
                : null}

            </SimpleBar>
            {/* Remove this comment and set the view more button when notifications are more than 5 */}
            <div className="p-2 border-top d-grid">
              {this.state.viewMore ?
                <Button className="btn btn-sm  font-size-14 text-center" color="light" onClick={this.toggleViewMore}>
                  <i className="mdi mdi-arrow-left-circle me-1" ></i> <span key="t-view-less">{this.props.t("Show Less..")}</span>
                </Button>
                :
                <Button className="btn btn-sm  font-size-14 text-center" color="light" onClick={this.toggleViewMore}>
                  <i className="mdi mdi-arrow-right-circle me-1"></i> <span key="t-view-more">{this.props.t("Show More..")}</span>
                </Button>
              }
              {/* <Link className="btn btn-sm btn-link font-size-14 text-center" onClick={this.toggleViewMore}>
                <i className="mdi mdi-arrow-right-circle me-1"></i> <span key="t-view-more">View More..</span>
              </Link> */}
            </div>
          </DropdownMenu>
        </Dropdown>
      </React.Fragment>
    )
  }
}

NotificationDropdown.propTypes = {
  t: PropTypes.any
}

export default withTranslation()(NotificationDropdown)
