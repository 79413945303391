import { call, put, takeEvery } from "redux-saga/effects"

// Ecommerce Redux States
import {
  
  GET_INDIE,
  ADD_NEW_INDIE,
  DELETE_INDIE,
  UPDATE_INDIE,

  GET_COMPANY,
  ADD_NEW_COMPANY,
  DELETE_COMPANY,
  UPDATE_COMPANY,
  
} from "./actionTypes"
import {
  
  getIndieFail,
  getIndieSuccess,
  addIndieFail,
  addIndieSuccess,
  updateIndieSuccess,
  updateIndieFail,
  deleteIndieSuccess,
  deleteIndieFail,

  getCompanyFail,
  getCompanySuccess,
  addCompanyFail,
  addCompanySuccess,
  updateCompanySuccess,
  updateCompanyFail,
  deleteCompanySuccess,
  deleteCompanyFail,
  
} from "./actions"

//Include Both Helper File with needed methods
import {
 
  getIndie,
  addNewIndie,
  updateIndie,
  deleteIndie,

  getCompany,
  addNewCompany,
  updateCompany,
  deleteCompany,
  
} from "helpers/fakebackend_helper"


function* fetchIndie() {
  try {
    const response = yield call(getIndie)
    yield put(getIndieSuccess(response))
  } catch (error) {
    yield put(getIndieFail(error))
  }
}

function* onAddNewIndie({ payload: indie }) {
  try {
    const response = yield call(addNewIndie, indie)
    yield put(addIndieSuccess(response))
  } catch (error) {

    yield put(addIndieFail(error))
  }
}

function* onUpdateIndie({ payload: indie }) {
  try {
    const response = yield call(updateIndie, indie)
    yield put(updateIndieSuccess(response))
  } catch (error) {
    yield put(updateIndieFail(error))
  }
}

function* onDeleteIndie({ payload: indie }) {
  try {
    const response = yield call(deleteIndie, indie)
    yield put(deleteIndieSuccess(response))
  } catch (error) {
    yield put(deleteIndieFail(error))
  }
}


//



function* fetchCompany() {
  try {
    const response = yield call(getCompany)
    yield put(getCompanySuccess(response))
  } catch (error) {
    yield put(getCompanyFail(error))
  }
}

function* onAddNewCompany({ payload: company }) {
  try {
    const response = yield call(addNewCompany, company)
    yield put(addCompanySuccess(response))
  } catch (error) {

    yield put(addCompanyFail(error))
  }
}

function* onUpdateCompany({ payload: company }) {
  try {
    const response = yield call(updateCompany, company)
    yield put(updateCompanySuccess(response))
  } catch (error) {
    yield put(updateCompanyFail(error))
  }
}

function* onDeleteCompany({ payload: company }) {
  try {
    const response = yield call(deleteCompany, company)
    yield put(deleteCompanySuccess(response))
  } catch (error) {
    yield put(deleteCompanyFail(error))
  }
}


function* clientSaga() {
  
  yield takeEvery(GET_INDIE, fetchIndie)
  yield takeEvery(ADD_NEW_INDIE, onAddNewIndie)
  yield takeEvery(UPDATE_INDIE, onUpdateIndie)
  yield takeEvery(DELETE_INDIE, onDeleteIndie)

  yield takeEvery(GET_COMPANY, fetchCompany)
  yield takeEvery(ADD_NEW_COMPANY, onAddNewCompany)
  yield takeEvery(UPDATE_COMPANY, onUpdateCompany)
  yield takeEvery(DELETE_COMPANY, onDeleteCompany)
  
}

export default clientSaga
