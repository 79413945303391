import React, { Component } from "react"
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap"
import { Link } from "react-router-dom"

class SocialSource extends Component {
  constructor(props) {
    super(props)
    this.state = {
      socials: [
        {
          title: "Facebook",
          bgColor: "bg-primary",
          iconClass: "mdi-facebook",
          description: "125",
        },
        {
          title: "Twitter",
          bgColor: "bg-info",
          iconClass: "mdi-twitter",
          description: "112",
        },
        {
          title: "Instagram",
          bgColor: "bg-pink",
          iconClass: "mdi-instagram",
          description: "104",
        },
      ],
    }
  }

  render() {
    return (
      <React.Fragment>
        <Card>
          <CardBody>
            
            <div className="text-center">
              <div className="avatar-sm mx-auto mb-3 mt-3">
                <span className="avatar-title rounded-circle bg-primary bg-soft font-size-24">
                  <i className="mdi mdi-facebook text-primary"/>
                </span>
              </div>
              <p className="font-16 text-muted mb-2"/>
              <h5>
                <Link to="#" className="text-dark">
                  Facebook -{" "}
                  <span className="text-muted font-16">125 sales</span>{" "}
                </Link>
              </h5>
              <p className="text-muted">
                Maecenas nec odio et ante tincidunt tempus. 
              </p>
              
            </div>
            
          </CardBody>
        </Card>
      </React.Fragment>
    )
  }
}

export default SocialSource
