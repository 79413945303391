import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import MetaTags from 'react-meta-tags';
import { withRouter } from "react-router-dom"
import { countBy, isEmpty, size } from "lodash"
import BootstrapTable from "react-bootstrap-table-next"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import Dropzone from "react-dropzone"
import toastr from "toastr";
import toast, { Toaster } from 'react-hot-toast';


import maintanence from "../../assets/images/coming-soon.svg"
import { withTranslation } from "react-i18next"
// Add the Firebase products that you want to use
import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import "firebase/database"
import "firebase/storage"

import
paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import { Link } from "react-router-dom"
import * as moment from 'moment';
import Select from "react-select"
import jsPDF from "jspdf";
import "jspdf-autotable";
import { UncontrolledDropdown, DropdownItem, DropdownMenu, DropdownToggle, Form, InputGroup, Input, FormGroup, Label, Button, Card, CardBody, Col, Table, ModalFooter, Container, Row, Modal, Badge, ModalHeader, ModalBody } from "reactstrap"

import { AvForm, AvField } from "availity-reactstrap-validation"
import SweetAlert from "react-bootstrap-sweetalert"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import {
  getSupplier,
  addNewSupplier,
  updateSupplier,
  deleteSupplier
} from "store/actions"


import exportFromJSON from 'export-from-json'
import { isThisExpression } from "@babel/types";
import { suppliers } from "common/data";

class Suppliers extends Component {
  constructor(props) {
    super(props)
    this.state = {
      user_id: "",
      loading: true,
      submitLoading: false,
      isShow: false,
      changed: false,
      gender: "",
      viewmodal: false,
      modal: false,
      isDelete: false,
      no_data: false,
      success_dlg: false,
      dynamic_title: '',
      dynamic_description: '',
      vc: "",
      selectedGroup: null,
      suppliers: [],
      supplier: [],
      date_birth: new Date(),
      SupplierColumns: [
        /* {
          dataField: "code",
          text: "#",
          sort: true,
          formatter: (cellContent, row) => (
            <Link to="#" className="text-body fw-bold">
              MZWS{row.code}
            </Link>
          ),
        }, */
        {
          dataField: "workshop_name",
          text: this.props.t("Name"),
          sort: true,
          formatter: (cellContent, row) => (
            <Link to="#" className="text-body fw-bold">
              {row.workshop_name}
            </Link>
          ),
        },
        {
          dataField: "email",
          text: this.props.t("E-mail"),
          sort: true,
        },

        {
          dataField: "website",
          text: this.props.t("Website"),
          sort: true,
        },
        {
          dataField: "phone_1",
          text: this.props.t("Phone Number"),
          sort: true,
        },

        {
          dataField: "address",
          text: this.props.t("Address"),
          sort: true,
        },
        {
          dataField: "createdAt",
          text: this.props.t("Date created"),
          sort: true,
          formatter: (cellContent, row) => (
            <>

              {" "}
              {moment(row.createdAt).format('YYYY-MM-DDTHH:mm')}
            </>
          ),
        },
        {
          dataField: "view",
          isDummyField: true,
          text: this.props.t("View Details"),
          sort: true,
          formatter: (cellContent, supplier) => (
            <Button
              type="button"
              color="primary"
              className="btn-sm btn-rounded"
              onClick={() => this.handleViewClick(supplier)}
            >
              {this.props.t("View Details")}
            </Button>
          ),
        },
        {
          dataField: "action",
          isDummyField: true,
          text: this.props.t("Action"),
          formatter: (cellContent, supplier) => (
            <>
              <div className="d-flex gap-3">
                <Link to="#" className="text-success">
                  <i className="mdi mdi-pencil font-size-18" id="edittooltip" onClick={() => this.handleSupplierClick(supplier)} />
                </Link>
                <Link to="#" className="text-danger">
                  <i className="mdi mdi-delete font-size-18" id="deletetooltip" onClick={() => this.setState({ isDelete: true, vc: supplier })} />
                </Link>
              </div>
              {/* <td>
                <UncontrolledDropdown>
                  <DropdownToggle href="#" className="card-drop" tag="a">
                    <i className="mdi mdi-dots-horizontal font-size-18" />
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-end">
                    <DropdownItem href="#" onClick={() => this.handleSupplierClick(supplier)}>
                      <i className="mdi mdi-pencil font-size-18 font-size-16 text-success me-1" id="edittooltip" />{" "}
                      {this.props.t("Edit")}

                    </DropdownItem>
                    <DropdownItem href="#" onClick={() => this.setState({ isDelete: true, vc: supplier })} >

                      <i className="mdi mdi-delete font-size-18 font-size-16 text-danger me-1" id="deletetooltip" />{" "}
                      {this.props.t("Delete")}
                    </DropdownItem>
                    <DropdownItem href="#" >
                      <i className="mdi mdi-file-cabinet font-size-16  me-1" />{" "}
                      {this.props.t("Vouchers")}
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>

              </td> */}



              {/* <div className="d-flex gap-3">
                <Link to="#" className="text-success">
                  <i className="mdi mdi-pencil font-size-18" id="edittooltip" onClick={() => this.handleSupplierClick(supplier)} />
                </Link>
                <Link to="#" className="text-danger">
                  <i className="mdi mdi-delete font-size-18" id="deletetooltip" onClick={() => this.setState({isDelete:true,vc:supplier})} />
                </Link>
              </div> */}
            </>
          ),
        },
      ],
      selectedFiles: []
    }

    this.handleSupplierClick = this.handleSupplierClick.bind(this)
    this.toggle = this.toggle.bind(this)
    this.handleValidSupplierSubmit = this.handleValidSupplierSubmit.bind(this)
    this.handleSupplierClicks = this.handleSupplierClicks.bind(this)
    this.toLowerCase1 = this.toLowerCase1.bind(this)
    this.toggleDelete = this.toggleDelete.bind(this)
    this.handleViewClick = this.handleViewClick.bind(this)
    this.handleAcceptedFiles = this.handleAcceptedFiles.bind(this)
    this.toggleIsShow = this.toggleIsShow.bind(this)
  }

  handleAcceptedFiles = files => {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: this.formatBytes(file.size),
      })
    )

    this.setState({ selectedFiles: files })
  }

  /**
   * Formats the size
   */
  formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  toLowerCase1(str) {
    return str.toLowerCase();
  }

  componentDidMount() {
    const { suppliers, onGetSupplier } = this.state
    if (suppliers && !suppliers.length) {
      this.getSupplier()
    }
    this.setState({ suppliers })



  }
  getSupplier = async () => {
    this.setState({ loading: true });

    // Unsubscribe previous listeners to prevent memory leaks
    if (this.unsubscribeSuppliers) {
      this.unsubscribeSuppliers();
    }

    this.unsubscribeSuppliers = firebase.auth().onAuthStateChanged(async user => {
      if (user) {
        const userId = user.uid;
        const usersCollection = firebase.firestore().collection("users")
        const userDocRef = usersCollection.doc(userId);

        try {
          const userDoc = await userDocRef.get();
          if (userDoc.exists) {
            const userId = userDoc.data().id;
            const userSuppliersCollectionRef = usersCollection.doc(userId).collection("workshops");
            this.setState({
              user_id: userId,
              account_user: userDoc.data()
            })
            this.unsubscribeSuppliers = userSuppliersCollectionRef.onSnapshot(snapshot => {
              let n = [];
              snapshot.docs.forEach(doc => {
                n.push(doc.data());
              });

              this.setState({
                loading: false,
                suppliers: n
              });
            });

            localStorage.setItem("authUser", JSON.stringify(user));
          } else {
            // User document not found
            localStorage.removeItem("authUser");

            this.setState({
              loading: false,
              suppliers: []
            });

          }
        } catch (error) {
          console.error("Error fetching user document:", error);
          // Handle error
        }
      } else {
        localStorage.removeItem("authUser");
        // Clear the reservations if user is not authenticated
        this.setState({
          loading: false,
          suppliers: []
        });
      }
    });

  }
  // eslint-disable-next-line no-unused-vars
  /* componentDidUpdate(prevProps, prevState, snapshot) {
    const { suppliers } = this.state

   //if (!isEmpty(suppliers) && size(prevProps.suppliers) !== size(suppliers)) {
     // this.setState({ suppliers: {}, isEdit: false })
    //} 
  } */

  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }))
  }
  toggleDelete() {
    this.setState(prevState => ({
      isDelete: !prevState.isDelete,
    }))
  }

  handleSupplierClicks = () => {
    this.setState({ supplier: [], isEdit: false, isShow: false })

    this.toggle()
  }

  // eslint-disable-next-line no-unused-vars
  handleTableChange = (type, { page, searchText }) => {
    const { suppliers } = this.props
    this.setState({
      suppliers: suppliers.filter(supplier =>
        Object.keys(supplier).some(
          key =>
            typeof supplier[key] === "string" &&
            supplier[key].toLowerCase().includes(searchText.toLowerCase())
        )
      ),
    })
  }

  toggleViewModal = () => {
    this.setState(prevState => ({
      viewmodal: !prevState.viewmodal,
    }))
  }

  toggleIsShow = () => {
    this.setState(prevState => ({
      isShow: !prevState.isShow,
    }))
  }


  /* Insert,Update Delete data */

  handleDeleteSupplier = (supplier) => {

    /* this.setState({
      success_dlg: true,
      dynamic_title: this.props.t("Deleted"),
      dynamic_description: this.props.t("Workshop has been deleted."),
    }) */
    const { onDeleteSupplier } = this.state
    this.deleteSupplier(supplier)
    /*  if (supplier['code'] === '') {
       //
     } else { */
    //onDeleteSupplier(supplier)
    // toast.success(this.props.t('Workshop infos Deleted '))
    this.toggleDelete()
    // }

  }

  handleViewClick = arg => {
    const supplier = arg

    this.setState({
      supplier: {
        id: supplier.id,
        workshop_name: supplier.workshop_name,
        email: supplier.email,
        fax: supplier.fax,
        website: supplier.website,
        phone_1: supplier.phone_1,
        phone_2: supplier.phone_2,
        address: supplier.address,
        city: supplier.city,
        state: supplier.state,
        other: supplier.other
      },

      isShow: true,
      isEdit: false

    })

    //this.toggleIsShow()
    this.toggle()

    //this.toggleViewModal()
  }


  handleSupplierClick = arg => {
    const supplier = arg

    //this.setState({selectedGroup:staff.designation})
    this.setState({
      supplier: {
        id: supplier.id,
        workshop_name: supplier.workshop_name,
        email: supplier.email,
        fax: supplier.fax,
        website: supplier.website,
        phone_1: supplier.phone_1,
        phone_2: supplier.phone_2,
        address: supplier.address,
        city: supplier.city,
        state: supplier.state,
        other: supplier.other
      },
      isEdit: true,
      isShow: false,
    })

    this.toggle()
  }

  /**
   * Handling submit Order on Order form
   */
  handleValidSupplierSubmit = (e, values) => {
    const { onAddNewSupplier, onUpdateSupplier } = this.props
    const { isEdit, suppliers, selectedSupplier } = this.state
    /* const cds = []
    this.props.suppliers.map(spl =>
      cds.push(spl.code)
    )
    const cd = cds.length == 0 ? 1 : Math.max.apply(null, cds) + 1 */
    /*  let des= this.state.selectedGroup.value
     if(this.state.changed){
       des= this.state.selectedGroup.value
       this.setState({changed:false})
     }else{
       des=this.state.selectedGroup
     }
      */

    /* this.setState({
      gender:this.state.staffs.gender
    }) */
    var em = 0, na = 0
    var emA = 0, naA = 0

    const foundem = suppliers.filter(element => element.email === values.email.trim() && this.state.supplier.id !== element.id);
    em = foundem.length
    const foundna = suppliers.filter(element => element.workshop_name === values.workshop_name.trim() && this.state.supplier.id !== element.id);
    na = foundna.length


    const foundemA = suppliers.filter(element => element.email === values.email.trim());
    emA = foundemA.length
    const foundnaA = suppliers.filter(element => element.workshop_name === values.workshop_name.trim());
    naA = foundnaA.length

    if (isEdit) {
      //des=this.state.selectedGroup.defaultInputValue

      const updateSupplier = {

        id: this.state.supplier.id,
        workshop_name: values.workshop_name.trim(),
        email: values.email.trim(),
        fax: values.fax.trim(),
        website: values.website.trim(),
        phone_1: values.phone_1.trim(),
        phone_2: values.phone_2.trim(),
        address: values.address.trim(),
        city: values.city.trim(),
        state: values.state.trim(),
        other: values.other.trim()


      }

      // update Order
      //onUpdateSupplier(updateSupplier)
      if (this.state.supplier.workshop_name == updateSupplier.workshop_name
        && this.state.supplier.email == updateSupplier.email
        && this.state.supplier.fax == updateSupplier.fax
        && this.state.supplier.website == updateSupplier.website
        && this.state.supplier.phone_1 == updateSupplier.phone_1
        && this.state.supplier.phone_2 == updateSupplier.phone_2
        && this.state.supplier.address == updateSupplier.address
        && this.state.supplier.city == updateSupplier.city
        && this.state.supplier.state == updateSupplier.state
        && this.state.supplier.other == updateSupplier.other) {
        this.setState({ submitLoading: false })
        this.toggle()
      } else {
        this.updateSupplier(updateSupplier, /* em, */ na)
      }

      //toast.success(this.props.t("Workshop updated "))
    } else {

      const newSupplier = {
        //code: cd,
        workshop_name: values['workshop_name'],
        email: values['email'],
        fax: values['fax'],
        website: values['website'],
        phone_1: values['phone_1'],
        phone_2: values['phone_2'],
        address: values['address'],
        city: values['city'],
        state: values['state'],
        other: values['other']
      }
      ////console.log(des)
      // save new Order
      // onAddNewSupplier(newSupplier)
      this.setState({ supplier: newSupplier })
      this.addNewSupplier(newSupplier,/*  emA, */ naA)
      // toast.success(this.props.t("Workshop added "))
    }
    this.setState({ selectedSupplier: null })
    // this.toggle()
  }


  deleteSupplier = (supplier) => {
    ////console.log(supplier)
    const collection = firebase.firestore().collection("users").doc(this.state.user_id).collection("workshops")
    const timeline = {
      statusTitle: "Workshop deleted",
      iconClass: "bx bx-building h2 text-danger",
      description: "Workshop information has been deleted for ",
      data: supplier.workshop_name ,
      time: new Date().getTime(),

    }
    collection.doc(supplier.id).delete().then(() => {

      //console.log("Element  deleted!");
      toast.success(this.props.t('Workshop infos Deleted '))
      this.addNewTimeline(timeline)
      this.setState({
        success_dlg: true,
        dynamic_title: this.props.t("Deleted"),
        dynamic_description: this.props.t("Workshop has been deleted."),
      })
    }).catch((error) => {
      console.error("Error removing element: ", error);
      toast.error(this.props.t("Error removing element"));

    });

  }


  updateSupplier = (supplier, na) => {

    this.setState({ submitLoading: true })
    const collection = firebase.firestore().collection("users").doc(this.state.user_id).collection("workshops")

    const timeline = {
      statusTitle: "Workshop updated",
      iconClass: "bx bx-building h2 text-warning",
      description: "Workshop information has been updated for ",
      data: supplier.workshop_name ,
      time: new Date().getTime(),

    }
    if ( na == 0) {


      collection.doc(supplier.id).update({

        workshop_name: supplier.workshop_name,
        email: supplier.email,
        fax: supplier.fax,
        website: supplier.website,
        phone_1: supplier.phone_1,
        phone_2: supplier.phone_2,
        address: supplier.address,
        city: supplier.city,
        state: supplier.state,
        other: supplier.other

      }).then(() => {
        //console.log("Document  Updated!");
        toast.success(this.props.t("Workshop  Updated!"))
        this.addNewTimeline(timeline)
        this.setState({ submitLoading: false })
        this.toggle()
      }).catch((error) => {
        console.error("Error updating document: ", error);
        toast.error(this.props.t("Error updating document"))
        this.setState({ submitLoading: false })
      });
    } else {
      /* if (em != 0) {
        console.error("Email already exist ");
        toast.error(this.props.t("Email already exist"))
        this.setState({ submitLoading: false })
      } */
      if (na != 0) {
        console.error("workshop already exist ");
        toast.error(this.props.t("workshop already exist"))
        this.setState({ submitLoading: false })
      }
    }
  }
  addNewTimeline = async (timeline) => {
    try {
      const { user_id, account_user } = this.state;
      const collection = firebase.firestore().collection("users").doc(user_id).collection("timeline");
      const newDoc = collection.doc();
      const by = account_user.isAdmin ? 'Admin' : `${account_user.first_name} ${account_user.last_name}`;

      await newDoc.set({
        id: newDoc.id,
        statusTitle: timeline.statusTitle,
        iconClass: timeline.iconClass,
        description: timeline.description,
        time: timeline.time,
        data:timeline.data,
        user: by
      });

      //console.log("Timeline  written!");
    } catch (error) {
      console.error("Error adding timeline:", error);
    }
  }


  addNewSupplier = async (supplier,  na) => {
    const collection = firebase.firestore().collection("users").doc(this.state.user_id).collection("workshops");
    this.setState({ submitLoading: true });

    const timeline = {
      statusTitle: "Workshop added",
      iconClass: "bx bx-building h2 text-success",
      description: "Workshop information has been added ",
      data: supplier.workshop_name ,
      time: new Date().getTime(),
    };

    try {
      if (/* em === 0 && */ na === 0) {
        const newDoc = collection.doc();
        await newDoc.set({
          id: newDoc.id,
          workshop_name: supplier.workshop_name,
          email: supplier.email,
          fax: supplier.fax,
          website: supplier.website,
          phone_1: supplier.phone_1,
          phone_2: supplier.phone_2,
          address: supplier.address,
          city: supplier.city,
          state: supplier.state,
          other: supplier.other,
          createdAt: new Date().getTime(),
        });

        //console.log("Workshop  added!");
        toast.success(this.props.t('Workshop added '));
        this.addNewTimeline(timeline);
        this.setState({ submitLoading: false });
        this.toggle();
      } else {
        /* if (em !== 0) {
          console.error("Email already exists");
          toast.error(this.props.t("Email already exists"));
        } */
        if (na !== 0) {
          console.error("Workshop already exists");
          toast.error(this.props.t("Workshop already exists"));
        }
        this.setState({ submitLoading: false });
      }
    } catch (error) {
      console.error("Error adding workshop:", error);
      toast.error(this.props.t("Error adding workshop"));
      this.setState({ submitLoading: false });
    }
  };



  handleValidDate = (date) => {
    const date1 = moment(new Date(date)).format('DD MMM Y');
    return date1;
  }


  render() {
    const emptyDataMessage = () => {

      return <Container>
        <Row>
          <Col lg="12">
            {this.state.loading ?
              <div className="text-center">
                <Row className="justify-content-center mt-5">
                  <Col sm="4">
                    <div>
                      <div >
                        <div className="spinner-chase">
                          <div className="chase-dot"></div>
                          <div className="chase-dot"></div>
                          <div className="chase-dot"></div>
                          <div className="chase-dot"></div>
                          <div className="chase-dot"></div>
                          <div className="chase-dot"></div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>

              :
              <div className="text-center">

                <Row className="justify-content-center mt-5">
                  <Col sm="4">
                    <div className="maintenance-img">
                      <img
                        src={maintanence}
                        alt=""
                        className="img-fluid mx-auto d-block"
                      />
                    </div>
                  </Col>
                </Row>
                <h4 className="mt-5">{this.props.t("Let's get started with Medios")}</h4>
                <p className="text-muted">
                  {this.props.t("Start adding workshops by clicking")}<h5 className="text-primary"><Link onClick={this.handleSupplierClicks} >{this.props.t("New Workshop")}</Link></h5>
                </p>


              </div>
            }

          </Col>
        </Row>
      </Container>


        ;
    }


    const { suppliers, user_id } = this.state
    const data = suppliers;

    let dataxcl = []
    data.map(function (item) {
      delete item.supplier;
      dataxcl.push({
        workshop_name: item.workshop_name,
        email: item.email,
        fax: item.fax,
        website: item.website,
        phone_1: item.phone_1,
        phone_2: item.phone_2,
        address: item.address,
        city: item.city,
        state: item.state,
        other: item.other
      })
    });
    /* let newArray = data.map(function (item) {
      delete item.supplier;
      return item;
    }); */

    const { SearchBar } = Search
    const { isEdit } = this.state
    const { isDelete } = this.state
    const { vc } = this.state

    //pagination customization


    const defaultSorted = [{
      dataField: 'createdAt',
      order: 'desc'
    }];

    const selectRow = {
      mode: 'checkbox',
    };
    function ExportToExcel() {
      exportFromJSON({ data: dataxcl, fileName: "Workshops", exportType: exportFromJSON.types.xls })
    }
    function exportPDF() {
      const unit = "pt";
      const size = "A3"; // Use A1, A2, A3 or A4
      const orientation = "landscape"; // portrait or landscape

      const marginLeft = 20;
      const doc = new jsPDF(orientation, unit, size);

      doc.setFontSize(15);

      const title = "Workshop Infos";
      const headers = [["Workshop name", "E-mail", "Fax", "Website", "Phone number", "Phone number 2", "Address"]];

      const dt = data.map(elt => [elt.workshop_name, elt.email, elt.fax, elt.website, elt.phone_1, elt.phone_2, elt.address + " \n" + elt.city]);

      let content = {
        startY: 50,
        head: headers,
        body: dt
      };

      doc.text(title, marginLeft, 20);
      doc.autoTable(content);
      doc.save("Workshops.pdf")
    }
    function printPDF() {
      const unit = "pt";
      const size = "A3"; // Use A1, A2, A3 or A4
      const orientation = "landscape"; // portrait or landscape

      const marginLeft = 20;
      const doc = new jsPDF(orientation, unit, size);

      doc.setFontSize(15);

      const title = "Workshop Infos";
      const headers = [["Workshop name", "E-mail", "Fax", "Website", "Phone number", "Phone number 2", "Address"]];

      const dt = data.map(elt => [elt.workshop_name, elt.email, elt.fax, elt.website, elt.phone_1, elt.phone_2, elt.address + " \n" + elt.city]);


      let content = {
        startY: 50,
        head: headers,
        body: dt
      };

      doc.text(title, marginLeft, 20);
      doc.autoTable(content);
      var string = doc.output('datauristring');
      var embed = "<embed width='100%' height='100%' src='" + string + "'/>"
      var x = window.open();
      x.document.open();
      x.document.write(embed);
      x.document.close();

    }

    return (

      <React.Fragment>


        <div className="page-content">
          <MetaTags>
            <title>{this.props.t("Workshops | Medios - Car Rental Management System")}</title>
          </MetaTags>
          <Container fluid>
            <Breadcrumbs title={this.props.t("Extras")} breadcrumbItem={this.props.t("Workshops")} />
            {this.state.success_dlg ? (
              <SweetAlert
                success
                title={this.state.dynamic_title}
                onConfirm={() => this.setState({ success_dlg: false })}
              >
                {this.state.dynamic_description}
              </SweetAlert>
            ) : null}

            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    <PaginationProvider
                      pagination={paginationFactory()}
                      keyField='id'
                      columns={(this.state.SupplierColumns || [])}
                      data={(suppliers || [])}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="id"
                          data={suppliers}
                          columns={(this.state.SupplierColumns || [])}
                          bootstrap4
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>
                              <Row className="mb-2" class="noPrint">
                                <Col sm="4">
                                  <div className="search-box me-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar
                                        {...toolkitProps.searchProps}
                                      />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </Col>

                                <Col sm="8" >

                                  <div className="text-sm-end" >
                                    <Button
                                      type="button"
                                      color="success"
                                      className="btn-rounded mb-2 me-2"
                                      onClick={this.handleSupplierClicks}
                                    >
                                      <i className="mdi mdi-plus me-1" />{" "}
                                      {this.props.t("New Workshop")}
                                    </Button>

                                    <Link to="#"

                                    >

                                      {/*  <i className="mdi mdi-file-export-outline font-size-18" /> */}
                                      <td>
                                        <UncontrolledDropdown>
                                          <DropdownToggle href="#" className="card-drop" tag="a">
                                            <i className="mdi mdi-dots-vertical font-size-18" />
                                          </DropdownToggle>
                                          <DropdownMenu className="dropdown-menu-end">
                                            <DropdownItem href="#" onClick={() => this.state.suppliers.length == 0 ? this.setState({ no_data: true }) : exportPDF()}>
                                              <i className="mdi mdi-file-pdf-box font-size-16 text-danger me-1" />{" "}
                                              {this.props.t("Export PDF")}

                                            </DropdownItem>
                                            <DropdownItem href="#" onClick={() => this.state.suppliers.length == 0 ? this.setState({ no_data: true }) : ExportToExcel()} >

                                              <i className="mdi mdi-microsoft-excel font-size-16 text-success me-1" />{" "}
                                              {this.props.t("Export Excel")}
                                            </DropdownItem>
                                            <DropdownItem href="#" onClick={() => this.state.suppliers.length == 0 ? this.setState({ no_data: true }) : printPDF()}>
                                              <i className="mdi mdi-printer font-size-16 text-grey me-1" />{" "}
                                              {this.props.t("Print")}
                                            </DropdownItem>
                                          </DropdownMenu>
                                        </UncontrolledDropdown>

                                      </td>
                                    </Link>
                                  </div>
                                </Col>
                              </Row>
                              <div className="table-responsive">
                                <BootstrapTable

                                  {...toolkitProps.baseProps}
                                  {...paginationTableProps}
                                  responsive
                                  defaultSorted={defaultSorted}
                                  bordered={false}
                                  striped={false}
                                  noDataIndication={emptyDataMessage}
                                  classes={
                                    "table align-middle table-nowrap table-check"
                                  }
                                  headerWrapperClasses={"table-light"}
                                />
                                <Modal
                                  isOpen={this.state.modal}
                                  className={this.props.className}
                                >
                                  <ModalHeader toggle={this.toggle} tag="h4">
                                    {!!isEdit ? this.props.t("Edit Workshop infos") : null}
                                    {!!this.state.isShow ? this.props.t("Workshop Infos") : null}
                                    {!isEdit && !this.state.isShow ? this.props.t("Add Workshop") : null}
                                  </ModalHeader>
                                  <ModalBody>
                                    {this.state.submitLoading ?
                                      <Col>
                                        <div>
                                          <div >
                                            <div className="spinner-chase">
                                              <div className="chase-dot"></div>
                                              <div className="chase-dot"></div>
                                              <div className="chase-dot"></div>
                                              <div className="chase-dot"></div>
                                              <div className="chase-dot"></div>
                                              <div className="chase-dot"></div>
                                            </div>
                                          </div>
                                        </div>
                                        <br />
                                        <h6 className='text-center'>{this.props.t("Uploading data ...")}</h6>
                                      </Col>
                                      :
                                      <AvForm
                                        onValidSubmit={
                                          this.handleValidSupplierSubmit
                                        }
                                        disabled={this.state.isShow ? true : false}
                                      >
                                        <Row>
                                          <Col md="12">
                                            <FormGroup className="mb-3">
                                              <Label htmlFor="validationCustom01">
                                                {this.props.t("Workshop name")}
                                              </Label>
                                              <AvField
                                                name="workshop_name"
                                                placeholder={this.props.t("Workshop name")}
                                                type="text"
                                                errorMessage={this.props.t("Enter Workshop name")}
                                                className="form-control"
                                                validate={{ required: { value: true } }}
                                                id="validationCustom01"
                                                value={this.state.supplier.workshop_name || ""}

                                              />
                                            </FormGroup>
                                          </Col>

                                        </Row>
                                        <Row>
                                          <Col md="6">

                                            <FormGroup className="mb-3">
                                              <Label htmlFor="validationCustom03">
                                                {this.props.t("E-mail")}
                                              </Label>
                                              <AvField
                                                name="email"
                                                placeholder={this.props.t("E-mail")}
                                                type="text"
                                                errorMessage={this.props.t("Enter a valid E-mail")}
                                                className="form-control"
                                                validate={{
                                                  /* required: { value: true }, */
                                                  email: { value: true },
                                                }}
                                                id="validationCustom03"
                                                value={this.state.supplier.email || ""}
                                              />
                                            </FormGroup>
                                          </Col>
                                          <Col md="6">
                                            <FormGroup className="mb-3">
                                              <Label htmlFor="validationCustom04">
                                                {this.props.t("Fax")}
                                              </Label>
                                              <AvField
                                                name="fax"
                                                placeholder={this.props.t("Fax")}
                                                type="text"
                                                id="validationCustom04"
                                                value={this.state.supplier.fax || ""}
                                                validate={{

                                                  number: true
                                                }}
                                              />
                                            </FormGroup>
                                          </Col>
                                        </Row>
                                      
                                        <Row>
                                          <Col md="6">
                                            <FormGroup className="mb-3">
                                              <Label htmlFor="validationCustom04">
                                                {this.props.t("Phone number")}
                                              </Label>
                                              <AvField
                                                name="phone_1"
                                                placeholder={this.props.t("Phone number")}
                                                type="text"
                                                errorMessage={this.props.t("Enter a valid phone number")}
                                                className="form-control"

                                                validate={{
                                                  required: { value: true },
                                                  number: true
                                                }}
                                                id="validationCustom04"
                                                value={this.state.supplier.phone_1 || ""}
                                              />
                                            </FormGroup>
                                          </Col>
                                          <Col md="6 ">
                                            <FormGroup className="mb-3">
                                              <Label >
                                                {this.props.t("Phone number 2")}
                                              </Label>
                                              <AvField
                                                name="phone_2"
                                                placeholder={this.props.t("Phone number 2")}
                                                type="text"
                                                value={this.state.supplier.phone_2 || ""}
                                                validate={{

                                                  number: true
                                                }}
                                              />
                                            </FormGroup>
                                          </Col>

                                        </Row>
                                        <Row>
                                          <Col md="12">
                                            <FormGroup className="mb-3">
                                              <Label >
                                                {this.props.t("Website")}
                                              </Label>
                                              <AvField
                                                name="website"
                                                placeholder={this.props.t("Website")}
                                                type="text"
                                                value={this.state.supplier.website || ""}
                                              />
                                            </FormGroup>
                                          </Col>


                                        </Row>
                                        <Row>
                                          <Col md="12">
                                            <FormGroup className="mb-3">
                                              <AvField
                                                name="address"
                                                label={this.props.t("Address")}
                                                type="textarea"
                                                errorMessage={this.props.t("Invalid Address")}
                                                rows="2"
                                                placeholder={this.props.t("Address")}
                                                value={this.state.supplier.address || ""}
                                              />
                                            </FormGroup>
                                          </Col>

                                        </Row>

                                        <Row>
                                          <Col md="6">
                                            <FormGroup className="mb-3">
                                              <AvField
                                                name="city"
                                                label={this.props.t("City")}
                                                type="text"
                                                errorMessage={this.props.t("Invalid City")}
                                                placeholder={this.props.t("City")}
                                                value={this.state.supplier.city || ""}
                                              />
                                            </FormGroup>
                                          </Col>
                                          <Col md="6">
                                            <FormGroup className="mb-3">
                                              <AvField
                                                name="state"
                                                label={this.props.t("State")}
                                                type="text"
                                                errorMessage={this.props.t("Invalid State")}
                                                placeholder={this.props.t("State")}
                                                value={this.state.supplier.state || ""}
                                              />
                                            </FormGroup>
                                          </Col>
                                        </Row>

                                        <Row>
                                          <Col md="12">
                                            <FormGroup className="mb-3">
                                              <AvField
                                                name="other"
                                                label={this.props.t("Other")}
                                                type="textarea"
                                                rows="1"
                                                value={this.state.supplier.other || ""}
                                              />
                                            </FormGroup>
                                          </Col>

                                        </Row>


                                        {this.state.isShow ? null :
                                          <Row>
                                            <Col>
                                              <div className="text-end">

                                                <button
                                                  type="submit"
                                                  className="btn btn-success save-user"
                                                >
                                                  {this.props.t("Save")}
                                                </button>
                                              </div>
                                            </Col>
                                          </Row>
                                        }

                                      </AvForm>
                                    }
                                  </ModalBody>
                                </Modal>


                                {this.state.isDelete ? (
                                  <SweetAlert
                                    title={this.props.t("Are you sure?")}
                                    warning
                                    showCancel
                                    confirmButtonText={this.props.t("Yes, delete it!")}
                                    confirmBtnBsStyle="success"
                                    cancelBtnBsStyle="danger"
                                    onConfirm={() =>
                                      this.handleDeleteSupplier(vc)
                                    }
                                    onCancel={() =>
                                      this.setState({
                                        isDelete: false,
                                      })
                                    }
                                  >
                                    {this.props.t("You won't be able to revert this!")}
                                  </SweetAlert>
                                ) : null}
                                {this.state.no_data ? (
                                  <SweetAlert
                                    title={this.props.t("No data!")}
                                    warning
                                    onConfirm={() => this.setState({ no_data: false })}
                                  >
                                    {this.props.t("There is no data to export")}
                                  </SweetAlert>
                                ) : null}

                              </div>

                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        <Toaster
          position="top-right"
          reverseOrder={false}
        />
      </React.Fragment>
    )
  }
}

Suppliers.propTypes = {
  suppliers: PropTypes.array,
  onGetSupplier: PropTypes.func,
  onAddNewSupplier: PropTypes.func,
  onDeleteSupplier: PropTypes.func,
  onUpdateSupplier: PropTypes.func,
  className: PropTypes.any,
  t: PropTypes.any,
}

const mapStateToProps = state => ({
  suppliers: state.supplier.suppliers
})

const mapDispatchToProps = dispatch => ({
  onGetSupplier: () => dispatch(getSupplier()),
  onAddNewSupplier: supplier => dispatch(addNewSupplier(supplier)),
  onUpdateSupplier: supplier => dispatch(updateSupplier(supplier)),
  onDeleteSupplier: supplier => dispatch(deleteSupplier(supplier)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(Suppliers)))
