import { call, put, takeEvery } from "redux-saga/effects"

// Ecommerce Redux States
import {
  
  GET_TARIFF,
  ADD_NEW_TARIFF,
  DELETE_TARIFF,
  UPDATE_TARIFF,
  
} from "./actionTypes"
import {
  
  getTariffFail,
  getTariffSuccess,
  addTariffFail,
  addTariffSuccess,
  updateTariffSuccess,
  updateTariffFail,
  deleteTariffSuccess,
  deleteTariffFail,
  
} from "./actions"

//Include Both Helper File with needed methods
import {
 
  getTariff,
  addNewTariff,
  updateTariff,
  deleteTariff,
  
} from "helpers/fakebackend_helper"


function* fetchTariff() {
  try {
    const response = yield call(getTariff)
    yield put(getTariffSuccess(response))
  } catch (error) {
    yield put(getTariffFail(error))
  }
}

function* onAddNewTariff({ payload: tariff }) {
  try {
    const response = yield call(addNewTariff, tariff)
    yield put(addTariffSuccess(response))
  } catch (error) {

    yield put(addTariffFail(error))
  }
}

function* onUpdateTariff({ payload: tariff }) {
  try {
    const response = yield call(updateTariff, tariff)
    yield put(updateTariffSuccess(response))
  } catch (error) {
    yield put(updateTariffFail(error))
  }
}

function* onDeleteTariff({ payload: tariff }) {
  try {
    const response = yield call(deleteTariff, tariff)
    yield put(deleteTariffSuccess(response))
  } catch (error) {
    yield put(deleteTariffFail(error))
  }
}

function* tariffSaga() {
  
  yield takeEvery(GET_TARIFF, fetchTariff)
  yield takeEvery(ADD_NEW_TARIFF, onAddNewTariff)
  yield takeEvery(UPDATE_TARIFF, onUpdateTariff)
  yield takeEvery(DELETE_TARIFF, onDeleteTariff)
  
}

export default tariffSaga
