
/* STAFF */
export const GET_STAFF = "GET_STAFF"
export const GET_STAFF_SUCCESS = "GET_STAFF_SUCCESS"
export const GET_STAFF_FAIL = "GET_STAFF_FAIL"

export const ADD_NEW_STAFF = "ADD_NEW_STAFF"
export const ADD_STAFF_SUCCESS = "ADD_STAFF_SUCCESS"
export const ADD_STAFF_FAIL = "ADD_STAFF_FAIL"

export const UPDATE_STAFF = "UPDATE_STAFF"
export const UPDATE_STAFF_SUCCESS = "UPDATE_STAFF_SUCCESS"
export const UPDATE_STAFF_FAIL = "UPDATE_STAFF_FAIL"

export const DELETE_STAFF = "DELETE_STAFF"
export const DELETE_STAFF_SUCCESS = "DELETE_STAFF_SUCCESS"
export const DELETE_STAFF_FAIL = "DELETE_STAFF_FAIL"

