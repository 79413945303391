
/* VEHICLE */
export const GET_VEHICLE = "GET_VEHICLE"
export const GET_VEHICLE_SUCCESS = "GET_VEHICLE_SUCCESS"
export const GET_VEHICLE_FAIL = "GET_VEHICLE_FAIL"

export const ADD_NEW_VEHICLE = "ADD_NEW_VEHICLE"
export const ADD_VEHICLE_SUCCESS = "ADD_VEHICLE_SUCCESS"
export const ADD_VEHICLE_FAIL = "ADD_VEHICLE_FAIL"

export const UPDATE_VEHICLE = "UPDATE_VEHICLE"
export const UPDATE_VEHICLE_SUCCESS = "UPDATE_VEHICLE_SUCCESS"
export const UPDATE_VEHICLE_FAIL = "UPDATE_VEHICLE_FAIL"

export const DELETE_VEHICLE = "DELETE_VEHICLE"
export const DELETE_VEHICLE_SUCCESS = "DELETE_VEHICLE_SUCCESS"
export const DELETE_VEHICLE_FAIL = "DELETE_VEHICLE_FAIL"

