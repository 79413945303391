
/* TARIFF */
export const GET_TARIFF = "GET_TARIFF"
export const GET_TARIFF_SUCCESS = "GET_TARIFF_SUCCESS"
export const GET_TARIFF_FAIL = "GET_TARIFF_FAIL"

export const ADD_NEW_TARIFF = "ADD_NEW_TARIFF"
export const ADD_TARIFF_SUCCESS = "ADD_TARIFF_SUCCESS"
export const ADD_TARIFF_FAIL = "ADD_TARIFF_FAIL"

export const UPDATE_TARIFF = "UPDATE_TARIFF"
export const UPDATE_TARIFF_SUCCESS = "UPDATE_TARIFF_SUCCESS"
export const UPDATE_TARIFF_FAIL = "UPDATE_TARIFF_FAIL"

export const DELETE_TARIFF = "DELETE_TARIFF"
export const DELETE_TARIFF_SUCCESS = "DELETE_TARIFF_SUCCESS"
export const DELETE_TARIFF_FAIL = "DELETE_TARIFF_FAIL"


