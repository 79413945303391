import React, { Component } from "react"
import ReactApexChart from "react-apexcharts"
import "./dashboard.scss"


import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import "firebase/database"
import "firebase/storage"

import * as moment from 'moment';

class ApexRadial extends Component {
  constructor(props) {
    super(props)

    this.state = {
      r:[],
      rev: 0,
      d: 0,
      v: 0,
      r_days: 0,
      n_veh: 0,
      chart: {
        height: 200,
        type: 'radialBar',
        offsetY: -10
      },
      options: {
        plotOptions: {
          radialBar: {
            startAngle: -135,
            endAngle: 135,
            dataLabels: {
              name: {
                fontSize: "13px",
                color: void 0,
                offsetY: 60,
              },
              value: {
                offsetY: 22,
                fontSize: "16px",
                color: void 0,
                formatter: function (e) {
                  return e + "%"
                },
              },
            },
          },
        },

        colors: ["#556ee6"],
        fill: {
          type: "gradient",
          gradient: {
            shade: "dark",
            shadeIntensity: 0.15,
            inverseColors: !1,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 50, 65, 91],
          },
        },
        stroke: {
          dashArray: 4,
        },
        labels: ["Rendement"],
      },
      series: [67],
    }
  }
  componentDidMount() {


    this.getReservation()
    this.getVehicle()

    //const { onGetChartsData } = this.props
    //change subscribe modal to true in order to show subscription to email
    //setTimeout(() => this.setState({ subscribemodal: false }), 2000);
    //onGetChartsData("yearly");
  }

  getReservation = async () => {
    this.setState({ loading: true });

    // Unsubscribe previous listeners to prevent memory leaks
    if (this.unsubscribeReservations) {
      this.unsubscribeReservations();
    }

    this.unsubscribeReservations = firebase.auth().onAuthStateChanged(async user => {
      if (user) {
        const userId = user.uid;
        const usersCollection = firebase.firestore().collection("users")
        const userDocRef = usersCollection.doc(userId);

        try {
          const userDoc = await userDocRef.get();
          if (userDoc.exists) {
            const userId = userDoc.data().id;
            const userReservationsCollectionRef = usersCollection.doc(userId).collection("reservations");

            this.unsubscribeReservations = userReservationsCollectionRef.onSnapshot(snapshot => {
              var res=[];
              snapshot.docs.forEach(e => {
                res.push(e.data())
              });
              
              this.setState({
                r:res
              })
            });

            localStorage.setItem("authUser", JSON.stringify(user));
          } else {
            // User document not found
            localStorage.removeItem("authUser");
            this.setState({
              loading: false,
              res: []
            });
          }
        } catch (error) {
          console.error("Error fetching user document:", error);
          // Handle error
        }
      } else {
        localStorage.removeItem("authUser");
        // Clear the reservations if user is not authenticated
        this.setState({
          loading: false,
          res: []
        });
      }
    });
  }
  getVehicle = async () => {

    this.setState({ loading: true });

    // Unsubscribe previous listeners to prevent memory leaks
    if (this.unsubscribeVehicles) {
      this.unsubscribeVehicles();
    }

    this.unsubscribeVehicles = firebase.auth().onAuthStateChanged(async user => {
      if (user) {
        const userId = user.uid;
        const usersCollection = firebase.firestore().collection("users")
        const userDocRef = usersCollection.doc(userId);

        try {
          const userDoc = await userDocRef.get();
          if (userDoc.exists) {
            const userId = userDoc.data().id;
            const userVehiclesCollectionRef = usersCollection.doc(userId).collection("vehicles");

            this.unsubscribeVehicles = userVehiclesCollectionRef.onSnapshot(snapshot => {
              this.setState({
                n_veh: snapshot.docs.length
              })
            });

            localStorage.setItem("authUser", JSON.stringify(user));
          } else {
            // User document not found
            localStorage.removeItem("authUser");
            this.setState({
              loading: false,
              n_veh: 0
            });
          }
        } catch (error) {
          console.error("Error fetching user document:", error);
          // Handle error
        }
      } else {
        localStorage.removeItem("authUser");
        // Clear the reservations if user is not authenticated
        this.setState({
          loading: false,
          n_veh: 0
        });
      }
    });


  }
  render() {
    const { r, n_veh, r_days } = this.state
    
    const today = new Date();
    const currentMonth = today.getMonth();
    const currentYear = today.getFullYear();
    const lastDayOfMonth = new Date(currentYear, currentMonth + 1, 0).getDate();
    const maxDaysUntilToday = today.getDate();
    const reservations = r
  
    const reservationsByDay = {};
    var count=0
    for (let day = 1; day <= maxDaysUntilToday; day++) {
      const currentDate = new Date(currentYear, currentMonth, day);
      const dateString = currentDate.toISOString().split('T')[0];
      const reservationsForDay = reservations.filter(reservation =>
        reservation.start.startsWith(dateString) || 
        (new Date(reservation.start) <= currentDate && new Date(reservation.end) >= currentDate)
      );
        count=count+reservationsForDay.length
      reservationsByDay[dateString] = reservationsForDay.length;
    }
  
    //console.log(count) ;

    var currentDate = moment(); // This will create a moment object representing the current date
    var daysInCurrentMonth = currentDate.daysInMonth();
    var total = n_veh * maxDaysUntilToday

    //console.log(maxDaysUntilToday,total, count)
    var percentage = Math.floor((count * 100) / total)
    var serie = []
    serie.push(percentage)
   //console.log(count,total)

    return (
      <React.Fragment>
        <ReactApexChart
          options={this.state.options}
          series={serie}
          type="radialBar"
          height="200"
          className='apex-charts'
        />
      </React.Fragment>
    )
  }
}
export default ApexRadial