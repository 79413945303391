import { call, put, takeEvery } from "redux-saga/effects"

// Ecommerce Redux States
import {
  
  GET_RESERVATION,
  ADD_NEW_RESERVATION,
  DELETE_RESERVATION,
  UPDATE_RESERVATION,
  
} from "./actionTypes"
import {
  
  getReservationFail,
  getReservationSuccess,
  addReservationFail,
  addReservationSuccess,
  updateReservationSuccess,
  updateReservationFail,
  deleteReservationSuccess,
  deleteReservationFail,
  
} from "./actions"

//Include Both Helper File with needed methods
import {
 
  getReservation,
  addNewReservation,
  updateReservation,
  deleteReservation,
  
} from "helpers/fakebackend_helper"


function* fetchReservation() {
  try {
    const response = yield call(getReservation)
    yield put(getReservationSuccess(response))
  } catch (error) {
    yield put(getReservationFail(error))
  }
}

function* onAddNewReservation({ payload: reservation }) {
  try {
    const response = yield call(addNewReservation, reservation)
    yield put(addReservationSuccess(response))
  } catch (error) {

    yield put(addReservationFail(error))
  }
}

function* onUpdateReservation({ payload: reservation }) {
  try {
    const response = yield call(updateReservation, reservation)
    yield put(updateReservationSuccess(response))
  } catch (error) {
    yield put(updateReservationFail(error))
  }
}

function* onDeleteReservation({ payload: reservation }) {
  try {
    const response = yield call(deleteReservation, reservation)
    yield put(deleteReservationSuccess(response))
  } catch (error) {
    yield put(deleteReservationFail(error))
  }
}

function* reservationSaga() {
  
  yield takeEvery(GET_RESERVATION, fetchReservation)
  yield takeEvery(ADD_NEW_RESERVATION, onAddNewReservation)
  yield takeEvery(UPDATE_RESERVATION, onUpdateReservation)
  yield takeEvery(DELETE_RESERVATION, onDeleteReservation)
  
}

export default reservationSaga
