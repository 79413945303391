import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import MetaTags from 'react-meta-tags';
import { withRouter } from "react-router-dom"
import { countBy, isEmpty, size } from "lodash"
import BootstrapTable from "react-bootstrap-table-next"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import Dropzone from "react-dropzone"
import { withTranslation } from "react-i18next"
import maintanence from "../../../assets/images/coming-soon.svg"
import toastr from "toastr";
import toast, { Toaster } from 'react-hot-toast';


import
paginationFactory, {
    PaginationProvider,
    PaginationListStandalone,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import { Link } from "react-router-dom"
import * as moment from 'moment';
import Select from "react-select"
import jsPDF from "jspdf";
import "jspdf-autotable";
import Moment from 'moment';
import { InputGroupAddon, UncontrolledDropdown, DropdownItem, DropdownMenu, DropdownToggle, Form, InputGroup, Input, FormGroup, Label, Button, Card, CardBody, Col, Table, ModalFooter, Container, Row, Modal, Badge, ModalHeader, ModalBody } from "reactstrap"

import TodayPickups from "../../Dashboard/Today_pickups"
import TodayReturns from "../../Dashboard/Today_returns"

// Add the Firebase products that you want to use
import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import "firebase/database"
import "firebase/storage"

import { AvForm, AvField } from "availity-reactstrap-validation"
import SweetAlert from "react-bootstrap-sweetalert"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import {
    getReservation,
    addNewReservation,
    updateReservation,
    deleteReservation,
    getVehicle,
    getIndie,
    getTariff,
    updateVehicle
} from "store/actions"


import exportFromJSON from 'export-from-json'
import { isThisExpression, traverseFast } from "@babel/types";
import { vehicle12 } from "assets/images/vehicle";
import { quotes, tariffs } from "common/data";
import Tariff from "store/rental/reducer";

class Quotes extends Component {
    constructor(props) {
        super(props)
        this.state = {
            user_id: "",
            modal1: false,
            selectedPeriod: "day",
            selectedVehicleName: "All Vehicles",
            selectedVehicle1: 1,
            selectedDate: "",
            selectedTS: null,
            selectedOption: "todo",
            currentYear: new Date().getFullYear(),
            now: "",
            loading: true,
            submitLoading: false,
            isShow: false,
            changed: false,
            vcchanged: false,
            clchanged: false,
            trfchanged: false,
            pdchanged: false,
            rdchanged: false,
            gender: "",
            viewmodal: false,
            modal: false,
            isDelete: false,
            no_data: false,
            totalAmount: 0,
            p: false,
            success_dlg: false,
            dynamic_title: '',
            dynamic_description: '',
            vc: "",
            mile: 0,
            selectedType: null,
            selectedVehicle: null,
            selectedClient: null,
            selectedTariff: null,
            PD: new Date(),
            RD: new Date().setDate(new Date().getDate() + 1),
            quotes: [],
            todaysP: [],
            todaysR: [],
            quote: [],
            vehicles: [],
            indies: [],
            tariffs: [],
            repairs: [],
            vehList: [],
            date_birth: new Date(),
            QuoteColumns: [

                {
                    dataField: "code",
                    text: this.props.t("Code"),
                    sort: true,
                    formatter: (cellContent, row) => (
                        <Link to="#" className="text-body fw-bold">

                            {row.code}
                        </Link>
                    ),
                }, {
                    dataField: "createdAt",
                    text: this.props.t("Date created"),
                    sort: true,
                    formatter: (cellContent, row) => (
                        <>

                            {" "}
                            {moment(row.createdAt).format('YYYY-MM-DDTHH:mm')}
                        </>
                    ),
                },
                {
                    dataField: "client",
                    text: this.props.t("Client"),
                    sort: true,

                },
                {
                    dataField: "vehicle",
                    text: this.props.t("Vehicle"),
                    sort: true,
                },

                {
                    dataField: "days",
                    text: this.props.t("Days"),
                    sort: true,

                },

                {
                    dataField: "total_amount",
                    text: this.props.t("Total amount"),
                    sort: true,
                    formatter: (cellContent, row) => (
                        <>

                            {" "}
                            {row.total_amount} dh
                        </>
                    ),
                },

                {
                    dataField: "view",
                    isDummyField: true,
                    text: this.props.t("View Details"),
                    sort: true,
                    formatter: (cellContent, quote) => (
                        <Button
                            type="button"
                            color="primary"
                            className="btn-sm btn-rounded"
                            onClick={() => this.handleViewClick(quote)}
                        >
                            {this.props.t("View Details")}
                        </Button>
                    ),
                },
                {
                    dataField: "action",
                    isDummyField: true,
                    text: this.props.t("Action"),
                    formatter: (cellContent, quote) => (
                        <>
                            {quote.status === "canceled" ? <div className="d-flex gap-3">
                                <Link to="#" className="text-danger">
                                    <i className="mdi mdi-delete font-size-18" id="deletetooltip" onClick={() => this.setState({ isDelete: true, vc: quote })} />
                                </Link>
                            </div> : <div className="d-flex gap-3">

                                <Link to="#" className="text-success">
                                    <i className="mdi mdi-pencil font-size-18" id="edittooltip" onClick={() => this.handleQuoteClick(quote)} />
                                </Link>
                                <Link to="#" className="text-danger">
                                    <i className="mdi mdi-delete font-size-18" id="deletetooltip" onClick={() => this.setState({ isDelete: true, vc: quote })} />
                                </Link>
                                <Link to={"/quote/"+quote.id} className="text-primary">
                                    <i className="mdi mdi-printer font-size-18" id="deletetooltip"  />
                                </Link>
                                {/*  <Link to="#"

                >

                  <td>
                    <UncontrolledDropdown>
                      <DropdownToggle href="#" className="card-drop" tag="a">
                        <i className="mdi mdi-file-send-outline font-size-18" />
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-menu-end">
                        <DropdownItem href="#" >
                          <Link to={"/print-contract/" + quote.id} target="_blank" rel="noopener noreferrer" className="text-dark ">
                            <i className="bx bx-edit-alt font-size-18 me-2" id="deletetooltip" />
                            {this.props.t("Contract")}
                          </Link>
                        </DropdownItem>
                        <DropdownItem href="#" >
                        <Link to={"/documents/" + quote.id} className="text-dark">
                          <i className="bx bx-receipt  font-size-18  me-2" id="deletetooltip" />
                          {this.props.t("Invoice")}
                        </Link>
                        </DropdownItem>
                        


                      </DropdownMenu>
                    </UncontrolledDropdown>

                  </td>
                </Link> */}


                            </div>}

                        </>
                    ),
                },
            ],
            selectedFiles: []
        }

        this.handleQuoteClick = this.handleQuoteClick.bind(this)
        this.toggle = this.toggle.bind(this)
        this.handleValidQuoteSubmit = this.handleValidQuoteSubmit.bind(this)
        this.handleQuoteClicks = this.handleQuoteClicks.bind(this)
        this.toLowerCase1 = this.toLowerCase1.bind(this)
        this.toggleDelete = this.toggleDelete.bind(this)
        this.handleViewClick = this.handleViewClick.bind(this)
        this.handleSelectType = this.handleSelectType.bind(this)
        this.handleSelectVehicle = this.handleSelectVehicle.bind(this)
        this.handleSelectClient = this.handleSelectClient.bind(this)
        this.handleSelectTariff = this.handleSelectTariff.bind(this)
        this.handlePDChange = this.handlePDChange.bind(this)
        this.handleRDChange = this.handleRDChange.bind(this)
        this.handleAcceptedFiles = this.handleAcceptedFiles.bind(this)
        this.toggleIsShow = this.toggleIsShow.bind(this)
        this.getAmount = this.getAmount.bind(this)
        this.toggle_modal = this.toggle_modal.bind(this)
        this.onValueChange = this.onValueChange.bind(this)
        this.handleValidEventSubmit = this.handleValidEventSubmit.bind(this)
        this.handleSelectVehicleFilter = this.handleSelectVehicleFilter.bind(this)
    }
    onValueChange(event) {
        const { notifs } = this.state
        this.setState({
            selectedOption: event.target.id
        });
        ////console.log(event.target.id)

    }
    dateChanged = d => {
        /*  const { vehicles, rdchanged, RD } = this.state
         this.setState({ PD: d.target.value })*/
        this.setState({
            selectedTS: moment(d.target.value, 'YYYY-MM-DD').valueOf(),
            selectedDate: d.target.value,
            selectedOption: "dia"
        })

        //console.log(d.target.value)
    }
    monthChanged = d => {

        this.setState({
            selectedTS: moment(d.target.value, 'YYYY-MM').valueOf(),
            selectedDate: d.target.value,
            selectedOption: "mes"
        })

        //console.log(d.target.value)
    }
    yearChanged = (event) => {
        const selectedYear = event.target.value;
        console.log(selectedYear)
        this.setState({
            selectedTS: moment(event.target.value, 'YYYY').valueOf(),
            selectedDate: event.target.value,
            selectedOption: "ano"
        })

        //console.log(d.target.value)
    }
    toggle_modal() {
        this.setState(prevState => ({
            modal1: !prevState.modal1,
        }))
        this.removeBodyCss()
    }

    removeBodyCss() {
        document.body.classList.add("no_padding")
    }

    handlePeriodChange = (e) => {
        this.setState({
            selectedPeriod: e.target.value
        })
        //console.log(e.target.value);
    };
    handleValidEventSubmit = (e, values) => {

        //console.log(values)
        if (values.period === "day") {
            this.setState({
                selectedTS: moment(values.date, 'YYYY-MM-DD').valueOf(),
                selectedOption: "dia",
                selectedDate: values.date,
            })
        }
        if (values.period === "month") {
            this.setState({
                selectedTS: moment(values.month, 'YYYY-MM').valueOf(),
                selectedOption: "mes",
                selectedDate: values.month,
            })
        }
        if (values.period === "year") {
            this.setState({
                selectedTS: moment(values.year, 'YYYY').valueOf(),
                selectedOption: "ano",
                selectedDate: values.year,
            })
        }



        this.toggle_modal()
    }

    handleAcceptedFiles = files => {
        files.map(file =>
            Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: this.formatBytes(file.size),
            })
        )

        this.setState({ selectedFiles: files })
    }

    /**
     * Formats the size
     */
    formatBytes = (bytes, decimals = 2) => {
        if (bytes === 0) return "0 Bytes"
        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

        const i = Math.floor(Math.log(bytes) / Math.log(k))
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
    }

    toLowerCase1(str) {
        return str.toLowerCase();
    }
    checkChange = p => {
        this.setState({ p: p.target.checked })
    }
    handlePDChange = PD => {
        const { vehicles, rdchanged, RD } = this.state
        this.setState({ PD: PD.target.value })
        this.setState({ pdchanged: true })
        /* //console.log("pdchanged")
        //console.log("pd : " + this.state.PD)
        //console.log("rd : " + this.state.RD) */
        //  //console.log("PD changed " + PD.target.value)
        // veh=[]


        //this.setState({ vehList: tmp })
    }
    handleRDChange = RD => {
        const { vehicles, pdchanged, PD } = this.state
        this.setState({ RD: RD.target.value })
        this.setState({ rdchanged: true })
        /* //console.log("pd : " + this.state.PD)
        //console.log("rd : " + this.state.RD) */
        //  this.setState({ vehList: tmp })
    }


    handleSelectType = selectedType => {

        this.setState({ selectedType: selectedType.value })
        this.setState({ changed: true })
    }
    handleSelectTariff = (selectedTariff, value) => {

        this.setState({ selectedTariff: value })
        this.setState({ trfchanged: true })

    }
    getAmount() {
        let oss = this.calculateDays(this.state.PD, this.state.RD)
        /*  //console.log(this.state.PD)
         //console.log(this.state.RD)
         //console.log(oss) */

        let kra2 = ""
        /*  //console.log(this.state.trfchanged)
         //console.log(this.state.selectedTariff) */
        if (this.state.selectedTariff !== null) {
            if (this.state.trfchanged) {
                kra2 = this.state.selectedTariff
                // let trfid = kra2.substring(kra2.indexOf("(") + 1, kra2.indexOf(")"))
                this.calculateAmount(kra2, oss)
            }
            else {
                kra2 = this.state.selectedTariff.value
                this.calculateAmount(kra2, oss)
            }
        }
        ////console.log(kra2)

        ////console.log(trfid)
        // this.calculateAmount(trfid, oss)


    }
    calculateAmount(tariff, days) {

        var total = 0;
        const { tariffs } = this.state
        if (days <= 0) {
            total = 0
        } else {
            tariffs.map(function (item, i) {
                if (item.id == tariff) {
                    if (days < 30) {
                        total = days * item.daily_rate;
                    } else {
                        var month = Math.floor(days / 30) * item.monthly_rate
                        var day = (days % 30) * item.daily_rate
                        total = month + day
                    }
                }

            })
        }

        this.setState({ totalAmount: total })
    }
    calculateDays(date1, date2) {

        var pd = new Date(date2);
        var rd = new Date(date1);
        var pppd = moment(pd, 'DD-MM-YYYY');
        var rrrd = moment(rd, 'DD-MM-YYYY');

        var DD = pppd.diff(rrrd, 'days');
        //   //console.log(DD)
        return DD
    }
    handleSelectVehicleFilter = (selectedVehicle, value) => {
        const { vehicles, indies } = this.state
        /*  let kra = selectedVehicle.target.value
         let plt = kra.substring(kra.indexOf("(") + 1, kra.indexOf(")")) */
        //console.log(value)
        if (value === 'All Clients') {
            this.setState({

                selectedVehicleName: "All Clients",
                selectedVehicle1: 1
            })
        } else {
            const p = indies.find(v => v.id === value);
            var plt = ""
            /*  if (p !== undefined) {
                 plt = p.plate_number
             } */
            //console.log(p)
            let m = 0
            const cl_name = p.first_name + " " + p.last_name
            //const name = p.make + " " + p.model + " ( " + p.plate_number + " )"
            //console.log(name)

            this.setState({
                selectedVehicleName: cl_name,
                selectedVehicle1: value
            })
        }

    }
    handleSelectVehicle = (selectedVehicle, value) => {
        const { vehicles, indies } = this.state
        const p = indies.find(v => v.id === value);
        let m = 0
        
        
    }
    handleSelectClient = selectedClient => {

        this.setState({ selectedClient: selectedClient })
        this.setState({ clchanged: true })
    }
    getDate = () => {
        var now = moment(new Date()).format('YYYY-MM-DDTHH:mm');
        this.setState({ now });
    };
    componentWillUnmount() {

        this.setState({
            loading: null,
            now: null,
            loading: null,
            submitLoading: null,
            isShow: null,
            changed: null,
            vcchanged: null,
            clchanged: null,
            trfchanged: null,
            pdchanged: null,
            rdchanged: null,
            gender: null,
            viewmodal: null,
            modal: null,
            isDelete: null,
            no_data: null,
            totalAmount: null,
            p: null,
            success_dlg: null,
            dynamic_title: null,
            dynamic_description: null,
            vc: null,
            mile: null,
            selectedType: null,
            selectedVehicle: null,
            selectedClient: null,
            selectedTariff: null,
            PD: null,
            RD: null,
            quotes: null,
            todaysP: null,
            todaysR: null,
            quote: null,
            vehicles: null,
            indies: null,
            tariffs: null,
            repairs: null,
            vehList: null,
            date_birth: null,
            QuoteColumns: null,
            selectedFiles: null
        });
    }

    componentDidMount() {

        const { quotes, onGetQuote } = this.state

        if (quotes && !quotes.length) {

            // onGetQuote()
            this.getQuote()
        }
        //this.setState({ quotes })
        const { vehicles, onGetVehicle } = this.state
        if (vehicles && !vehicles.length) {
            //onGetVehicle()
            this.getVehicle()
        }
        this.setState({ vehicles })

        const { indies, onGetIndie } = this.state
        if (indies && !indies.length) {
            //onGetIndie()
            this.getIndie()
        }
        this.setState({ indies })

        const { tariffs, onGetTariff } = this.state
        if (tariffs && !tariffs.length) {
            //onGetTariff()
            this.getTariff()
        }
        this.setState({ tariffs })

        const { repairs } = this.state
        if (repairs && !repairs.length) {
            //onGetTariff()
            this.getRepair()
        }
        this.setState({ repairs })
        // const { onUpdateVehicle } = this.props

    }
    getRepair = async () => {
        this.setState({ loading: true });

        // Unsubscribe previous listeners to prevent memory leaks
        if (this.unsubscribeRepairs) {
            this.unsubscribeRepairs();
        }

        this.unsubscribeRepairs = firebase.auth().onAuthStateChanged(async user => {
            if (user) {
                const userId = user.uid;
                const usersCollection = firebase.firestore().collection("users")
                const userDocRef = usersCollection.doc(userId);

                try {
                    const userDoc = await userDocRef.get();
                    if (userDoc.exists) {
                        const userId = userDoc.data().id;
                        const userRepairsCollectionRef = usersCollection.doc(userId).collection("repairs");

                        this.unsubscribeRepairs = userRepairsCollectionRef.onSnapshot(snapshot => {
                            let kra = []
                            snapshot.docs.forEach(e => {
                                kra.push(e.data())
                            });
                            this.setState({
                                loading: false,
                                repairs: kra,
                            })
                        })

                        localStorage.setItem("authUser", JSON.stringify(user))
                    } else {
                        ////console.log("not user")
                        localStorage.removeItem("authUser")
                    }
                } catch (error) {
                    console.error("Error fetching user document:", error);
                    // Handle error
                }
            }

        })
    }

    getQuote = async () => {
        this.setState({ loading: true });

        // Unsubscribe previous listeners to prevent memory leaks
        if (this.unsubscribeQuotes) {
            this.unsubscribeQuotes();
        }

        this.unsubscribeQuotes = firebase.auth().onAuthStateChanged(async user => {
            if (user) {
                const userId = user.uid;
                const usersCollection = firebase.firestore().collection("users")
                const userDocRef = usersCollection.doc(userId);

                try {
                    const userDoc = await userDocRef.get();
                    if (userDoc.exists) {
                        const userId = userDoc.data().id;
                        const userQuotesCollectionRef = usersCollection.doc(userId).collection("quotes");
                        const CurrentDate = moment().unix();
                        const lyoum = moment.unix(CurrentDate).format("DD/MM/YYYY");
                        this.setState({
                            user_id: userId,
                            account_user: userDoc.data()
                        })
                        this.unsubscribeQuotes = userQuotesCollectionRef.onSnapshot(snapshot => {
                            let quotes = [];
                            let kayninP = [];
                            let kayninR = [];
                            let kkk = "";
                            let kkkk = "";

                            snapshot.docs.forEach(e => {
                                kkk = moment.unix(e.data().pd / 1000).format("DD/MM/YYYY");
                                kkkk = moment.unix(e.data().rd / 1000).format("DD/MM/YYYY");
                                if (kkk === lyoum && e.data().status !== "canceled") {
                                    kayninP.push(e.data());
                                }
                                if (kkkk === lyoum && e.data().status !== "canceled") {
                                    kayninR.push(e.data());
                                }
                                quotes.push(e.data());
                            });
                            this.setState({
                                loading: false,
                                quotes: quotes,
                                todaysP: kayninP,
                                todaysR: kayninR
                            });

                        });

                        localStorage.setItem("authUser", JSON.stringify(user));
                    } else {
                        // User document not found
                        localStorage.removeItem("authUser");
                        this.setState({
                            loading: false,
                            quotes: [],
                            todaysP: [],
                            todaysR: []
                        });
                    }
                } catch (error) {
                    console.error("Error fetching user document:", error);
                    // Handle error
                }
            } else {
                localStorage.removeItem("authUser");
                // Clear the quotes if user is not authenticated
                this.setState({
                    loading: false,
                    quotes: [],
                    todaysP: [],
                    todaysR: []
                });
            }
        });
    }


    getVehicle = async () => {
        this.setState({ loading: true });

        // Unsubscribe previous listeners to prevent memory leaks
        if (this.unsubscribeVehicles) {
            this.unsubscribeVehicles();
        }

        this.unsubscribeVehicles = firebase.auth().onAuthStateChanged(async user => {
            if (user) {
                const userId = user.uid;
                const usersCollection = firebase.firestore().collection("users")
                const userDocRef = usersCollection.doc(userId);

                try {
                    const userDoc = await userDocRef.get();
                    if (userDoc.exists) {
                        const userId = userDoc.data().id;
                        const userVehiclesCollectionRef = usersCollection.doc(userId).collection("vehicles");
                        this.setState({
                            user_id: userId
                        })
                        this.unsubscribeVehicles = userVehiclesCollectionRef.onSnapshot(snapshot => {
                            let vehicles = [];
                            snapshot.docs.forEach(doc => {
                                vehicles.push(doc.data());
                            });

                            this.setState({
                                loading: false,
                                vehicles: vehicles
                            });
                        });

                        localStorage.setItem("authUser", JSON.stringify(user));
                    } else {
                        // User document not found
                        localStorage.removeItem("authUser");
                        this.setState({
                            loading: false,
                            vehicles: []
                        });
                    }
                } catch (error) {
                    console.error("Error fetching user document:", error);
                    // Handle error
                }
            } else {
                localStorage.removeItem("authUser");
                // Clear the quotes if user is not authenticated
                this.setState({
                    loading: false,
                    vehicles: []
                });
            }
        });

    }


    getIndie = async () => {
        this.setState({ loading: true });

        // Unsubscribe previous listeners to prevent memory leaks
        if (this.unsubscribeIndies) {
            this.unsubscribeIndies();
        }

        this.unsubscribeIndies = firebase.auth().onAuthStateChanged(async user => {
            if (user) {
                const userId = user.uid;
                const usersCollection = firebase.firestore().collection("users")
                const userDocRef = usersCollection.doc(userId);

                try {
                    const userDoc = await userDocRef.get();
                    if (userDoc.exists) {
                        const userId = userDoc.data().id;
                        const userIndiesCollectionRef = usersCollection.doc(userId).collection("indies");
                        this.setState({
                            user_id: userId
                        })
                        this.unsubscribeIndies = userIndiesCollectionRef.onSnapshot(snapshot => {
                            let n = [];
                            snapshot.docs.forEach(doc => {
                                n.push(doc.data());
                            });

                            this.setState({
                                loading: false,
                                indies: n
                            });
                        });

                        localStorage.setItem("authUser", JSON.stringify(user));
                    } else {
                        // User document not found
                        localStorage.removeItem("authUser");

                        this.setState({
                            loading: false,
                            indies: []
                        });

                    }
                } catch (error) {
                    console.error("Error fetching user document:", error);
                    // Handle error
                }
            } else {
                localStorage.removeItem("authUser");
                // Clear the quotes if user is not authenticated
                this.setState({
                    loading: false,
                    indies: []
                });
            }
        });

    }


    getTariff = async () => {
        this.setState({ loading: true });

        // Unsubscribe previous listeners to prevent memory leaks
        if (this.unsubscribeTariffs) {
            this.unsubscribeTariffs();
        }

        this.unsubscribeTariffs = firebase.auth().onAuthStateChanged(async user => {
            if (user) {
                const userId = user.uid;
                const usersCollection = firebase.firestore().collection("users")
                const userDocRef = usersCollection.doc(userId);

                try {
                    const userDoc = await userDocRef.get();
                    if (userDoc.exists) {
                        const userId = userDoc.data().id;
                        const userTariffsCollectionRef = usersCollection.doc(userId).collection("tariffs");
                        this.setState({
                            user_id: userId
                        })
                        this.unsubscribeTariffs = userTariffsCollectionRef.onSnapshot(snapshot => {
                            let t = [];
                            snapshot.docs.forEach(doc => {
                                t.push(doc.data());
                            });

                            this.setState({
                                loading: false,
                                tariffs: t
                            });
                        });

                        localStorage.setItem("authUser", JSON.stringify(user));
                    } else {
                        // User document not found
                        localStorage.removeItem("authUser");
                        this.setState({
                            loading: false,
                            tariffs: []
                        });
                    }
                } catch (error) {
                    console.error("Error fetching user document:", error);
                    // Handle error
                }
            } else {
                localStorage.removeItem("authUser");
                // Clear the quotes if user is not authenticated
                this.setState({
                    loading: false,
                    tariffs: []
                });
            }
        });


    }



    // eslint-disable-next-line no-unused-vars
    /* componentDidUpdate(prevProps, prevState, snapshot) {
  
      const { quotes } = this.state
      const { vehicles } = this.state
      const { indies } = this.state
      const { tariffs } = this.state
      const { repairs } = this.state
  
  
  
      if (!isEmpty(quotes) && size(prevProps.quotes) !== size(quotes)) {
        //this.setState({ quotes: {}, isEdit: false })
      }
      if (!isEmpty(vehicles) && size(prevProps.vehicles) !== size(vehicles)) {
        //this.setState({ vehicles: {}, isEdit: false })
      }
      if (!isEmpty(indies) && size(prevProps.indies) !== size(indies)) {
        //this.setState({ indies: {}, isEdit: false })
      }
  
      if (!isEmpty(tariffs) && size(prevProps.tariffs) !== size(tariffs)) {
        //this.setState({ tariffs: {}, isEdit: false })
      }
    } */

    toggle() {
        this.setState(prevState => ({
            modal: !prevState.modal,
        }))
    }
    toggleDelete() {
        this.setState(prevState => ({
            isDelete: !prevState.isDelete,
        }))
    }

    handleQuoteClicks = () => {
        this.setState({ quote: [], rdchanged: false, pdchanged: false, isEdit: false, isShow: false, selectedTariff: null, selectedVehicle: null, selectedClient: null, totalAmount: null, p: false, PD: new Date(), RD: new Date().setDate(new Date().getDate() + 1) })
        const date = new Date()
        const day = date.getDate();
        const month = date.getMonth();
        const year = date.getFullYear();

        const currectDate = new Date();
        const currentHour = currectDate.getHours();
        const currentMin = currectDate.getMinutes();
        const currentSec = currectDate.getSeconds();
        const modifiedDate = new Date(year, month, day, currentHour, currentMin, currentSec);
        const md = moment(modifiedDate).format('YYYY-MM-DDTHH:mm')
        //console.log("handle date click " + md)
        const k = new Date(md).setDate(new Date(md).getDate() + 1)
        //console.log("next date", moment(k).format('YYYY-MM-DDTHH:mm'))
        /* const modifiedData = { ...arg, date: md };
        this.setState({ selectedDay: modifiedData }) */
        this.setState({
            quote: {

                pickup_date: md,
                return_date: moment(k).format('YYYY-MM-DDTHH:mm'),
                start: md,
                end: moment(k).format('YYYY-MM-DDTHH:mm'),
                /* reciept:quote.reciept,
                invoice:quote.invoice */
            },
            PD: md,
            RD: new Date(md).setDate(new Date(md).getDate() + 1),
            isShow: false,
        })

        this.toggle()
    }
    /* handleSearch = (value) => {
      const { quotes } = this.state
      //console.log("dkhel lhad lqlawai")
      var kra=quotes.filter(quote =>
        Object.keys(quote).some(
          key =>
            typeof quote[key] === "string" &&
            quote[key].toLowerCase().includes(value.toLowerCase())
        )
      )
      //console.log(kra)
  }; */
    // eslint-disable-next-line no-unused-vars
    handleTableChange = (type, { page, searchText }) => {
        const { quotes } = this.props
        //console.log("dkhel lhad lqlawai")
        const kra = quotes.filter(quote =>
            Object.keys(quote).some(
                key =>
                    typeof quote[key] === "string" &&
                    quote[key].toLowerCase().includes(searchText.toLowerCase())
            )
        )
        //console.log(kra)
        this.setState({
            quotes: quotes.filter(quote =>
                Object.keys(quote).some(
                    key =>
                        typeof quote[key] === "string" &&
                        quote[key].toLowerCase().includes(searchText.toLowerCase())
                )
            ),
        })
    }

    toggleViewModal = () => {
        this.setState(prevState => ({
            viewmodal: !prevState.viewmodal,
        }))
    }

    toggleIsShow = () => {
        this.setState(prevState => ({
            isShow: !prevState.isShow,
        }))
    }


    /* Insert,Update Delete data */

    handleDeleteQuote = (quote) => {
        const { vehicles, onUpdateVehicle } = this.state


        //const { onDeleteQuote } = this.props

        /* let v_update = []
    
        v_update.map((v, key) => {
          //onUpdateVehicle(v)
          this.updateVehicle(v)
          //console.log(v)
        })
    
        v_update = null */
        this.deleteQuote(quote)
        //toast.success(this.props.t("Item Deleted "))
        this.toggleDelete()
        //this.toggle()
        //}

    }

    handleViewClick = arg => {
        const quote = arg
        this.setState({ selectedVehicle: { label: quote.vehicle, value: quote.plate_number } })
        this.setState({ selectedClient: { label: quote.client, value: quote.client_id } })
        this.setState({ selectedTariff: { label: quote.vehicle_type, value: quote.tariff_id } })
        // //console.log(quote)
        this.setState({
            quote: {
                id: quote.id,
                code: quote.code,
                plate_number: quote.plate_number,
                vehicle: quote.vehicle,
                client: quote.client,
                client_id: quote.client_id,
                pickup_date: quote.pickup_date,
                pd: quote.pd,
                pickup_location: quote.pickup_location,
                return_date: quote.return_date,
                rd: quote.rd,
                return_location: quote.return_location,
                vehicle_type: quote.vehicle_type,
                tariff_id: quote.tariff_id,
                amount: quote.amount,
                total_amount: quote.total_amount,
                notes: quote.notes,
                days: quote.days,
                status: quote.status,
                start: quote.start,
                end: quote.end,
                frais_livraison: quote.frais_livraison,
                frais_reprise: quote.frais_reprise,
                frais_divers: quote.frais_divers,
                caution: quote.caution,
                mileage_pickup: quote.mileage_pickup,
                mileage_return: quote.mileage_return,
                fuel_level_pickup: quote.fuel_level_pickup,
                fuel_level_return: quote.fuel_level_return,
                title: quote.vehicle + " (" + quote.client + ")",

            },

            isShow: true,
            isEdit: false

        })

        //this.toggleIsShow()
        this.toggle()

        //this.toggleViewModal()
    }


    handleQuoteClick = arg => {
        const quote = arg
        //console.log(quote)
        this.setState({ rdchanged: false, pdchanged: false, totalAmount: null, p: quote.paid, PD: quote.pickup_date, RD: quote.return_date })
        this.setState({ selectedVehicle: { label: quote.vehicle, value: quote.plate_number } })
        this.setState({ selectedClient: { label: quote.client, value: quote.client_id } })
        this.setState({ selectedTariff: { label: quote.vehicle_type, value: quote.tariff_id } })
        this.setState({
            quote: {
                id: quote.id,
                code: quote.code,
                plate_number: quote.plate_number,
                vehicle: quote.vehicle,
                client: quote.client,
                client_id: quote.client_id,
                pickup_date: quote.pickup_date,
                pd: quote.pd,
                pickup_location: quote.pickup_location,
                return_date: quote.return_date,
                rd: quote.rd,
                return_location: quote.return_location,
                vehicle_type: quote.vehicle_type,
                tariff_id: quote.tariff_id,
                amount: quote.amount,
                total_amount: quote.total_amount,
                notes: quote.notes,
                days: quote.days,
                status: quote.status,
                start: quote.start,
                end: quote.end,
                frais_livraison: quote.frais_livraison,
                frais_reprise: quote.frais_reprise,
                frais_divers: quote.frais_divers,
                caution: quote.caution,
                mileage_pickup: quote.mileage_pickup,
                mileage_return: quote.mileage_return,
                fuel_level_pickup: quote.fuel_level_pickup,
                fuel_level_return: quote.fuel_level_return,
                title: quote.vehicle + " (" + quote.client + ")"
                /* reciept:quote.reciept,
                invoice:quote.invoice */
            },
            isEdit: true,
            isShow: false,
        })

        this.toggle()
    }

    /**
     * Handling submit Order on Order form
     */
    handleValidQuoteSubmit = (e, values) => {
        const { onAddNewQuote, onUpdateQuote } = this.props
        const { isEdit, quotes, selectedQuote, vehicles } = this.state

        const cds = []

        quotes.forEach(e => {
            var cc = new Date(values.pickup_date)
            var y = cc.getFullYear()
            var year = moment(new Date(e.pickup_date)).year()
            //console.log(y, year)
            if (y === year) {
                cds.push(e.code.substring(0, e.code.indexOf("/")))
            }
        });

        var cd = cds.length == 0 ? 1 : Math.max.apply(null, cds) + 1
        const codens = cd + " / " + moment(new Date(values.pickup_date)).year()
        //console.log(cd)
        // status 

        let stts = ""
        var pd = new Date(values.pickup_date);
        var today = new Date();
        var rd = new Date(values.return_date);
        let ossan = this.calculateDays(values.pickup_date, values.return_date)
        // //console.log("ossan " + ossan)

        if (rd.getTime() > today.getTime() && pd.getTime() > today.getTime()) {
            stts = "Created"
            //  //console.log(stts)
        }
        if (pd.getTime() < today.getTime() && rd.getTime() > today.getTime()) {
            // //console.log("stattus changed ")
            stts = "Rental"
            //   //console.log(stts)
        }
        if (today.getTime() > rd.getTime() && today.getTime() > pd.getTime()) {
            stts = "Done"
            //  //console.log(stts)
        }

        /* let veh = ""
        if (this.state.selectedVehicle != null) {
          veh = this.state.selectedVehicle.label
          if (this.state.vcchanged) {
            veh = this.state.selectedVehicle.label
            this.setState({ vcchanged: false })
          } else {
            veh = this.state.selectedVehicle.label
          }
        } */

        /*  let kra = values.vehicle
         let plt = kra.substring(kra.indexOf("(") + 1, kra.indexOf(")"))
      */
        const p = vehicles.find(v => v.id === values.vehicle);
        var plt = ""
        var vehicle = ""
        if (p !== undefined) {
            plt = p.plate_number
            vehicle = p.make + " (" + p.plate_number + ")"
        }
        let kra2 = values.vehicle_type
        let trfid = kra2.substring(kra2.indexOf("(") + 1, kra2.indexOf(")"))
        ////console.log(trfid)
        /* if (this.state.selectedVehicle != null) {
          plt = this.state.selectedVehicle.value
          if (this.state.vcchanged) {
            plt = this.state.selectedVehicle.value
            this.setState({ vcchanged: false })
          } else {
            plt = this.state.selectedVehicle.value
          }
    
        } */

        /* let cl = ""
        if (this.state.selectedClient != null) {
          cl = this.state.selectedClient.label
          if (this.state.clchanged) {
            cl = this.state.selectedClient.label
            this.setState({ clchanged: false })
          } else {
            cl = this.state.selectedClient.label
          }
        } */


        let kra3 = values.client
        //let clid = kra3.substring(kra3.indexOf("(") + 1, kra3.indexOf(")"))
        ////console.log(values.client)
        let ikh = this.state.indies.find(e => e.id === values.client);
        let cl = ""
        if (ikh !== undefined) {
            cl = ikh.first_name + " " + ikh.last_name
        }

        let ikha = this.state.tariffs.find(e => e.id === values.vehicle_type);
        let trf = ikha.tariff_name


        /* if (this.state.selectedClient != null) {
          clid = this.state.selectedClient.value
          if (this.state.clchanged) {
            clid = this.state.selectedClient.value
            this.setState({ clchanged: false })
          } else {
            clid = this.state.selectedClient.value
          }
        } */


        /* let trf = ""
        if (this.state.selectedTariff != null) {
          trf = this.state.selectedTariff.label
          if (this.state.trfchanged) {
            trf = this.state.selectedTariff.label
            this.setState({ trfchanged: false })
          } else {
            trf = this.state.selectedTariff.label
          }
        } */


        /* let trfid = ""
        if (this.state.selectedTariff != null) {
          trfid = this.state.selectedTariff.value
          if (this.state.trfchanged) {
            trfid = this.state.selectedTariff.value
            this.setState({ trfchanged: false })
          } else {
            trfid = this.state.selectedTariff.value
          }
        } */
        var pida = new Date(values.pickup_date).getTime()
        var reda = new Date(values.return_date).getTime()
        this.calculateAmount(values.vehicle_type, ossan)
        /*  var paid = e.target.paid.checked
     
         //   //console.log("paid ", e.target.paid.checked)
         var restam = 0
         var paidam = parseInt(values.amount)+parseInt(values.frais_livraison)+parseInt(values.frais_divers)+parseInt(values.frais_reprise)
     
         if (paid) {
           restam = 0
           paidam = parseInt(values.amount)+parseInt(values.frais_livraison)+parseInt(values.frais_divers)+parseInt(values.frais_reprise)
         } else {
           restam = (parseInt(values.amount)+parseInt(values.frais_livraison)+parseInt(values.frais_divers)+parseInt(values.frais_reprise)) - values.paid_amount
         }
         if (paidam === (parseInt(values.amount)+parseInt(values.frais_livraison)+parseInt(values.frais_divers)+parseInt(values.frais_reprise))) {
           //  //console.log("dkhel l condition")
           paid = true
         } */
        // //console.log("paid ", paid)
        /* this.setState({
          gender:this.state.staffs.gender
        }) */

        if (isEdit) {
            //des=this.state.selectedType.defaultInputValue

            const updateQuote = {
                id: this.state.quote.id,
                code: this.state.quote.code,
                /*  plate_number: plt,
                 vehicle: values.vehicle, */
                vehicle: this.state.quote.vehicle,
                client: cl,
                client_id: values.client,
                pickup_date: values.pickup_date,
                pd: pida,
                return_date: values.return_date,
                rd: reda,
                vehicle_type: trf,
                tariff_id: values.vehicle_type,
                amount: parseInt(values.amount),
                total_amount: parseInt(parseInt(values.amount) + parseInt(values.frais_divers) + parseInt(values.frais_reprise) + parseInt(values.frais_livraison)),
                notes: values.notes,
                days: ossan,
                frais_livraison: parseInt(values.frais_livraison),
                frais_reprise: parseInt(values.frais_reprise),
                frais_divers: parseInt(values.frais_divers),
                caution: parseInt(values.caution),
                start: values.pickup_date,
                end: values.return_date,
                title: this.state.quote.vehicle + " (" + cl + ")"

            }
            if (/* this.state.quote.vehicle == updateQuote.vehicle && */
                this.state.quote.client == updateQuote.client &&
                this.state.quote.pickup_date == updateQuote.pickup_date &&
                this.state.quote.return_date == updateQuote.return_date &&
                this.state.quote.vehicle_type == updateQuote.vehicle_type &&
                this.state.quote.amount == updateQuote.amount &&
                this.state.quote.notes == updateQuote.notes &&
                this.state.quote.frais_livraison == updateQuote.frais_livraison &&
                this.state.quote.frais_reprise == updateQuote.frais_reprise &&
                this.state.quote.frais_divers == updateQuote.frais_divers &&
                this.state.quote.caution == updateQuote.caution) {
                this.setState({ submitLoading: false })
                this.toggle()
            } else {
                this.updateQuote(updateQuote)
            }
            // update Order
            //onUpdateQuote(updateQuote)
            //toast.success(this.props.t("Quote updated "))
        } else {

            const newQuote = {
                code: codens,
                plate_number: plt,
                vehicle: vehicle,
                v_id: values.vehicle,
                client: cl,
                client_id: values["client"],
                pickup_date: values['pickup_date'],
                pd: pida,
                return_date: values['return_date'],
                rd: reda,
                vehicle_type: trf,
                tariff_id: values["vehicle_type"],
                amount: parseInt(values['amount']),
                total_amount: parseInt(parseInt(values['amount']) + parseInt(values['frais_divers']) + parseInt(values['frais_reprise']) + parseInt(values['frais_livraison'])),
                notes: values["notes"],
                days: ossan,
                start: values['pickup_date'],
                end: values['return_date'],
                frais_livraison: parseInt(values['frais_livraison']),
                frais_reprise: parseInt(values['frais_reprise']),
                frais_divers: parseInt(values['frais_divers']),
                caution: parseInt(values['caution']),
                title: vehicle + " (" + cl + ")"

            }
            this.setState({ quote: newQuote })
            this.addNewQuote(newQuote)
            //toast.success(this.props.t("Quote added "))
        }
        this.setState({ selectedQuote: null })
        //this.toggle()
    }

    deleteQuote = (quote) => {

        const collection = firebase.firestore().collection("users").doc(this.state.user_id).collection("quotes")
        const timeline = {
            statusTitle: "Quote deleted",
            iconClass: "bx bx-spreadsheet h2 text-danger",
            description: "Quote information has been deleted for ",
            data: quote.vehicle,
            time: new Date().getTime(),

        }
        collection.doc(quote.id).delete().then(() => {


            //console.log("Element  deleted!");
            toast.success(this.props.t("Quote deleted!"))
            this.addNewTimeline(timeline)

            this.setState({
                success_dlg: true,
                dynamic_title: this.props.t("Deleted"),
                dynamic_description: this.props.t("Element has been deleted."),
            })
        }).catch((error) => {
            console.error("Error removing element: ", error);
            toast.error(this.props.t("Error removing element"));

        });
    }

    updateQuote = async (quote) => {
        const { quotes, repairs } = this.state
        var rl = []
        /* var exist = false
        //console.log(quote)
        quotes.map(function (item, i) {
          // if (item.status === "Rental" || item.status === "Created") {
          if (quote.plate_number === item.plate_number) {
            rl.push({ start_date: item.pd, end_date: item.rd, id: item.id })
          }
          //  }
        })
        repairs.map(function (item, i) {
          if (quote.plate_number === item.plate_number) {
            rl.push({ start_date: item.sd, end_date: item.ed, id: item.id })
          }
        })
        ////console.log("kra ", rl)
        if (rl.length != 0) {
          rl.forEach(e => {
            if (e.id !== quote.id) {
              if ((e.start_date >= quote.pd && e.start_date <= quote.rd) ||
                (quote.pd >= e.start_date && quote.pd <= e.end_date)) {
                exist = true
    
              }
            }
    
          });
        } else {
          exist = false
        } */
        //  //console.log(exist)
        const timeline = {
            statusTitle: "Quote updated",
            iconClass: "bx bx-spreadsheet h2 text-warning",
            description: "Quote information has been updated for ",
            data: quote.vehicle,
            time: new Date().getTime(),

        }
        this.setState({ submitLoading: true })
        const collection = firebase.firestore().collection("users").doc(this.state.user_id).collection("quotes")
        if (quote.days !== 0) {
            if (quote.pd < quote.rd) {
                /* if (!exist) { */

                collection.doc(quote.id).update({

                    /*  plate_number: quote.plate_number,
                     vehicle: quote.vehicle, */
                    client: quote.client,
                    client_id: quote.client_id,
                    pickup_date: quote.pickup_date,
                    pd: quote.pd,
                    return_date: quote.return_date,
                    rd: quote.rd,
                    vehicle_type: quote.vehicle_type,
                    tariff_id: quote.tariff_id,
                    amount: quote.amount,
                    total_amount: quote.total_amount,
                    notes: quote.notes,
                    days: quote.days,
                    frais_livraison: quote.frais_livraison,
                    frais_reprise: quote.frais_reprise,
                    frais_divers: quote.frais_divers,
                    caution: quote.caution,
                    start: quote.pickup_date,
                    end: quote.return_date,
                    title: quote.title

                }).then(() => {
                    //console.log("Quote  Updated!");
                    toast.success(this.props.t("Quote Updated!"))
                    this.addNewTimeline(timeline)
                    this.setState({ submitLoading: false })
                    this.toggle()
                }).catch((error) => {
                   // console.error("Error updating Quote: ", error);
                    toast.error(this.props.t("Error updating Quote"))
                    this.setState({ submitLoading: false })
                });

                /* } else {
                  console.error("Error updating Quote: Vehicle unavailable ");
                  toast.error(this.props.t("Error updating Quote : Vehicle unavailable"))
                  this.setState({ submitLoading: false })
                } */
            } else {
                //console.error("Error updating Quote: Invalid dates ");
                toast.error(this.props.t("Error updating Quote : Invalid dates"))
                this.setState({ submitLoading: false })
            }
        } else {
            console.error("Error Updating Quote: Number of days cannot be zero ");
            toast.error(this.props.t("Error Updating Quote : Number of days cannot be zero!"))
            this.setState({ submitLoading: false })
        }

    }

    addNewTimeline = async (timeline) => {
        try {
            const { user_id, account_user } = this.state;
            const collection = firebase.firestore().collection("users").doc(user_id).collection("timeline");
            const newDoc = collection.doc();
            const by = account_user.isAdmin ? 'Admin' : `${account_user.first_name} ${account_user.last_name}`;

            await newDoc.set({
                id: newDoc.id,
                statusTitle: timeline.statusTitle,
                iconClass: timeline.iconClass,
                description: timeline.description,
                time: timeline.time,
                data: timeline.data,
                user: by
            });

            //console.log("Timeline  written!");
        } catch (error) {
            console.error("Error adding timeline:", error);
        }
    }
    addNewQuote = async (quote) => {
        const { quotes, repairs, vehicles } = this.state
        var rl = []
        var exist = false
        var isOffline = false
        vehicles.map(function (item, i) {
            if (quote.plate_number === item.plate_number) {
                if (item.offline) {
                    isOffline = true
                }
            }
        })
        quotes.map(function (item, i) {
            // if (item.status === "Rental" || item.status === "Created") {
            if (quote.plate_number === item.plate_number) {
                rl.push({ start_date: item.pd, end_date: item.rd, id: item.id })
            }
            //  }
        })
        repairs.map(function (item, i) {
            if (quote.plate_number === item.plate_number) {
                rl.push({ start_date: item.sd, end_date: item.ed, id: item.id })
            }
        })

        if (rl.length != 0) {
            rl.forEach(e => {
                if ((e.start_date >= quote.pd && e.start_date <= quote.rd) ||
                    (quote.pd >= e.start_date && quote.pd <= e.end_date)) {
                    exist = true
                }
            });
        } else {
            exist = false
        }
        const timeline = {
            statusTitle: "New Quote created",
            iconClass: "bx bx-spreadsheet h2 text-success",
            description: "Quote information has been created ",
            data: quote.vehicle,
            time: new Date().getTime(),

        }
        const collection = firebase.firestore().collection("users").doc(this.state.user_id).collection("quotes")
        // //console.log(exist)
        this.setState({ submitLoading: true })
        if (quote.days !== 0) {
            if (quote.pd < quote.rd) {
                if (!exist) {
                    if (!isOffline) {
                        var newDoc = collection.doc();
                        var inf = false
                        if (quote.mileage_return !== "" && quote.fuel_level_return !== "") {
                            inf = true
                        }
                        newDoc.set(
                            {
                                id: newDoc.id,
                                code: quote.code,
                                plate_number: quote.plate_number,
                                vehicle: quote.vehicle,
                                v_id: quote.v_id,
                                client: quote.client,
                                client_id: quote.client_id,
                                pickup_date: quote.pickup_date,
                                pd: quote.pd,
                                return_date: quote.return_date,
                                rd: quote.rd,
                                vehicle_type: quote.vehicle_type,
                                tariff_id: quote.tariff_id,
                                amount: quote.amount,
                                total_amount: quote.total_amount,
                                notes: quote.notes,
                                days: quote.days,
                                frais_livraison: parseInt(quote.frais_livraison),
                                frais_reprise: parseInt(quote.frais_reprise),
                                frais_divers: parseInt(quote.frais_divers),
                                caution: parseInt(quote.caution),
                                start: quote.pickup_date,
                                end: quote.return_date,
                                title: quote.title,
                                r_infos: inf,
                                createdAt: new Date().getTime()

                            }).then(() => {

                                //console.log("Quote  written!");
                                toast.success(this.props.t('Quote added'))
                                this.addNewTimeline(timeline)
                                this.setState({ submitLoading: false })
                                this.toggle()
                            })
                            .catch((error) => {
                                console.error("Error Adding Quote infos: ", error);
                                toast.error(this.props.t("Error Adding Quote infos"))
                                this.setState({ submitLoading: false })
                            });
                    } else {
                        console.error("Error Adding Quote: Vehicle is Offline ");
                        toast.error(this.props.t("Error Adding Quote : Vehicle is Offline"))
                        this.setState({ submitLoading: false })
                    }
                } else {
                    console.error("Error Adding Quote: Vehicle unavailable ");
                    toast.error(this.props.t("Error Adding Quote : Vehicle unavailable"))
                    this.setState({ submitLoading: false })
                }
            } else {
                console.error("Error Adding Quote: Invalid dates ");
                toast.error(this.props.t("Error Adding Quote : Invalid dates"))
                this.setState({ submitLoading: false })
            }
        } else {
            console.error("Error Adding Quote: Number of days cannot be zero ");
            toast.error(this.props.t("Error Adding Quote : Number of days cannot be zero!"))
            this.setState({ submitLoading: false })
        }

    }

    updateVehicle = async (vehicle) => {
        //console.log(vehicle.status)
        const collection = firebase.firestore().collection("users").doc(this.state.user_id).collection("vehicles")
        collection.doc(vehicle.id).update({
            status: vehicle.status,
            start_date: vehicle.start_date,
            available_date: vehicle.available_date,
            rented_to: vehicle.rented_to
        }).then(() => {
            //console.log("vehicle  Updated!");
        }).catch((error) => {
            console.error("Error updating vehicle: ", error);
        });
    }
    updateQuote1 = async (quote) => {
        // //console.log(quote.status)
        const collection = firebase.firestore().collection("users").doc(this.state.user_id).collection("quotes")
        collection.doc(quote.id).update({
            status: quote.status,
        }).then(() => {
            //console.log("quote  Updated!");
        }).catch((error) => {
            console.error("Error updating vehicle: ", error);
        });
    }




    handleValidDate = (date) => {
        const date1 = moment(new Date(date)).format('DD MMM Y');
        return date1;
    }




    render() {
        const emptyDataMessage = () => {
            return <Container>
                <Row>
                    <Col lg="12">
                        {this.state.loading ?
                            <div className="text-center">
                                <Row className="justify-content-center mt-5">
                                    <Col sm="4">
                                        <div>
                                            <div >
                                                <div className="spinner-chase">
                                                    <div className="chase-dot"></div>
                                                    <div className="chase-dot"></div>
                                                    <div className="chase-dot"></div>
                                                    <div className="chase-dot"></div>
                                                    <div className="chase-dot"></div>
                                                    <div className="chase-dot"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            :
                            <div className="text-center">

                                {/* <Row className="justify-content-center mt-5">
                                    <Col sm="4">
                                        <div className="maintenance-img">
                                            <img
                                                src={maintanence}
                                                alt=""
                                                className="img-fluid mx-auto d-block"
                                            />
                                        </div>
                                    </Col>
                                </Row> */}
                                <h4 className="mt-1 mb-2">{this.props.t("No Data")}</h4>

                            </div>
                        }
                    </Col>
                </Row>
            </Container>


                ;
        }

        const { currentYear, selectedVehicle1, selectedVehicleName, selectedOption, selectedTS, selectedDate, selectedPeriod, quotes, todaysP, todaysR } = this.state
        const { vehList } = this.state
        var tr = 0, td = 0, tre = 0
        var rese
        rese = quotes

        //console.log(selectedTS)
        switch (selectedOption) {
            case 'todo':
                var r = [], d = 0, rev = 0
                quotes.map(function (item) {
                    if (selectedVehicle1 === 1) {
                        r.push(item)
                        d = d + item.days
                        rev = rev + item.total_amount
                    }
                    if (item.client_id === selectedVehicle1) {
                        r.push(item)
                        d = d + item.days
                        rev = rev + item.total_amount
                    }
                });

                rese = r
                tr = rese.length
                td = d
                tre = rev
                break;
            case 'hoy':
                var r = [], d = 0, rev = 0
                quotes.map(function (item) {
                    var sd = moment(new Date()).format('MM-DD-YYYY')
                    var ds = moment(item.pd).format("MM-DD-YYYY");
                    var t = moment(ds)
                    var s = moment(sd)
                    if (s.isSame(t, 'day') && selectedVehicle1 === 1) {
                        r.push(item)
                        d = d + item.days
                        rev = rev + item.total_amount
                    }
                    if (s.isSame(t, 'day') && item.client_id === selectedVehicle1) {
                        r.push(item)
                        d = d + item.days
                        rev = rev + item.total_amount
                    }
                });


                rese = r
                tr = rese.length
                td = d
                tre = rev
                break;
            case 'sempa':
                var r = [], d = 0, rev = 0
                quotes.map(function (item) {
                    if (moment(new Date().getTime()).isSame(moment(item.pd), 'week') && selectedVehicle1 === 1) {
                        r.push(item)
                        d = d + item.days
                        rev = rev + item.total_amount
                    }
                    if (moment(new Date().getTime()).isSame(moment(item.pd), 'week') && item.client_id === selectedVehicle1) {
                        r.push(item)
                        d = d + item.days
                        rev = rev + item.total_amount
                    }
                });

                rese = r
                tr = rese.length
                td = d
                tre = rev
                break;
            case 'mespa':
                var r = [], d = 0, rev = 0
                quotes.map(function (item) {
                    if (moment(new Date().getTime()).isSame(moment(item.pd), 'month') && selectedVehicle1 === 1) {
                        r.push(item)
                        d = d + item.days
                        rev = rev + item.total_amount
                    }
                    if (moment(new Date().getTime()).isSame(moment(item.pd), 'month') && item.client_id === selectedVehicle1) {
                        r.push(item)
                        d = d + item.days
                        rev = rev + item.total_amount
                    }
                });


                rese = r
                tr = rese.length
                td = d
                tre = rev
                break;
            case 'dia':
                //console.log("diaaaaa")
                var r = [], d = 0, rev = 0
                quotes.map(function (item) {
                    var sd = moment(selectedTS).format('MM-DD-YYYY')
                    var ds = moment(item.pd).format("MM-DD-YYYY");
                    var t = moment(ds)
                    var s = moment(sd)
                    if (s.isSame(t, 'day') && selectedVehicle1 === 1) {
                        r.push(item)
                        d = d + item.days
                        rev = rev + item.total_amount
                    }
                    if (s.isSame(t, 'day') && item.client_id === selectedVehicle1) {
                        r.push(item)
                        d = d + item.days
                        rev = rev + item.total_amount
                    }
                });


                rese = r
                tr = rese.length
                td = d
                tre = rev
                break;
            case 'mes':
                //console.log("meeeees")
                var r = [], d = 0, rev = 0
                quotes.map(function (item) {
                    if (moment(selectedTS).isSame(moment(item.pd), 'month') && selectedVehicle1 === 1) {
                        r.push(item)
                        d = d + item.days
                        rev = rev + item.total_amount
                    }
                    if (moment(selectedTS).isSame(moment(item.pd), 'month') && item.client_id === selectedVehicle1) {
                        r.push(item)
                        d = d + item.days
                        rev = rev + item.total_amount
                    }
                });


                rese = r
                tr = rese.length
                td = d
                tre = rev
                break;
            case 'ano':
                //console.log("anooooo")
                var r = [], d = 0, rev = 0
                quotes.map(function (item) {
                    if (moment(selectedTS).isSame(moment(item.pd), 'year') && selectedVehicle1 === 1) {
                        r.push(item)
                        d = d + item.days
                        rev = rev + item.total_amount
                    }
                    if (moment(selectedTS).isSame(moment(item.pd), 'year') && item.client_id === selectedVehicle1) {
                        r.push(item)
                        d = d + item.days
                        rev = rev + item.total_amount
                    }
                });


                rese = r
                tr = rese.length
                td = d
                tre = rev
                break;
            default:

        }
        //console.log(tr, td, tre)
        let dataxcl = []
        rese.map(function (item) {
            delete item.quote;
            dataxcl.push({
                code: item.code,
                plate_number: item.plate_number,
                vehicle: item.vehicle,
                client: item.client,
                pickup_date: item.pickup_date,
                pickup_location: item.pickup_location,
                return_date: item.return_date,
                return_location: item.return_location,
                vehicle_type: item.vehicle_type,
                amount: item.amount,
                total_amount: item.total_amount,
                notes: item.notes,
                days: item.days,
                frais_livraison: item.frais_livraison,
                frais_reprise: item.frais_reprise,
                frais_divers: item.frais_divers,
                caution: item.caution,
                mileage_pickup: item.mileage_pickup,
                mileage_return: item.mileage_return,
                fuel_level_pickup: item.fuel_level_pickup,
                fuel_level_return: item.fuel_level_return,

            })
        });

        const { selectedType } = this.state
        const { selectedVehicle } = this.state
        const { selectedClient } = this.state
        const { selectedTariff } = this.state

        const { SearchBar } = Search
        const { isEdit } = this.state
        const { isDelete } = this.state
        const { vc } = this.state
        const { vehicles } = this.state

        const { indies } = this.state
        const { tariffs } = this.state
        const client_list = []

        client_list.push({ label: "All Clients", value: "All" })
        indies.map(function (item, i) {

            client_list.push({ id: item.id, label: item.first_name + " " + item.last_name, value: item.id })

        })
        //pagination customization
        const vcList = []
        const vcList1 = []
        const clList = []
        const trfList = []
        vcList1.push({ label: "All vehicles", value: "All" })
        vehicles.map(function (item, i) {

            vcList1.push({ id: item.id, label: item.make + "(" + item.plate_number + ")", value: item.plate_number })

        })
        vcList.push({ label: "", value: "" })
        vehicles.map(function (item, i) {

            vcList.push({ id: item.id, label: item.make + "(" + item.plate_number + ")", value: item.plate_number })

        })

        clList.push({ label: "", value: "" })
        indies.map(function (item, i) {

            clList.push({ label: item.first_name + " " + item.last_name, value: item.id, dlx: item.dl_expiration_date })

        })

        trfList.push({ label: "", value: "" })
        tariffs.map(function (item, i) {
            trfList.push({ label: item.tariff_name, value: item.id })

        })



        const defaultSorted = [{
            dataField: 'createdAt',
            order: 'desc'
        }];

        const selectRow = {
            mode: 'checkbox',
        };



        function ExportToExcel() {
            exportFromJSON({ data: dataxcl, fileName: 'Quotes', exportType: exportFromJSON.types.xls })
        }
        function exportPDF() {
            const unit = "pt";
            const size = "A4"; // Use A1, A2, A3 or A4
            const orientation = "landscape"; // portrait or landscape

            const marginLeft = 20;
            const doc = new jsPDF(orientation, unit, size);

            doc.setFontSize(15);

            const title = "Quotes Infos";
            const headers = [["Code", "Pickup date", "Return date", "Pickup location", "Return location", "Vehicle type", "Vehicle", "Client", "Total amount", "Days", "Notes"]];

            const dt = dataxcl.map(elt => [elt.code, elt.pickup_date, elt.return_date, elt.pickup_location, elt.return_location, elt.vehicle_type, elt.vehicle, elt.client, elt.amount + "dh", elt.days, elt.notes]);

            let content = {
                startY: 50,
                head: headers,
                body: dt
            };

            doc.text(title, marginLeft, 20);
            doc.autoTable(content);
            doc.save("Quotes.pdf")
        }
        function printPDF() {
            const unit = "pt";
            const size = "A4"; // Use A1, A2, A3 or A4
            const orientation = "landscape"; // portrait or landscape

            const marginLeft = 20;
            const doc = new jsPDF(orientation, unit, size);

            doc.setFontSize(15);

            const title = "Quotes Infos";
            const headers = [["Code", "Pickup date", "Return date", "Pickup location", "Return location", "Vehicle type", "Vehicle", "Client", "Total amount", "Days", "Notes"]];

            const dt = dataxcl.map(elt => [elt.code, elt.pickup_date, elt.return_date, elt.pickup_location, elt.return_location, elt.vehicle_type, elt.vehicle, elt.client, elt.amount + "dh", elt.days, elt.notes]);

            let content = {
                startY: 50,
                head: headers,
                body: dt
            };

            doc.text(title, marginLeft, 20);
            doc.autoTable(content);
            var string = doc.output('datauristring');
            var embed = "<embed width='100%' height='100%' src='" + string + "'/>"
            var x = window.open();
            x.document.open();
            x.document.write(embed);
            x.document.close();

        }



        return (

            <React.Fragment>


                <div className="page-content">
                    <MetaTags>
                        <title>{this.props.t("Quotes | Medios - Car Rental Management System")}</title>
                    </MetaTags>
                    <Container fluid>
                        <Breadcrumbs title={this.props.t("Rental")} breadcrumbItem={this.props.t("Quotes")} />
                        {this.state.success_dlg ? (
                            <SweetAlert
                                success
                                title={this.state.dynamic_title}
                                onConfirm={() => this.setState({ success_dlg: false })}
                            >
                                {this.state.dynamic_description}
                            </SweetAlert>
                        ) : null}
                        <Row className="mb-2" >

                            <Row>

                            <Col dm="3" className="text-sm-center mb-2">
                                    <AvForm>
                                        <AvField
                                            name="title"
                                            type="select"
                                            onChange={this.handleSelectVehicleFilter}
                                            validate={{
                                                required: { value: true },
                                            }}

                                        >
                                            {client_list.map((e, key) => {

                                                return <option key={key} value={e.id}>{e.label}</option>;

                                            })}</AvField>
                                    </AvForm>



                                </Col>
                                <Col md="6" >
                                    <div className="text-end " >

                                        <Col   >

                                            <div className="btn-group me-2" role="group" aria-label="Basic radio toggle button group" onChange={this.onChangeValue}>
                                                <input type="radio" className="btn-check" name="btnradio" id="todo" onClick={this.onValueChange} autoComplete="off" defaultChecked />
                                                <label className="btn btn-primary" htmlFor="todo">{this.props.t("All")}</label>

                                                <input type="radio" className="btn-check" name="btnradio" id="hoy" onClick={this.onValueChange} autoComplete="off" />
                                                <label className="btn btn-primary" htmlFor="hoy">{this.props.t("Today")}</label>

                                                <input type="radio" className="btn-check" name="btnradio" id="sempa" onClick={this.onValueChange} autoComplete="off" />
                                                <label className="btn btn-primary" htmlFor="sempa">{this.props.t("This week")}</label>

                                                <input type="radio" className="btn-check" name="btnradio" id="mespa" onClick={this.onValueChange} autoComplete="off" />
                                                <label className="btn btn-primary" htmlFor="mespa">{this.props.t("This month")}</label>

                                                <button
                                                    type="button"
                                                    className="btn btn-light mb-2"
                                                    onClick={this.toggle_modal}
                                                >
                                                    <i className="bx bx-filter  font-size-22 align-middle "></i>

                                                </button>
                                            </div>

                                        </Col>

                                    </div>
                                </Col>


                                <Modal
                                    isOpen={this.state.modal1}
                                    toggle={this.toggle_modal}
                                    id="event-modal"
                                >
                                    <ModalHeader toggle={this.toggle_modal} tag="h4">
                                        {this.props.t("Custom Period")}
                                    </ModalHeader>
                                    <ModalBody>
                                        <AvForm onValidSubmit={this.handleValidEventSubmit}>
                                            <Row form>
                                                <Col className="col-12 mb-3">

                                                    {/* <AvField
  name="title"
  label="Vehicles"
  type="select"
  onChange={this.handleSelectVehicle}
  validate={{
    required: { value: true },
  }}

>
  {vcList.map((e, key) => {

    return <option key={key} value={e.id}>{e.label}</option>;

  })}</AvField> */}
                                                </Col>
                                                <Col className="col-12 mb-3">
                                                    <AvField
                                                        type="select"
                                                        name="period"
                                                        label={this.props.t("Select Period")}
                                                        defaultValue={selectedPeriod}
                                                        validate={{
                                                            required: { value: true },
                                                        }}
                                                        onChange={this.handlePeriodChange}
                                                    >
                                                        <option value="day">{this.props.t("Day")}</option>
                                                        <option value="month">{this.props.t("Month")}</option>
                                                        <option value="year">{this.props.t("Year")}</option>
                                                    </AvField>
                                                </Col>

                                                {selectedPeriod === "day" ?
                                                    <div className="mb-3 mt-4 row">
                                                        <label
                                                            htmlFor="example-month-input"
                                                            className="col-md-2 col-form-label"
                                                        >
                                                            {this.props.t("Day")}
                                                        </label>

                                                        <div className="col">
                                                            <AvField
                                                                className="form-control text-sm-start"
                                                                name="date"
                                                                type="date"
                                                                onChange={this.dateChanged}
                                                                id="example-date-input"
                                                                validate={{
                                                                    required: { value: true },
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    : null}
                                                {selectedPeriod === "month" ?
                                                    <div className="mb-6 row mb-3">
                                                        <label
                                                            htmlFor="example-month-input"
                                                            className="col-md-2 col-form-label"
                                                        >
                                                            {this.props.t("Month")}
                                                        </label>
                                                        <div className="col">
                                                            <AvField
                                                                name="month"
                                                                className="form-control"
                                                                type="month"
                                                                onChange={this.monthChanged}
                                                                id="example-month-input"
                                                                validate={{
                                                                    required: { value: true },
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    : null}
                                                {selectedPeriod === "year" ?
                                                    <div className="mb-3 row mb-3">
                                                        <AvField
                                                            type="select"
                                                            name="year"
                                                            label={this.props.t("Select year")}
                                                            onChange={this.yearChanged}
                                                            defaultValue={currentYear}
                                                            validate={{
                                                                required: { value: true },
                                                            }}
                                                        >
                                                            <option value={currentYear}>{currentYear}</option>
                                                            <option value={currentYear - 1}>{currentYear - 1}</option>
                                                            <option value={currentYear - 2}>{currentYear - 2}</option>
                                                            <option value={currentYear - 3}>{currentYear - 3}</option>
                                                            <option value={currentYear - 4}>{currentYear - 4}</option>
                                                        </AvField>
                                                    </div>
                                                    : null}
                                            </Row>
                                            {/* <h5 className="text-center mt-4">
{this.state.selectedDate || this.state.selectedMonth}
</h5> */}
                                            <div className="modal-footer">
                                                <button
                                                    type="button"
                                                    className="btn btn-light"
                                                    data-dismiss="modal"
                                                    onClick={this.toggle_modal}
                                                >
                                                    {this.props.t("Close")}
                                                </button>

                                                <button
                                                    type="submit"
                                                    className="btn btn-success"
                                                >
                                                    {this.props.t("Confirm")}
                                                </button>
                                            </div>
                                        </AvForm>
                                    </ModalBody>


                                </Modal>

                            </Row>

                        </Row>
                        <Row>
                            <Col xs="12">

                                <Card>
                                    <CardBody>
                                        <PaginationProvider
                                            pagination={paginationFactory()}
                                            keyField='id'
                                            columns={(this.state.QuoteColumns || [])}
                                            data={(rese || [])}
                                        >
                                            {({ paginationProps, paginationTableProps }) => (
                                                <ToolkitProvider
                                                    keyField="id"
                                                    data={rese}
                                                    columns={(this.state.QuoteColumns || [])}
                                                    bootstrap4
                                                    search
                                                >
                                                    {toolkitProps => (
                                                        <React.Fragment>
                                                            <Row className="mb-2" class="noPrint">
                                                                <Col sm="4">
                                                                    <div className="search-box me-2 mb-2 d-inline-block">
                                                                        <div className="position-relative">
                                                                            <SearchBar
                                                                                {...toolkitProps.searchProps}
                                                                            />
                                                                            <i className="bx bx-search-alt search-icon" />
                                                                        </div>
                                                                    </div>
                                                                </Col>

                                                                <Col sm="8" >

                                                                    <div className="text-sm-end" >
                                                                        <Button
                                                                            type="button"
                                                                            color="success"
                                                                            className="btn-rounded mb-2 me-2"
                                                                            onClick={this.handleQuoteClicks}
                                                                        >
                                                                            <i className="mdi mdi-plus me-1" />{" "}
                                                                            {this.props.t("New Quote")}
                                                                        </Button>

                                                                        <Link to="#"

                                                                        >

                                                                            {/*  <i className="mdi mdi-file-export-outline font-size-18" /> */}

                                                                        </Link>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <div className="table-responsive">
                                                                <BootstrapTable

                                                                    {...toolkitProps.baseProps}
                                                                    {...paginationTableProps}
                                                                    responsive
                                                                    defaultSorted={defaultSorted}
                                                                    bordered={false}
                                                                    striped={false}
                                                                    noDataIndication={emptyDataMessage}
                                                                    classes={
                                                                        "table align-middle table-nowrap table-check"
                                                                    }
                                                                    headerWrapperClasses={"table-light"}
                                                                />
                                                                <Modal
                                                                    isOpen={this.state.modal}
                                                                    className={this.props.className}
                                                                >
                                                                    <ModalHeader toggle={this.toggle} tag="h4">
                                                                        {!!isEdit ? this.props.t("Edit Quote") : null}
                                                                        {!!this.state.isShow ? this.props.t("Quote Infos") : null}
                                                                        {!isEdit && !this.state.isShow ? this.props.t("Add Quote") : null}

                                                                    </ModalHeader>
                                                                    <ModalBody>
                                                                        {this.state.submitLoading ?
                                                                            <Col>
                                                                                <div>
                                                                                    <div >
                                                                                        <div className="spinner-chase">
                                                                                            <div className="chase-dot"></div>
                                                                                            <div className="chase-dot"></div>
                                                                                            <div className="chase-dot"></div>
                                                                                            <div className="chase-dot"></div>
                                                                                            <div className="chase-dot"></div>
                                                                                            <div className="chase-dot"></div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <br />
                                                                                <h6 className='text-center'>{this.props.t("Uploading data ...")}</h6>
                                                                            </Col>

                                                                            :

                                                                            <AvForm
                                                                                onValidSubmit={
                                                                                    this.handleValidQuoteSubmit
                                                                                }
                                                                            //disabled={this.state.isShow ? true : false}
                                                                            >
                                                                                <Row>
                                                                                    <Col md="6">
                                                                                        <FormGroup className="mb-4">
                                                                                            <Label> {this.props.t("Pickup date")}</Label>
                                                                                            <AvField
                                                                                                name="pickup_date"
                                                                                                type="datetime-local"
                                                                                                defaultValue={this.state.quote.pickup_date || ""}
                                                                                                errorMessage={this.props.t("Select a Pickup date")}
                                                                                                disabled={this.state.isShow ? true : false}
                                                                                                onChange={this.handlePDChange}
                                                                                                validate={{
                                                                                                    required: { value: true },
                                                                                                }}
                                                                                            />
                                                                                        </FormGroup>
                                                                                    </Col>
                                                                                    <Col md="6">
                                                                                        <FormGroup className="mb-4">
                                                                                            <Label> {this.props.t("Return date")}</Label>
                                                                                            <AvField
                                                                                                name="return_date"
                                                                                                type="datetime-local"
                                                                                                defaultValue={this.state.quote.return_date || ""}
                                                                                                errorMessage={this.props.t("Select a Return date")}
                                                                                                disabled={this.state.isShow ? true : false}
                                                                                                onChange={this.handleRDChange}
                                                                                                validate={{
                                                                                                    required: { value: true },
                                                                                                }}
                                                                                            />
                                                                                        </FormGroup>
                                                                                    </Col>

                                                                                </Row>


                                                                                <Row>

                                                                                    <Col md="6">
                                                                                        <FormGroup className="mb-3">
                                                                                            <div className="mb-3 select2-container">
                                                                                                <Label>{this.props.t("Rate")}</Label>
                                                                                                <AvField
                                                                                                    name="vehicle_type"
                                                                                                    type="select"
                                                                                                    //placeholder={this.state.quotes.vehicle_type}
                                                                                                    onChange={this.handleSelectTariff}
                                                                                                    // options={trfList}
                                                                                                    value={this.state.quote.tariff_id}
                                                                                                    disabled={this.state.isShow ? true : false}
                                                                                                    errorMessage={this.props.t("Select a Rate")}
                                                                                                    className="form-control"

                                                                                                    validate={{
                                                                                                        required: { value: true },
                                                                                                    }}
                                                                                                >

                                                                                                    {trfList.map((e, key) => {
                                                                                                        if (key == 0) {
                                                                                                            return <option key={key} value="" disabled>{this.props.t("Rate")}</option>;
                                                                                                        } else {
                                                                                                            return <option key={key} value={e.value}>{e.label}</option>;
                                                                                                        }

                                                                                                    })}
                                                                                                </AvField>
                                                                                                <Link to="/tariffs" target="_blank" rel="noopener noreferrer">{this.props.t("Add new Rate")}</Link>
                                                                                            </div>

                                                                                        </FormGroup>
                                                                                    </Col>
                                                                                    <Col md="6">
                                                                                        <FormGroup className="mb-3">
                                                                                            <div className="mb-3 select2-container">
                                                                                                <Label>{this.props.t("Vehicle")}</Label>
                                                                                                {!isEdit && !this.state.isShow ?
                                                                                                    <div>
                                                                                                        <AvField
                                                                                                            name="vehicle"
                                                                                                            type="select"
                                                                                                            placeholder={""}
                                                                                                            onChange={this.handleSelectVehicle}
                                                                                                            //options={vcList}
                                                                                                            //value={this.state.quote.vehicle}
                                                                                                            disabled={false}
                                                                                                            /* disabled={this.state.isShow || !!isEdit ? true : false} */
                                                                                                            errorMessage={this.props.t("Select a Vehicle")}
                                                                                                            className="form-control"

                                                                                                            validate={{
                                                                                                                required: { value: true },
                                                                                                            }}
                                                                                                        >

                                                                                                            {vcList.map((e, key) => {
                                                                                                                if (key == 0) {
                                                                                                                    return <option key={key} value="" disabled>{this.props.t("vehicle")}</option>;
                                                                                                                } else {
                                                                                                                    return <option key={key} value={e.id}>{e.label}</option>;
                                                                                                                }
                                                                                                            })}
                                                                                                        </AvField>
                                                                                                        <Link to="/vehicles" target="_blank" rel="noopener noreferrer">{this.props.t("Add new Vehicle")}</Link>
                                                                                                    </div>
                                                                                                    :
                                                                                                    <AvField
                                                                                                        name="vehicle"
                                                                                                        type="text"
                                                                                                        disabled={this.state.isShow || !!isEdit ? true : false}
                                                                                                        value={this.state.quote.vehicle || ""}
                                                                                                    />
                                                                                                } </div>

                                                                                        </FormGroup>
                                                                                    </Col>
                                                                                </Row>

                                                                                <Row>
                                                                                    <Col md="6">
                                                                                        <FormGroup className="mb-3">
                                                                                            <div className="mb-3 select2-container">
                                                                                                <Label>{this.props.t("Client")}</Label>
                                                                                                <AvField
                                                                                                    name="client"
                                                                                                    type="select"
                                                                                                    //placeholder={this.state.quotes.client}
                                                                                                    //onChange={this.handleSelectClient}
                                                                                                    //options={clList}

                                                                                                    value={this.state.quote.client_id}
                                                                                                    errorMessage={this.props.t("Select a Client")}
                                                                                                    className="form-control"
                                                                                                    disabled={this.state.isShow || !!isEdit ? true : false}

                                                                                                    validate={{
                                                                                                        required: { value: true },
                                                                                                    }}
                                                                                                >

                                                                                                    {clList.map((e, key) => {
                                                                                                        if (key == 0) {
                                                                                                            return <option key={key} value="" disabled>{this.props.t("Client")}</option>;
                                                                                                        } else {
                                                                                                            return <option key={key} value={e.value}
                                                                                                                style={Moment(new Date()).diff(Moment(e.dlx), 'months') <= 0 ? { color: 'orange' } : { color: 'black' }}
                                                                                                            >{e.label}</option>;
                                                                                                        }

                                                                                                    })}
                                                                                                </AvField>
                                                                                                {!isEdit && !this.state.isShow ? <Link to="/clients" target="_blank" rel="noopener noreferrer">{this.props.t("Add new Client")}</Link> : null}

                                                                                            </div>

                                                                                        </FormGroup>
                                                                                    </Col>
                                                                                    <Col md="6">
                                                                                        <FormGroup className="mb-3">
                                                                                            <AvField
                                                                                                name="amount"
                                                                                                label={this.props.t("Amount")}
                                                                                                placeholder={this.state.quote.amount || this.props.t("Total amount")}
                                                                                                errorMessage={this.props.t("Enter Amount")}
                                                                                                type="number"
                                                                                                disabled={this.state.isShow ? true : false}
                                                                                                validate={{ required: { value: true } }}
                                                                                                value={this.state.totalAmount || this.state.quote.amount}
                                                                                            />
                                                                                            <Link to="#" onClick={this.getAmount} color="danger">{this.props.t("Calculate total")}</Link>

                                                                                        </FormGroup>

                                                                                    </Col>
                                                                                </Row>
                                                                                <Row>
                                                                                    <Col md="6">
                                                                                        <FormGroup className="mb-3">
                                                                                            <AvField
                                                                                                name="frais_livraison"
                                                                                                label={this.props.t("Delivery fee")}
                                                                                                placeholder={this.state.quote.frais_livraison || this.props.t("Delivery fee")}
                                                                                                type="number"
                                                                                                validate={{ required: { value: true } }}
                                                                                                disabled={this.state.isShow ? true : false}
                                                                                                value={this.state.quote.frais_livraison || "0"}
                                                                                            />
                                                                                        </FormGroup>
                                                                                    </Col>
                                                                                    <Col md="6">
                                                                                        <FormGroup className="mb-3">
                                                                                            <AvField
                                                                                                name="frais_reprise"
                                                                                                label={this.props.t("Return fee")}
                                                                                                placeholder={this.state.quote.frais_reprise || this.props.t("Return fee")}
                                                                                                type="number"
                                                                                                validate={{ required: { value: true } }}
                                                                                                disabled={this.state.isShow ? true : false}
                                                                                                value={this.state.quote.frais_reprise || "0"}
                                                                                            />
                                                                                        </FormGroup>
                                                                                    </Col>
                                                                                </Row>
                                                                                <Row>
                                                                                    <Col md="6">
                                                                                        <FormGroup className="mb-3">
                                                                                            <AvField
                                                                                                name="frais_divers"
                                                                                                label={this.props.t("Other expenses")}
                                                                                                placeholder={this.state.quote.frais_divers || this.props.t("Other expenses")}
                                                                                                type="number"
                                                                                                validate={{ required: { value: true } }}
                                                                                                disabled={this.state.isShow ? true : false}
                                                                                                value={this.state.quote.frais_divers || "0"}
                                                                                            />
                                                                                        </FormGroup>
                                                                                    </Col>
                                                                                    <Col md="6">
                                                                                        <FormGroup className="mb-3">
                                                                                            <AvField
                                                                                                name="caution"
                                                                                                label={this.props.t("Caution")}
                                                                                                placeholder={this.state.quote.caution || this.props.t("Caution")}
                                                                                                type="number"
                                                                                                disabled={this.state.isShow ? true : false}
                                                                                                value={this.state.quote.caution || "0"}
                                                                                            />
                                                                                        </FormGroup>
                                                                                    </Col>
                                                                                </Row>
                                                                                {/* <Row>
                                          <Col md="6">
                                            <FormGroup className="mb-3">
                                              <AvField
                                                name="paid_amount"
                                                label={this.props.t("Paid amount")}
                                                placeholder={this.state.quote.paid_amount || this.props.t("Paid amount")}
                                                type="number"
                                                max={(parseInt(this.state.quote.amount)+parseInt(this.state.quote.frais_livraison)+parseInt(this.state.quote.frais_divers)+parseInt(this.state.quote.frais_reprise))}
                                                disabled={this.state.isShow || this.state.p ? true : false}
                                                value={this.state.p ? (parseInt(this.state.quote.amount)+parseInt(this.state.quote.frais_livraison)+parseInt(this.state.quote.frais_divers)+parseInt(this.state.quote.frais_reprise)) : this.state.quote.paid_amount}
                                              />
                                            </FormGroup>
                                          </Col>
                                          <Col md="6">
                                            <FormGroup className="mb-3">
                                              <Label>{this.props.t("Paid")}</Label>
                                              <div className="form-check mb-3">
                                                <input
                                                  name="paid"
                                                  className="form-check-input"
                                                  type="checkbox"
                                                  disabled={this.state.isShow ? true : false}
                                                  checked={this.state.p}
                                                  onChange={this.checkChange}
                                                />
                                                <Label
                                                  className="form-check-label"
                                                  htmlFor="defaultCheck1"
                                                >
                                                  All Paid
                                                </Label>
                                              </div>

                                            </FormGroup>
                                          </Col>
                                        </Row> */}


                                                                                <Row>
                                                                                    <Col md="12">

                                                                                        <FormGroup className="mb-3">
                                                                                            <Label >
                                                                                                {this.props.t("Notes")}
                                                                                            </Label>
                                                                                            <AvField
                                                                                                name="notes"
                                                                                                placeholder={this.props.t("Notes")}
                                                                                                disabled={this.state.isShow ? true : false}
                                                                                                type="textarea"
                                                                                                rows="2"
                                                                                                value={this.state.quote.notes || ""}
                                                                                            />
                                                                                        </FormGroup>
                                                                                    </Col>


                                                                                </Row>
                                                                                {this.state.isShow ? null :
                                                                                    <Row>
                                                                                        <Col>
                                                                                            <div className="text-end">

                                                                                                <button
                                                                                                    type="submit"
                                                                                                    className="btn btn-success save-user"
                                                                                                >
                                                                                                    {this.props.t("Confirm")}
                                                                                                </button>
                                                                                            </div>
                                                                                        </Col>
                                                                                    </Row>
                                                                                }

                                                                            </AvForm>
                                                                        }
                                                                    </ModalBody>
                                                                </Modal>


                                                                {this.state.isDelete ? (
                                                                    <SweetAlert
                                                                        title={this.props.t("Are you sure?")}
                                                                        warning
                                                                        showCancel
                                                                        confirmButtonText={this.props.t("Yes, delete it!")}
                                                                        confirmBtnBsStyle="success"
                                                                        cancelBtnBsStyle="danger"
                                                                        onConfirm={() =>
                                                                            this.handleDeleteQuote(vc)
                                                                        }
                                                                        onCancel={() =>
                                                                            this.setState({
                                                                                isDelete: false,
                                                                            })
                                                                        }
                                                                    >
                                                                        {this.props.t("You won't be able to revert this!")}
                                                                    </SweetAlert>
                                                                ) : null}
                                                                {this.state.no_data ? (
                                                                    <SweetAlert
                                                                        title={this.props.t("No data!")}
                                                                        warning
                                                                        onConfirm={() => this.setState({ no_data: false })}
                                                                    >
                                                                        {this.props.t("There is no data to export")}
                                                                    </SweetAlert>
                                                                ) : null}

                                                            </div>


                                                        </React.Fragment>
                                                    )}
                                                </ToolkitProvider>
                                            )}
                                        </PaginationProvider>


                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <Toaster
                    position="top-right"
                    reverseOrder={false}
                />
            </React.Fragment>
        )
    }
}

Quotes.propTypes = {
    quotes: PropTypes.array,
    vehicles: PropTypes.array,
    indies: PropTypes.array,
    tariffs: PropTypes.array,
    onGetQuote: PropTypes.func,
    onAddNewQuote: PropTypes.func,
    onDeleteQuote: PropTypes.func,
    onUpdateQuote: PropTypes.func,
    onUpdateVehicle: PropTypes.func,
    onGetVehicle: PropTypes.func,
    onGetIndie: PropTypes.func,
    onGetTariff: PropTypes.func,
    className: PropTypes.any,
    t: PropTypes.any,

}

/* const mapStateToProps = state => ({
  quotes: state.quote.quotes,
  vehicles: state.vehicle.vehicles,
  indies: state.clients.indies,
  tariffs: state.rental.tariffs,
}) */

const mapDispatchToProps = dispatch => ({

    onGetVehicle: () => dispatch(getVehicle()),
    onGetIndie: () => dispatch(getIndie()),
    onGetTariff: () => dispatch(getTariff()),
    onUpdateVehicle: vehicle => dispatch(updateVehicle(vehicle)),
    onGetQuote: () => dispatch(getQuote()),
    onAddNewQuote: quote => dispatch(addNewQuote(quote)),
    onUpdateQuote: quote => dispatch(updateQuote(quote)),
    onDeleteQuote: quote => dispatch(deleteQuote(quote)),
})

export default connect(
    /* mapStateToProps, */
    mapDispatchToProps
)(withRouter(withTranslation()(Quotes)))
