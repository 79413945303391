import { call, put, takeEvery } from "redux-saga/effects"

// Ecommerce Redux States
import {
  
  GET_TAX,
  ADD_NEW_TAX,
  DELETE_TAX,
  UPDATE_TAX,
  
} from "./actionTypes"
import {
  
  getTaxFail,
  getTaxSuccess,
  addTaxFail,
  addTaxSuccess,
  updateTaxSuccess,
  updateTaxFail,
  deleteTaxSuccess,
  deleteTaxFail,
  
} from "./actions"

//Include Both Helper File with needed methods
import {
 
  getTax,
  addNewTax,
  updateTax,
  deleteTax,
  
} from "helpers/fakebackend_helper"


function* fetchTax() {
  try {
    const response = yield call(getTax)
    yield put(getTaxSuccess(response))
  } catch (error) {
    yield put(getTaxFail(error))
  }
}

function* onAddNewTax({ payload: tax }) {
  try {
    const response = yield call(addNewTax, tax)
    yield put(addTaxSuccess(response))
  } catch (error) {

    yield put(addTaxFail(error))
  }
}

function* onUpdateTax({ payload: tax }) {
  try {
    const response = yield call(updateTax, tax)
    yield put(updateTaxSuccess(response))
  } catch (error) {
    yield put(updateTaxFail(error))
  }
}

function* onDeleteTax({ payload: tax }) {
  try {
    const response = yield call(deleteTax, tax)
    yield put(deleteTaxSuccess(response))
  } catch (error) {
    yield put(deleteTaxFail(error))
  }
}

function* taxSaga() {
  
  yield takeEvery(GET_TAX, fetchTax)
  yield takeEvery(ADD_NEW_TAX, onAddNewTax)
  yield takeEvery(UPDATE_TAX, onUpdateTax)
  yield takeEvery(DELETE_TAX, onDeleteTax)
  
}

export default taxSaga
