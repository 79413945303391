import usFlag from "../assets/images/flags/us.jpg";
import spain from "../assets/images/flags/spain.jpg";
import french from "../assets/images/flags/french.jpg";
import arabic from "../assets/images/flags/arabic.jpg";
const languages = {
  ar: {
    label: "Arabic",
    flag: arabic,
  },
  es: {
    label: "Spanish",
    flag: spain,
  },
  
  fr: {
    label: "French",
    flag: french,
  },
  en: {
    label: "English",
    flag: usFlag,
  },
}

export default languages;
