import React from "react";
import {
  Form,
  Card,
  CardBody,
  Col,
  Row,
  Container,
} from "reactstrap";
import { Editor } from '@tinymce/tinymce-react';
import Breadcrumbs from "../../components/Common/Breadcrumb";

const FormEditors = () => {
  document.title = "Form Editors | Skote - React Admin & Dashboard Template";

  const initialContent = `
     <header>
                        <Row class="justify-items-center">
                          {/* <Col class="col-sm-7 text-center text-sm-start mb-2  mb-sm-0"> <img id="logo" src={logo} width="90" height="30" title="Medios" alt="Medios"></img> </Col> */}
                          <Col class=" text-center ">
                            <h4 class="mb-0 ">{this.props.t("Rental agreement")}</h4>
                            <p class="mb-2 ">{this.props.t("Contrat No")} : <span class="fw-bold">{reservation.code}</span></p>
                          </Col>
                        </Row>
                        <hr class="my-1" />
                      </header>



                      <Row class="text-center">
                        <Col class="col-sm-4 mb-2 mb-sm-0 text-start"> <span class="text-black-50 text-uppercase ">{this.props.t("Make & Model")}:</span><br />
                          <span class="fw-500 text-3">{car_make + " " + car_model}</span></Col>
                        <Col class="col-sm-4 mb-2 mb-sm-0 text-start"> <span class="text-black-50 text-uppercase">{this.props.t("Car Category")}:</span><br />
                          <span class="fw-500 text-3">{reservation.vehicle_type}</span></Col>
                        <Col class="col-sm-4 text-start"> <span class="text-black-50 text-uppercase">{this.props.t("Particular")}:</span><br />
                          <span class="fw-500 text-3">{this.props.t("Car on Self Drive")}</span> </Col>
                      </Row>
                      <hr class="my-0" />
                      <Row >
                        <Col class="col-sm-3 mb-2 mb-sm-0 text-start"> <span class="text-black-50 text-uppercase">{this.props.t("Rental Start Date")}:</span><br />
                          <span class="fw-500 text-3">{reservation.pickup_date}</span> </Col>
                        <Col class="col-sm-3 mb-2 mb-sm-0 text-start"> <span class="text-black-50 text-uppercase">{this.props.t("Rental End Date")}:</span><br />
                          <span class="fw-500 text-3">{reservation.return_date}</span> </Col>
                        <Col class="col-sm-3 text-start"> <span class="text-black-50 text-uppercase">{this.props.t("Total Day")}<small>(s)</small>:</span><br />
                          <span class="fw-500 text-3">{reservation.days}</span> </Col>
                        <Col class="col-sm-3 mb-2 mb-sm-0 text-start"> <span class="text-black-50 text-uppercase">{this.props.t("Immatriculation No")}:</span><br />
                          <span class="fw-500 text-3">{reservation.plate_number}</span> </Col>
                      </Row>
                      <hr class="my-0" />
                      <Row >
                        <Col class="col-sm-3 mb-2 mb-sm-0 text-start"> <span class="text-black-50 text-uppercase">{this.props.t("Out km")}<small>(s)</small>:</span><br />
                          <span class="fw-500 text-3">{reservation.mileage_pickup || "----"}</span> </Col>
                        <Col class="col-sm-3 mb-2 mb-sm-0 text-start"> <span class="text-black-50 text-uppercase">{this.props.t("In km")}<small>(s)</small>:</span><br />
                          <span class="fw-500 text-3">{reservation.mileage_return || "----"}</span> </Col>
                        <Col class="col-sm-3 mb-2s mb-sm-0 text-start"> <span class="text-black-50 text-uppercase">{this.props.t("Total km")}<small>(s)</small>:</span><br />
                          <span class="fw-500 text-3">{(reservation.mileage_return !== null && reservation.mileage_pickup !== null && reservation.mileage_return - reservation.mileage_pickup > 0) ? reservation.mileage_return - reservation.mileage_pickup || "----" : "----"}</span> </Col>
                        <Col class="col-sm-3 text-start"> <span class="text-black-50 text-uppercase">{this.props.t("Security Deposit")}:</span><br />
                          <span class="fw-500 text-3">{reservation.caution + " dh" || " dh"}</span> </Col>
                      </Row>
                      <hr class="my-1" />
                      <div >
                        <table class="table table-bordered table-sm">
                          <tbody>
                            <tr>
                              <td class="col-4 text-start fw-bold">{this.props.t("Nom du locataire")} :</td>
                              <td class="col-8">{client_infos.first_name + " " + client_infos.last_name}</td>
                            </tr>
                            <tr>
                              <td class="text-start fw-bold"> {this.props.t("Address")}:</td>
                              <td> {client_infos.address + ", " + client_infos.city + ", " + client_infos.country}</td>
                            </tr>

                            <tr>
                              <td class="text-start fw-bold">{this.props.t("Driving License No")}:</td>
                              <td>{client_infos.dl_number}</td>
                            </tr>
                            <tr>
                              <td class="text-start fw-bold">{this.props.t("License Issued By")}:</td>
                              <td>{client_infos.city}</td>
                            </tr>
                            <tr>
                              <td class="text-start fw-bold">{this.props.t("License Expiry Date")}:</td>
                              <td>{client_infos.dl_expiration_date}</td>
                            </tr>
                            <tr>
                              <td class="text-start fw-bold">{this.props.t("Date of Birth")}:</td>
                              <td>{client_infos.date_birth}</td>
                            </tr>
                            <tr>
                              <td class="text-start fw-bold">{this.props.t("ID  No")}:</td>
                              <td>{client_infos.id_number}</td>
                            </tr>

                            <tr>
                              <td class="text-start fw-bold">{this.props.t("Valid Till")}:</td>
                              <td>{client_infos.id_expiration_date}</td>
                            </tr>
                            <tr>
                              <td class="text-start fw-bold">{this.props.t("Remarks")}:</td>
                              <td>{reservation.notes}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      <div class="">
                        <table class="table table-border table-sm">
                          <thead class="card-header">
                            <tr>
                              <td class="col-6 text-start fw-bold">{this.props.t("Rate Sheet")}</td>
                              <td class="col-3 text-end fw-bold">{this.props.t("Rate")}</td>
                              <td class="col-3 text-end fw-bold">{this.props.t("Amount")}</td>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td class="col-6 text-start">{this.props.t("Net de location")} </td>
                              <td class="col-3 text-end">{reservation.amount}</td>
                              <td class="col-3 text-end">{reservation.amount}</td>
                            </tr>
                            <tr>
                              <td class="col-6 text-start">{this.props.t("Divers")}</td>
                              <td class="text-end">{reservation.frais_divers}</td>
                              <td class="text-end">{reservation.frais_divers}</td>
                            </tr>
                            <tr>
                              <td class="col-6 text-start">{this.props.t("Delivery fee")}<br />
                                {/* <span class="text-1">SUMMERFUN - 10% One Time Discount</span>*/}</td>
                              <td class="text-end">{reservation.frais_livraison}</td>
                              <td class="text-end">{reservation.frais_livraison}</td>
                            </tr>
                            <tr>
                              <td class="col-6 text-start">{this.props.t("Return fee")}<br />
                                {/* <span class="text-1">SUMMERFUN - 10% One Time Discount</span>*/}</td>
                              <td class="text-end">{reservation.frais_reprise}</td>
                              <td class="text-end">{reservation.frais_reprise}</td>
                            </tr>
                          </tbody>
                          <tfoot class="card-footer">
                            {/*  <tr>
                        <td colSpan="2" class="text-end"><strong>Sub Total:</strong></td>
                        <td class="text-end">$1350.00</td>
                      </tr>
                      <tr>
                        <td colSpan="2" class="text-end"><strong>Tax @ 18%:</strong></td>
                        <td class="text-end">$243.00</td>
                      </tr> */}
                            <hr class="my-1" />
                            <tr>
                              <td colSpan="2" class="text-end border-bottom-0 "><strong>{this.props.t("Total")}:</strong></td>
                              <td class="text-end border-bottom-0">{parseInt(reservation.amount) + parseInt(reservation.frais_livraison) + parseInt(reservation.frais_reprise) + parseInt(reservation.frais_divers) + " DH"}</td>
                            </tr>
                          </tfoot>

                        </table>

                        <hr></hr>
                        <Row class="text-center mt-0 mb-0 ms-4 me-4" >
                          <p>

                            <strong>{this.props.t("The tenant acknowledges their responsibility for any damages incurred to the car unless the existence of another party responsible for the damages is established.")}

                            </strong></p>
                        </Row>
                      </div>

                      <hr></hr>


                      <div class="">
                        <table class="table table-borderless mb-0">
                          <tbody class="card-header">
                            <tr>

                              <td class="col-4 text-start fw-bold"> {agency.company_name}</td>
                              <td class="col-4 text-center fw-bold">{this.props.t("The tenant")}</td>
                              <td class="col-4 text-end fw-bold">{this.props.t("Return")}</td>
                            </tr>


                            <tr>
                              <td class="col-4 text-start" style={{ height: 60 }}> </td>
                              <td class="col-4 text-center " style={{ height: 60 }}></td>
                              <td class="col-4 text-end" style={{ height: 60 }}></td>
                            </tr>

                          </tbody>
                        </table>
                      </div>
                      <br />
                      <p><strong>{agency.company_name}</strong><br />
                        {agency.address} - {this.props.t("Email")} : {agency.email} <br />
                        {this.props.t("Tél")}: {agency.phone} - {this.props.t("RC")} : {agency.rc} - {this.props.t("T.P")}: {agency.tp} - {this.props.t("ICE")}: {agency.ice} - {this.props.t("IF")}: {agency.id_fiscal}.
                      </p>
  `;

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Forms" breadcrumbItem="Form Editors" />
          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                  <h4 className="card-title">Tinymce wysihtml5</h4>
                  <p className="card-title-desc">Bootstrap-wysihtml5 is a javascript
                    plugin that makes it easy to create simple, beautiful wysiwyg editors
                    with the help of wysihtml5 and Twitter Bootstrap.</p>
                  <Form method="post">
                    <Editor
                      apiKey='n5hzwzswz2poueztddx36b0iqd5kec4s6pa805sonekkxcv8'
                      initialValue={initialContent}
                      init={{
                        height: 350,
                        menubar: true,
                        plugins: [
                          'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                          'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                          'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                        ],
                        toolbar: 'undo redo | blocks | ' +
                          'bold italic forecolor | alignleft aligncenter ' +
                          'alignright alignjustify | bullist numlist outdent indent | ' +
                          'removeformat | help',
                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                      }}
                    />
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default FormEditors;