import {
 
  GET_SUPPLIER_FAIL,
  GET_SUPPLIER_SUCCESS,
  ADD_SUPPLIER_SUCCESS,
  ADD_SUPPLIER_FAIL,
  UPDATE_SUPPLIER_SUCCESS,
  UPDATE_SUPPLIER_FAIL,
  DELETE_SUPPLIER_SUCCESS,
  DELETE_SUPPLIER_FAIL,
  
} from "./actionTypes"

const INIT_STATE = {
 
  suppliers: []
  
}

const Supplier = (state = INIT_STATE, action) => {
  switch (action.type) {
    
    case GET_SUPPLIER_SUCCESS:
      return {
        ...state,
        suppliers: action.payload,
      }

    case GET_SUPPLIER_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_SUPPLIER_SUCCESS:
      return {
        ...state,
        suppliers: [...state.suppliers, action.payload],
      }

    case ADD_SUPPLIER_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_SUPPLIER_SUCCESS:
      return {
        ...state,
        suppliers: state.suppliers.map(supplier =>
          supplier.code.toString() === action.payload.code.toString()
            ? { supplier, ...action.payload }
            : supplier
        ),
      }

    case UPDATE_SUPPLIER_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_SUPPLIER_SUCCESS:
      return {
        ...state,
        suppliers: state.suppliers.filter(
          supplier => supplier.code.toString() !== action.payload.code.toString()
        ),
      }

    case DELETE_SUPPLIER_FAIL:
      return {
        ...state,
        error: action.payload,
      }

   
    default:
      return state
  }
}

export default Supplier
