import i18n from "i18next"
import detector from "i18next-browser-languagedetector"
import { initReactI18next } from "react-i18next"

import translationFr from "./locales/fr/translation.json"
import translationSP from "./locales/sp/translation.json"
import translationENG from "./locales/en/translation.json"
import translationAR from "./locales/ar/translation.json"

// the translations
const resources = {
  ar: {
    translation: translationAR,
  },
  fr: {
    translation: translationFr,
  },
  es: {
    translation: translationSP,
  },
  eng: {
    translation: translationENG,
  },
}

/* const language = localStorage.getItem("I18N_LANGUAGE")
if (!language) {
  localStorage.setItem("I18N_LANGUAGE", "en")
} */
  const supportedLanguages = ['fr', 'en', 'es', 'ar'];

  const language = localStorage.getItem("I18N_LANGUAGE");
  
  // Check if language is not set in localStorage and browser language is supported
  const detectedLanguage = navigator.language.slice(0, 2).toLowerCase(); // Get first two characters (language code)
  const shouldUseDetectedLanguage = !language && supportedLanguages.includes(detectedLanguage);
  
  const initialLanguage = shouldUseDetectedLanguage ? detectedLanguage : 'en'; // Default to en if not supported
  localStorage.setItem("I18N_LANGUAGE",language || (supportedLanguages.includes(detectedLanguage)?detectedLanguage:"en"))
  //console.log(initialLanguage,detectedLanguage,language,shouldUseDetectedLanguage)
i18n
  .use(detector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: language || (supportedLanguages.includes(detectedLanguage)?detectedLanguage:"en"),
    fallbackLng: "en", // use en if detected lng is not available

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  })

export default i18n
