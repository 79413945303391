import {
 
  GET_TARIFF_FAIL,
  GET_TARIFF_SUCCESS,
  ADD_TARIFF_SUCCESS,
  ADD_TARIFF_FAIL,
  UPDATE_TARIFF_SUCCESS,
  UPDATE_TARIFF_FAIL,
  DELETE_TARIFF_SUCCESS,
  DELETE_TARIFF_FAIL,
  
} from "./actionTypes"

const INIT_STATE = {
 
  tariffs: []
  
}

const Tariff = (state = INIT_STATE, action) => {
  switch (action.type) {
    
    case GET_TARIFF_SUCCESS:
      return {
        ...state,
        tariffs: action.payload,
      }

    case GET_TARIFF_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_TARIFF_SUCCESS:
      return {
        ...state,
        tariffs: [...state.tariffs, action.payload],
      }

    case ADD_TARIFF_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_TARIFF_SUCCESS:
      return {
        ...state,
        tariffs: state.tariffs.map(tariff =>
          tariff.code.toString() === action.payload.code.toString()
            ? { tariff, ...action.payload }
            : tariff
        ),
      }

    case UPDATE_TARIFF_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_TARIFF_SUCCESS:
      return {
        ...state,
        tariffs: state.tariffs.filter(
          tariff => tariff.code.toString() !== action.payload.code.toString()
        ),
      }

    case DELETE_TARIFF_FAIL:
      return {
        ...state,
        error: action.payload,
      }

   
    default:
      return state
  }
}

export default Tariff
