import {
 
  GET_INDIE_FAIL,
  GET_INDIE_SUCCESS,
  ADD_INDIE_SUCCESS,
  ADD_INDIE_FAIL,
  UPDATE_INDIE_SUCCESS,
  UPDATE_INDIE_FAIL,
  DELETE_INDIE_SUCCESS,
  DELETE_INDIE_FAIL,

  GET_COMPANY_FAIL,
  GET_COMPANY_SUCCESS,
  ADD_COMPANY_SUCCESS,
  ADD_COMPANY_FAIL,
  UPDATE_COMPANY_SUCCESS,
  UPDATE_COMPANY_FAIL,
  DELETE_COMPANY_SUCCESS,
  DELETE_COMPANY_FAIL,
  
} from "./actionTypes"

const INIT_STATE = {
 
  indies: [],
  companies:[]
  
}

const Clients = (state = INIT_STATE, action) => {
  switch (action.type) {
    
    case GET_INDIE_SUCCESS:
      return {
        ...state,
        indies: action.payload,
      }

    case GET_INDIE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_INDIE_SUCCESS:
      return {
        ...state,
        indies: [...state.indies, action.payload],
      }

    case ADD_INDIE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_INDIE_SUCCESS:
      return {
        ...state,
        indies: state.indies.map(indie =>
          indie.code.toString() === action.payload.code.toString()
            ? { indie, ...action.payload }
            : indie
        ),
      }

    case UPDATE_INDIE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_INDIE_SUCCESS:
      return {
        ...state,
        indies: state.indies.filter(
          indie => indie.code.toString() !== action.payload.code.toString()
        ),
      }

    case DELETE_INDIE_FAIL:
      return {
        ...state,
        error: action.payload,
      }



      //companies

      case GET_COMPANY_SUCCESS:
      return {
        ...state,
        companies: action.payload,
      }

    case GET_COMPANY_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_COMPANY_SUCCESS:
      return {
        ...state,
        companies: [...state.companies, action.payload],
      }

    case ADD_COMPANY_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_COMPANY_SUCCESS:
      return {
        ...state,
        companies: state.companies.map(indie =>
          indie.code.toString() === action.payload.code.toString()
            ? { indie, ...action.payload }
            : indie
        ),
      }

    case UPDATE_COMPANY_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_COMPANY_SUCCESS:
      return {
        ...state,
        companies: state.companies.filter(
          indie => indie.code.toString() !== action.payload.code.toString()
        ),
      }

    case DELETE_COMPANY_FAIL:
      return {
        ...state,
        error: action.payload,
      }


   
    default:
      return state
  }
}

export default Clients
