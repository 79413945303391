
/* REPAIR */
export const GET_REPAIR = "GET_REPAIR"
export const GET_REPAIR_SUCCESS = "GET_REPAIR_SUCCESS"
export const GET_REPAIR_FAIL = "GET_REPAIR_FAIL"

export const ADD_NEW_REPAIR = "ADD_NEW_REPAIR"
export const ADD_REPAIR_SUCCESS = "ADD_REPAIR_SUCCESS"
export const ADD_REPAIR_FAIL = "ADD_REPAIR_FAIL"

export const UPDATE_REPAIR = "UPDATE_REPAIR"
export const UPDATE_REPAIR_SUCCESS = "UPDATE_REPAIR_SUCCESS"
export const UPDATE_REPAIR_FAIL = "UPDATE_REPAIR_FAIL"

export const DELETE_REPAIR = "DELETE_REPAIR"
export const DELETE_REPAIR_SUCCESS = "DELETE_REPAIR_SUCCESS"
export const DELETE_REPAIR_FAIL = "DELETE_REPAIR_FAIL"

