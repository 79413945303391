import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import MetaTags from 'react-meta-tags';
import { withRouter } from "react-router-dom"
import { countBy, isEmpty, size } from "lodash"
import BootstrapTable from "react-bootstrap-table-next"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import Dropzone from "react-dropzone"
import toastr from "toastr";
import toast, { Toaster } from 'react-hot-toast';


import { withTranslation } from "react-i18next"

// Add the Firebase products that you want to use
import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import "firebase/database"
import "firebase/storage"

import
paginationFactory, {
    PaginationProvider,
    PaginationListStandalone,
} from "react-bootstrap-table2-paginator"

import maintanence from "../../../assets/images/coming-soon.svg"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import { Link } from "react-router-dom"
import * as moment from 'moment';
import Select from "react-select"
import jsPDF from "jspdf";
import "jspdf-autotable";
import { UncontrolledDropdown, DropdownItem, DropdownMenu, DropdownToggle, Form, InputGroup, Input, FormGroup, Label, Button, Card, CardBody, Col, Table, ModalFooter, Container, Row, Modal, Badge, ModalHeader, ModalBody } from "reactstrap"

import { AvForm, AvField } from "availity-reactstrap-validation"
import SweetAlert from "react-bootstrap-sweetalert"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import {
    getTariff,
    addNewTariff,
    updateTariff,
    deleteTariff
} from "store/actions"


import exportFromJSON from 'export-from-json'
import { isThisExpression } from "@babel/types";

class Tariffs extends Component {
    constructor(props) {
        super(props)
        this.state = {
            user_id:"",
            loading: true,
            submitLoading: false,
            isShow: false,
            changed: false,
            gender: "",
            viewmodal: false,
            modal: false,
            isDelete: false,
            no_data: false,
            success_dlg: false,
            dynamic_title: '',
            dynamic_description: '',
            vc: "",
            selectedGroup: null,
            tariffs: [],
            tariff: [],
            date_birth: new Date(),
            TariffColumns: [

                {
                    dataField: "tariff_name",
                    text: this.props.t("name"),
                    sort: true,
                    formatter: (cellContent, row) => (
                        <Link to="#" className="text-body fw-bold">
                            {row.tariff_name}
                        </Link>
                    ),
                },
                {
                    dataField: "daily_rate",
                    isDummyField: true,
                    text: this.props.t("Daily Rate"),
                    sort: true,
                    formatter: (cellContent, row) => (
                        <>
                            <i className="far fa-clock me-1"
                            />{" "}
                            {row.daily_rate} dh
                        </>
                    ),
                },

                {
                    dataField: "monthly_rate",
                    isDummyField: true,
                    text: this.props.t("Monthly Rate"),
                    sort: true,
                    formatter: (cellContent, row) => (
                        <>
                            <i className="far fa-calendar-alt me-1"
                            />{" "}
                            {row.monthly_rate} dh
                        </>
                    ),
                },

                {
                    dataField: "action",
                    isDummyField: true,
                    text: this.props.t("Action"),
                    formatter: (cellContent, tariff) => (
                        <>
                            <div className="d-flex gap-3">
                                <Link to="#" className="text-success">
                                    <i className="mdi mdi-pencil font-size-18" id="edittooltip" onClick={() => this.handleTariffClick(tariff)} />
                                </Link>
                                <Link to="#" className="text-danger">
                                    <i className="mdi mdi-delete font-size-18" id="deletetooltip" onClick={() => this.setState({ isDelete: true, vc: tariff })} />
                                </Link>
                            </div>
                        </>
                    ),
                },

            ],
            selectedFiles: []
        }

        this.handleTariffClick = this.handleTariffClick.bind(this)
        this.toggle = this.toggle.bind(this)
        this.handleValidTariffSubmit = this.handleValidTariffSubmit.bind(this)
        this.handleTariffClicks = this.handleTariffClicks.bind(this)
        this.toggleDelete = this.toggleDelete.bind(this)
        this.handleViewClick = this.handleViewClick.bind(this)
        this.toggleIsShow = this.toggleIsShow.bind(this)
    }



    /**
     * Formats the size
     */



    componentDidMount() {
        const { tariffs, onGetTariff } = this.state
        if (tariffs && !tariffs.length) {
            //onGetTariff()
            this.getTariff()
        }
        this.setState({ tariffs })

    }
    getTariff = async () => {
        this.setState({ loading: true });

    // Unsubscribe previous listeners to prevent memory leaks
    if (this.unsubscribeTariffs) {
      this.unsubscribeTariffs();
    }

    this.unsubscribeTariffs = firebase.auth().onAuthStateChanged(async user => {
      if (user) {
        const userId = user.uid;
        const usersCollection = firebase.firestore().collection("users")
        const userDocRef = usersCollection.doc(userId);
        
        try {
          const userDoc = await userDocRef.get();
          if (userDoc.exists) {
            const userId = userDoc.data().id;
            const userTariffsCollectionRef = usersCollection.doc(userId).collection("tariffs");
            this.setState({
                user_id:userId,
                account_user: userDoc.data()
              })
            this.unsubscribeTariffs = userTariffsCollectionRef.onSnapshot(snapshot => {
              let t = [];
              snapshot.docs.forEach(doc => {
                t.push(doc.data());
              });

              this.setState({
                loading: false,
                tariffs: t
              });
            });

            localStorage.setItem("authUser", JSON.stringify(user));
          } else {
            // User document not found
            localStorage.removeItem("authUser");
            this.setState({
              loading: false,
              tariffs: []
            });
          }
        } catch (error) {
          console.error("Error fetching user document:", error);
          // Handle error
        }
      } else {
        localStorage.removeItem("authUser");
        // Clear the reservations if user is not authenticated
        this.setState({
          loading: false,
          tariffs: []
        });
      }
    });

    }
    // eslint-disable-next-line no-unused-vars
    /* componentDidUpdate(prevProps, prevState, snapshot) {
        const { tariffs } = this.state


    } */

    toggle() {
        this.setState(prevState => ({
            modal: !prevState.modal,
        }))
    }
    toggleDelete() {
        this.setState(prevState => ({
            isDelete: !prevState.isDelete,
        }))
    }

    handleTariffClicks = () => {
        this.setState({ tariff: [], isEdit: false, isShow: false })

        this.toggle()
    }

    // eslint-disable-next-line no-unused-vars
    handleTableChange = (type, { page, searchText }) => {
        const { tariffs } = this.props
        this.setState({
            tariffs: tariffs.filter(tariff =>
                Object.keys(tariff).some(
                    key =>
                        typeof tariff[key] === "string" &&
                        tariff[key].toLowerCase().includes(searchText.toLowerCase())
                )
            ),
        })
    }

    toggleViewModal = () => {
        this.setState(prevState => ({
            viewmodal: !prevState.viewmodal,
        }))
    }

    toggleIsShow = () => {
        this.setState(prevState => ({
            isShow: !prevState.isShow,
        }))
    }


    /* Insert,Update Delete data */

    handleDeleteTariff = (tariff) => {


        const { onDeleteTariff } = this.state
        /* if (tariff['code'] === '') {
            //
        } else { */
        //onDeleteTariff(tariff)
        this.deleteTariff(tariff)
        this.toggleDelete()
        //}

    }

    handleViewClick = arg => {
        const tariff = arg

        this.setState({
            tariff: {
                id: tariff.id,
                code:tariff.code,
                tariff_name: tariff.tariff_name,
                daily_rate: tariff.daily_rate,
                monthly_rate: tariff.monthly_rate,

            },

            isShow: true,
            isEdit: false
        })

        //this.toggleIsShow()
        this.toggle()

        //this.toggleViewModal()
    }


    handleTariffClick = arg => {
        const tariff = arg

        //this.setState({selectedGroup:staff.designation})
        this.setState({
            tariff: {
                id: tariff.id,
                code:tariff.code,
                tariff_name: tariff.tariff_name,
                daily_rate: tariff.daily_rate,
                monthly_rate: tariff.monthly_rate,
            },
            isEdit: true,
            isShow: false,
        })

        this.toggle()
    }

    /**
     * Handling submit Order on Order form
     */
    handleValidTariffSubmit = (e, values) => {
        const { onAddNewTariff, onUpdateTariff } = this.props
        const { isEdit, tariffs, selectedTariff } = this.state
         const cds = []
         this.state.tariffs.map(spl =>
             cds.push(spl.code)
         )
         const cd =cds.length==0?1:Math.max.apply(null,cds)+1
        /*  let des= this.state.selectedGroup.value
         if(this.state.changed){
           des= this.state.selectedGroup.value
           this.setState({changed:false})
         }else{
           des=this.state.selectedGroup
         }
          */

        /* this.setState({
          gender:this.state.staffs.gender
        }) */
        var na = 0, naA = 0
        const foundna = tariffs.filter(element => element.tariff_name === values.tariff_name && this.state.tariff.id !== element.id);
        na = foundna.length


        const foundnaA = tariffs.filter(element => element.tariff_name === values.tariff_name);
        naA = foundnaA.length
        if (isEdit) {
            //des=this.state.selectedGroup.defaultInputValue

            const updateTariff = {
                code:cd,
                id: this.state.tariff.id,
                tariff_name: values.tariff_name.trim(),
                daily_rate: parseInt(values.daily_rate),
                monthly_rate:parseInt(values.monthly_rate) ,
            }

            // update Order
            ///onUpdateTariff(updateTariff)
            if (this.state.tariff.tariff_name == updateTariff.tariff_name
                && this.state.tariff.daily_rate == updateTariff.daily_rate
                && this.state.tariff.monthly_rate == updateTariff.monthly_rate) {
                this.setState({ submitLoading: false })
                this.toggle()
            } else {
                this.updateTariff(updateTariff, na)
            }

        } else {

            const newTariff = {
                code:cd,
                tariff_name: values['tariff_name'],
                daily_rate: parseInt(values['daily_rate']),
                monthly_rate: parseInt(values['monthly_rate']),
            }
            ////console.log(des)
            // save new Order
            //onAddNewTariff(newTariff)
            this.setState({ tariff: newTariff })
            this.addNewTariff(newTariff, naA)
        }
        this.setState({ selectedTariff: null })
        //this.toggle()
    }

    deleteTariff = (tariff) => {
        ////console.log(supplier)
        const collection = firebase.firestore().collection("users").doc(this.state.user_id).collection("tariffs")
        const timeline = {
            statusTitle: "Rate deleted",
            iconClass: "bx bx-money h2 text-danger",
            description: "Rental rate information has been deleted for ",
            data: tariff.tariff_name,
            time: new Date().getTime(),
      
          }
        collection.doc(tariff.id).delete().then(() => {

            //console.log("Element  deleted!");
            toast.success(this.props.t("Rate infos Deleted "))
            this.addNewTimeline(timeline)
            this.setState({
                success_dlg: true,
                dynamic_title: this.props.t("Deleted"),
                dynamic_description: this.props.t("Rate has been deleted."),
            })
        }).catch((error) => {
            console.error("Error removing element: ", error);
            toast.error(this.props.t("Error removing element"))

        });

    }

    updateTariff = (tariff, na) => {

        this.setState({ submitLoading: true })
        const collection = firebase.firestore().collection("users").doc(this.state.user_id).collection("tariffs")
        const timeline = {
            statusTitle: "Rate updated",
            iconClass: "bx bx-money h2 text-warning",
            description: "Rental rate information has been updated for ",
            data: tariff.tariff_name,
            time: new Date().getTime(),
      
          }

        if (na == 0) {


            collection.doc(tariff.id).update({
                code:tariff.code,
                tariff_name: tariff.tariff_name,
                daily_rate: tariff.daily_rate,
                monthly_rate: tariff.monthly_rate,

            }).then(() => {
                //console.log("Document  Updated!");
                toast.success(this.props.t("Rate updated "))
                this.addNewTimeline(timeline)
                this.setState({ submitLoading: false })
                this.toggle()
            }).catch((error) => {
                console.error("Error updating document: ", error);
                toast.error(this.props.t("Error updating document"))
                this.setState({ submitLoading: false })
            });
        } else {

            if (na != 0) {
                console.error("Tariff already exist ");
                toast.error(this.props.t("Rate already exist"))
                this.setState({ submitLoading: false })
            }
        }
    }

    addNewTimeline = async (timeline) => {
        try {
            const { user_id, account_user } = this.state;
            const collection = firebase.firestore().collection("users").doc(user_id).collection("timeline");
            const newDoc = collection.doc();
            const by = account_user.isAdmin ? 'Admin' : `${account_user.first_name} ${account_user.last_name}`;
    
            await newDoc.set({
                id: newDoc.id,
                statusTitle: timeline.statusTitle,
                iconClass: timeline.iconClass,
                description: timeline.description,
                time: timeline.time,
                data:timeline.data,
                user: by
            });
            
            //console.log("Timeline  written!");
        } catch (error) {
            console.error("Error adding timeline:", error);
        }
    }
    addNewTariff = (tariff, na) => {

        const collection = firebase.firestore().collection("users").doc(this.state.user_id).collection("tariffs")

        this.setState({ submitLoading: true })
        const timeline = {
            statusTitle: "New Rate created",
            iconClass: "bx bx-money h2 text-success",
            description: "Rental rate information has been created ",
            data: tariff.tariff_name,
            time: new Date().getTime(),
      
          }
        if (na == 0) {

            var newDoc = collection.doc();
            newDoc.set(
                {
                    id: newDoc.id,
                    code:tariff.code,
                    tariff_name: tariff.tariff_name,
                    daily_rate: tariff.daily_rate,
                    monthly_rate: tariff.monthly_rate,

                }).then(() => {
                    //console.log("trf  written!");
                    toast.success(this.props.t("Rate added "))
                    this.addNewTimeline(timeline)
                    this.setState({ submitLoading: false })
                    this.toggle()
                })
                .catch((error) => {
                    console.error("Error Adding tariff: ", error);
                    toast.error(this.props.t("Error Adding Rate"))
                    this.setState({ submitLoading: false })
                });

        } else {

            if (na != 0) {
                console.error("Tariff already exist ");
                toast.error(this.props.t("Rate already exist"))
                this.setState({ submitLoading: false })
            }
        }
    }



    render() {
        
        const emptyDataMessage = () => {

            return <Container>
                <Row>
                    <Col lg="12">
                        {this.state.loading ?
                            <div className="text-center">
                                <Row className="justify-content-center mt-5">
                                    <Col sm="4">
                                        <div>
                                            <div >
                                                <div className="spinner-chase">
                                                    <div className="chase-dot"></div>
                                                    <div className="chase-dot"></div>
                                                    <div className="chase-dot"></div>
                                                    <div className="chase-dot"></div>
                                                    <div className="chase-dot"></div>
                                                    <div className="chase-dot"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>

                            :
                            <div className="text-center">

                                <Row className="justify-content-center mt-5">
                                    <Col sm="4">
                                        <div className="maintenance-img">
                                            <img
                                                src={maintanence}
                                                alt=""
                                                className="img-fluid mx-auto d-block"
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <h4 className="mt-5">{this.props.t("Let's get started with Medios")}</h4>
                                <p className="text-muted">
                                    {this.props.t("Start adding your Rates by clicking")}<h5 className="text-primary"><Link onClick={this.handleTariffClicks} >{this.props.t("New Rate")}</Link></h5>
                                </p>


                            </div>
                        }

                    </Col>
                </Row>
            </Container>


                ;
        }


        const { tariffs } = this.state
        const data = tariffs;
        let dataxcl = []
        data.map(function (item) {
            delete item.tariff;
            dataxcl.push({
                tariff_name: item.tariff_name,
                daily_rate: item.daily_rate,
                monthly_rate: item.monthly_rate,
            })
        });

        const { selectedGroup } = this.state
        const { SearchBar } = Search
        const { isEdit } = this.state
        const { isDelete } = this.state
        const { vc } = this.state

        //pagination customization


        const defaultSorted = [{
            dataField: 'daily_rate',
            order: 'asc'
        }];

        const selectRow = {
            mode: 'checkbox',
        };
        function ExportToExcel() {
            exportFromJSON({ data: dataxcl, fileName: 'Tariffs', exportType: exportFromJSON.types.xls })
        }
        function exportPDF() {
            const unit = "pt";
            const size = "A4"; // Use A1, A2, A3 or A4
            const orientation = "portrait"; // portrait or landscape

            const marginLeft = 20;
            const doc = new jsPDF(orientation, unit, size);

            doc.setFontSize(15);

            const title = "Tariffs Infos";
            const headers = [["Tariff Name", "Daily Rate", "Monthly Rate"]];

            const dt = data.map(elt => [elt.tariff_name, elt.daily_rate, elt.monthly_rate]);

            let content = {
                startY: 50,
                head: headers,
                body: dt
            };

            doc.text(title, marginLeft, 20);
            doc.autoTable(content);
            doc.save("Tariffs.pdf")
        }
        function printPDF() {
            const unit = "pt";
            const size = "A4"; // Use A1, A2, A3 or A4
            const orientation = "portrait"; // portrait or landscape

            const marginLeft = 20;
            const doc = new jsPDF(orientation, unit, size);

            doc.setFontSize(15);

            const title = "Tariffs Infos";
            const headers = [["Tariff Name", "Daily Rate", "Monthly Rate"]];

            const dt = data.map(elt => [elt.tariff_name, elt.daily_rate, elt.monthly_rate]);

            let content = {
                startY: 50,
                head: headers,
                body: dt
            };

            doc.text(title, marginLeft, 20);
            doc.autoTable(content);
            var string = doc.output('datauristring');
            var embed = "<embed width='100%' height='100%' src='" + string + "'/>"
            var x = window.open();
            x.document.open();
            x.document.write(embed);
            x.document.close();

        }

        return (

            <React.Fragment>


                <div className="page-content">
                    <MetaTags>
                        <title>{this.props.t("Rates | Medios - Car Rental Management System")}</title>
                    </MetaTags>
                    <Container fluid>
                        <Breadcrumbs title={this.props.t("Rental")} breadcrumbItem={this.props.t("Rates")} />
                        {this.state.success_dlg ? (
                            <SweetAlert
                                success
                                title={this.state.dynamic_title}
                                onConfirm={() => this.setState({ success_dlg: false })}
                            >
                                {this.state.dynamic_description}
                            </SweetAlert>
                        ) : null}

                        <Row>
                            <Col xs="12">
                                <Card>
                                    <CardBody>
                                        <PaginationProvider
                                            pagination={paginationFactory()}
                                            keyField='id'
                                            columns={(this.state.TariffColumns || [])}
                                            data={(tariffs || [])}
                                        >
                                            {({ paginationProps, paginationTableProps }) => (
                                                <ToolkitProvider
                                                    keyField="id"
                                                    data={tariffs}
                                                    columns={(this.state.TariffColumns || [])}
                                                    bootstrap4
                                                    search
                                                >
                                                    {toolkitProps => (
                                                        <React.Fragment>
                                                            <Row className="mb-2" class="noPrint">
                                                                <Col sm="4">
                                                                    <div className="search-box me-2 mb-2 d-inline-block">
                                                                        <div className="position-relative">
                                                                            <SearchBar
                                                                                {...toolkitProps.searchProps}
                                                                            />
                                                                            <i className="bx bx-search-alt search-icon" />
                                                                        </div>
                                                                    </div>
                                                                </Col>

                                                                <Col sm="8" >

                                                                    <div className="text-sm-end" >
                                                                        <Button
                                                                            type="button"
                                                                            color="success"
                                                                            className="btn-rounded mb-2 me-2"
                                                                            onClick={this.handleTariffClicks}
                                                                        >
                                                                            <i className="mdi mdi-plus me-1" />{" "}
                                                                            {this.props.t("New Rate")}
                                                                        </Button>

                                                                        <Link to="#"

                                                                        >

                                                                            {/*  <i className="mdi mdi-file-export-outline font-size-18" /> */}
                                                                            <td>
                                                                                <UncontrolledDropdown>
                                                                                    <DropdownToggle href="#" className="card-drop" tag="a">
                                                                                        <i className="mdi mdi-dots-vertical font-size-18" />
                                                                                    </DropdownToggle>
                                                                                    <DropdownMenu className="dropdown-menu-end">
                                                                                        <DropdownItem href="#" onClick={() => this.state.tariffs.length == 0 ? this.setState({ no_data: true }) : exportPDF()}>
                                                                                            <i className="mdi mdi-file-pdf-box font-size-16 text-danger me-1" />{" "}
                                                                                            {this.props.t("Export PDF")}

                                                                                        </DropdownItem>
                                                                                        <DropdownItem href="#" onClick={() => this.state.tariffs.length == 0 ? this.setState({ no_data: true }) : ExportToExcel()} >

                                                                                            <i className="mdi mdi-microsoft-excel font-size-16 text-success me-1" />{" "}
                                                                                            {this.props.t("Export Excel")}
                                                                                        </DropdownItem>
                                                                                        <DropdownItem href="#" onClick={() => this.state.tariffs.length == 0 ? this.setState({ no_data: true }) : printPDF()}>
                                                                                            <i className="mdi mdi-printer font-size-16 text-grey me-1" />{" "}
                                                                                            {this.props.t("Print")}
                                                                                        </DropdownItem>
                                                                                    </DropdownMenu>
                                                                                </UncontrolledDropdown>

                                                                            </td>
                                                                        </Link>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <div className="table-responsive">
                                                                <BootstrapTable

                                                                    {...toolkitProps.baseProps}
                                                                    {...paginationTableProps}
                                                                    responsive
                                                                    defaultSorted={defaultSorted}
                                                                    bordered={false}
                                                                    striped={false}
                                                                    noDataIndication={emptyDataMessage}
                                                                    classes={
                                                                        "table align-middle table-nowrap table-check"
                                                                    }
                                                                    headerWrapperClasses={"table-light"}
                                                                />
                                                                <Modal
                                                                    isOpen={this.state.modal}
                                                                    className={this.props.className}
                                                                    size='md'
                                                                >
                                                                    <ModalHeader toggle={this.toggle} tag="h4">
                                                                        {!!isEdit ? this.props.t("Edit Rates") : null}
                                                                        {!!this.state.isShow ? this.props.t("Rates Infos") : null}
                                                                        {!isEdit && !this.state.isShow ? this.props.t("Add Rate") : null}
                                                                    </ModalHeader>
                                                                    <ModalBody>
                                                                        {this.state.submitLoading ?

                                                                            <Col>
                                                                                <div>
                                                                                    <div >
                                                                                        <div className="spinner-chase">
                                                                                            <div className="chase-dot"></div>
                                                                                            <div className="chase-dot"></div>
                                                                                            <div className="chase-dot"></div>
                                                                                            <div className="chase-dot"></div>
                                                                                            <div className="chase-dot"></div>
                                                                                            <div className="chase-dot"></div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <br />
                                                                                <h6 className='text-center'>{this.props.t("Uploading data ...")}</h6>
                                                                            </Col>
                                                                            :
                                                                            <AvForm
                                                                                onValidSubmit={
                                                                                    this.handleValidTariffSubmit
                                                                                }
                                                                                disabled={this.state.isShow ? true : false}
                                                                            >
                                                                                <Row>
                                                                                    <Col md="12">
                                                                                        <FormGroup className="mb-3">
                                                                                            <Label htmlFor="validationCustom01">
                                                                                                {this.props.t("Rate name")}
                                                                                            </Label>
                                                                                            <AvField
                                                                                                name="tariff_name"
                                                                                                placeholder={this.props.t("Rate name")}
                                                                                                type="text"
                                                                                                errorMessage={this.props.t("Enter Rate name")}
                                                                                                className="form-control"
                                                                                                validate={{ required: { value: true } }}
                                                                                                id="validationCustom01"
                                                                                                value={this.state.tariff.tariff_name || ""}

                                                                                            />
                                                                                        </FormGroup>
                                                                                    </Col>

                                                                                </Row>
                                                                                <Row>
                                                                                    <Col md="6">

                                                                                        <FormGroup className="mb-3">
                                                                                            <Label htmlFor="validationCustom01">
                                                                                                {this.props.t("Daily Rate")}
                                                                                            </Label>
                                                                                            <AvField
                                                                                                name="daily_rate"
                                                                                                placeholder={this.props.t("Daily Rate")}
                                                                                                type="text"
                                                                                                errorMessage={this.props.t("Enter Daily Rate")}
                                                                                                className="form-control"
                                                                                                validate={{ required: { value: true } }}
                                                                                                id="validationCustom01"
                                                                                                value={this.state.tariff.daily_rate || ""}

                                                                                            />
                                                                                        </FormGroup>
                                                                                    </Col>
                                                                                    <Col md="6">
                                                                                        <FormGroup className="mb-3">
                                                                                            <Label htmlFor="validationCustom01">
                                                                                                {this.props.t("Monthly Rate")}
                                                                                            </Label>
                                                                                            <AvField
                                                                                                name="monthly_rate"
                                                                                                placeholder={this.props.t("Monthly Rate")}
                                                                                                type="text"
                                                                                                errorMessage={this.props.t("Enter Monthly Rate")}
                                                                                                className="form-control"
                                                                                                validate={{ required: { value: true } }}
                                                                                                id="validationCustom01"
                                                                                                value={this.state.tariff.monthly_rate || ""}

                                                                                            />
                                                                                        </FormGroup>
                                                                                    </Col>
                                                                                </Row>





                                                                                {this.state.isShow ? null :
                                                                                    <Row>
                                                                                        <Col>
                                                                                            <div className="text-end">

                                                                                                <button
                                                                                                    type="submit"
                                                                                                    className="btn btn-success save-user"
                                                                                                >
                                                                                                    {this.props.t("Save")}
                                                                                                </button>
                                                                                            </div>
                                                                                        </Col>
                                                                                    </Row>
                                                                                }

                                                                            </AvForm>
                                                                        }
                                                                    </ModalBody>
                                                                </Modal>


                                                                {this.state.isDelete ? (
                                                                    <SweetAlert
                                                                        title={this.props.t("Are you sure?")}
                                                                        warning
                                                                        showCancel
                                                                        confirmButtonText={this.props.t("Yes, delete it!")}
                                                                        confirmBtnBsStyle="success"
                                                                        cancelBtnBsStyle="danger"
                                                                        onConfirm={() =>
                                                                            this.handleDeleteTariff(vc)
                                                                        }
                                                                        onCancel={() =>
                                                                            this.setState({
                                                                                isDelete: false,
                                                                            })
                                                                        }
                                                                    >
                                                                        {this.props.t("You won't be able to revert this!")}
                                                                    </SweetAlert>
                                                                ) : null}
                                                                {this.state.no_data ? (
                                                                    <SweetAlert
                                                                        title={this.props.t("No data!")}
                                                                        warning
                                                                        onConfirm={() => this.setState({ no_data: false })}
                                                                    >
                                                                        {this.props.t("There is no data to export")}
                                                                    </SweetAlert>
                                                                ) : null}

                                                            </div>

                                                        </React.Fragment>
                                                    )}
                                                </ToolkitProvider>
                                            )}
                                        </PaginationProvider>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <Toaster
  position="top-right"
  reverseOrder={false}
/>
            </React.Fragment>
        )
    }
}

Tariffs.propTypes = {
    tariffs: PropTypes.array,
    onGetTariff: PropTypes.func,
    onAddNewTariff: PropTypes.func,
    onDeleteTariff: PropTypes.func,
    onUpdateTariff: PropTypes.func,
    className: PropTypes.any,
    t: PropTypes.any,
}

const mapStateToProps = state => ({
    tariffs: state.rental.tariffs
})

const mapDispatchToProps = dispatch => ({
    onGetTariff: () => dispatch(getTariff()),
    onAddNewTariff: tariff => dispatch(addNewTariff(tariff)),
    onUpdateTariff: tariff => dispatch(updateTariff(tariff)),
    onDeleteTariff: tariff => dispatch(deleteTariff(tariff)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withTranslation()(Tariffs)))
