import PropTypes from 'prop-types'
import React from "react"
import { Link } from "react-router-dom"
import { map } from "lodash"
import {
  Badge,
  Card,
  CardImg,
  CardBody,
  Col, Row,
  Media,
} from "reactstrap"
import { vehicleImages } from "assets/images/vehicle"
import StarRatings from "react-star-ratings"

const CardVehicle = ({ vehicles }) => {
  return (
    <React.Fragment>
      {map(vehicles, (vehicle, dkey) => (
        
        <Col md={6} xl={3} key={"__projectcd_" + dkey}>
  
          <Card style={{ borderRadius: 10 }} className="shadow">
            <CardImg style={{ borderRadius: 10 }} top  src={vehicleImages[vehicle.subImage]} alt={vehicle.make + " " + vehicle.model} />
            <CardBody>

              {/* <img
                                  src={vehicleImages[vehicle.subImage]}
                                  alt=""
                                  className="img-fluid mx-auto d-block"
                                /> */}


              <div >
                <ul className="list-inline mb-0">

                  <div className="mt-6 text-center">
                    <h6 className="mb-1 ">
                      <Link
                        to={"/vehicles-detail/" + vehicle.code}
                        className="text-dark"
                      >
                        {vehicle.make + "  " + vehicle.model + "  " + vehicle.year}{" "}
                      </Link><div className="text-muted mb-6">
                        <StarRatings
                          rating={vehicle.rating}
                          starRatedColor="#F1B44C"
                          starEmptyColor="#2D363F"
                          numberOfStars={5}
                          name="rating"
                          starDimension="14px"
                          starSpacing="3px"
                        />
                      </div>
                    </h6>


                  </div>
                  <Row className="mb-2" >
                    <Col sm="4">
                      <Badge
                        className={vehicle.status === 'available' ? "font-size-8 badge-soft-success" : (vehicle.status === "unavailable") ? "font-size-8 badge-soft-warning" : "font-size-8 badge-soft-danger"}
                        color={vehicle.status}
                        pill
                      >
                        {vehicle.status}
                      </Badge>
                    </Col>

                    <Col sm="8" >

                      <div className="text-sm-end" >
                        <Link
                          to={"/vehicles-detail/" + vehicle.code}
                          className="text-primary text-decoration-underline mb-2"
                        >
                          Details
                        </Link>
                      </div>
                    </Col>
                  </Row>
                </ul>
              </div>
            </CardBody>

          </Card>
        </Col>
      ))}
    </React.Fragment>
  )
}

CardVehicle.propTypes = {
  vehicles: PropTypes.array
}

export default CardVehicle
