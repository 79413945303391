import {
 
  GET_REPAIR_FAIL,
  GET_REPAIR_SUCCESS,
  ADD_REPAIR_SUCCESS,
  ADD_REPAIR_FAIL,
  UPDATE_REPAIR_SUCCESS,
  UPDATE_REPAIR_FAIL,
  DELETE_REPAIR_SUCCESS,
  DELETE_REPAIR_FAIL,
  
} from "./actionTypes"

const INIT_STATE = {
 
  repairs: []
  
}

const Repair = (state = INIT_STATE, action) => {
  switch (action.type) {
    
    case GET_REPAIR_SUCCESS:
      return {
        ...state,
        repairs: action.payload,
      }

    case GET_REPAIR_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_REPAIR_SUCCESS:
      return {
        ...state,
        repairs: [...state.repairs, action.payload],
      }

    case ADD_REPAIR_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_REPAIR_SUCCESS:
      return {
        ...state,
        repairs: state.repairs.map(repair =>
          repair.code.toString() === action.payload.code.toString()
            ? { repair, ...action.payload }
            : repair
        ),
      }

    case UPDATE_REPAIR_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_REPAIR_SUCCESS:
      return {
        ...state,
        repairs: state.repairs.filter(
          
          repair => repair.code.toString() !== action.payload.code.toString()
        ),
      }

    case DELETE_REPAIR_FAIL:
      return {
        ...state,
        error: action.payload,
      }

   
    default:
      return state
  }
}

export default Repair
