
/* INSSURANCE */
export const GET_INSSURANCE = "GET_INSSURANCE"
export const GET_INSSURANCE_SUCCESS = "GET_INSSURANCE_SUCCESS"
export const GET_INSSURANCE_FAIL = "GET_INSSURANCE_FAIL"

export const ADD_NEW_INSSURANCE = "ADD_NEW_INSSURANCE"
export const ADD_INSSURANCE_SUCCESS = "ADD_INSSURANCE_SUCCESS"
export const ADD_INSSURANCE_FAIL = "ADD_INSSURANCE_FAIL"

export const UPDATE_INSSURANCE = "UPDATE_INSSURANCE"
export const UPDATE_INSSURANCE_SUCCESS = "UPDATE_INSSURANCE_SUCCESS"
export const UPDATE_INSSURANCE_FAIL = "UPDATE_INSSURANCE_FAIL"

export const DELETE_INSSURANCE = "DELETE_INSSURANCE"
export const DELETE_INSSURANCE_SUCCESS = "DELETE_INSSURANCE_SUCCESS"
export const DELETE_INSSURANCE_FAIL = "DELETE_INSSURANCE_FAIL"

