
/* INDIE */
export const GET_INDIE = "GET_INDIE"
export const GET_INDIE_SUCCESS = "GET_INDIE_SUCCESS"
export const GET_INDIE_FAIL = "GET_INDIE_FAIL"

export const ADD_NEW_INDIE = "ADD_NEW_INDIE"
export const ADD_INDIE_SUCCESS = "ADD_INDIE_SUCCESS"
export const ADD_INDIE_FAIL = "ADD_INDIE_FAIL"

export const UPDATE_INDIE = "UPDATE_INDIE"
export const UPDATE_INDIE_SUCCESS = "UPDATE_INDIE_SUCCESS"
export const UPDATE_INDIE_FAIL = "UPDATE_INDIE_FAIL"

export const DELETE_INDIE = "DELETE_INDIE"
export const DELETE_INDIE_SUCCESS = "DELETE_INDIE_SUCCESS"
export const DELETE_INDIE_FAIL = "DELETE_INDIE_FAIL"

/* COMPANY */
export const GET_COMPANY = "GET_COMPANY"
export const GET_COMPANY_SUCCESS = "GET_COMPANY_SUCCESS"
export const GET_COMPANY_FAIL = "GET_COMPANY_FAIL"

export const ADD_NEW_COMPANY = "ADD_NEW_COMPANY"
export const ADD_COMPANY_SUCCESS = "ADD_COMPANY_SUCCESS"
export const ADD_COMPANY_FAIL = "ADD_COMPANY_FAIL"

export const UPDATE_COMPANY = "UPDATE_COMPANY"
export const UPDATE_COMPANY_SUCCESS = "UPDATE_COMPANY_SUCCESS"
export const UPDATE_COMPANY_FAIL = "UPDATE_COMPANY_FAIL"

export const DELETE_COMPANY = "DELETE_COMPANY"
export const DELETE_COMPANY_SUCCESS = "DELETE_COMPANY_SUCCESS"
export const DELETE_COMPANY_FAIL = "DELETE_COMPANY_FAIL"

