import { call, put, takeEvery } from "redux-saga/effects"

// Ecommerce Redux States
import {
  
  GET_REPAIR,
  ADD_NEW_REPAIR,
  DELETE_REPAIR,
  UPDATE_REPAIR,
  
} from "./actionTypes"
import {
  
  getRepairFail,
  getRepairSuccess,
  addRepairFail,
  addRepairSuccess,
  updateRepairSuccess,
  updateRepairFail,
  deleteRepairSuccess,
  deleteRepairFail,
  
} from "./actions"

//Include Both Helper File with needed methods
import {
 
  getRepair,
  addNewRepair,
  updateRepair,
  deleteRepair,
  
} from "helpers/fakebackend_helper"


function* fetchRepair() {
  try {
    const response = yield call(getRepair)
    yield put(getRepairSuccess(response))
  } catch (error) {
    yield put(getRepairFail(error))
  }
}

function* onAddNewRepair({ payload: repair }) {
  try {
    const response = yield call(addNewRepair, repair)
    yield put(addRepairSuccess(response))
  } catch (error) {

    yield put(addRepairFail(error))
  }
}

function* onUpdateRepair({ payload: repair }) {
  try {
    const response = yield call(updateRepair, repair)
    yield put(updateRepairSuccess(response))
  } catch (error) {
    yield put(updateRepairFail(error))
  }
}

function* onDeleteRepair({ payload: repair }) {
  try {
    const response = yield call(deleteRepair, repair)
    yield put(deleteRepairSuccess(response))
  } catch (error) {
    yield put(deleteRepairFail(error))
  }
}

function* repairSaga() {
  
  yield takeEvery(GET_REPAIR, fetchRepair)
  yield takeEvery(ADD_NEW_REPAIR, onAddNewRepair)
  yield takeEvery(UPDATE_REPAIR, onUpdateRepair)
  yield takeEvery(DELETE_REPAIR, onDeleteRepair)
  
}

export default repairSaga
