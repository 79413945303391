import React, { Component } from "react"
import PropTypes from "prop-types"
import { Row, Col, Collapse } from "reactstrap"
import { Link, withRouter } from "react-router-dom"
import classname from "classnames"

//i18n
import { withTranslation } from "react-i18next"

class Navbar extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    let matchingMenuItem = null
    const ul = document.getElementById("navigation")
    const items = ul.getElementsByTagName("a")
    for (let i = 0; i < items.length; ++i) {
      if (this.props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i]
        break
      }
    }
    if (matchingMenuItem) {
      this.activateParentDropdown(matchingMenuItem)
    }
  }

  activateParentDropdown = item => {
    item.classList.add("active")
    const parent = item.parentElement
    if (parent) {
      parent.classList.add("active") // li
      const parent2 = parent.parentElement
      parent2.classList.add("active") // li
      const parent3 = parent2.parentElement
      if (parent3) {
        parent3.classList.add("active") // li
        const parent4 = parent3.parentElement
        if (parent4) {
          parent4.classList.add("active") // li
          const parent5 = parent4.parentElement
          if (parent5) {
            parent5.classList.add("active") // li
            const parent6 = parent5.parentElement
            if (parent6) {
              parent6.classList.add("active") // li
            }
          }
        }
      }
    }
    return false
  }

  render() {
    return (
      <React.Fragment>
        <div className="topnav">
          <div className="container-fluid">
            <nav
              className="navbar navbar-light navbar-expand-lg topnav-menu"
              id="navigation"
            >
              <Collapse
                isOpen={this.props.menuOpen}
                className="navbar-collapse"
                id="topnav-menu-content"
              >
                <ul className="navbar-nav">
                  <li className="nav-item dropdown">

                    <li className="nav-item dropdown arrow-none">
                      <Link
                        to="/dashboard"
                        className="nav-link dropdown-toggle arrow-none">
                        <i className="bx bx-home-circle me-2" />
                        {this.props.t("Dashboard")} {this.props.menuOpen}

                      </Link>

                    </li>
                    {/* <Link
                      className="nav-link dropdown-toggle arrow-none"
                      onClick={e => {
                        e.preventDefault()
                        this.setState({ isDashboard: !this.state.isDashboard })
                      }}
                      to="/dashboard"
                    >
                      <i className="bx bx-home-circle me-2" />
                      {this.props.t("Dashboard")} {this.props.menuOpen}
                      <div className="arrow-down" />
                    </Link> */}
                    {/* <div
                      className={classname("dropdown-menu", {
                        show: this.state.isDashboard,
                      })}
                    >
                      <Link to="/dashboard" className="dropdown-item">
                        {this.props.t("Default")}
                      </Link>
                      <Link to="/dashboard-saas" className="dropdown-item">
                        {this.props.t("Saas")}
                      </Link>
                      
                      <Link to="/dashboard-blog" className="dropdown-item">
                        {this.props.t("Blog")}
                      </Link>
                    </div> */}
                  </li>

                  <li className="nav-item dropdown">
                    <Link
                      className="nav-link dropdown-toggle arrow-none"
                      onClick={e => {
                        e.preventDefault()
                        this.setState({ isRental: !this.state.isRental })
                      }}
                      to="/dashboard"
                    >
                      <i className="bx bx-spreadsheet me-2" />
                      {this.props.t("Rental")} {this.props.menuOpen}
                      <div className="arrow-down" />
                    </Link>
                    <div
                      className={classname("dropdown-menu", {
                        show: this.state.isRental,
                      })}
                    >
                      <Link to="/rental-calendar" className="dropdown-item">
                        {this.props.t("Calendar")}
                      </Link>
                      <Link to="/reservations" className="dropdown-item">
                        {this.props.t("Reservations")}
                      </Link>
                      <Link to="/booking_requests" className="dropdown-item">
                        {this.props.t("Online Reservations")}
                      </Link>
                      <Link to="/tariffs" className="dropdown-item">
                        {this.props.t("Vehicles Types (Rates)")}
                      </Link>


                    </div>
                  </li>


                  <li className="nav-item dropdown">
                    <Link
                      className="nav-link dropdown-toggle arrow-none"
                      onClick={e => {
                        e.preventDefault()
                        this.setState({ isFleet: !this.state.isFleet })
                      }}
                      to="/dashboard"
                    >
                      <i className="bx bx-car me-2" />
                      {this.props.t("Fleet")} {this.props.menuOpen}
                      <div className="arrow-down" />
                    </Link>
                    <div
                      className={classname("dropdown-menu", {
                        show: this.state.isFleet,
                      })}
                    >
                      <Link to="/vehicles" className="dropdown-item">
                        {this.props.t("Vehicles")}
                      </Link>
                      <Link to="/maintenance" className="dropdown-item">
                        {this.props.t("Maintenance")}
                      </Link>

                      <Link to="/vignette" className="dropdown-item">
                        {this.props.t("Annual tax")}
                      </Link>
                      <Link to="/inssurance" className="dropdown-item">
                        {this.props.t("Inssurance")}
                      </Link>
                      {/* <Link to="/inspection" className="dropdown-item">
                        {this.props.t("Inspections")}
                      </Link> */}

                    </div>
                  </li>



                  <li className="nav-item dropdown arrow-none">
                    <Link
                      to="/individuals"
                      className="nav-link dropdown-toggle arrow-none">
                      <i className="bx bx-user me-2" />
                      {this.props.t("Clients")} {this.props.menuOpen}

                    </Link>

                  </li>




                  {/*  <li className="nav-item dropdown arrow-none">
                    <Link
                      className="nav-link dropdown-toggle arrow-none"
                      onClick={e => {
                        e.preventDefault()
                        this.setState({ isChat: !this.state.isChat })
                      }}
                      to="/dashboard"
                    >
                      <i className="bx bx-chat me-2" />
                      {this.props.t("Chat")} {this.props.menuOpen}
                      
                    </Link>
                    
                  </li>
 */}


                  <li className="nav-item dropdown arrow-none">
                    <Link
                      to="/staff"
                      className="nav-link dropdown-toggle arrow-none">
                      <i className="bx bx-group me-2" />
                      {this.props.t("Staff")} {this.props.menuOpen}

                    </Link>

                  </li>

                  <li className="nav-item dropdown arrow-none">
                    <Link
                      className="nav-link dropdown-toggle arrow-none"

                      to="/suppliers"
                    >
                      <i className="bx bx-package me-2" />
                      {this.props.t("Workshops")} {this.props.menuOpen}

                    </Link>

                  </li>
                  <li className="nav-item dropdown arrow-none">
                    <Link
                      className="nav-link dropdown-toggle arrow-none"

                      to="/fines"
                    >
                      <i className="bx bx-receipt me-2" />
                      {this.props.t("Traffic fines")} {this.props.menuOpen}

                    </Link>

                  </li>



                  {/* <li className="nav-item dropdown">
                    <Link
                      className="nav-link dropdown-toggle arrow-none"
                      onClick={e => {
                        e.preventDefault()
                        this.setState({ isVoucher: !this.state.isVoucher })
                      }}
                      to="/dashboard"
                    >
                      <i className="bx bx-receipt me-2" />
                      {this.props.t("Vouchers")} {this.props.menuOpen}
                      <div className="arrow-down" />
                    </Link>
                    <div
                      className={classname("dropdown-menu", {
                        show: this.state.isVoucher,
                      })}
                    >
                      <Link to="/dashboard" className="dropdown-item">
                        {this.props.t("Invoices")}
                      </Link>
                      <Link to="/dashboard-saas" className="dropdown-item">
                        {this.props.t("Receipts")}
                      </Link>
                      <Link to="/dashboard" className="dropdown-item">
                        {this.props.t("Purchase orders")}
                      </Link>
                      <Link to="/dashboard-saas" className="dropdown-item">
                        {this.props.t("Payments")}
                      </Link>
                     
                      
                    </div>
                  </li>
 */}

                  <li className="nav-item dropdown">
                    <Link
                      className="nav-link dropdown-toggle arrow-none"
                      onClick={e => {
                        e.preventDefault()
                        this.setState({ isReport: !this.state.isReport })
                      }}
                      to="/dashboard"
                    >
                      <i className="bx bx-bar-chart me-2" />
                      {this.props.t("Reports")} {this.props.menuOpen}
                      <div className="arrow-down" />
                    </Link>
                    <div
                      className={classname("dropdown-menu", {
                        show: this.state.isReport,
                      })}
                    >
                      <Link to="/report-vehicles" className="dropdown-item">
                        {this.props.t("Vehicles reports")}
                      </Link>
                      <Link to="/report-clients" className="dropdown-item">
                        {this.props.t("Clients reports")}
                      </Link>
                      <Link to="/performance" className="dropdown-item">
                        {this.props.t("Performance")}
                      </Link>

                    </div>
                  </li>

                  <li className="nav-item dropdown">
                    <Link
                      className="nav-link dropdown-toggle arrow-none"
                      onClick={e => {
                        e.preventDefault()
                        this.setState({ isOther: !this.state.isOther })
                      }}
                      to="/dashboard"
                    >
                      <i className="mdi mdi-dots-vertical me-2" />

                    </Link>
                    <div
                      className={classname("dropdown-menu", {
                        show: this.state.isOther,
                      })}
                    >
                      <Link to="/chat" className="dropdown-item">
                        {this.props.t("Chat")}
                      </Link>
                      <Link to="/qrcode" className="dropdown-item">
                        {this.props.t("Qr Code")}
                      </Link>

                    </div>
                  </li>

                </ul>
              </Collapse>
            </nav>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

Navbar.propTypes = {
  location: PropTypes.object,
  menuOpen: PropTypes.any,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(Navbar))
