import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types'
import { connect } from "react-redux"
import toastr from "toastr";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"
import { Link, withRouter } from "react-router-dom"

// actions
import { apiError, loginUser } from "../../store/actions"
import { Container, Row, Col, CardBody, Card, Button, Alert } from "reactstrap"
import avatar1 from "../../assets/images/users/avatar-1.jpg"
// import images
import profileImg from "../../assets/images/profile-img.png"
import logoImg from "../../assets/images/logo.svg"
import avatar from "../../assets/images/users/avatar-1.jpg"
import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import "firebase/database"
import "firebase/storage"

class LockScreen extends Component {
  constructor(props) {
    super(props)
    this.state = {
      user: [],
    }
    this.handleValidSubmit = this.handleValidSubmit.bind(this)
  }
  componentDidMount() {
    //console.log("did mount")
    /* const { user } = this.state
      */

    this.getUser()
    // //console.log(user)

    //sessionStorage.reload = true;

  }



  logout = () => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .signOut()
        .then(() => {

          resolve(true)
        })
        .catch(error => {
          reject(this._handleError(error))
        })
    })
  }

  getUser = async () => {
    const currentUser = firebase.auth().currentUser;

    if (currentUser) {
      const uid = currentUser.uid;
      //console.log(uid);

      const userRef = firebase.firestore().collection("users").doc(uid);

      userRef.get().then((doc) => {
        if (doc.exists) {
          const userData = doc.data();
          const userId = userData.id;
          const userCollectionRef = firebase.firestore().collection("users").doc(userId)
          localStorage.setItem("admin", JSON.stringify(userData.isAdmin))
          if (!userData.isAdmin) {
            localStorage.setItem("acc_em", JSON.stringify(userData.email))
            this.setState({
              account_email: userData.email,
              account_user: userData,
              isAdmin: false,
              user_id: userId
            })
          } else {
            this.setState({
              isAdmin: true,
              user_id: userId
            })
          }
          userCollectionRef.get().then((d) => {
            if (d.exists) {
              let kra = d.data()
              localStorage.setItem("em", JSON.stringify(kra.email))
              localStorage.setItem("img", JSON.stringify(kra.image_url))
              localStorage.setItem("com", JSON.stringify(kra.company_name))
              this.setState({
                account_user: kra,
                user: kra,
              })
              this.logout(); 
            }
          })
          

          // Assuming logout is a function to be called after user data retrieval
        } else {
          //console.log("No such document!");
        }
      }).catch((error) => {
        console.error("Error getting user document:", error);
      });
    }

  }
  loginUser = (email, password) => {

    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then(
          user => {
            //console.log("logged in", user)
            resolve(firebase.auth().currentUser)

            this.props.history.goBack();
          },
          error => {
            console.error(error.message)
            toastr.error("Error unlocking account! ", error.message)
          }
        )
    })
  }
  handleValidSubmit(event, values) {

    const admin = JSON.parse(localStorage.getItem("admin"))


    var email
    if (admin) {

      email = JSON.parse(localStorage.getItem("em"))

    } else {

      email = JSON.parse(localStorage.getItem("acc_em"))
    }
    //console.log(email,admin)

    this.loginUser(email, values.password)


  }




  render() {
    const com = JSON.parse(localStorage.getItem("com"))
    const img = JSON.parse(localStorage.getItem("img"))
    const account_email = JSON.parse(localStorage.getItem("acc_em"))
    const items = { ...localStorage }
    //const { user } = this.state
    const admin = JSON.parse(localStorage.getItem("admin"))

    //console.log(img)
    var email
    if (admin) {

      email = JSON.parse(localStorage.getItem("em"))

    } else {

      email = JSON.parse(localStorage.getItem("acc_em"))
    }
    var av=""
   if(img==""){
    av=avatar1
   }else{
    av=img
   }
   
   
    ////console.log(items)
    ////console.log(account_email)
    return (
      <React.Fragment>
        <MetaTags>
          <title>Lock Screen | Medios - Car Rental Management System</title>
        </MetaTags>
        <div className="home-btn d-none d-sm-block">
          <a href="https://medios.ma" className="text-dark">
            <i className="fas fa-home h2" />
          </a>
        </div>
        <div className="account-pages my-5 pt-sm-5">
          <Container>
            <Row className="justify-content-center">
              <Col md="8" lg="6" xl="5">
                <Card className="overflow-hidden">
                  <div className="bg-primary bg-soft">
                    <Row>
                      <Col xs="7">
                        <div className="text-primary p-4">
                          <h5 className="text-primary">Lock screen</h5>
                          <p>Enter your password to unlock the screen!</p>
                        </div>
                      </Col>
                      <Col xs="5" className="align-self-end">
                        <img src={profileImg} alt="" className="img-fluid" />
                      </Col>
                    </Row>
                  </div>
                  <CardBody className="pt-0">
                    <div>
                      <Link to="/">
                        <div className="avatar-md profile-user-wid mb-4">
                          <span className="avatar-title rounded-circle bg-light">
                            <img
                              src={logoImg}
                              alt=""
                              className="rounded-circle"
                              height="34"
                            />
                          </span>
                        </div>
                      </Link>
                    </div>
                    <div className="p-2">
                      <AvForm className="form-horizontal"

                        onValidSubmit={this.handleValidSubmit}>
                          {this.props.error && this.props.error ? (
                          <Alert color="danger">{this.props.error}</Alert>
                        ) : null}
                        <div className="user-thumb text-center mb-4">
                          <img
                            src={av}
                            className="rounded-circle img-thumbnail avatar-md"
                            alt="thumbnail"
                          />
                          <h5 className="font-size-15 mt-3">{com}</h5>
                          <p className="font-size-15 mt-3">{email}</p>
                        </div>


                        <div className="mb-3">
                          <AvField
                            name="password"
                            label="Password"
                            type="password"
                            required
                            placeholder="Enter Password"
                          />
                        </div>

                        <div className="text-end">
                          <Col xs="12" className="text-end">
                            <Button
                              color="primary"
                              className=" w-md"
                              type="submit"

                            >
                              Unlock
                            </Button>
                          </Col>
                        </div>
                      </AvForm>
                    </div>
                  </CardBody>
                </Card>
                <div className="mt-5 text-center">
                  <p>
                    Not you ? return{" "}
                    <Link
                      to="/login"
                      className="fw-medium text-primary"
                    >
                      {" "}
                      Sign In{" "}
                    </Link>{" "}
                  </p>
                  <p>
                    © 2024 Medios.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}
LockScreen.propTypes = {
  history: PropTypes.object,
  loginUser: PropTypes.func,
  error: PropTypes.any
}
const mapStateToProps = state => {
  const { error } = state.Login
  return { error }
}
export default withRouter(
  connect(mapStateToProps, { loginUser })(LockScreen)
)