import {
 
  GET_TAX_FAIL,
  GET_TAX_SUCCESS,
  ADD_TAX_SUCCESS,
  ADD_TAX_FAIL,
  UPDATE_TAX_SUCCESS,
  UPDATE_TAX_FAIL,
  DELETE_TAX_SUCCESS,
  DELETE_TAX_FAIL,
  
} from "./actionTypes"

const INIT_STATE = {
 
  taxs: []
  
}

const Tax = (state = INIT_STATE, action) => {
  switch (action.type) {
    
    case GET_TAX_SUCCESS:
      return {
        ...state,
        taxs: action.payload,
      }

    case GET_TAX_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_TAX_SUCCESS:
      return {
        ...state,
        taxs: [...state.taxs, action.payload],
      }

    case ADD_TAX_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_TAX_SUCCESS:
      return {
        ...state,
        taxs: state.taxs.map(tax =>
          tax.code.toString() === action.payload.code.toString()
            ? { tax, ...action.payload }
            : tax
        ),
      }

    case UPDATE_TAX_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_TAX_SUCCESS:
      return {
        ...state,
        taxs: state.taxs.filter(
          
          tax => tax.code.toString() !== action.payload.code.toString()
        ),
      }

    case DELETE_TAX_FAIL:
      return {
        ...state,
        error: action.payload,
      }

   
    default:
      return state
  }
}

export default Tax
