import {
 
  GET_STAFF_FAIL,
  GET_STAFF_SUCCESS,
  ADD_STAFF_SUCCESS,
  ADD_STAFF_FAIL,
  UPDATE_STAFF_SUCCESS,
  UPDATE_STAFF_FAIL,
  DELETE_STAFF_SUCCESS,
  DELETE_STAFF_FAIL,
  
} from "./actionTypes"

const INIT_STATE = {
 
  staffs: []
  
}

const Staff = (state = INIT_STATE, action) => {
  switch (action.type) {
    
    case GET_STAFF_SUCCESS:
      return {
        ...state,
        staffs: action.payload,
      }

    case GET_STAFF_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_STAFF_SUCCESS:
      return {
        ...state,
        staffs: [...state.staffs, action.payload],
      }

    case ADD_STAFF_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_STAFF_SUCCESS:
      return {
        ...state,
        staffs: state.staffs.map(staff =>
          staff.code.toString() === action.payload.code.toString()
            ? { staff, ...action.payload }
            : staff
        ),
      }

    case UPDATE_STAFF_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_STAFF_SUCCESS:
      return {
        ...state,
        staffs: state.staffs.filter(
          staff => staff.code.toString() !== action.payload.code.toString()
        ),
      }

    case DELETE_STAFF_FAIL:
      return {
        ...state,
        error: action.payload,
      }

   
    default:
      return state
  }
}

export default Staff
