import React, { Component } from "react"
import PropTypes from "prop-types"
import MetaTags from 'react-meta-tags';
import { connect } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import noRequests from "../../../assets/images/error-img.png"
import {
  Col, Container, Row, Card,
  CardBody,
  CardFooter,
  UncontrolledTooltip
} from "reactstrap"


import * as moment from 'moment';

import CardContact from "./card-contact"
import { countBy, isEmpty, size, map } from "lodash"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"


import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import "firebase/database"
import "firebase/storage"


class Requests extends Component {
  constructor(props) {
    super(props)
    this.state = {
      req: [],
      loading: false,
    }
  }
  componentDidMount() {

    const { req } = this.state
    if (req && !req.length) {

      this.getRequests()
    }
    this.setState({ req })
  }

  getRequests = async () => {

    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        user = firebase.auth().currentUser.uid
        const collection = firebase.firestore().collection("users")

        this.setState({ loading: true }, () => {
          collection.doc(user).collection("requests")
            .onSnapshot((doc) => {
              let kra = []
              doc.docs.forEach(e => {
                kra.push(e.data())
              });
              this.setState({
                loading: false,
                req: kra,
              })
            }
            );
        });

        localStorage.setItem("authUser", JSON.stringify(user))
      } else {
        ////console.log("not user")
        localStorage.removeItem("authUser")
      }
    })

  }

  render() {
    const { req } = this.state
    let r = []
    req.map(function (item) {
      r.push(item)
    })
    
    //console.log("requests ", r.length)
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Booking requests | Medios - Responsive Bootstrap 5 Admin Dashboard</title>
          </MetaTags>
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title="Rental" breadcrumbItem="Booking requests" />
            <Row>
              {r.length ==0 ?
              <div className="text-center">
              <Row className="justify-content-center mt-5">
              <Col sm="4">
                <div className="maintenance-img">
                  <img
                    src={noRequests}
                    alt=""
                    className="img-fluid mx-auto d-block"
                  />
                </div>
              </Col>
            </Row>
            <h4 className="mt-5">No online reservations yet!</h4>
            <p className="text-muted">
              You can find the reservation requests from online users <h5 className="text-primary"></h5>
            </p>
            </div>
              :map(r, (request, key) => (
                <CardContact request={request} key={"_user_" + key} />
              ))}
            </Row>
            <Row>
              {this.state.loading ?<Col xs="12">
                <div className="text-center my-3">
                  <Link to="#" className="text-success">
                    <i className="bx bx-hourglass bx-spin me-2" />
                    Loading . . .
                  </Link>
                </div>
              </Col>:null}
              
            </Row>
          </Container>
        </div>
        
      </React.Fragment>
    )
  }
}

Requests.propTypes = {
  requests: PropTypes.array
}
const mapStateToProps = state => ({
  requests: state.requests,
})
export default connect(
  mapStateToProps
)(withRouter(Requests))

