import { call, put, takeEvery } from "redux-saga/effects"

// Ecommerce Redux States
import {
  
  GET_SUPPLIER,
  ADD_NEW_SUPPLIER,
  DELETE_SUPPLIER,
  UPDATE_SUPPLIER,
  
} from "./actionTypes"
import {
  
  getSupplierFail,
  getSupplierSuccess,
  addSupplierFail,
  addSupplierSuccess,
  updateSupplierSuccess,
  updateSupplierFail,
  deleteSupplierSuccess,
  deleteSupplierFail,
  
} from "./actions"

//Include Both Helper File with needed methods
import {
 
  getSupplier,
  addNewSupplier,
  updateSupplier,
  deleteSupplier,
  
} from "helpers/fakebackend_helper"


function* fetchSupplier() {
  try {
    const response = yield call(getSupplier)
    yield put(getSupplierSuccess(response))
  } catch (error) {
    yield put(getSupplierFail(error))
  }
}

function* onAddNewSupplier({ payload: supplier }) {
  try {
    const response = yield call(addNewSupplier, supplier)
    yield put(addSupplierSuccess(response))
  } catch (error) {

    yield put(addSupplierFail(error))
  }
}

function* onUpdateSupplier({ payload: supplier }) {
  try {
    const response = yield call(updateSupplier, supplier)
    yield put(updateSupplierSuccess(response))
  } catch (error) {
    yield put(updateSupplierFail(error))
  }
}

function* onDeleteSupplier({ payload: supplier }) {
  try {
    const response = yield call(deleteSupplier, supplier)
    yield put(deleteSupplierSuccess(response))
  } catch (error) {
    yield put(deleteSupplierFail(error))
  }
}

function* supplierSaga() {
  
  yield takeEvery(GET_SUPPLIER, fetchSupplier)
  yield takeEvery(ADD_NEW_SUPPLIER, onAddNewSupplier)
  yield takeEvery(UPDATE_SUPPLIER, onUpdateSupplier)
  yield takeEvery(DELETE_SUPPLIER, onDeleteSupplier)
  
}

export default supplierSaga
