import { call, put, takeEvery } from "redux-saga/effects"

// Ecommerce Redux States
import {
  
  GET_VEHICLE,
  ADD_NEW_VEHICLE,
  DELETE_VEHICLE,
  UPDATE_VEHICLE,
  
} from "./actionTypes"
import {
  
  getVehicleFail,
  getVehicleSuccess,
  addVehicleFail,
  addVehicleSuccess,
  updateVehicleSuccess,
  updateVehicleFail,
  deleteVehicleSuccess,
  deleteVehicleFail,
  
} from "./actions"

//Include Both Helper File with needed methods
import {
 
  getVehicle,
  addNewVehicle,
  updateVehicle,
  deleteVehicle,
  
} from "helpers/fakebackend_helper"


function* fetchVehicle() {
  try {
    const response = yield call(getVehicle)
    yield put(getVehicleSuccess(response))
  } catch (error) {
    yield put(getVehicleFail(error))
  }
}

function* onAddNewVehicle({ payload: vehicle }) {
  try {
    const response = yield call(addNewVehicle, vehicle)
    yield put(addVehicleSuccess(response))
  } catch (error) {

    yield put(addVehicleFail(error))
  }
}

function* onUpdateVehicle({ payload: vehicle }) {
  try {
    const response = yield call(updateVehicle, vehicle)
    yield put(updateVehicleSuccess(response))
  } catch (error) {
    yield put(updateVehicleFail(error))
  }
}

function* onDeleteVehicle({ payload: vehicle }) {
  try {
    const response = yield call(deleteVehicle, vehicle)
    yield put(deleteVehicleSuccess(response))
  } catch (error) {
    yield put(deleteVehicleFail(error))
  }
}

function* vehicleSaga() {
  
  yield takeEvery(GET_VEHICLE, fetchVehicle)
  yield takeEvery(ADD_NEW_VEHICLE, onAddNewVehicle)
  yield takeEvery(UPDATE_VEHICLE, onUpdateVehicle)
  yield takeEvery(DELETE_VEHICLE, onDeleteVehicle)
  
}

export default vehicleSaga
