

/* RESERVATION */
export const GET_RESERVATION = "GET_RESERVATION"
export const GET_RESERVATION_SUCCESS = "GET_RESERVATION_SUCCESS"
export const GET_RESERVATION_FAIL = "GET_RESERVATION_FAIL"

export const ADD_NEW_RESERVATION = "ADD_NEW_RESERVATION"
export const ADD_RESERVATION_SUCCESS = "ADD_RESERVATION_SUCCESS"
export const ADD_RESERVATION_FAIL = "ADD_RESERVATION_FAIL"

export const UPDATE_RESERVATION = "UPDATE_RESERVATION"
export const UPDATE_RESERVATION_SUCCESS = "UPDATE_RESERVATION_SUCCESS"
export const UPDATE_RESERVATION_FAIL = "UPDATE_RESERVATION_FAIL"

export const DELETE_RESERVATION = "DELETE_RESERVATION"
export const DELETE_RESERVATION_SUCCESS = "DELETE_RESERVATION_SUCCESS"
export const DELETE_RESERVATION_FAIL = "DELETE_RESERVATION_FAIL"

