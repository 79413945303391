import {
 
  GET_VEHICLE_FAIL,
  GET_VEHICLE_SUCCESS,
  ADD_VEHICLE_SUCCESS,
  ADD_VEHICLE_FAIL,
  UPDATE_VEHICLE_SUCCESS,
  UPDATE_VEHICLE_FAIL,
  DELETE_VEHICLE_SUCCESS,
  DELETE_VEHICLE_FAIL,
  
} from "./actionTypes"

const INIT_STATE = {
 
  vehicles: []
  
}

const Vehicle = (state = INIT_STATE, action) => {
  switch (action.type) {
    
    case GET_VEHICLE_SUCCESS:
      return {
        ...state,
        vehicles: action.payload,
      }

    case GET_VEHICLE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_VEHICLE_SUCCESS:
      return {
        ...state,
        vehicles: [...state.vehicles, action.payload],
      }

    case ADD_VEHICLE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_VEHICLE_SUCCESS:
      return {
        ...state,
        vehicles: state.vehicles.map(vehicle =>
          vehicle.code.toString() === action.payload.code.toString()
            ? { vehicle, ...action.payload }
            : vehicle
        ),
      }

    case UPDATE_VEHICLE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_VEHICLE_SUCCESS:
      return {
        ...state,
        vehicles: state.vehicles.filter(
          
          vehicle => vehicle.code.toString() !== action.payload.code.toString()
        ),
      }

    case DELETE_VEHICLE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

   
    default:
      return state
  }
}

export default Vehicle
