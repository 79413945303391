import {
  
  GET_VEHICLE ,
  GET_VEHICLE_FAIL,
  GET_VEHICLE_SUCCESS,
  ADD_NEW_VEHICLE,
  ADD_VEHICLE_SUCCESS,
  ADD_VEHICLE_FAIL,
  UPDATE_VEHICLE,
  UPDATE_VEHICLE_SUCCESS,
  UPDATE_VEHICLE_FAIL,
  DELETE_VEHICLE,
  DELETE_VEHICLE_SUCCESS,
  DELETE_VEHICLE_FAIL,
  
} from "./actionTypes"



export const getVehicle = () => ({
  type: GET_VEHICLE,
})

export const getVehicleSuccess = vehicle => ({
  type: GET_VEHICLE_SUCCESS,
  payload: vehicle,
})

export const getVehicleFail = error => ({
  type: GET_VEHICLE_FAIL,
  payload: error,
})

export const addNewVehicle = vehicle => ({
  type: ADD_NEW_VEHICLE,
  payload: vehicle,
})

export const addVehicleSuccess = vehicle => ({
  type: ADD_VEHICLE_SUCCESS,
  payload: vehicle,
})

export const addVehicleFail = error => ({
  type: ADD_VEHICLE_FAIL,
  payload: error,
})

export const updateVehicle = vehicle => ({
  type: UPDATE_VEHICLE,
  payload: vehicle,
})

export const updateVehicleSuccess = vehicle => ({
  type: UPDATE_VEHICLE_SUCCESS,
  payload: vehicle,
})

export const updateVehicleFail = error => ({
  type: UPDATE_VEHICLE_FAIL,
  payload: error,
})

export const deleteVehicle = vehicle => ({
  type: DELETE_VEHICLE,
  payload: vehicle,
})

export const deleteVehicleSuccess = vehicle => ({
  type: DELETE_VEHICLE_SUCCESS,
  payload: vehicle,
})

export const deleteVehicleFail = error => ({
  type: DELETE_VEHICLE_FAIL,
  payload: error,
})
