import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import MetaTags from 'react-meta-tags';
import { withRouter } from "react-router-dom"
import { countBy, isEmpty, size } from "lodash"
import BootstrapTable from "react-bootstrap-table-next"
import "flatpickr/dist/themes/material_blue.css"
import classnames from "classnames"
import Flatpickr from "react-flatpickr"
import Dropzone from "react-dropzone"
import toastr from "toastr";
import { withTranslation } from "react-i18next"
import Moment from 'moment';

import { v4 as uuid } from "uuid"
import imageCompression from 'browser-image-compression';


// Add the Firebase products that you want to use
import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import "firebase/database"
import "firebase/storage"

import cities from "../../common/data/villes.json"


import maintanence from "../../assets/images/coming-soon.svg"
import
paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import { Link } from "react-router-dom"
import * as moment from 'moment';
import Select from "react-select"
import jsPDF from "jspdf";
import "jspdf-autotable";

import StarRatings from "react-star-ratings"
import { CardImg, NavLink, NavItem, TabContent, TabPane, UncontrolledDropdown, DropdownItem, DropdownMenu, DropdownToggle, Form, InputGroup, Input, FormGroup, Label, Button, Card, CardBody, Col, Table, ModalFooter, Container, Row, Modal, Badge, ModalHeader, ModalBody } from "reactstrap"

import { AvForm, AvField } from "availity-reactstrap-validation"
import SweetAlert from "react-bootstrap-sweetalert"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import {
  getVehicle,
  addNewVehicle,
  updateVehicle,
  deleteVehicle,
  getReservation,
} from "store/actions"


import { vehicleImages } from "assets/images/vehicle"
import exportFromJSON from 'export-from-json'
import { propTypes } from "react-bootstrap-editable";

class Av_Vehicles extends Component {
  constructor(props) {
    super(props)
    this.state = {
      //
      pd:"",
      rd:"",
      selectedCity: null,
      searchError: false,
      searchErrorMsg: "",
      defaultAvatar: "",
      selectedTariff: null,
      trfchanged: false,
      ibadl: false,
      loading: false,
      submitLoading: false,
      page: 1,
      totalPage: 5,
      activeTab: 1,
      passedSteps: [1],
      isShow: false,
      fuel_changed: false,
      transmission_changed: false,
      status_changed: false,
      features_changed: false,
      gender: "",
      viewmodal: false,
      modal: false,
      isDelete: false,
      no_data: false,
      success_dlg: false,
      dynamic_title: '',
      dynamic_description: '',
      vc: "",
      selectedFeatures: [],
      selectedFuel: "",
      selectedTransmission: "",
      selectedStatus: "",
      vehicles: [],
      reservations: [],
      vehicle: [],
      tariffs: [],
      reg_file: null,
      other_file: null,
      ex1: null,
      ex2: null,
      ex3: null,
      ex4: null,
      ex5: null,
      in1: null,
      in2: null,
      in3: null,
      in4: null,
      in5: null,
      purchase_date: new Date(),
      inssurance_date: new Date(),
      reg_date: new Date(),
      VehicleColumns: [

        {
          dataField: "make",
          text: this.props.t("Make"),
          sort: true,
          formatter: (cellContent, row) => (
            <Link to="#" className="text-body fw-bold">
              {row.make}
            </Link>
          ),
        },
        {
          dataField: "model",
          text: this.props.t("Model"),
          sort: true,
          formatter: (cellContent, row) => (
            <Link to="#" className="text-body fw-bold">
              {row.model}
            </Link>
          ),
        },
        {
          dataField: "daily_rate",
          text: this.props.t("Daily Rate"),
          sort: true,
          formatter: (cellContent, row) => (
            <Link to="#" className="text-body fw-bold">
              {row.daily_rate} DHs
            </Link>
          ),
        },
        {
          dataField: "monthly_rate",
          text: this.props.t("Monthly Rate"),
          sort: true,
          formatter: (cellContent, row) => (
            <Link to="#" className="text-body fw-bold">
              {row.monthly_rate} DHs
            </Link>
          ),
        },
        {
          dataField: "year",
          text: this.props.t("Year"),
          sort: true,
          /* formatter: (cellContent, row) => (
            this.handleValidDate(row.orderdate)
          ), */
        },
        {
          dataField: "mileage",
          text: this.props.t("Mileage"),
          sort: true,
        },
        {
          dataField: "transmission",
          isDummyField: true,
          text: this.props.t("Transmission"),
          sort: true,
          formatter: (cellContent, row) => (
            //kraaaaaaaa
            <>

              <i className={
                row.transmission === this.props.t("Manual") ?
                  "fas fa-hand-paper me-1"
                  : row.transmission === this.props.t("Automatic") ? "fas fa-robot" : null
              }
              />{" "}
              {row.transmission}
            </>
          ),
        },
        {
          dataField: "fuel_type",
          text: this.props.t("Fuel Type"),
          sort: true,
        },

        {
          dataField: "view",
          isDummyField: true,
          text: this.props.t("Book now"),
          sort: true,
          formatter: (cellContent, vehicle) => (
            <Button
              type="button"
              color="primary"
              className="btn-sm btn-rounded"
              onClick={() => this.handleViewClick(vehicle)}
            >
              {this.props.t("Book now")}{" "}<i className="mdi mdi-arrow-right ms-1" />
            </Button>
          ),
        }
        ,
      ],

      activeViewTab: "1",
      selectedFiles: [],
      selectedFiles1: [],
      next: "Next"
    }

    this.handleVehicleClick = this.handleVehicleClick.bind(this)
    this.toggle = this.toggle.bind(this)
    this.handleValidVehicleSubmit = this.handleValidVehicleSubmit.bind(this)
    this.handleVehicleClicks = this.handleVehicleClicks.bind(this)
    this.toLowerCase1 = this.toLowerCase1.bind(this)
    this.toggleDelete = this.toggleDelete.bind(this)
    this.handleViewClick = this.handleViewClick.bind(this)
    this.handleSelectFuel = this.handleSelectFuel.bind(this)
    this.handleSelectTransmission = this.handleSelectTransmission.bind(this)
    this.handleSelectStatus = this.handleSelectStatus.bind(this)
    this.handleSelectFeatures = this.handleSelectFeatures.bind(this)
    this.handleSelectTariff = this.handleSelectTariff.bind(this)
    this.handleAcceptedFiles = this.handleAcceptedFiles.bind(this)
    this.handleAcceptedFilesDZ1 = this.handleAcceptedFilesDZ1.bind(this)
    this.toggleIsShow = this.toggleIsShow.bind(this)
    this.handleDeleteVehicle = this.handleDeleteVehicle.bind(this)
    this.toggleViewTab = this.toggleViewTab.bind(this)
    this.toggleTab.bind(this)
    this.handleSelectCity = this.handleSelectCity.bind(this)
  }

  handleSelectCity = selectedCity => {

    this.setState({ selectedCity: selectedCity })

  }

  handlePageClick = page => {
    this.setState({ page })
  }
  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      if (tab >= 1 && tab <= 4) {
        this.setState({
          activeTab: tab,
        })
      }
    }
  }
  toggleViewTab(tab) {
    if (this.state.activeViewTab !== tab) {
      if (tab >= 1 && tab <= 4) {
        this.setState({
          activeViewTab: tab,
        })
      }
    }
  }

  handleAcceptedFiles = files => {
    this.setState({ ibadl: true })
    files.map(file =>

      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: this.formatBytes(file.size),
      }),

    )


    this.setState({ selectedFiles: files })
  }
  handleAcceptedFilesDZ1 = files => {
    this.setState({ ibadl: true })
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: this.formatBytes(file.size),
      })
    )

    this.setState({ selectedFiles1: files })
  }

  /**
   * Formats the size
   */
  formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }



  toLowerCase1(str) {
    return str.toLowerCase();
  }
  handleSelectTariff = (selectedTariff, value) => {

    this.setState({ selectedTariff: value })
    this.setState({ trfchanged: true })
    //console.log(value)
  }
  handleSelectFeatures = (e) => {

    // let value = Array.from(e.target.selectedOptions, option => option.value);

    const t = new Array()

    e.forEach(element => {
      t.push(element)
    });
    //console.log(e)
    this.setState({ selectedFeatures: t });
  }

  handleSelectFuel = selectedFuel => {

    this.setState({ selectedFuel: selectedFuel })
    this.setState({ fuel_changed: true })
  }
  handleSelectTransmission = selectedTransmission => {

    this.setState({ selectedTransmission: selectedTransmission })
    this.setState({ transmission_changed: true })
  }
  handleSelectStatus = selectedStatus => {

    this.setState({ selectedStatus: selectedStatus })
    this.setState({ status_changed: true })
  }
  componentDidMount() {
    const { vehicles, onGetVehicle } = this.props
    const { onUpdateVehicle } = this.props
    if (vehicles && !vehicles.length) {
      //onGetVehicle()
      //this.getVehicle()
    }
    this.setState({ vehicles })


  }

  getVehicle = async (search_id) => {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        //console.log("seaaaaarch iiiiiiiiiiddddd ", search_id)
        user = firebase.auth().currentUser.uid

        const db = firebase.database();
        const ref = db.ref('search/' + search_id + '/results/vehicles');

        ref.on('value', (snapshot) => {
          let kra=[]
          snapshot.forEach((childSnapshot) => {
            var childKey = childSnapshot.key;
            var childData = childSnapshot.val();
            kra.push(childSnapshot.val())
            // ...
          });
          //console.log(kra);
          
            
            this.setState({
              loading: false,
              vehicles: kra,
            })
        }, (errorObject) => {
          //console.log('The read failed: ' + errorObject.name);
        });
        /* const collection = firebase.firestore().collection("search/" + search_id + "/results")
        this.setState({ loading: true }, () => {
          collection.onSnapshot((doc) => {
            let kra = []
            doc.docs.forEach(e => {
              kra.push(e.data())

            });
            this.setState({
              loading: false,
              vehicles: kra,
            })
          }

          );
        }); */
        localStorage.setItem("authUser", JSON.stringify(user))
      } else {
        ////console.log("not user")
        localStorage.removeItem("authUser")
      }
    })

  }




  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { vehicles } = this.state
    const { onUpdateVehicle } = this.props
    if (!isEmpty(vehicles) && size(prevProps.vehicles) !== size(vehicles)) {
      //this.setState({ vehicles: {}, isEdit: false })
    }
    /* const { reservations } = this.props

    if (!isEmpty(reservations) && size(prevProps.reservations) !== size(reservations)) {
      this.setState({ reservations: {}, isEdit: false })
    } */


  }

  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal,
      ibadl: false,
      activeTab: 1,
    }))
  }
  toggleDelete() {
    this.setState(prevState => ({
      isDelete: !prevState.isDelete,
    }))
  }

  handleVehicleClicks = () => {

    this.setState({
      vehicle: [],
      reg_file: null,
      other_file: null,
      ex1: null,
      ex2: null,
      ex3: null,
      ex4: null,
      ex5: null,
      in1: null,
      in2: null,
      in3: null,
      in4: null,
      in5: null, isEdit: false, isShow: false, selectedTransmission: null, selectedFuel: null, selectedStatus: null
    })

    this.toggle()
  }

  // eslint-disable-next-line no-unused-vars
  handleTableChange = (type, { page, searchText }) => {
    const { vehicles } = this.props
    this.setState({
      vehicles: vehicles.filter(vehicle =>
        Object.keys(vehicle).some(
          key =>
            typeof vehicle[key] === "string" &&
            vehicle[key].toLowerCase().includes(searchText.toLowerCase())
        )
      ),
    })
  }

  toggleViewModal = () => {
    this.setState(prevState => ({
      viewmodal: !prevState.viewmodal,
    }))
  }

  toggleIsShow = () => {
    this.setState(prevState => ({
      isShow: !prevState.isShow,
    }))
  }


  /* Insert,Update Delete data */

  handleDeleteVehicle = (vehicle) => {


    const { onDeleteVehicle } = this.props
    ///

    // onDeleteVehicle(vehicle)
    this.deleteVehicle(vehicle)

    this.toggleDelete()
    //  }

  }

  handleViewClick = arg => {
    const vehicle = arg
    
    const db = firebase.database().ref("requests")
     var client="I6COGDnYPVai0p2qRrm7pgseEEs1"
     var request_id = db.push().getKey()
     var created =moment(new Date()).format('YYYY-MM-DDTHH:mm')
     //console.log(vehicle)

 
     db.child(request_id).set(
       {
         id: request_id,
         pickup_date:this.state.pd,
         return_date:this.state.rd,
         agency_id:vehicle.agency_id,
         vehicle_id:vehicle.vehicle_id,
         client_id:client,
         createdAt:created
 
       }).then(() => {
         //console.log("request  written!");
        
       })
       .catch((error) => {
         console.error("Error Adding Request: ", error);
       });
    
    

  }


  handleVehicleClick = arg => {
    const vehicle = arg
    //console.log(vehicle)
    this.setState({ selectedFeatures: vehicle.features })
    this.setState({ selectedFuel: { label: vehicle.fuel_type, value: vehicle.fuel_type } })
    this.setState({ selectedTransmission: { label: vehicle.transmission, value: vehicle.transmission } })
    this.setState({ selectedStatus: { label: vehicle.status, value: vehicle.status } })
    this.setState({ selectedTariff: { label: vehicle.vehicle_type, value: vehicle.trf_id } })
    this.setState({
      selectedFiles1: [],
      selectedFiles: [],
      vehicle: {
        id: vehicle.id,
        plate_number: vehicle.plate_number,
        make: vehicle.make,
        model: vehicle.model,
        year: vehicle.year,
        monthly_rate: vehicle.monthly_rate,
        daily_rate: vehicle.daily_rate,
        t_name: vehicle.t_name,
        trf_id: vehicle.trf_id,
        chassis_number: vehicle.chassis_number,
        fiscal_power: vehicle.fiscal_power,
        mileage: vehicle.mileage,
        rating: vehicle.rating,
        fuel_type: vehicle.fuel_type,
        transmission: vehicle.transmission,
        doors_number: vehicle.doors_number,
        seats_number: vehicle.seats_number,
        features: vehicle.features,
        additional_infos: vehicle.additional_infos,
        notes: vehicle.notes,
        purchase_date: vehicle.purchase_date,
        reg_date: vehicle.reg_date,
        reg_number: vehicle.reg_number,
        warranty_kms: vehicle.warranty_kms,
        warranty_months: vehicle.warranty_months,
        status: vehicle.status,
        available_date: vehicle.available_date,
        start_date: vehicle.start_date,
        ex1: vehicle.ex1,
        ex2: vehicle.ex2,
        ex3: vehicle.ex3,
        ex4: vehicle.ex4,
        ex5: vehicle.ex5,
        in1: vehicle.in1,
        in2: vehicle.in2,
        in3: vehicle.in3,
        in4: vehicle.in4,
        in5: vehicle.in5,
        ex1_url: vehicle.ex1_url,
        ex2_url: vehicle.ex2_url,
        ex3_url: vehicle.ex3_url,
        ex4_url: vehicle.ex4_url,
        ex5_url: vehicle.ex5_url,
        in1_url: vehicle.in1_url,
        in2_url: vehicle.in2_url,
        in3_url: vehicle.in3_url,
        in4_url: vehicle.in4_url,
        in5_url: vehicle.in5_url,
        rented_to: vehicle.rented_to
      },

      isEdit: true,
      isShow: false,
    })

    this.toggle()
  }

  /**
   * Handling submit Order on Order form
   */
  handleValidVehicleSubmit = (e, values) => {
    this.setState({
      searchError: false,
      searchErrorMsg: ""
    })

    /* if (this.state.selectedCity === null || this.state.selectedCity === undefined) {
      toastr.error("Please select a city")
    } else { */
    this.setState({
      pd:values.pickup_date,
      rd:values.return_date
    })
    //console.log(values)
    this.SearchVehicle(values)
    //}
  }

  SearchVehicle = (values) => {

    var pickupD = new Date(values.pickup_date).getTime()
    var returnD = new Date(values.return_date).getTime()
    const search = {
      latitude: parseFloat(values.lat),
      longitude: parseFloat(values.lng),
      radius: parseInt(values.radius),
      pickup: pickupD,
      return: returnD
    }
    this.addNewSearch(search)


  }
  addNewSearch = async (s) => {
    const collection = firebase.firestore().collection("search")
    const db = firebase.database().ref("search")
   // admin.database().ref("search/"+id+"/results/vehicles/"+doc.data().id).set(veh)
    var newDoc = collection.doc();
    var search_id = db.push().getKey()

    db.child(search_id).set(
      {

        id: search_id,
        latitude: s.latitude,
        longitude: s.longitude,
        radius: s.radius,
        pickup_date: s.pickup,
        return_date: s.return,
        done: false

      }).then(() => {
        this.getVehicle(search_id)
        //console.log("search  written!");
        this.toggle()
      })
      .catch((error) => {
        console.error("Error Adding search: ", error);
      });

  }
  



  handleValidDate = (date) => {
    const date1 = moment(new Date(date)).format('DD MMM Y');
    return date1;
  }


  changeText = (text) => {

    this.setState({ next: text });
  }

  render() {
    const villes = []
    //console.log("pickup ", this.state.pd)
    //console.log("return ", this.state.rd)
    const { selectedCity } = this.state
    cities.map(function (item, i) {
      villes.push({ label: item.ville, value: item.ville })

    })
    const emptyDataMessage = () => {

      return <Container>
        <Row>
          <Col lg="12">
            {this.state.loading ?
              <div className="text-center">
                <Row className="justify-content-center mt-5">
                  <Col sm="4">
                    <div>
                      <div >
                        <div className="spinner-chase">
                          <div className="chase-dot"></div>
                          <div className="chase-dot"></div>
                          <div className="chase-dot"></div>
                          <div className="chase-dot"></div>
                          <div className="chase-dot"></div>
                          <div className="chase-dot"></div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>

              :
              
                <Row className="justify-content-center mt-5">
                  <Col sm="4">
                    <div className="maintenance-img">
                      <img
                        src={maintanence}
                        alt=""
                        className="img-fluid mx-auto d-block"
                      />
                    </div>
                  </Col>
                </Row>
                
            }

          </Col>
        </Row>
      </Container>


        ;
    }


    const { selectedFuel } = this.state
    const { selectedStatus } = this.state
    const { selectedTransmission } = this.state
    const { vehicles/* , reservations  */ } = this.state
    const data = vehicles;
    let dataxcl = []
    data.map(function (item) {
      delete item.vehicle;
      dataxcl.push({
        plate_number: item.plate_number,
        make: item.make,
        model: item.model,
        year: item.year,
        monthly_rate: item.monthly_rate,
        daily_rate: item.daily_rate,
        t_name: item.t_name,
        chassis_number: item.chassis_number,
        fiscal_power: item.fiscal_power,
        mileage: item.mileage,
        rating: item.rating,
        fuel_type: item.fuel_type,
        transmission: item.transmission,
        doors_number: item.doors_number,
        seats_number: item.seats_number,
        features: item.features,
        additional_infos: item.additional_infos,
        notes: item.notes,
        purchase_date: item.purchase_date,
        reg_date: item.reg_date,
        reg_number: item.reg_number,
        warranty_kms: item.warranty_kms,
        warranty_months: item.warranty_months,
        status: item.status,
      })
    });

    const { selectedGroup } = this.state
    const { SearchBar } = Search
    const { reg_date } = this.state;
    const { purchase_date } = this.state;
    const { isEdit } = this.state
    const { isDelete } = this.state
    const { vc } = this.state
    const trfList = []
    const { tariffs } = this.state

    trfList.push({ label: "", value: "" })
    tariffs.map(function (item, i) {
      trfList.push({ label: item.tariff_name, value: item.id })

    })

    function exportPDF() {
      const unit = "pt";
      const size = "A3"; // Use A1, A2, A3 or A4
      const orientation = "landscape"; // portrait or landscape

      const marginLeft = 30;
      const doc = new jsPDF(orientation, unit, size);

      doc.setFontSize(15);

      const title = "Vehicles Infos";
      const headers = [["Plate Number", "Make", "Model", "Year", "Chassis N", "Fiscal Power(CV)", "Mileage", "Rating", "Fuel Type", "Transmission", "Doors", "Purchase date", "Registration Number", "Status"]];

      const dt = data.map(elt => [elt.plate_number, elt.make, elt.model, elt.year, elt.chassis_number, elt.fiscal_power, elt.mileage, elt.rating, elt.fuel_type, elt.transmission, elt.doors_number, elt.purchase_date, elt.reg_number, elt.status]);

      let content = {
        startY: 100,
        head: headers,
        body: dt
      };

      doc.text(title, marginLeft, 40);
      doc.autoTable(content);
      doc.save("Vehicles.pdf")
    }
    function printPDF() {
      const unit = "pt";
      const size = "A3"; // Use A1, A2, A3 or A4
      const orientation = "landscape"; // portrait or landscape

      const marginLeft = 30;
      const doc = new jsPDF(orientation, unit, size);

      doc.setFontSize(15);

      const title = "Vehicles Infos";
      const headers = [["Plate Number", "Make", "Model", "Year", "Chassis N", "Fiscal Power(CV)", "Mileage", "Rating", "Fuel Type", "Transmission", "Doors", "Purchase date", "Registration Number", "Status"]];

      const dt = data.map(elt => [elt.plate_number, elt.make, elt.model, elt.year, elt.chassis_number, elt.fiscal_power, elt.mileage, elt.rating, elt.fuel_type, elt.transmission, elt.doors_number, elt.purchase_date, elt.reg_number, elt.status]);
      let content = {
        startY: 100,
        head: headers,
        body: dt
      };

      doc.text(title, marginLeft, 40);
      doc.autoTable(content);
      var string = doc.output('datauristring');
      var embed = "<embed width='100%' height='100%' src='" + string + "'/>"
      var x = window.open();
      x.document.open();
      x.document.write(embed);
      x.document.close();

    }
    const optionTransmission =
      [
        { label: "", value: "" },
        { label: this.props.t("Manual"), value: "Manual" },
        { label: this.props.t("Automatic"), value: "Automatic" },
      ]
    const optionFuel =
      [
        { label: "", value: "" },
        { label: this.props.t("Gasoline"), value: "Gasoline" },
        { label: this.props.t("Diesel"), value: "Diesel" },
        { label: this.props.t("Electric"), value: "Electric" },
        { label: this.props.t("Hybrid"), value: "Hybrid" },
        { label: "GPL", value: "GPL" },
      ]
    const optionFeatures =
      [
        { label: this.props.t("Air conditionner"), value: "Air conditionner" },
        { label: this.props.t("Cruise control"), value: "Cruise control" },
        { label: "GPS", value: "GPS" },
        { label: this.props.t("Baby Seat"), value: "Baby Seat" },
        { label: this.props.t("Bike rack"), value: "Bike rack" },
        { label: this.props.t("Audio/ipod Input"), value: "Audio/ipod Input" },
        { label: this.props.t("Wheel chair accessible"), value: "Wheel chair accessible" },
      ]



    //pagination customization


    const defaultSorted = [{
      dataField: 'orderId',
      order: 'desc'
    }];

    const selectRow = {
      mode: 'checkbox',
    };
    function ExportToExcel() {
      exportFromJSON({ data: dataxcl, fileName: 'Vehicles', exportType: exportFromJSON.types.xls })
    }


    /*  var myCurrentDate=new Date();
     var d=new Date(myCurrentDate);
         d.setFullYear(d.getFullYear() - 18);
     var minDate=d.getFullYear()+"-01"
         //console.log(minDate) */
    return (

      <React.Fragment>


        <div className="page-content">
          <MetaTags>
            <title>Vehicles | Medios - Car Rental Management System</title>
          </MetaTags>
          <Container fluid>
            <Breadcrumbs title={this.props.t("Fleet")} breadcrumbItem={this.props.t("Vehicles")} />
            {this.state.success_dlg ? (
              <SweetAlert
                success
                title={this.state.dynamic_title}
                onConfirm={() => this.setState({ success_dlg: false })}
              >
                {this.state.dynamic_description}
              </SweetAlert>
            ) : null}

            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    <PaginationProvider
                      pagination={paginationFactory()}
                      keyField='id'
                      columns={(this.state.VehicleColumns || [])}
                      data={vehicles}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="id"
                          data={vehicles}
                          columns={(this.state.VehicleColumns || [])}
                          bootstrap4
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>
                              <Row className="mb-2" class="noPrint">

                                <Col sm="4">
                                  <div className="search-box me-2 mb-2 d-inline-block">
                                    {this.state.activeViewTab === "1" ? <div className="position-relative" >
                                      <SearchBar
                                        {...toolkitProps.searchProps}

                                      />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div> : <div className="position-relative" >

                                    </div>}

                                  </div>
                                </Col>

                                <Col sm="8" >

                                  <div className="text-sm-end" >
                                    <Button
                                      type="button"
                                      color="success"
                                      className="btn-rounded mb-2 me-2"
                                      onClick={this.handleVehicleClicks}
                                    >
                                      <i className="mdi mdi-calendar-search me-1" />{" "}
                                      {this.props.t("Search for vehicle")}
                                    </Button>

                                    <Link to="#"

                                    >
                                      {/* <td>
                                        <Nav className="product-view-nav" pills>
                                          <NavItem>
                                            <NavLink
                                              className={classnames({
                                                active: this.state.activeViewTab === "1",
                                              })}
                                              onClick={() => {
                                                this.toggleViewTab("1")
                                              }}
                                            >
                                              <i className="bx bx-list-ul" />
                                            </NavLink>
                                          </NavItem>
                                          <NavItem>
                                            <NavLink
                                              className={classnames({
                                                active: this.state.activeViewTab === "2",
                                              })}
                                              onClick={() => {
                                                this.toggleViewTab("2")
                                              }}
                                            >
                                              <i className="bx bx-grid-alt" />
                                            </NavLink>
                                          </NavItem>
                                        </Nav>
                                      </td> */}
                                      {/*  <i className="mdi mdi-file-export-outline font-size-18" /> */}
                                      {/* {this.state.activeViewTab === "1" ? <td>
                                        <UncontrolledDropdown>
                                          <DropdownToggle href="#" className="card-drop" tag="a">
                                            <i className="mdi mdi-dots-vertical font-size-16" />
                                          </DropdownToggle>
                                          <DropdownMenu className="dropdown-menu-end">
                                            <DropdownItem href="#" onClick={() => this.state.vehicles.length == 0 ? this.setState({ no_data: true }) : exportPDF()}>
                                              <i className="mdi mdi-file-pdf-box font-size-16 text-danger me-1" />{" "}
                                              {this.props.t("Export PDF")}

                                            </DropdownItem>
                                            <DropdownItem href="#" onClick={() => this.state.vehicles.length == 0 ? this.setState({ no_data: true }) : ExportToExcel()} >

                                              <i className="mdi mdi-microsoft-excel font-size-16 text-success me-1" />{" "}
                                              {this.props.t("Export Excel")}
                                            </DropdownItem>
                                            <DropdownItem href="#" onClick={() => this.state.vehicles.length == 0 ? this.setState({ no_data: true }) : printPDF()}>
                                              <i className="mdi mdi-printer font-size-16 text-grey me-1" />{" "}
                                              {this.props.t("Print")}
                                            </DropdownItem>
                                          </DropdownMenu>
                                        </UncontrolledDropdown>

                                      </td> : null}
 */}
                                    </Link>
                                  </div>
                                </Col>
                              </Row>
                              {this.state.activeViewTab === "1" ? <div className="table-responsive">
                                <BootstrapTable

                                  {...toolkitProps.baseProps}
                                  {...paginationTableProps}
                                  responsive
                                  defaultSorted={defaultSorted}
                                  bordered={false}
                                  striped={false}
                                  noDataIndication={emptyDataMessage}
                                  classes={
                                    "table align-middle table-nowrap table-check"
                                  }
                                  headerWrapperClasses={"table-light"}
                                />



                                {this.state.isDelete ? (
                                  <SweetAlert
                                    title={this.props.t("Are you sure?")}
                                    warning
                                    showCancel
                                    confirmButtonText={this.props.t("Yes, delete it!")}
                                    confirmBtnBsStyle="success"
                                    cancelBtnBsStyle="danger"
                                    onConfirm={() =>
                                      this.handleDeleteVehicle(vc)
                                    }
                                    onCancel={() =>
                                      this.setState({
                                        isDelete: false,
                                      })
                                    }
                                  >
                                    {this.props.t("You won't be able to revert this!")}
                                  </SweetAlert>
                                ) : null}

                                {this.state.no_data ? (
                                  <SweetAlert
                                    title={this.props.t("No data!")}
                                    warning
                                    onConfirm={() => this.setState({ no_data: false })}
                                  >
                                    {this.props.t("There is no data to export")}
                                  </SweetAlert>
                                ) : null}
                              </div> :
                                <div>
                                  <Row>
                                    {/* Import Cards */}
                                    {/* 
                                      <CardVehicle vehicles={vehicles} key={"cardvehicle"} /> */}
                                  </Row>

                                  <Row>
                                    {/* <Col lg="12">
                                <Pagination className="pagination pagination-rounded justify-content-center mt-2 mb-5">
                                  <PaginationItem disabled={page === 1}>
                                    <PaginationLink
                                      previous
                                      href="#"
                                      onClick={() => this.handlePageClick(page - 1)}
                                    />
                                  </PaginationItem>
                                  {map(Array(totalPage), (item, i) => (
                                    <PaginationItem active={i + 1 === page} key={'_k'+i}>
                                      <PaginationLink
                                        onClick={() => this.handlePageClick(i + 1)}
                                        href="#"
                                      >
                                        {i + 1}
                                      </PaginationLink>
                                    </PaginationItem>
                                  ))}
                                  <PaginationItem disabled={page === totalPage}>
                                    <PaginationLink
                                      next
                                      href="#"
                                      onClick={() => this.handlePageClick(page + 1)}
                                    />
                                  </PaginationItem>
                                </Pagination>
                              </Col> */}
                                  </Row>
                                </div>
                              }

                              {/* <div className="pagination pagination-rounded justify-content-end mb-2">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </div> */}
                              <Modal
                                isOpen={this.state.modal}
                                className={this.props.className}
                                size='md'
                              >
                                <ModalHeader toggle={this.toggle} tag="h4">
                                  {!!isEdit ? this.props.t("Edit Vehicle ") : null}
                                  {!!this.state.isShow ? this.props.t("Vehicle Infos") : null}
                                  {!isEdit && !this.state.isShow ? this.props.t("Search for Vehicle") : null}
                                </ModalHeader>
                                <ModalBody>
                                  {this.state.submitLoading ?


                                    <Col>
                                      <div>
                                        <div >
                                          <div className="spinner-chase">
                                            <div className="chase-dot"></div>
                                            <div className="chase-dot"></div>
                                            <div className="chase-dot"></div>
                                            <div className="chase-dot"></div>
                                            <div className="chase-dot"></div>
                                            <div className="chase-dot"></div>
                                          </div>
                                        </div>
                                      </div>
                                      <br />
                                      <h6 className='text-center'>uploading data ...</h6>
                                    </Col>
                                    :
                                    <AvForm
                                      onValidSubmit={
                                        this.handleValidVehicleSubmit
                                      }

                                    >
                                      <Row>
                                        {/* <Col md="12">
                                          <FormGroup className="mb-3">
                                            <Label htmlFor="validationCustom03">
                                              City
                                            </Label>
                                            <Select
                                              name="city"
                                              onChange={this.handleSelectCity}
                                              options={villes}
                                              value={selectedCity}
                                              required
                                            />                                                                                        </FormGroup>
                                        </Col> */}
                                        <Col md="4">
                                          <FormGroup className="mb-3">
                                            <Label htmlFor="validationCustom03">
                                              Latitude
                                            </Label>
                                            <AvField
                                              name="lat"
                                             
                                              required
                                            />                                                                                        </FormGroup>
                                        </Col>
                                        <Col md="4">
                                          <FormGroup className="mb-3">
                                            <Label htmlFor="validationCustom03">
                                              Longitude
                                            </Label>
                                            <AvField
                                              name="lng"
                                              required
                                            />                                                                                        </FormGroup>
                                        </Col>
                                        <Col md="4">
                                          <FormGroup className="mb-3">
                                            <Label htmlFor="validationCustom03">
                                              Radius
                                            </Label>
                                            <AvField
                                              name="radius"
                                              required
                                            />                                                                                        </FormGroup>
                                        </Col>

                                      </Row>
                                      <Row>
                                        <Col md="6">

                                          <FormGroup className="mb-3">

                                            <Label> {this.props.t("Pickup date")}</Label>
                                            <AvField
                                              name="pickup_date"
                                              type="datetime-local"
                                              errorMessage={this.props.t("Select a Pickup date")}
                                              onChange={this.handlePDChange}
                                              validate={{
                                                required: { value: true },
                                              }}
                                            />
                                          </FormGroup>
                                        </Col>
                                        <Col md="6">
                                          <FormGroup className="mb-3">

                                            <Label> {this.props.t("Return date")}</Label>
                                            <AvField
                                              name="return_date"
                                              type="datetime-local"
                                              errorMessage={this.props.t("Select a Return date")}
                                              onChange={this.handleRDChange}
                                              validate={{
                                                required: { value: true },
                                              }}
                                            />
                                          </FormGroup>
                                        </Col>
                                      </Row>


                                      {this.state.isShow ? null :
                                        <Row>
                                          <Col>
                                            <div className="text-end">

                                              <button
                                                type="submit"
                                                className="btn btn-success save-user"
                                              >
                                                {this.props.t("Search")}
                                              </button>
                                            </div>
                                          </Col>
                                        </Row>
                                      }

                                    </AvForm>
                                  }
                                </ModalBody>
                              </Modal>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

Av_Vehicles.propTypes = {

  reservations: PropTypes.array,
  onGetReservation: PropTypes.func,
  vehicles: PropTypes.array,
  onGetVehicle: PropTypes.func,
  onAddNewVehicle: PropTypes.func,
  onDeleteVehicle: PropTypes.func,
  onUpdateVehicle: PropTypes.func,
  className: PropTypes.any,
  t: PropTypes.any,
}

const mapStateToProps = state => ({
  vehicles: state.vehicle.vehicles,
  reservations: state.reservation.reservations,
})

const mapDispatchToProps = dispatch => ({
  onGetVehicle: () => dispatch(getVehicle()),
  onGetReservation: () => dispatch(getReservation()),
  onAddNewVehicle: vehicle => dispatch(addNewVehicle(vehicle)),
  onUpdateVehicle: vehicle => dispatch(updateVehicle(vehicle)),
  onDeleteVehicle: vehicle => dispatch(deleteVehicle(vehicle)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(Av_Vehicles)))
