import {
  
  GET_STAFF ,
  GET_STAFF_FAIL,
  GET_STAFF_SUCCESS,
  ADD_NEW_STAFF,
  ADD_STAFF_SUCCESS,
  ADD_STAFF_FAIL,
  UPDATE_STAFF,
  UPDATE_STAFF_SUCCESS,
  UPDATE_STAFF_FAIL,
  DELETE_STAFF,
  DELETE_STAFF_SUCCESS,
  DELETE_STAFF_FAIL,
  
} from "./actionTypes"



export const getStaff = () => ({
  type: GET_STAFF,
})

export const getStaffSuccess = staff => ({
  type: GET_STAFF_SUCCESS,
  payload: staff,
})

export const getStaffFail = error => ({
  type: GET_STAFF_FAIL,
  payload: error,
})

export const addNewStaff = staff => ({
  type: ADD_NEW_STAFF,
  payload: staff,
})

export const addStaffSuccess = staff => ({
  type: ADD_STAFF_SUCCESS,
  payload: staff,
})

export const addStaffFail = error => ({
  type: ADD_STAFF_FAIL,
  payload: error,
})

export const updateStaff = staff => ({
  type: UPDATE_STAFF,
  payload: staff,
})

export const updateStaffSuccess = staff => ({
  type: UPDATE_STAFF_SUCCESS,
  payload: staff,
})

export const updateStaffFail = error => ({
  type: UPDATE_STAFF_FAIL,
  payload: error,
})

export const deleteStaff = staff => ({
  type: DELETE_STAFF,
  payload: staff,
})

export const deleteStaffSuccess = staff => ({
  type: DELETE_STAFF_SUCCESS,
  payload: staff,
})

export const deleteStaffFail = error => ({
  type: DELETE_STAFF_FAIL,
  payload: error,
})
