import React from "react"
import ReactDOM from "react-dom"
import App from "./App"
import * as serviceWorker from "./serviceWorker"
import { BrowserRouter } from "react-router-dom"
import "./i18n"

import { Provider } from "react-redux"
import { GoogleOAuthProvider } from '@react-oauth/google';

import store from "./store"
import { createRoot } from 'react-dom/client';
const container = document.getElementById('root');
const root = createRoot(container);

const app = (
  <Provider store={store}>
    <BrowserRouter>
    <GoogleOAuthProvider clientId="53122160804-epd7tc6m4tdel9m7c35238jv12k7a843.apps.googleusercontent.com">
      <App />
    </GoogleOAuthProvider>
      
    </BrowserRouter>
  </Provider>
)
root.render(app);
//ReactDOM.render(app, document.getElementById("root"))
serviceWorker.unregister()