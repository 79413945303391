import React, { Component } from "react"
import ReactApexChart from "react-apexcharts"

import PropTypes from 'prop-types'
import { withTranslation } from "react-i18next"
class Apaexlinecolumn extends Component {
  constructor(props) {
    super(props)

    this.state = {
      
      options: {
        chart: {
          toolbar: {
            show: true,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "60%",
            endingShape: "rounded",
            borderRadius: 5
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 1.8,
          colors: ["transparent"],
        },

        colors: ["#34c38f", "#556ee6", "#f46a6a"],
        xaxis: {
          categories: [
            this.props.t("Jan"),
            this.props.t("Feb"),
            this.props.t("Mar"),
            this.props.t("Apr"),
            this.props.t("May"),
            this.props.t("Jun"),
            this.props.t("Jul"),
            this.props.t("Aug"),
            this.props.t("Sep"),
            this.props.t("Oct"),
            this.props.t("Nov"),
            this.props.t("Dec")
          ],
        },
        yaxis: {
          title: {
            text: " MAD",
          },
        },
        grid: {
          borderColor: "#f1f1f1",
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val + " MAD"
            },
          },
        },
      },
    }
  }
  render() {
    return (
      <React.Fragment>
        <ReactApexChart
          options={this.state.options}
          series={this.props.chartSeries || []}
          type="bar"
          height={350}
          className="apex-charts"
        />
      </React.Fragment>
    )
  }
}
Apaexlinecolumn.propTypes = {
  chartSeries: PropTypes.any,
  t: PropTypes.any
}

export default withTranslation()(Apaexlinecolumn);
