import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

const generatePDF = () => {
  const input = document.getElementById('down');
  
  // Check if the element exists
  if (!input) {
    console.error("Element with id 'down' not found");
    return;
  }

  // Log the dimensions of the element
  console.log("Element dimensions:", input.offsetWidth, input.offsetHeight);

  // If the element has no height, try to find out why
  if (input.offsetHeight === 0) {
    console.error("Element has no height. This could be due to:");
    console.error("1. The element is hidden (display: none)");
    console.error("2. The element has no content");
    console.error("3. CSS issues preventing the element from rendering properly");
    return;
  }

  html2canvas(input, { 
    scale: 2,
    useCORS: true,
    logging: true,
    allowTaint: true,
  }).then((canvas) => {
    // Log canvas dimensions
    console.log("Canvas dimensions:", canvas.width, canvas.height);

    if (canvas.height === 0) {
      console.error("Generated canvas has no height. html2canvas failed to capture the element.");
      return;
    }

    const imgData = canvas.toDataURL('image/jpeg', 0.8);
    const pdf = new jsPDF('p', 'mm', 'a4');
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();
    const imgWidth = canvas.width;
    const imgHeight = canvas.height;
    const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
    const imgX = (pdfWidth - imgWidth * ratio) / 2;
    const imgY = 30;
    pdf.addImage(imgData, 'JPEG', imgX, imgY, imgWidth * ratio, imgHeight * ratio);
    
    // Open PDF in a new tab
    window.open(pdf.output('bloburl'), '_blank');
  }).catch(error => {
    console.error("Error in html2canvas:", error);
  });
};

export default generatePDF;