import {
  
  GET_RESERVATION ,
  GET_RESERVATION_FAIL,
  GET_RESERVATION_SUCCESS,
  ADD_NEW_RESERVATION,
  ADD_RESERVATION_SUCCESS,
  ADD_RESERVATION_FAIL,
  UPDATE_RESERVATION,
  UPDATE_RESERVATION_SUCCESS,
  UPDATE_RESERVATION_FAIL,
  DELETE_RESERVATION,
  DELETE_RESERVATION_SUCCESS,
  DELETE_RESERVATION_FAIL,
} from "./actionTypes"




export const getReservation = () => ({
  type: GET_RESERVATION,
})

export const getReservationSuccess = reservation => ({
  type: GET_RESERVATION_SUCCESS,
  payload: reservation,
})

export const getReservationFail = error => ({
  type: GET_RESERVATION_FAIL,
  payload: error,
})

export const addNewReservation = reservation => ({
  type: ADD_NEW_RESERVATION,
  payload: reservation,
})

export const addReservationSuccess = reservation => ({
  type: ADD_RESERVATION_SUCCESS,
  payload: reservation,
})

export const addReservationFail = error => ({
  type: ADD_RESERVATION_FAIL,
  payload: error,
})

export const updateReservation = reservation => ({
  type: UPDATE_RESERVATION,
  payload: reservation,
})

export const updateReservationSuccess = reservation => ({
  type: UPDATE_RESERVATION_SUCCESS,
  payload: reservation,
})

export const updateReservationFail = error => ({
  type: UPDATE_RESERVATION_FAIL,
  payload: error,
})

export const deleteReservation = reservation => ({
  type: DELETE_RESERVATION,
  payload: reservation,
})

export const deleteReservationSuccess = reservation => ({
  type: DELETE_RESERVATION_SUCCESS,
  payload: reservation,
})

export const deleteReservationFail = error => ({
  type: DELETE_RESERVATION_FAIL,
  payload: error,
})
