import {
 
  GET_INSSURANCE_FAIL,
  GET_INSSURANCE_SUCCESS,
  ADD_INSSURANCE_SUCCESS,
  ADD_INSSURANCE_FAIL,
  UPDATE_INSSURANCE_SUCCESS,
  UPDATE_INSSURANCE_FAIL,
  DELETE_INSSURANCE_SUCCESS,
  DELETE_INSSURANCE_FAIL,
  
} from "./actionTypes"

const INIT_STATE = {
 
  inssurances: []
  
}

const Inssurance = (state = INIT_STATE, action) => {
  switch (action.type) {
    
    case GET_INSSURANCE_SUCCESS:
      return {
        ...state,
        inssurances: action.payload,
      }

    case GET_INSSURANCE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_INSSURANCE_SUCCESS:
      return {
        ...state,
        inssurances: [...state.inssurances, action.payload],
      }

    case ADD_INSSURANCE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_INSSURANCE_SUCCESS:
      return {
        ...state,
        inssurances: state.inssurances.map(inssurance =>
          inssurance.code.toString() === action.payload.code.toString()
            ? { inssurance, ...action.payload }
            : inssurance
        ),
      }

    case UPDATE_INSSURANCE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_INSSURANCE_SUCCESS:
      return {
        ...state,
        inssurances: state.inssurances.filter(
          
          inssurance => inssurance.code.toString() !== action.payload.code.toString()
        ),
      }

    case DELETE_INSSURANCE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

   
    default:
      return state
  }
}

export default Inssurance
