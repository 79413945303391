import {
  
  GET_INSSURANCE ,
  GET_INSSURANCE_FAIL,
  GET_INSSURANCE_SUCCESS,
  ADD_NEW_INSSURANCE,
  ADD_INSSURANCE_SUCCESS,
  ADD_INSSURANCE_FAIL,
  UPDATE_INSSURANCE,
  UPDATE_INSSURANCE_SUCCESS,
  UPDATE_INSSURANCE_FAIL,
  DELETE_INSSURANCE,
  DELETE_INSSURANCE_SUCCESS,
  DELETE_INSSURANCE_FAIL,
  
} from "./actionTypes"



export const getInssurance = () => ({
  type: GET_INSSURANCE,
})

export const getInssuranceSuccess = inssurance => ({
  type: GET_INSSURANCE_SUCCESS,
  payload: inssurance,
})

export const getInssuranceFail = error => ({
  type: GET_INSSURANCE_FAIL,
  payload: error,
})

export const addNewInssurance = inssurance => ({
  type: ADD_NEW_INSSURANCE,
  payload: inssurance,
})

export const addInssuranceSuccess = inssurance => ({
  type: ADD_INSSURANCE_SUCCESS,
  payload: inssurance,
})

export const addInssuranceFail = error => ({
  type: ADD_INSSURANCE_FAIL,
  payload: error,
})

export const updateInssurance = inssurance => ({
  type: UPDATE_INSSURANCE,
  payload: inssurance,
})

export const updateInssuranceSuccess = inssurance => ({
  type: UPDATE_INSSURANCE_SUCCESS,
  payload: inssurance,
})

export const updateInssuranceFail = error => ({
  type: UPDATE_INSSURANCE_FAIL,
  payload: error,
})

export const deleteInssurance = inssurance => ({
  type: DELETE_INSSURANCE,
  payload: inssurance,
})

export const deleteInssuranceSuccess = inssurance => ({
  type: DELETE_INSSURANCE_SUCCESS,
  payload: inssurance,
})

export const deleteInssuranceFail = error => ({
  type: DELETE_INSSURANCE_FAIL,
  payload: error,
})
