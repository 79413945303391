import {
  
  GET_INDIE ,
  GET_INDIE_FAIL,
  GET_INDIE_SUCCESS,
  ADD_NEW_INDIE,
  ADD_INDIE_SUCCESS,
  ADD_INDIE_FAIL,
  UPDATE_INDIE,
  UPDATE_INDIE_SUCCESS,
  UPDATE_INDIE_FAIL,
  DELETE_INDIE,
  DELETE_INDIE_SUCCESS,
  DELETE_INDIE_FAIL,

  GET_COMPANY ,
  GET_COMPANY_FAIL,
  GET_COMPANY_SUCCESS,
  ADD_NEW_COMPANY,
  ADD_COMPANY_SUCCESS,
  ADD_COMPANY_FAIL,
  UPDATE_COMPANY,
  UPDATE_COMPANY_SUCCESS,
  UPDATE_COMPANY_FAIL,
  DELETE_COMPANY,
  DELETE_COMPANY_SUCCESS,
  DELETE_COMPANY_FAIL,
  
} from "./actionTypes"



export const getIndie = () => ({
  type: GET_INDIE,
})

export const getIndieSuccess = indie => ({
  type: GET_INDIE_SUCCESS,
  payload: indie,
})

export const getIndieFail = error => ({
  type: GET_INDIE_FAIL,
  payload: error,
})

export const addNewIndie = indie => ({
  type: ADD_NEW_INDIE,
  payload: indie,
})

export const addIndieSuccess = indie => ({
  type: ADD_INDIE_SUCCESS,
  payload: indie,
})

export const addIndieFail = error => ({
  type: ADD_INDIE_FAIL,
  payload: error,
})

export const updateIndie = indie => ({
  type: UPDATE_INDIE,
  payload: indie,
})

export const updateIndieSuccess = indie => ({
  type: UPDATE_INDIE_SUCCESS,
  payload: indie,
})

export const updateIndieFail = error => ({
  type: UPDATE_INDIE_FAIL,
  payload: error,
})

export const deleteIndie = indie => ({
  type: DELETE_INDIE,
  payload: indie,
})

export const deleteIndieSuccess = indie => ({
  type: DELETE_INDIE_SUCCESS,
  payload: indie,
})

export const deleteIndieFail = error => ({
  type: DELETE_INDIE_FAIL,
  payload: error,
})


//


export const getCompany = () => ({
  type: GET_COMPANY,
})

export const getCompanySuccess = company => ({
  type: GET_COMPANY_SUCCESS,
  payload: company,
})

export const getCompanyFail = error => ({
  type: GET_COMPANY_FAIL,
  payload: error,
})

export const addNewCompany = company => ({
  type: ADD_NEW_COMPANY,
  payload: company,
})

export const addCompanySuccess = company => ({
  type: ADD_COMPANY_SUCCESS,
  payload: company,
})

export const addCompanyFail = error => ({
  type: ADD_COMPANY_FAIL,
  payload: error,
})

export const updateCompany = company => ({
  type: UPDATE_COMPANY,
  payload: company,
})

export const updateCompanySuccess = company => ({
  type: UPDATE_COMPANY_SUCCESS,
  payload: company,
})

export const updateCompanyFail = error => ({
  type: UPDATE_COMPANY_FAIL,
  payload: error,
})

export const deleteCompany = company => ({
  type: DELETE_COMPANY,
  payload: company,
})

export const deleteCompanySuccess = company => ({
  type: DELETE_COMPANY_SUCCESS,
  payload: company,
})

export const deleteCompanyFail = error => ({
  type: DELETE_COMPANY_FAIL,
  payload: error,
})
