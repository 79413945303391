import vehicle1 from "./img-1.png";
import vehicle2 from "./img-2.png";
import vehicle3 from "./img-3.png";
import vehicle4 from "./img-4.png";
import vehicle5 from "./img-5.png";
import vehicle6 from "./img-6.png";
import vehicle7 from "./img-7.png"
import vehicle8 from "./img-8.png"
import vehicle9 from "./img-9.png"
import vehicle10 from "./img-10.png";
import vehicle11 from "./img-11.png";
import vehicle12 from "./img-12.png";
import vehicle13 from "./img-13.png";

export { vehicle1, vehicle2, vehicle3, vehicle4, vehicle5, vehicle6, vehicle7, vehicle8, vehicle9, vehicle10, vehicle11, vehicle12,vehicle13 }

export const vehicleImages = {
  vehicle1: vehicle1,
  vehicle2: vehicle2,
  vehicle3: vehicle3,
  vehicle4: vehicle4,
  vehicle5: vehicle5,
  vehicle6: vehicle6,
  vehicle7: vehicle7,
  vehicle8: vehicle8,
  vehicle9: vehicle9,
  vehicle10: vehicle10,
  vehicle11: vehicle11,
  vehicle12: vehicle12,
  vehicle13:vehicle13
}
