import {
 
  GET_RESERVATION_FAIL,
  GET_RESERVATION_SUCCESS,
  ADD_RESERVATION_SUCCESS,
  ADD_RESERVATION_FAIL,
  UPDATE_RESERVATION_SUCCESS,
  UPDATE_RESERVATION_FAIL,
  DELETE_RESERVATION_SUCCESS,
  DELETE_RESERVATION_FAIL,
  
} from "./actionTypes"

const INIT_STATE = {
 
  reservations: []
  
}

const Reservation = (state = INIT_STATE, action) => {
  switch (action.type) {
    
    case GET_RESERVATION_SUCCESS:
      return {
        ...state,
        reservations: action.payload,
      }

    case GET_RESERVATION_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_RESERVATION_SUCCESS:
      return {
        ...state,
        reservations: [...state.reservations, action.payload],
      }

    case ADD_RESERVATION_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_RESERVATION_SUCCESS:
      return {
        ...state,
        reservations: state.reservations.map(reservation =>
          reservation.code.toString() === action.payload.code.toString()
            ? { reservation, ...action.payload }
            : reservation
        ),
      }

    case UPDATE_RESERVATION_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_RESERVATION_SUCCESS:
      return {
        ...state,
        reservations: state.reservations.filter(
          reservation => reservation.code.toString() !== action.payload.code.toString()
        ),
      }

    case DELETE_RESERVATION_FAIL:
      return {
        ...state,
        error: action.payload,
      }

   
    default:
      return state
  }
}

export default Reservation
