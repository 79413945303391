import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import ModalVideo from "react-modal-video";
import { withTranslation } from "react-i18next"
import PropTypes from 'prop-types'
import {

  ListGroupItem,
  ListGroup,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Media,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap"
import { Link } from "react-router-dom"
import image1 from "assets/images/1.png"
import image2 from "assets/images/2.png"
import image3 from "assets/images/3.png"
import image4 from "assets/images/4.png"
import image5 from "assets/images/5.png"
import image6 from "assets/images/6.png"
import image7 from "assets/images/7.png"
import image8 from "assets/images/8.png"
import image9 from "assets/images/9.png"
import image10 from "assets/images/10.png"
import image11 from "assets/images/11.png"
import image12 from "assets/images/12.png"
import classnames from "classnames"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import reactRouterDom from "react-router-dom";

class Tutorial extends Component {
  constructor() {
    super()
    this.state = {
      activeTab: "1",
      isOpen: false,
      videoId: ""
    }
    this.toggleTab = this.toggleTab.bind(this)
  }

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      })
    }
  }
  openModal = (videoId) => {
    this.setState({ isOpen: true, videoId: videoId });
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>{this.props.t("Tutorial | Medios - Car Rental Management System")}</title>
          </MetaTags>
          <ModalVideo
            channel="youtube"
            isOpen={this.state.isOpen}
            videoId={this.state.videoId}
            onClose={() => this.setState({ isOpen: false })}
          />
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title={this.props.t("Extra")} breadcrumbItem={this.props.t("Tutorial")} />

            <div className="checkout-tabs">
              <Row>

                <Row>
                  <Col md="6">
                    <Card>
                      <CardBody>

                        <CardTitle className="mb-3 h4">
                          {this.props.t("Dashboard Overview")}
                        </CardTitle>
                        <div className="text-muted font-size-14">
                          <p>
                            {this.props.t("Get a quick grasp of your dashboard&apos;s features for smooth navigation and efficient management of your vehicle rental business.")}
                          </p>
                        </div>

                        <Row className="align-items-center">

                          <Col >
                            <div className="position-relative">
                              <img
                                src={image1}
                                className="rounded img-fluid mx-auto d-block"
                                alt=""
                                onClick={() => this.openModal("aFajXRy4zc4")}
                              />

                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  
                  <Col md="6">
                    <Card>
                      <CardBody>

                        <CardTitle className="mb-3 h4">
                          {this.props.t("Add your rates")}
                        </CardTitle>
                        <div className="text-muted font-size-14">
                          <p>
                            {this.props.t("Customize rental pricing easily, adapting to market demands while maximizing revenue.")}
                          </p>

                        </div>
                        <Row className="align-items-center">

                          <Col >
                            <div className="position-relative">
                              <img
                                src={image3}
                                className="rounded img-fluid mx-auto d-block"
                                alt=""
                                onClick={() => this.openModal("aFajXRy4zc4")}
                              />

                            </div>
                          </Col>
                        </Row>

                      </CardBody>
                    </Card>
                  </Col>
                </Row>


                <Row>
                <Col md="6">
                    <Card>
                      <CardBody>

                        <CardTitle className="mb-3 h4">
                          {this.props.t("Managing Vehicles")}
                        </CardTitle>
                        <div className="text-muted font-size-14 ">
                          <p>{this.props.t("Control your fleet effortlessly, from adding new vehicles to tracking usage, ensuring optimal performance.")}</p>


                        </div>
                        <Row className="align-items-center">

                          <Col >
                            <div className="position-relative">
                              <img
                                src={image2}
                                className="rounded img-fluid mx-auto d-block"
                                alt=""
                                onClick={() => this.openModal("aFajXRy4zc4")}
                              />

                            </div>
                          </Col>
                        </Row>

                      </CardBody>
                    </Card>
                  </Col>
                  <Col md="6">
                    <Card>
                      <CardBody>

                        <CardTitle className="mb-3 h4">
                          {this.props.t("Managing Reservations")}
                        </CardTitle>
                        <div className="text-muted font-size-14">
                          <p>
                            {this.props.t("Streamline booking processes, ensuring organized workflows and seamless customer experiences.")}
                          </p>


                        </div>

                        <Row className="align-items-center">

                          <Col >
                            <div className="position-relative">
                              <img
                                src={image4}
                                className="rounded img-fluid mx-auto d-block"
                                alt=""
                                onClick={() => this.openModal("aFajXRy4zc4")}
                              />

                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>


                <Row>
                  <Col md="6">
                    <Card>
                      <CardBody>

                        <CardTitle className="mb-3 h4">
                          {this.props.t("Reservation Calendar")}
                        </CardTitle>
                        <div className="text-muted font-size-14">
                          <p>
                            {this.props.t("Visualize upcoming bookings at a glance, managing availability and avoiding conflicts effortlessly.")}
                          </p>


                        </div>
                        <Row className="align-items-center">

                          <Col >
                            <div className="position-relative">
                              <img
                                src={image5}
                                className="rounded img-fluid mx-auto d-block"
                                alt=""
                                onClick={() => this.openModal("aFajXRy4zc4")}
                              />

                            </div>
                          </Col>
                        </Row>

                      </CardBody>
                    </Card>
                  </Col>
                  <Col md="6">
                    <Card>
                      <CardBody>

                        <CardTitle className="mb-3 h4">
                          {this.props.t("Maintenances")}
                        </CardTitle>
                        <div className="text-muted font-size-14">
                          <p>
                            {this.props.t("Schedule and track vehicle maintenance efficiently, minimizing downtime and maximizing reliability.")}
                          </p>


                        </div>
                        <Row className="align-items-center">

                          <Col >
                            <div className="position-relative">
                              <img
                                src={image6}
                                className="rounded img-fluid mx-auto d-block"
                                alt=""
                                onClick={() => this.openModal("aFajXRy4zc4")}
                              />

                            </div>
                          </Col>
                        </Row>

                      </CardBody>
                    </Card>
                  </Col>
                </Row>

                <Row>
                  <Col md="6">
                    <Card>
                      <CardBody>

                        <CardTitle className="mb-3 h4">
                          {this.props.t("Inssurances")}
                        </CardTitle>
                        <div className="text-muted font-size-14">
                          <p>
                            {this.props.t("Manage insurance policies and claims effectively, safeguarding your assets and ensuring compliance.")}
                          </p>

                        </div>
                        <Row className="align-items-center">

                          <Col >
                            <div className="position-relative">
                              <img
                                src={image7}
                                className="rounded img-fluid mx-auto d-block"
                                alt=""
                                onClick={() => this.openModal("aFajXRy4zc4")}
                              />

                            </div>
                          </Col>
                        </Row>

                      </CardBody>
                    </Card>
                  </Col>
                  <Col md="6">
                    <Card>
                      <CardBody>

                        <CardTitle className="mb-3 h4">
                          {this.props.t("Annual tax")}
                        </CardTitle>
                        <div className="text-muted font-size-14">
                          <p>
                            {this.props.t("Simplify tax calculations and reporting, ensuring financial transparency and regulatory compliance.")}
                          </p>


                        </div>
                        <Row className="align-items-center">

                          <Col >
                            <div className="position-relative">
                              <img
                                src={image8}
                                className="rounded img-fluid mx-auto d-block"
                                alt=""
                                onClick={() => this.openModal("aFajXRy4zc4")}
                              />

                            </div>
                          </Col>
                        </Row>

                      </CardBody>
                    </Card>
                  </Col>
                </Row>

                <Row>
                  <Col md="6">
                    <Card>
                      <CardBody>

                        <CardTitle className="mb-3 h4">
                          {this.props.t("Traffic fines")}
                        </CardTitle>
                        <div className="text-muted font-size-14">
                          <p>
                            {this.props.t("Stay informed about violations and manage fines promptly to uphold traffic regulations.")}
                          </p>


                        </div>
                        <Row className="align-items-center">

                          <Col >
                            <div className="position-relative">
                              <img
                                src={image9}
                                className="rounded img-fluid mx-auto d-block"
                                alt=""
                                onClick={() => this.openModal("aFajXRy4zc4")}
                              />

                            </div>
                          </Col>
                        </Row>

                      </CardBody>
                    </Card>
                  </Col>
                  <Col md="6">
                    <Card>
                      <CardBody>

                        <CardTitle className="mb-3 h4">
                          {this.props.t("Clients")}
                        </CardTitle>
                        <div className="text-muted font-size-14">
                          <p>
                            {this.props.t("Maintain strong client relationships through personalized services and efficient management.")}
                          </p>


                        </div>
                        <Row className="align-items-center">

                          <Col >
                            <div className="position-relative">
                              <img
                                src={image10}
                                className="rounded img-fluid mx-auto d-block"
                                alt=""
                                onClick={() => this.openModal("aFajXRy4zc4")}
                              />

                            </div>
                          </Col>
                        </Row>

                      </CardBody>
                    </Card>
                  </Col>
                </Row>

                <Row>
                  <Col md="6">
                    <Card>
                      <CardBody>

                        <CardTitle className="mb-3 h4">
                          {this.props.t("Workshops")}
                        </CardTitle>
                        <div className="text-muted font-size-14">
                          <p>
                            {this.props.t("Coordinate maintenance tasks seamlessly, ensuring timely completion and vehicle quality standards.")}
                          </p>


                        </div>
                        <Row className="align-items-center">

                          <Col >
                            <div className="position-relative">
                              <img
                                src={image11}
                                className="rounded img-fluid mx-auto d-block"
                                alt=""
                                onClick={() => this.openModal("aFajXRy4zc4")}
                              />

                            </div>
                          </Col>
                        </Row>

                      </CardBody>
                    </Card>
                  </Col>
                  <Col md="6">
                    <Card>
                      <CardBody>

                        <CardTitle className="mb-3 h4">
                          {this.props.t("Staff members")}
                        </CardTitle>

                        <div className="text-muted font-size-14">
                          <p>
                            {this.props.t("Empower your team with intuitive management tools, fostering collaboration and optimizing efficiency.")}
                          </p>


                        </div>
                        <Row className="align-items-center">

                          <Col >
                            <div className="position-relative">
                              <img
                                src={image12}
                                className="rounded img-fluid mx-auto d-block"
                                alt=""
                                onClick={() => this.openModal("aFajXRy4zc4")}
                              />

                            </div>
                          </Col>
                        </Row>

                      </CardBody>
                    </Card>
                  </Col>
                </Row>



              </Row>
            </div>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

Tutorial.propTypes = {
  t: PropTypes.any
}


export default withTranslation()(Tutorial);