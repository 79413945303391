import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import MetaTags from 'react-meta-tags';
import { withRouter } from "react-router-dom"
import { countBy, isEmpty, size } from "lodash"
import BootstrapTable from "react-bootstrap-table-next"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import Dropzone from "react-dropzone"
import { withTranslation } from "react-i18next"
import writtenNumber, { i18n } from 'written-number';
import maintanence from "../../../assets/images/coming-soon.svg"
import toastr from "toastr";
import toast, { Toaster } from 'react-hot-toast';
import "./../../Rental/print.css";

import
paginationFactory, {
    PaginationProvider,
    PaginationListStandalone,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import { Link } from "react-router-dom"
import * as moment from 'moment';
import Select from "react-select"
import jsPDF from "jspdf";
import "jspdf-autotable";
import Moment from 'moment';
import { InputGroupAddon, UncontrolledDropdown, DropdownItem, DropdownMenu, DropdownToggle, Form, InputGroup, Input, FormGroup, Label, Button, Card, CardBody, Col, Table, ModalFooter, Container, Row, Modal, Badge, ModalHeader, ModalBody } from "reactstrap"

import TodayPickups from "../../Dashboard/Today_pickups"
import TodayReturns from "../../Dashboard/Today_returns"

// Add the Firebase products that you want to use
import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import "firebase/database"
import "firebase/storage"

import { AvForm, AvField } from "availity-reactstrap-validation"
import SweetAlert from "react-bootstrap-sweetalert"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import {
    getReservation,
    addNewReservation,
    updateReservation,
    deleteReservation,
    getVehicle,
    getIndie,
    getTariff,
    updateVehicle
} from "store/actions"



/* import Invoices from "store/invoices/reducer"; */

class Documents extends Component {
    constructor(props) {
        super(props)
        this.state = {
            user_id: "",
            modal1: false,
            selectedPeriod: "day",
            selectedVehicleName: "All Clients",
            selectedVehicle1: 1,
            selectedDate: "",
            selectedTS: null,
            selectedOption: "todo",
            currentYear: new Date().getFullYear(),
            now: "",
            loading: true,
            submitLoading: false,
            isShow: false,
            changed: false,
            vcchanged: false,
            clchanged: false,
            trfchanged: false,
            pdchanged: false,
            rdchanged: false,
            gender: "",
            viewmodal: false,
            modal: false,
            isDelete: false,
            no_data: false,
            totalAmount: 0,
            p: false,
            success_dlg: false,
            dynamic_title: '',
            dynamic_description: '',
            vc: "",
            mile: 0,
            selectedType: null,
            selectedVehicle: null,
            selectedClient: null,
            selectedTariff: null,
            PD: new Date(),
            RD: new Date().setDate(new Date().getDate() + 1),
            reservations: [],
            client_infos: [],
            agency: [],
            inv_modal: false,
            reservation: [],
            vehicles: [],
            indies: [],

            date_birth: new Date(),
            ReservationColumns: [

                {
                    dataField: "code",
                    text: this.props.t("Document number"),
                    sort: true,
                    formatter: (cellContent, row) => (
                        <Link to="#" className="text-body fw-bold">

                            {row.code}
                        </Link>
                    ),
                }, {
                    dataField: "createdAt",
                    text: this.props.t("Date created"),
                    sort: true,
                    formatter: (cellContent, row) => (
                        <>

                            {" "}
                            {moment(row.createdAt).format('YYYY-MM-DDTHH:mm')}
                        </>
                    ),
                },
                {
                    dataField: "client",
                    text: this.props.t("Client"),
                    sort: true,

                },
                {
                    dataField: "vehicle",
                    text: this.props.t("Vehicle"),
                    sort: true,
                },
                /* {
                    dataField: "pickup_date",
                    text: this.props.t("Pickup date"),
                    sort: true,
                },
                {
                    dataField: "return_date",
                    text: this.props.t("Return date"),
                    sort: true,
                }, */


                {
                    dataField: "days",
                    text: this.props.t("Days"),
                    sort: true,

                },
                /* {
                    dataField: "amount",
                    text: this.props.t("Amount"),
                    sort: true,
                    formatter: (cellContent, row) => (
                        <>

                            {" "}
                            {row.amount} dh
                        </>
                    ),
                }, */
                {
                    dataField: "total_amount",
                    text: this.props.t("Total amount"),
                    sort: true,
                    formatter: (cellContent, row) => (
                        <>

                            {" "}
                            {row.total_amount} dh
                        </>
                    ),
                },
                {
                    dataField: "status",
                    isDummyField: true,
                    text: this.props.t(" Rental status"),
                    sort: true,
                    formatter: (cellContent, row) => (

                        <Badge
                            className={
                                row.status === 'Created'
                                    ? "font-size-12 badge-soft-info"
                                    : row.status === 'Rental'
                                        ? "font-size-12 badge-soft-warning"
                                        : row.status === 'Done'
                                            ? "font-size-12 badge-soft-success"
                                            : "font-size-12 badge-soft-danger"
                            }
                            color={row.status}
                            pill
                        >
                            {this.props.t(row.status)}
                        </Badge>

                    ),
                },

                /* {
                    dataField: "view",
                    isDummyField: true,
                    text: this.props.t("View Details"),
                    sort: true,
                    formatter: (cellContent, reservation) => (
                        <Button
                            type="button"
                            color="primary"
                            className="btn-sm btn-rounded"
                            onClick={() => this.handleViewClick(reservation)}
                        >
                            {this.props.t("View Details")}
                        </Button>
                    ),
                }, */
                {
                    dataField: "action",
                    isDummyField: true,
                    text: this.props.t("Documents"),
                    formatter: (cellContent, reservation) => (
                        <>
                            <div className="d-flex gap-3">
                            <Link to={"/invoice/" + reservation.id}>
                                <Button
                                    type="button"
                                    color="primary"
                                    className="btn-sm btn-rounded"
                                    
                                >
                                    <i className="bx bx-receipt me-1" id="edittooltip" />
                                    {this.props.t("Invoice")}
                                </Button>
                                </Link>
                                <Link to={"/print-contract/" + reservation.id}>
                                    <Button
                                        type="button"
                                        color="primary"
                                        className="btn-sm btn-rounded"

                                    >
                                        <i className="mdi mdi-file-document-edit-outline me-1" id="edittooltip" />
                                        {this.props.t("Contract")}
                                    </Button>
                                </Link>

                            </div>

                        </>
                    ),
                },
            ],
            selectedFiles: []
        }

        this.handleReservationClick = this.handleReservationClick.bind(this)
        this.toggle = this.toggle.bind(this)
        this.handleValidReservationSubmit = this.handleValidReservationSubmit.bind(this)
        this.handleReservationClicks = this.handleReservationClicks.bind(this)
        this.toLowerCase1 = this.toLowerCase1.bind(this)
        this.toggleDelete = this.toggleDelete.bind(this)
        this.handleViewClick = this.handleViewClick.bind(this)
        this.handleInvClick = this.handleInvClick.bind(this)
        this.handleSelectType = this.handleSelectType.bind(this)
        this.handleSelectVehicle = this.handleSelectVehicle.bind(this)
        this.handleSelectClient = this.handleSelectClient.bind(this)
        this.handleSelectTariff = this.handleSelectTariff.bind(this)
        this.handlePDChange = this.handlePDChange.bind(this)
        this.handleRDChange = this.handleRDChange.bind(this)
        this.handleAcceptedFiles = this.handleAcceptedFiles.bind(this)
        this.toggleIsShow = this.toggleIsShow.bind(this)
        this.toggleInv = this.toggleInv.bind(this)
        this.getAmount = this.getAmount.bind(this)
        this.toggle_modal = this.toggle_modal.bind(this)
        this.onValueChange = this.onValueChange.bind(this)
        this.handleValidEventSubmit = this.handleValidEventSubmit.bind(this)
        this.handleSelectVehicleFilter = this.handleSelectVehicleFilter.bind(this)
    }
    toggleInv() {
        this.setState(prevState => ({
            inv_modal: !prevState.inv_modal,
        }))
    }
    onValueChange(event) {
        const { notifs } = this.state
        this.setState({
            selectedOption: event.target.id
        });
        ////console.log(event.target.id)

    }
    dateChanged = d => {
        /*  const { vehicles, rdchanged, RD } = this.state
         this.setState({ PD: d.target.value })*/
        this.setState({
            selectedTS: moment(d.target.value, 'YYYY-MM-DD').valueOf(),
            selectedDate: d.target.value,
            selectedOption: "dia"
        })

        //console.log(d.target.value)
    }
    monthChanged = d => {

        this.setState({
            selectedTS: moment(d.target.value, 'YYYY-MM').valueOf(),
            selectedDate: d.target.value,
            selectedOption: "mes"
        })

        //console.log(d.target.value)
    }
    yearChanged = (event) => {
        const selectedYear = event.target.value;
        console.log(selectedYear)
        this.setState({
            selectedTS: moment(event.target.value, 'YYYY').valueOf(),
            selectedDate: event.target.value,
            selectedOption: "ano"
        })

        //console.log(d.target.value)
    }
    toggle_modal() {
        this.setState(prevState => ({
            modal1: !prevState.modal1,
        }))
        this.removeBodyCss()
    }

    removeBodyCss() {
        document.body.classList.add("no_padding")
    }

    handlePeriodChange = (e) => {
        this.setState({
            selectedPeriod: e.target.value
        })
        //console.log(e.target.value);
    };
    handleValidEventSubmit = (e, values) => {

        //console.log(values)
        if (values.period === "day") {
            this.setState({
                selectedTS: moment(values.date, 'YYYY-MM-DD').valueOf(),
                selectedOption: "dia",
                selectedDate: values.date,
            })
        }
        if (values.period === "month") {
            this.setState({
                selectedTS: moment(values.month, 'YYYY-MM').valueOf(),
                selectedOption: "mes",
                selectedDate: values.month,
            })
        }
        if (values.period === "year") {
            this.setState({
                selectedTS: moment(values.year, 'YYYY').valueOf(),
                selectedOption: "ano",
                selectedDate: values.year,
            })
        }



        this.toggle_modal()
    }

    handleAcceptedFiles = files => {
        files.map(file =>
            Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: this.formatBytes(file.size),
            })
        )

        this.setState({ selectedFiles: files })
    }

    /**
     * Formats the size
     */
    formatBytes = (bytes, decimals = 2) => {
        if (bytes === 0) return "0 Bytes"
        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

        const i = Math.floor(Math.log(bytes) / Math.log(k))
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
    }

    toLowerCase1(str) {
        return str.toLowerCase();
    }
    checkChange = p => {
        this.setState({ p: p.target.checked })
    }
    handlePDChange = PD => {
        const { vehicles, rdchanged, RD } = this.state
        this.setState({ PD: PD.target.value })
        this.setState({ pdchanged: true })
        /* //console.log("pdchanged")
        //console.log("pd : " + this.state.PD)
        //console.log("rd : " + this.state.RD) */
        //  //console.log("PD changed " + PD.target.value)
        // veh=[]


        //this.setState({ vehList: tmp })
    }
    handleRDChange = RD => {
        const { vehicles, pdchanged, PD } = this.state
        this.setState({ RD: RD.target.value })
        this.setState({ rdchanged: true })
        /* //console.log("pd : " + this.state.PD)
        //console.log("rd : " + this.state.RD) */
        //  this.setState({ vehList: tmp })
    }


    handleSelectType = selectedType => {

        this.setState({ selectedType: selectedType.value })
        this.setState({ changed: true })
    }
    handleSelectTariff = (selectedTariff, value) => {

        this.setState({ selectedTariff: value })
        this.setState({ trfchanged: true })

    }
    getAmount() {
        let oss = this.calculateDays(this.state.PD, this.state.RD)
        /*  //console.log(this.state.PD)
         //console.log(this.state.RD)
         //console.log(oss) */

        let kra2 = ""
        /*  //console.log(this.state.trfchanged)
         //console.log(this.state.selectedTariff) */
        if (this.state.selectedTariff !== null) {
            if (this.state.trfchanged) {
                kra2 = this.state.selectedTariff
                // let trfid = kra2.substring(kra2.indexOf("(") + 1, kra2.indexOf(")"))
                this.calculateAmount(kra2, oss)
            }
            else {
                kra2 = this.state.selectedTariff.value
                this.calculateAmount(kra2, oss)
            }
        }
        ////console.log(kra2)

        ////console.log(trfid)
        // this.calculateAmount(trfid, oss)


    }
    calculateAmount(tariff, days) {

        var total = 0;
        const { tariffs } = this.state
        if (days <= 0) {
            total = 0
        } else {
            tariffs.map(function (item, i) {
                if (item.id == tariff) {
                    if (days < 30) {
                        total = days * item.daily_rate;
                    } else {
                        var month = Math.floor(days / 30) * item.monthly_rate
                        var day = (days % 30) * item.daily_rate
                        total = month + day
                    }
                }

            })
        }

        this.setState({ totalAmount: total })
    }
    calculateDays(date1, date2) {

        var pd = new Date(date2);
        var rd = new Date(date1);
        var pppd = moment(pd, 'DD-MM-YYYY');
        var rrrd = moment(rd, 'DD-MM-YYYY');

        var DD = pppd.diff(rrrd, 'days');
        //   //console.log(DD)
        return DD
    }
    handleSelectVehicleFilter = (selectedVehicle, value) => {
        const { vehicles, indies } = this.state
        /*  let kra = selectedVehicle.target.value
         let plt = kra.substring(kra.indexOf("(") + 1, kra.indexOf(")")) */
        //console.log(value)
        if (value === 'All Clients') {
            this.setState({

                selectedVehicleName: "All Clients",
                selectedVehicle1: 1
            })
        } else {
            const p = indies.find(v => v.id === value);
            var plt = ""
            /*  if (p !== undefined) {
                 plt = p.plate_number
             } */
            let m = 0
            const cl_name = p.first_name + " " + p.last_name
            //const name = p.make + " " + p.model + " ( " + p.plate_number + " )"
            //console.log(name)

            this.setState({
                selectedVehicleName: cl_name,
                selectedVehicle1: value
            })
        }

    }
    handleSelectVehicle = (selectedVehicle, value) => {
        const { vehicles, indies } = this.state
        /*  let kra = selectedVehicle.target.value
         let plt = kra.substring(kra.indexOf("(") + 1, kra.indexOf(")")) */

        const p = indies.find(v => v.id === value);
        /* var plt = ""
        if (p !== undefined) {
            plt = p.plate_number
        } */
        let m = 0
        //  //console.log(plt)
        /* vehicles.map((e, key) => {

            if (e.plate_number === plt) {
                m = e.mileage
            }
        })
        this.setState({
            mile: m
        }) */
    }
    handleSelectClient = selectedClient => {

        this.setState({ selectedClient: selectedClient })
        this.setState({ clchanged: true })
    }
    getDate = () => {
        var now = moment(new Date()).format('YYYY-MM-DDTHH:mm');
        this.setState({ now });
    };
    componentWillUnmount() {

        this.setState({
            loading: null,
            now: null,
            loading: null,
            submitLoading: null,
            isShow: null,
            changed: null,
            vcchanged: null,
            clchanged: null,
            trfchanged: null,
            pdchanged: null,
            rdchanged: null,
            gender: null,
            viewmodal: null,
            modal: null,
            isDelete: null,
            no_data: null,
            totalAmount: null,
            p: null,
            success_dlg: null,
            dynamic_title: null,
            dynamic_description: null,
            vc: null,
            mile: null,
            selectedType: null,
            selectedVehicle: null,
            selectedClient: null,
            selectedTariff: null,
            PD: null,
            RD: null,
            reservations: null,
            todaysP: null,
            todaysR: null,
            reservation: null,
            vehicles: null,
            indies: null,
            tariffs: null,
            repairs: null,
            vehList: null,
            date_birth: null,
            ReservationColumns: null,
            selectedFiles: null
        });
    }

    componentDidMount() {

        const { reservations, onGetReservation } = this.state
        const { reservation, agency } = this.state
        const pathParts = window.location.pathname.split('/');
        const url = window.location.href.toString()
        const r_id = url.substring(url.lastIndexOf("/") + 1)
        if (reservations && !reservations.length) {

            // onGetReservation()

            this.getReservation()
        }
        this.setState({ reservations })



        const { indies, onGetIndie } = this.state
        if (indies && !indies.length) {
            //onGetIndie()
            this.getIndie()
        }
        this.setState({ indies })

        const { tariffs, onGetTariff } = this.state
        if (tariffs && !tariffs.length) {
            //onGetTariff()
            this.getTariff()
        }
        this.setState({ tariffs })

        const { repairs } = this.state
        if (repairs && !repairs.length) {
            //onGetTariff()
            this.getRepair()
        }
        this.setState({ repairs })
        // const { onUpdateVehicle } = this.props

        if (pathParts.length > 2 && pathParts[2]) {
            this.setState({ inv_modal: true });
        } else {
            this.setState({ inv_modal: false });
        }

        if (reservation && !reservation.length && r_id !== "" && r_id !== "documents") {
            this.getReservationData(r_id)
        }
        this.setState({ reservation })
        if (agency && !agency.length) {
            this.getAgency()
        }
        this.setState({ agency })

    }

    getAgency = async () => {
        this.setState({ loading: true });

        // Unsubscribe previous listeners to prevent memory leaks
        if (this.unsubscribeUsers) {
            this.unsubscribeUsers();
        }

        this.unsubscribeUsers = firebase.auth().onAuthStateChanged(async user => {
            if (user) {
                const userId = user.uid;
                const usersCollection = firebase.firestore().collection("users")
                const userDocRef = usersCollection.doc(userId);
                //console.log(user)
                try {
                    const userDoc = await userDocRef.get();
                    if (userDoc.exists) {
                        const userId = userDoc.data().id;
                        const userUsersCollectionRef = usersCollection.doc(userId)


                        this.unsubscribeUsers = userUsersCollectionRef.onSnapshot(snapshot => {
                            let kra = snapshot.data()
                            this.setState({
                                loading: false,
                                agency: kra,
                            })
                        });



                        localStorage.setItem("authUser", JSON.stringify(user));
                    } else {
                        // User document not found
                        localStorage.removeItem("authUser");

                    }
                } catch (error) {
                    console.error("Error fetching user document:", error);
                    // Handle error
                }
            } else {
                localStorage.removeItem("authUser");
                // Clear the reservations if user is not authenticated

            }
        });
    }
    getReservationData = async (id) => {
        this.setState({ loading: true });

        // Unsubscribe previous listeners to prevent memory leaks
        if (this.unsubscribeReservations) {
            this.unsubscribeReservations();
        }

        this.unsubscribeReservations = firebase.auth().onAuthStateChanged(async user => {
            if (user) {
                const userId = user.uid;
                const usersCollection = firebase.firestore().collection("users")
                const userDocRef = usersCollection.doc(userId);

                try {
                    const userDoc = await userDocRef.get();
                    if (userDoc.exists) {
                        const userId = userDoc.data().id;

                        const rDoc = await usersCollection.doc(userId).collection("reservations").doc(id).get();
                        if (rDoc.exists) {
                            let kra = null, cl = null, cli = null
                            kra = rDoc.data()

                            this.setState({
                                loading: false,
                                reservation: kra,
                            })
                            const cDoc = await usersCollection.doc(userId).collection("indies").doc(rDoc.data().client_id).get();
                            if (cDoc.exists) {
                                cli = cDoc.data()

                                this.setState({
                                    loading: false,
                                    client_infos: cli,
                                })
                            }
                            const vDoc = await usersCollection.doc(userId).collection("vehicles").doc(rDoc.data().v_id).get();
                            if (vDoc.exists) {
                                this.setState({
                                    loading: false,
                                    car_make: vDoc.data().make,
                                    car_model: vDoc.data().model,
                                })
                            }
                        }
                    } else {
                        // User document not found
                        localStorage.removeItem("authUser");

                    }
                } catch (error) {
                    console.error("Error fetching user document:", error);
                    // Handle error
                }
            } else {
                localStorage.removeItem("authUser");
                // Clear the reservations if user is not authenticated

            }
        });


    }
    getRepair = async () => {
        this.setState({ loading: true });

        // Unsubscribe previous listeners to prevent memory leaks
        if (this.unsubscribeRepairs) {
            this.unsubscribeRepairs();
        }

        this.unsubscribeRepairs = firebase.auth().onAuthStateChanged(async user => {
            if (user) {
                const userId = user.uid;
                const usersCollection = firebase.firestore().collection("users")
                const userDocRef = usersCollection.doc(userId);

                try {
                    const userDoc = await userDocRef.get();
                    if (userDoc.exists) {
                        const userId = userDoc.data().id;
                        const userRepairsCollectionRef = usersCollection.doc(userId).collection("repairs");

                        this.unsubscribeRepairs = userRepairsCollectionRef.onSnapshot(snapshot => {
                            let kra = []
                            snapshot.docs.forEach(e => {
                                kra.push(e.data())
                            });
                            this.setState({
                                loading: false,
                                repairs: kra,
                            })
                        })

                        localStorage.setItem("authUser", JSON.stringify(user))
                    } else {
                        ////console.log("not user")
                        localStorage.removeItem("authUser")
                    }
                } catch (error) {
                    console.error("Error fetching user document:", error);
                    // Handle error
                }
            }

        })
    }

    getReservation = async () => {
        this.setState({ loading: true });

        // Unsubscribe previous listeners to prevent memory leaks
        if (this.unsubscribeReservations) {
            this.unsubscribeReservations();
        }

        this.unsubscribeReservations = firebase.auth().onAuthStateChanged(async user => {
            if (user) {
                const userId = user.uid;
                const usersCollection = firebase.firestore().collection("users")
                const userDocRef = usersCollection.doc(userId);

                try {
                    const userDoc = await userDocRef.get();
                    if (userDoc.exists) {
                        const userId = userDoc.data().id;
                        const userReservationsCollectionRef = usersCollection.doc(userId).collection("reservations");
                        const CurrentDate = moment().unix();
                        const lyoum = moment.unix(CurrentDate).format("DD/MM/YYYY");
                        this.setState({
                            user_id: userId,
                            account_user: userDoc.data()
                        })
                        this.unsubscribeReservations = userReservationsCollectionRef.onSnapshot(snapshot => {
                            let reservations = [];
                            let kayninP = [];
                            let kayninR = [];
                            let kkk = "";
                            let kkkk = "";

                            snapshot.docs.forEach(e => {
                                kkk = moment.unix(e.data().pd / 1000).format("DD/MM/YYYY");
                                kkkk = moment.unix(e.data().rd / 1000).format("DD/MM/YYYY");
                                if (kkk === lyoum && e.data().status !== "canceled") {
                                    kayninP.push(e.data());
                                }
                                if (kkkk === lyoum && e.data().status !== "canceled") {
                                    kayninR.push(e.data());
                                }
                                reservations.push(e.data());
                            });
                            this.setState({
                                loading: false,
                                reservations: reservations,
                                todaysP: kayninP,
                                todaysR: kayninR
                            });

                        });

                        localStorage.setItem("authUser", JSON.stringify(user));
                    } else {
                        // User document not found
                        localStorage.removeItem("authUser");
                        this.setState({
                            loading: false,
                            reservations: [],
                            todaysP: [],
                            todaysR: []
                        });
                    }
                } catch (error) {
                    console.error("Error fetching user document:", error);
                    // Handle error
                }
            } else {
                localStorage.removeItem("authUser");
                // Clear the reservations if user is not authenticated
                this.setState({
                    loading: false,
                    reservations: [],
                    todaysP: [],
                    todaysR: []
                });
            }
        });
    }


    getVehicle = async () => {
        this.setState({ loading: true });

        // Unsubscribe previous listeners to prevent memory leaks
        if (this.unsubscribeVehicles) {
            this.unsubscribeVehicles();
        }

        this.unsubscribeVehicles = firebase.auth().onAuthStateChanged(async user => {
            if (user) {
                const userId = user.uid;
                const usersCollection = firebase.firestore().collection("users")
                const userDocRef = usersCollection.doc(userId);

                try {
                    const userDoc = await userDocRef.get();
                    if (userDoc.exists) {
                        const userId = userDoc.data().id;
                        const userVehiclesCollectionRef = usersCollection.doc(userId).collection("vehicles");
                        this.setState({
                            user_id: userId
                        })
                        this.unsubscribeVehicles = userVehiclesCollectionRef.onSnapshot(snapshot => {
                            let vehicles = [];
                            snapshot.docs.forEach(doc => {
                                vehicles.push(doc.data());
                            });

                            this.setState({
                                loading: false,
                                vehicles: vehicles
                            });
                        });

                        localStorage.setItem("authUser", JSON.stringify(user));
                    } else {
                        // User document not found
                        localStorage.removeItem("authUser");
                        this.setState({
                            loading: false,
                            vehicles: []
                        });
                    }
                } catch (error) {
                    console.error("Error fetching user document:", error);
                    // Handle error
                }
            } else {
                localStorage.removeItem("authUser");
                // Clear the reservations if user is not authenticated
                this.setState({
                    loading: false,
                    vehicles: []
                });
            }
        });

    }


    getIndie = async () => {
        this.setState({ loading: true });

        // Unsubscribe previous listeners to prevent memory leaks
        if (this.unsubscribeIndies) {
            this.unsubscribeIndies();
        }

        this.unsubscribeIndies = firebase.auth().onAuthStateChanged(async user => {
            if (user) {
                const userId = user.uid;
                const usersCollection = firebase.firestore().collection("users")
                const userDocRef = usersCollection.doc(userId);

                try {
                    const userDoc = await userDocRef.get();
                    if (userDoc.exists) {
                        const userId = userDoc.data().id;
                        const userIndiesCollectionRef = usersCollection.doc(userId).collection("indies");
                        this.setState({
                            user_id: userId
                        })
                        this.unsubscribeIndies = userIndiesCollectionRef.onSnapshot(snapshot => {
                            let n = [];
                            snapshot.docs.forEach(doc => {
                                n.push(doc.data());
                            });

                            this.setState({
                                loading: false,
                                indies: n
                            });
                        });

                        localStorage.setItem("authUser", JSON.stringify(user));
                    } else {
                        // User document not found
                        localStorage.removeItem("authUser");

                        this.setState({
                            loading: false,
                            indies: []
                        });

                    }
                } catch (error) {
                    console.error("Error fetching user document:", error);
                    // Handle error
                }
            } else {
                localStorage.removeItem("authUser");
                // Clear the reservations if user is not authenticated
                this.setState({
                    loading: false,
                    indies: []
                });
            }
        });

    }


    getTariff = async () => {
        this.setState({ loading: true });

        // Unsubscribe previous listeners to prevent memory leaks
        if (this.unsubscribeTariffs) {
            this.unsubscribeTariffs();
        }

        this.unsubscribeTariffs = firebase.auth().onAuthStateChanged(async user => {
            if (user) {
                const userId = user.uid;
                const usersCollection = firebase.firestore().collection("users")
                const userDocRef = usersCollection.doc(userId);

                try {
                    const userDoc = await userDocRef.get();
                    if (userDoc.exists) {
                        const userId = userDoc.data().id;
                        const userTariffsCollectionRef = usersCollection.doc(userId).collection("tariffs");
                        this.setState({
                            user_id: userId
                        })
                        this.unsubscribeTariffs = userTariffsCollectionRef.onSnapshot(snapshot => {
                            let t = [];
                            snapshot.docs.forEach(doc => {
                                t.push(doc.data());
                            });

                            this.setState({
                                loading: false,
                                tariffs: t
                            });
                        });

                        localStorage.setItem("authUser", JSON.stringify(user));
                    } else {
                        // User document not found
                        localStorage.removeItem("authUser");
                        this.setState({
                            loading: false,
                            tariffs: []
                        });
                    }
                } catch (error) {
                    console.error("Error fetching user document:", error);
                    // Handle error
                }
            } else {
                localStorage.removeItem("authUser");
                // Clear the reservations if user is not authenticated
                this.setState({
                    loading: false,
                    tariffs: []
                });
            }
        });


    }



    // eslint-disable-next-line no-unused-vars
    /* componentDidUpdate(prevProps, prevState, snapshot) {
  
      const { reservations } = this.state
      const { vehicles } = this.state
      const { indies } = this.state
      const { tariffs } = this.state
      const { repairs } = this.state
  
  
  
      if (!isEmpty(reservations) && size(prevProps.reservations) !== size(reservations)) {
        //this.setState({ reservations: {}, isEdit: false })
      }
      if (!isEmpty(vehicles) && size(prevProps.vehicles) !== size(vehicles)) {
        //this.setState({ vehicles: {}, isEdit: false })
      }
      if (!isEmpty(indies) && size(prevProps.indies) !== size(indies)) {
        //this.setState({ indies: {}, isEdit: false })
      }
  
      if (!isEmpty(tariffs) && size(prevProps.tariffs) !== size(tariffs)) {
        //this.setState({ tariffs: {}, isEdit: false })
      }
    } */

    toggle() {
        this.setState(prevState => ({
            modal: !prevState.modal,
        }))
    }
    toggleDelete() {
        this.setState(prevState => ({
            isDelete: !prevState.isDelete,
        }))
    }

    handleReservationClicks = () => {
        this.setState({ reservation: [], rdchanged: false, pdchanged: false, isEdit: false, isShow: false, selectedTariff: null, selectedVehicle: null, selectedClient: null, totalAmount: null, p: false, PD: new Date(), RD: new Date().setDate(new Date().getDate() + 1) })
        const date = new Date()
        const day = date.getDate();
        const month = date.getMonth();
        const year = date.getFullYear();

        const currectDate = new Date();
        const currentHour = currectDate.getHours();
        const currentMin = currectDate.getMinutes();
        const currentSec = currectDate.getSeconds();
        const modifiedDate = new Date(year, month, day, currentHour, currentMin, currentSec);
        const md = moment(modifiedDate).format('YYYY-MM-DDTHH:mm')
        //console.log("handle date click " + md)
        const k = new Date(md).setDate(new Date(md).getDate() + 1)
        //console.log("next date", moment(k).format('YYYY-MM-DDTHH:mm'))
        /* const modifiedData = { ...arg, date: md };
        this.setState({ selectedDay: modifiedData }) */
        this.setState({
            reservation: {

                pickup_date: md,
                return_date: moment(k).format('YYYY-MM-DDTHH:mm'),
                start: md,
                end: moment(k).format('YYYY-MM-DDTHH:mm'),
                /* reciept:reservation.reciept,
                invoice:reservation.invoice */
            },
            PD: md,
            RD: new Date(md).setDate(new Date(md).getDate() + 1),
            isShow: false,
        })

        this.toggle()
    }
    /* handleSearch = (value) => {
      const { reservations } = this.state
      //console.log("dkhel lhad lqlawai")
      var kra=reservations.filter(reservation =>
        Object.keys(reservation).some(
          key =>
            typeof reservation[key] === "string" &&
            reservation[key].toLowerCase().includes(value.toLowerCase())
        )
      )
      //console.log(kra)
  }; */
    // eslint-disable-next-line no-unused-vars
    handleTableChange = (type, { page, searchText }) => {
        const { reservations } = this.props
        //console.log("dkhel lhad lqlawai")
        const kra = reservations.filter(reservation =>
            Object.keys(reservation).some(
                key =>
                    typeof reservation[key] === "string" &&
                    reservation[key].toLowerCase().includes(searchText.toLowerCase())
            )
        )
        //console.log(kra)
        this.setState({
            reservations: reservations.filter(reservation =>
                Object.keys(reservation).some(
                    key =>
                        typeof reservation[key] === "string" &&
                        reservation[key].toLowerCase().includes(searchText.toLowerCase())
                )
            ),
        })
    }

    toggleViewModal = () => {
        this.setState(prevState => ({
            viewmodal: !prevState.viewmodal,
        }))
    }

    toggleIsShow = () => {
        this.setState(prevState => ({
            isShow: !prevState.isShow,
        }))
    }


    /* Insert,Update Delete data */

    handleDeleteReservation = (reservation) => {
        const { vehicles, onUpdateVehicle } = this.state


        //const { onDeleteReservation } = this.props

        /* let v_update = []
    
        v_update.map((v, key) => {
          //onUpdateVehicle(v)
          this.updateVehicle(v)
          //console.log(v)
        })
    
        v_update = null */
        this.deleteReservation(reservation)
        //toast.success(this.props.t("Item Deleted "))
        this.toggleDelete()
        //this.toggle()
        //}

    }

    handleViewClick = arg => {
        const reservation = arg
        this.setState({ selectedVehicle: { label: reservation.vehicle, value: reservation.plate_number } })
        this.setState({ selectedClient: { label: reservation.client, value: reservation.client_id } })
        this.setState({ selectedTariff: { label: reservation.vehicle_type, value: reservation.tariff_id } })
        // //console.log(reservation)
        this.setState({
            reservation: {
                id: reservation.id,
                code: reservation.code,
                plate_number: reservation.plate_number,
                vehicle: reservation.vehicle,
                client: reservation.client,
                client_id: reservation.client_id,
                pickup_date: reservation.pickup_date,
                pd: reservation.pd,
                pickup_location: reservation.pickup_location,
                return_date: reservation.return_date,
                rd: reservation.rd,
                return_location: reservation.return_location,
                vehicle_type: reservation.vehicle_type,
                tariff_id: reservation.tariff_id,
                amount: reservation.amount,
                total_amount: reservation.total_amount,
                notes: reservation.notes,
                days: reservation.days,
                status: reservation.status,
                start: reservation.start,
                end: reservation.end,
                frais_livraison: reservation.frais_livraison,
                frais_reprise: reservation.frais_reprise,
                frais_divers: reservation.frais_divers,
                caution: reservation.caution,
                mileage_pickup: reservation.mileage_pickup,
                mileage_return: reservation.mileage_return,
                fuel_level_pickup: reservation.fuel_level_pickup,
                fuel_level_return: reservation.fuel_level_return,
                title: reservation.vehicle + " (" + reservation.client + ")",

            },

            isShow: true,
            isEdit: false

        })

        //this.toggleIsShow()
        this.toggle()

        //this.toggleViewModal()
    }

    handleInvClick = arg => {
        const reservation = arg
        this.getReservationData(reservation.id)

        this.toggleInv()

    }
    handleReservationClick = arg => {
        const reservation = arg
        //console.log(reservation)
        this.setState({ rdchanged: false, pdchanged: false, totalAmount: null, p: reservation.paid, PD: reservation.pickup_date, RD: reservation.return_date })
        this.setState({ selectedVehicle: { label: reservation.vehicle, value: reservation.plate_number } })
        this.setState({ selectedClient: { label: reservation.client, value: reservation.client_id } })
        this.setState({ selectedTariff: { label: reservation.vehicle_type, value: reservation.tariff_id } })
        this.setState({
            reservation: {
                id: reservation.id,
                code: reservation.code,
                plate_number: reservation.plate_number,
                vehicle: reservation.vehicle,
                client: reservation.client,
                client_id: reservation.client_id,
                pickup_date: reservation.pickup_date,
                pd: reservation.pd,
                pickup_location: reservation.pickup_location,
                return_date: reservation.return_date,
                rd: reservation.rd,
                return_location: reservation.return_location,
                vehicle_type: reservation.vehicle_type,
                tariff_id: reservation.tariff_id,
                amount: reservation.amount,
                total_amount: reservation.total_amount,
                notes: reservation.notes,
                days: reservation.days,
                status: reservation.status,
                start: reservation.start,
                end: reservation.end,
                frais_livraison: reservation.frais_livraison,
                frais_reprise: reservation.frais_reprise,
                frais_divers: reservation.frais_divers,
                caution: reservation.caution,
                mileage_pickup: reservation.mileage_pickup,
                mileage_return: reservation.mileage_return,
                fuel_level_pickup: reservation.fuel_level_pickup,
                fuel_level_return: reservation.fuel_level_return,
                title: reservation.vehicle + " (" + reservation.client + ")"
                /* reciept:reservation.reciept,
                invoice:reservation.invoice */
            },
            isEdit: true,
            isShow: false,
        })

        this.toggle()
    }

    /**
     * Handling submit Order on Order form
     */
    handleValidReservationSubmit = (e, values) => {
        const { onAddNewReservation, onUpdateReservation } = this.props
        const { isEdit, reservations, selectedReservation, vehicles } = this.state

        const cds = []

        reservations.forEach(e => {
            var cc = new Date(values.pickup_date)
            var y = cc.getFullYear()
            var year = moment(new Date(e.pickup_date)).year()
            //console.log(y, year)
            if (y === year) {
                cds.push(e.code.substring(0, e.code.indexOf("/")))
            }
        });

        var cd = cds.length == 0 ? 1 : Math.max.apply(null, cds) + 1
        const codens = cd + " / " + moment(new Date(values.pickup_date)).year()
        //console.log(cd)
        // status 

        let stts = ""
        var pd = new Date(values.pickup_date);
        var today = new Date();
        var rd = new Date(values.return_date);
        let ossan = this.calculateDays(values.pickup_date, values.return_date)
        // //console.log("ossan " + ossan)

        if (rd.getTime() > today.getTime() && pd.getTime() > today.getTime()) {
            stts = "Created"
            //  //console.log(stts)
        }
        if (pd.getTime() < today.getTime() && rd.getTime() > today.getTime()) {
            // //console.log("stattus changed ")
            stts = "Rental"
            //   //console.log(stts)
        }
        if (today.getTime() > rd.getTime() && today.getTime() > pd.getTime()) {
            stts = "Done"
            //  //console.log(stts)
        }

        /* let veh = ""
        if (this.state.selectedVehicle != null) {
          veh = this.state.selectedVehicle.label
          if (this.state.vcchanged) {
            veh = this.state.selectedVehicle.label
            this.setState({ vcchanged: false })
          } else {
            veh = this.state.selectedVehicle.label
          }
        } */

        /*  let kra = values.vehicle
         let plt = kra.substring(kra.indexOf("(") + 1, kra.indexOf(")"))
      */
        const p = vehicles.find(v => v.id === values.vehicle);
        var plt = ""
        var vehicle = ""
        if (p !== undefined) {
            plt = p.plate_number
            vehicle = p.make + " (" + p.plate_number + ")"
        }
        let kra2 = values.vehicle_type
        let trfid = kra2.substring(kra2.indexOf("(") + 1, kra2.indexOf(")"))
        ////console.log(trfid)
        /* if (this.state.selectedVehicle != null) {
          plt = this.state.selectedVehicle.value
          if (this.state.vcchanged) {
            plt = this.state.selectedVehicle.value
            this.setState({ vcchanged: false })
          } else {
            plt = this.state.selectedVehicle.value
          }
    
        } */

        /* let cl = ""
        if (this.state.selectedClient != null) {
          cl = this.state.selectedClient.label
          if (this.state.clchanged) {
            cl = this.state.selectedClient.label
            this.setState({ clchanged: false })
          } else {
            cl = this.state.selectedClient.label
          }
        } */


        let kra3 = values.client
        //let clid = kra3.substring(kra3.indexOf("(") + 1, kra3.indexOf(")"))
        ////console.log(values.client)
        let ikh = this.state.indies.find(e => e.id === values.client);
        let cl = ""
        if (ikh !== undefined) {
            cl = ikh.first_name + " " + ikh.last_name
        }

        let ikha = this.state.tariffs.find(e => e.id === values.vehicle_type);
        let trf = ikha.tariff_name


        /* if (this.state.selectedClient != null) {
          clid = this.state.selectedClient.value
          if (this.state.clchanged) {
            clid = this.state.selectedClient.value
            this.setState({ clchanged: false })
          } else {
            clid = this.state.selectedClient.value
          }
        } */


        /* let trf = ""
        if (this.state.selectedTariff != null) {
          trf = this.state.selectedTariff.label
          if (this.state.trfchanged) {
            trf = this.state.selectedTariff.label
            this.setState({ trfchanged: false })
          } else {
            trf = this.state.selectedTariff.label
          }
        } */


        /* let trfid = ""
        if (this.state.selectedTariff != null) {
          trfid = this.state.selectedTariff.value
          if (this.state.trfchanged) {
            trfid = this.state.selectedTariff.value
            this.setState({ trfchanged: false })
          } else {
            trfid = this.state.selectedTariff.value
          }
        } */
        var pida = new Date(values.pickup_date).getTime()
        var reda = new Date(values.return_date).getTime()
        this.calculateAmount(values.vehicle_type, ossan)
        /*  var paid = e.target.paid.checked
     
         //   //console.log("paid ", e.target.paid.checked)
         var restam = 0
         var paidam = parseInt(values.amount)+parseInt(values.frais_livraison)+parseInt(values.frais_divers)+parseInt(values.frais_reprise)
     
         if (paid) {
           restam = 0
           paidam = parseInt(values.amount)+parseInt(values.frais_livraison)+parseInt(values.frais_divers)+parseInt(values.frais_reprise)
         } else {
           restam = (parseInt(values.amount)+parseInt(values.frais_livraison)+parseInt(values.frais_divers)+parseInt(values.frais_reprise)) - values.paid_amount
         }
         if (paidam === (parseInt(values.amount)+parseInt(values.frais_livraison)+parseInt(values.frais_divers)+parseInt(values.frais_reprise))) {
           //  //console.log("dkhel l condition")
           paid = true
         } */
        // //console.log("paid ", paid)
        /* this.setState({
          gender:this.state.staffs.gender
        }) */

        if (isEdit) {
            //des=this.state.selectedType.defaultInputValue

            const updateReservation = {
                id: this.state.reservation.id,
                code: this.state.reservation.code,
                /*  plate_number: plt,
                 vehicle: values.vehicle, */
                vehicle: this.state.reservation.vehicle,
                client: cl,
                client_id: values.client,
                pickup_date: values.pickup_date,
                pd: pida,
                pickup_location: values.pickup_location,
                return_date: values.return_date,
                rd: reda,
                return_location: values.return_location,
                vehicle_type: trf,
                tariff_id: values.vehicle_type,
                amount: parseInt(values.amount),
                total_amount: parseInt(parseInt(values.amount) + parseInt(values.frais_divers) + parseInt(values.frais_reprise) + parseInt(values.frais_livraison)),
                notes: values.notes,
                days: ossan,
                status: stts,
                frais_livraison: parseInt(values.frais_livraison),
                frais_reprise: parseInt(values.frais_reprise),
                frais_divers: parseInt(values.frais_divers),
                caution: parseInt(values.caution),
                mileage_pickup: parseInt(values.mileage_pickup),
                mileage_return: parseInt(values.mileage_return),
                fuel_level_pickup: values.fuel_level_pickup,
                fuel_level_return: values.fuel_level_return,
                start: values.pickup_date,
                end: values.return_date,
                title: this.state.reservation.vehicle + " (" + cl + ")"

            }
            if (/* this.state.reservation.vehicle == updateReservation.vehicle && */
                this.state.reservation.client == updateReservation.client &&
                this.state.reservation.pickup_date == updateReservation.pickup_date &&
                this.state.reservation.return_date == updateReservation.return_date &&
                this.state.reservation.pickup_location == updateReservation.pickup_location &&
                this.state.reservation.return_location == updateReservation.return_location &&
                this.state.reservation.vehicle_type == updateReservation.vehicle_type &&
                this.state.reservation.amount == updateReservation.amount &&
                this.state.reservation.notes == updateReservation.notes &&
                this.state.reservation.frais_livraison == updateReservation.frais_livraison &&
                this.state.reservation.frais_reprise == updateReservation.frais_reprise &&
                this.state.reservation.frais_divers == updateReservation.frais_divers &&
                this.state.reservation.caution == updateReservation.caution &&
                this.state.reservation.mileage_pickup == updateReservation.mileage_pickup &&
                this.state.reservation.mileage_return == updateReservation.mileage_return &&
                this.state.reservation.fuel_level_pickup == updateReservation.fuel_level_pickup &&
                this.state.reservation.fuel_level_return == updateReservation.fuel_level_return) {
                this.setState({ submitLoading: false })
                this.toggle()
            } else {
                this.updateReservation(updateReservation)
            }
            // update Order
            //onUpdateReservation(updateReservation)
            //toast.success(this.props.t("Reservation updated "))
        } else {

            const newReservation = {
                code: codens,
                plate_number: plt,
                vehicle: vehicle,
                v_id: values.vehicle,
                client: cl,
                client_id: values["client"],
                pickup_date: values['pickup_date'],
                pd: pida,
                pickup_location: values['pickup_location'],
                return_date: values['return_date'],
                rd: reda,
                return_location: values['return_location'],
                vehicle_type: trf,
                tariff_id: values["vehicle_type"],
                amount: parseInt(values['amount']),
                total_amount: parseInt(parseInt(values['amount']) + parseInt(values['frais_divers']) + parseInt(values['frais_reprise']) + parseInt(values['frais_livraison'])),
                notes: values["notes"],
                days: ossan,
                status: stts,
                start: values['pickup_date'],
                end: values['return_date'],
                frais_livraison: parseInt(values['frais_livraison']),
                frais_reprise: parseInt(values['frais_reprise']),
                frais_divers: parseInt(values['frais_divers']),
                caution: parseInt(values['caution']),
                mileage_pickup: values['mileage_pickup'],
                mileage_return: values['mileage_return'],
                fuel_level_pickup: values['fuel_level_pickup'],
                fuel_level_return: values['fuel_level_return'],
                title: vehicle + " (" + cl + ")"

            }
            this.setState({ reservation: newReservation })
            this.addNewReservation(newReservation)
            //toast.success(this.props.t("Reservation added "))
        }
        this.setState({ selectedReservation: null })
        //this.toggle()
    }

    deleteReservation = (reservation) => {

        const collection = firebase.firestore().collection("users").doc(this.state.user_id).collection("reservations")
        const timeline = {
            statusTitle: "Reservation deleted",
            iconClass: "bx bx-spreadsheet h2 text-danger",
            description: "Reservation information has been deleted for ",
            data: reservation.vehicle,
            time: new Date().getTime(),

        }
        collection.doc(reservation.id).delete().then(() => {


            //console.log("Element  deleted!");
            toast.success(this.props.t("Reservation  deleted!"))
            this.addNewTimeline(timeline)

            this.setState({
                success_dlg: true,
                dynamic_title: this.props.t("Deleted"),
                dynamic_description: this.props.t("Element has been deleted."),
            })
        }).catch((error) => {
            console.error("Error removing element: ", error);
            toast.error(this.props.t("Error removing element: ", error));

        });
    }

    updateReservation = async (reservation) => {
        const { reservations, repairs } = this.state
        var rl = []
        var exist = false
        //console.log(reservation)
        reservations.map(function (item, i) {
            // if (item.status === "Rental" || item.status === "Created") {
            if (reservation.plate_number === item.plate_number) {
                rl.push({ start_date: item.pd, end_date: item.rd, id: item.id })
            }
            //  }
        })
        repairs.map(function (item, i) {
            if (reservation.plate_number === item.plate_number) {
                rl.push({ start_date: item.sd, end_date: item.ed, id: item.id })
            }
        })
        ////console.log("kra ", rl)
        if (rl.length != 0) {
            rl.forEach(e => {
                if (e.id !== reservation.id) {
                    if ((e.start_date >= reservation.pd && e.start_date <= reservation.rd) ||
                        (reservation.pd >= e.start_date && reservation.pd <= e.end_date)) {
                        exist = true

                    }
                }

            });
        } else {
            exist = false
        }
        //  //console.log(exist)
        const timeline = {
            statusTitle: "Reservation updated",
            iconClass: "bx bx-spreadsheet h2 text-warning",
            description: "Reservation information has been updated for ",
            data: reservation.vehicle,
            time: new Date().getTime(),

        }
        this.setState({ submitLoading: true })
        const collection = firebase.firestore().collection("users").doc(this.state.user_id).collection("reservations")
        if (reservation.days !== 0) {
            if (reservation.pd < reservation.rd) {
                if (!exist) {
                    var inf = false
                    if (reservation.mileage_return !== "" && reservation.fuel_level_return !== "") {
                        inf = true
                    }
                    collection.doc(reservation.id).update({

                        /*  plate_number: reservation.plate_number,
                         vehicle: reservation.vehicle, */
                        client: reservation.client,
                        client_id: reservation.client_id,
                        pickup_date: reservation.pickup_date,
                        pd: reservation.pd,
                        pickup_location: reservation.pickup_location,
                        return_date: reservation.return_date,
                        rd: reservation.rd,
                        return_location: reservation.return_location,
                        vehicle_type: reservation.vehicle_type,
                        tariff_id: reservation.tariff_id,
                        amount: reservation.amount,
                        total_amount: reservation.total_amount,
                        notes: reservation.notes,
                        days: reservation.days,
                        status: reservation.status,
                        frais_livraison: reservation.frais_livraison,
                        frais_reprise: reservation.frais_reprise,
                        frais_divers: reservation.frais_divers,
                        caution: reservation.caution,
                        mileage_pickup: reservation.mileage_pickup,
                        mileage_return: reservation.mileage_return,
                        fuel_level_pickup: reservation.fuel_level_pickup,
                        fuel_level_return: reservation.fuel_level_return,
                        start: reservation.pickup_date,
                        end: reservation.return_date,
                        title: reservation.title,
                        r_infos: inf

                    }).then(() => {
                        //console.log("Reservation  Updated!");
                        toast.success(this.props.t("Reservation  Updated!"))
                        this.addNewTimeline(timeline)
                        this.setState({ submitLoading: false })
                        this.toggle()
                    }).catch((error) => {
                        console.error("Error updating Reservation: ", error);
                        toast.error(this.props.t("Error updating Reservation"))
                        this.setState({ submitLoading: false })
                    });

                } else {
                    console.error("Error updating Reservation: Vehicle unavailable ");
                    toast.error(this.props.t("Error updating Reservation : Vehicle unavailable"))
                    this.setState({ submitLoading: false })
                }
            } else {
                console.error("Error updating Reservation: Invalid dates ");
                toast.error(this.props.t("Error updating Reservation : Invalid dates"))
                this.setState({ submitLoading: false })
            }
        } else {
            console.error("Error Updating Reservation: Number of days cannot be zero ");
            toast.error(this.props.t("Error Updating Reservation : Number of days cannot be zero "))
            this.setState({ submitLoading: false })
        }

    }

    addNewTimeline = async (timeline) => {
        try {
            const { user_id, account_user } = this.state;
            const collection = firebase.firestore().collection("users").doc(user_id).collection("timeline");
            const newDoc = collection.doc();
            const by = account_user.isAdmin ? 'Admin' : `${account_user.first_name} ${account_user.last_name}`;

            await newDoc.set({
                id: newDoc.id,
                statusTitle: timeline.statusTitle,
                iconClass: timeline.iconClass,
                description: timeline.description,
                time: timeline.time,
                data: timeline.data,
                user: by
            });

            //console.log("Timeline  written!");
        } catch (error) {
            console.error("Error adding timeline:", error);
        }
    }
    addNewReservation = async (reservation) => {
        const { reservations, repairs, vehicles } = this.state
        var rl = []
        var exist = false
        var isOffline = false
        vehicles.map(function (item, i) {
            if (reservation.plate_number === item.plate_number) {
                if (item.offline) {
                    isOffline = true
                }
            }
        })
        reservations.map(function (item, i) {
            // if (item.status === "Rental" || item.status === "Created") {
            if (reservation.plate_number === item.plate_number) {
                rl.push({ start_date: item.pd, end_date: item.rd, id: item.id })
            }
            //  }
        })
        repairs.map(function (item, i) {
            if (reservation.plate_number === item.plate_number) {
                rl.push({ start_date: item.sd, end_date: item.ed, id: item.id })
            }
        })

        if (rl.length != 0) {
            rl.forEach(e => {
                if ((e.start_date >= reservation.pd && e.start_date <= reservation.rd) ||
                    (reservation.pd >= e.start_date && reservation.pd <= e.end_date)) {
                    exist = true
                }
            });
        } else {
            exist = false
        }
        const timeline = {
            statusTitle: "New Reservation created",
            iconClass: "bx bx-spreadsheet h2 text-success",
            description: "Reservation information has been created ",
            data: reservation.vehicle,
            time: new Date().getTime(),

        }
        const collection = firebase.firestore().collection("users").doc(this.state.user_id).collection("reservations")
        // //console.log(exist)
        this.setState({ submitLoading: true })
        if (reservation.days !== 0) {
            if (reservation.pd < reservation.rd) {
                if (!exist) {
                    if (!isOffline) {
                        var newDoc = collection.doc();
                        var inf = false
                        if (reservation.mileage_return !== "" && reservation.fuel_level_return !== "") {
                            inf = true
                        }
                        newDoc.set(
                            {
                                id: newDoc.id,
                                code: reservation.code,
                                plate_number: reservation.plate_number,
                                vehicle: reservation.vehicle,
                                v_id: reservation.v_id,
                                client: reservation.client,
                                client_id: reservation.client_id,
                                pickup_date: reservation.pickup_date,
                                pd: reservation.pd,
                                pickup_location: reservation.pickup_location,
                                return_date: reservation.return_date,
                                rd: reservation.rd,
                                return_location: reservation.return_location,
                                vehicle_type: reservation.vehicle_type,
                                tariff_id: reservation.tariff_id,
                                amount: reservation.amount,
                                total_amount: reservation.total_amount,
                                notes: reservation.notes,
                                days: reservation.days,
                                status: reservation.status,
                                frais_livraison: parseInt(reservation.frais_livraison),
                                frais_reprise: parseInt(reservation.frais_reprise),
                                frais_divers: parseInt(reservation.frais_divers),
                                caution: parseInt(reservation.caution),
                                mileage_pickup: parseInt(reservation.mileage_pickup),
                                mileage_return: parseInt(reservation.mileage_return),
                                fuel_level_pickup: reservation.fuel_level_pickup,
                                fuel_level_return: reservation.fuel_level_return,
                                start: reservation.pickup_date,
                                end: reservation.return_date,
                                title: reservation.title,
                                r_infos: inf,
                                createdAt: new Date().getTime()

                            }).then(() => {

                                //console.log("Reservation  written!");
                                toast.success(this.props.t('Reservation added  '))
                                this.addNewTimeline(timeline)
                                this.setState({ submitLoading: false })
                                this.toggle()
                            })
                            .catch((error) => {
                                console.error("Error Adding Reservation infos: ", error);
                                toast.error(this.props.t("Error Adding Reservation infos"))
                                this.setState({ submitLoading: false })
                            });
                    } else {
                        console.error("Error Adding Reservation: Vehicle is Offline ");
                        toast.error(this.props.t("Error Adding Reservation : Vehicle is Offline"))
                        this.setState({ submitLoading: false })
                    }
                } else {
                    console.error("Error Adding Reservation: Vehicle unavailable ");
                    toast.error(this.props.t("Error Adding Reservation : Vehicle unavailable"))
                    this.setState({ submitLoading: false })
                }
            } else {
                console.error("Error Adding Reservation: Invalid dates ");
                toast.error(this.props.t("Error Adding Reservation : Invalid dates"))
                this.setState({ submitLoading: false })
            }
        } else {
            console.error("Error Adding Reservation: Number of days cannot be zero ");
            toast.error(this.props.t("Error Adding Reservation : Number of days cannot be zero "))
            this.setState({ submitLoading: false })
        }

    }

    updateVehicle = async (vehicle) => {
        //console.log(vehicle.status)
        const collection = firebase.firestore().collection("users").doc(this.state.user_id).collection("vehicles")
        collection.doc(vehicle.id).update({
            status: vehicle.status,
            start_date: vehicle.start_date,
            available_date: vehicle.available_date,
            rented_to: vehicle.rented_to
        }).then(() => {
            //console.log("vehicle  Updated!");
        }).catch((error) => {
            console.error("Error updating vehicle: ", error);
        });
    }
    updateReservation1 = async (reservation) => {
        // //console.log(reservation.status)
        const collection = firebase.firestore().collection("users").doc(this.state.user_id).collection("reservations")
        collection.doc(reservation.id).update({
            status: reservation.status,
        }).then(() => {
            //console.log("reservation  Updated!");
        }).catch((error) => {
            console.error("Error updating vehicle: ", error);
        });
    }




    handleValidDate = (date) => {
        const date1 = moment(new Date(date)).format('DD MMM Y');
        return date1;
    }

    printInvoice = () => {
        window.print()
    }


    render() {

        const emptyDataMessage = () => {
            return <Container>
                <Row>
                    <Col lg="12">
                        {this.state.loading ?
                            <div className="text-center">
                                <Row className="justify-content-center mt-5">
                                    <Col sm="4">
                                        <div>
                                            <div >
                                                <div className="spinner-chase">
                                                    <div className="chase-dot"></div>
                                                    <div className="chase-dot"></div>
                                                    <div className="chase-dot"></div>
                                                    <div className="chase-dot"></div>
                                                    <div className="chase-dot"></div>
                                                    <div className="chase-dot"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            :
                            <div className="text-center">

                                {/* <Row className="justify-content-center mt-5">
                                    <Col sm="4">
                                        <div className="maintenance-img">
                                            <img
                                                src={maintanence}
                                                alt=""
                                                className="img-fluid mx-auto d-block"
                                            />
                                        </div>
                                    </Col>
                                </Row> */}
                                <h4 className="mt-1 mb-2">{this.props.t("No Data")}</h4>

                            </div>
                        }
                    </Col>
                </Row>
            </Container>


                ;
        }

        const { currentYear, selectedVehicle1, selectedVehicleName, selectedOption, selectedTS, selectedDate, selectedPeriod, reservations, todaysP, todaysR } = this.state
        const { reservation, client_infos, car_make, car_model, agency, inv_modal, invoices, loading } = this.state
        const subtotal = parseInt(reservation.amount) + parseInt(reservation.frais_livraison) + parseInt(reservation.frais_reprise) + parseInt(reservation.frais_divers)
        const tva = (parseInt(reservation.amount) + parseInt(reservation.frais_livraison) + parseInt(reservation.frais_reprise) + parseInt(reservation.frais_divers)) * 0.2
        const total = subtotal + tva
        const data = invoices;
        const lang = this.props.i18n.language
        const numberInArabic = writtenNumber(12441, { lang: lang });
        const { vehList } = this.state
        var tr = 0, td = 0, tre = 0
        var rese
        rese = reservations

        //console.log(rese)
        switch (selectedOption) {
            case 'todo':
                var r = [], d = 0, rev = 0
                reservations.map(function (item) {
                    if (selectedVehicle1 === 1) {
                        r.push(item)
                        d = d + item.days
                        rev = rev + item.total_amount
                    }
                    if (item.client_id === selectedVehicle1) {
                        r.push(item)
                        d = d + item.days
                        rev = rev + item.total_amount
                    }
                });

                rese = r
                tr = rese.length
                td = d
                tre = rev
                break;
            case 'hoy':
                var r = [], d = 0, rev = 0
                reservations.map(function (item) {
                    var sd = moment(new Date()).format('MM-DD-YYYY')
                    var ds = moment(item.pd).format("MM-DD-YYYY");
                    var t = moment(ds)
                    var s = moment(sd)
                    if (s.isSame(t, 'day') && selectedVehicle1 === 1) {
                        r.push(item)
                        d = d + item.days
                        rev = rev + item.total_amount
                    }
                    if (s.isSame(t, 'day') && item.client_id === selectedVehicle1) {
                        r.push(item)
                        d = d + item.days
                        rev = rev + item.total_amount
                    }
                });


                rese = r
                tr = rese.length
                td = d
                tre = rev
                break;
            case 'sempa':
                var r = [], d = 0, rev = 0
                reservations.map(function (item) {
                    if (moment(new Date().getTime()).isSame(moment(item.pd), 'week') && selectedVehicle1 === 1) {
                        r.push(item)
                        d = d + item.days
                        rev = rev + item.total_amount
                    }
                    if (moment(new Date().getTime()).isSame(moment(item.pd), 'week') && item.client_id === selectedVehicle1) {
                        r.push(item)
                        d = d + item.days
                        rev = rev + item.total_amount
                    }
                });

                rese = r
                tr = rese.length
                td = d
                tre = rev
                break;
            case 'mespa':
                var r = [], d = 0, rev = 0
                reservations.map(function (item) {
                    if (moment(new Date().getTime()).isSame(moment(item.pd), 'month') && selectedVehicle1 === 1) {
                        r.push(item)
                        d = d + item.days
                        rev = rev + item.total_amount
                    }
                    if (moment(new Date().getTime()).isSame(moment(item.pd), 'month') && item.client_id === selectedVehicle1) {
                        r.push(item)
                        d = d + item.days
                        rev = rev + item.total_amount
                    }
                });


                rese = r
                tr = rese.length
                td = d
                tre = rev
                break;
            case 'dia':
                //console.log("diaaaaa")
                var r = [], d = 0, rev = 0
                reservations.map(function (item) {
                    var sd = moment(selectedTS).format('MM-DD-YYYY')
                    var ds = moment(item.pd).format("MM-DD-YYYY");
                    var t = moment(ds)
                    var s = moment(sd)
                    if (s.isSame(t, 'day') && selectedVehicle1 === 1) {
                        r.push(item)
                        d = d + item.days
                        rev = rev + item.total_amount
                    }
                    if (s.isSame(t, 'day') && item.client_id === selectedVehicle1) {
                        r.push(item)
                        d = d + item.days
                        rev = rev + item.total_amount
                    }
                });


                rese = r
                tr = rese.length
                td = d
                tre = rev
                break;
            case 'mes':
                //console.log("meeeees")
                var r = [], d = 0, rev = 0
                reservations.map(function (item) {
                    if (moment(selectedTS).isSame(moment(item.pd), 'month') && selectedVehicle1 === 1) {
                        r.push(item)
                        d = d + item.days
                        rev = rev + item.total_amount
                    }
                    if (moment(selectedTS).isSame(moment(item.pd), 'month') && item.client_id === selectedVehicle1) {
                        r.push(item)
                        d = d + item.days
                        rev = rev + item.total_amount
                    }
                });


                rese = r
                tr = rese.length
                td = d
                tre = rev
                break;
            case 'ano':
                //console.log("anooooo")
                var r = [], d = 0, rev = 0
                reservations.map(function (item) {
                    if (moment(selectedTS).isSame(moment(item.pd), 'year') && selectedVehicle1 === 1) {
                        r.push(item)
                        d = d + item.days
                        rev = rev + item.total_amount
                    }
                    if (moment(selectedTS).isSame(moment(item.pd), 'year') && item.client_id === selectedVehicle1) {
                        r.push(item)
                        d = d + item.days
                        rev = rev + item.total_amount
                    }
                });


                rese = r
                tr = rese.length
                td = d
                tre = rev
                break;
            default:

        }
        //console.log(tr, td, tre)


        const { SearchBar } = Search
        const { isEdit } = this.state
        const { vc } = this.state
        const { vehicles } = this.state

        const { indies } = this.state
        const { tariffs } = this.state
        //pagination customization

        const client_list = []

        client_list.push({ label: "All Clients", value: "All" })
        indies.map(function (item, i) {

            client_list.push({ id: item.id, label: item.first_name + " " + item.last_name, value: item.id })

        })



        const defaultSorted = [{
            dataField: 'createdAt',
            order: 'desc'
        }];

        const selectRow = {
            mode: 'checkbox',
        };









        return (

            <React.Fragment>


                <div className="page-content">
                    <MetaTags>
                        <title>{this.props.t("Documents | Medios - Car Rental Management System")}</title>
                    </MetaTags>
                    <Container fluid>


                        <Breadcrumbs title={this.props.t("Rental")} breadcrumbItem={this.props.t("Documents")} />
                        {this.state.success_dlg ? (
                            <SweetAlert
                                success
                                title={this.state.dynamic_title}
                                onConfirm={() => this.setState({ success_dlg: false })}
                            >
                                {this.state.dynamic_description}
                            </SweetAlert>
                        ) : null}
                        <Row className="mb-2" >

                            <Row>

                                <Col dm="3" className="text-sm-center mb-2">
                                    <AvForm>
                                        <AvField
                                            name="title"
                                            type="select"
                                            onChange={this.handleSelectVehicleFilter}
                                            validate={{
                                                required: { value: true },
                                            }}

                                        >
                                            {client_list.map((e, key) => {

                                                return <option key={key} value={e.id}>{e.label}</option>;

                                            })}</AvField>
                                    </AvForm>



                                </Col>
                                <Col md="6" >
                                    {/*  <div className="text-end " >

                                        <Col   >

                                            <div className="btn-group me-2" role="group" aria-label="Basic radio toggle button group" onChange={this.onChangeValue}>
                                                <input type="radio" className="btn-check" name="btnradio" id="todo" onClick={this.onValueChange} autoComplete="off" defaultChecked />
                                                <label className="btn btn-primary" htmlFor="todo">{this.props.t("All")}</label>

                                                <input type="radio" className="btn-check" name="btnradio" id="hoy" onClick={this.onValueChange} autoComplete="off" />
                                                <label className="btn btn-primary" htmlFor="hoy">{this.props.t("Today")}</label>

                                                <input type="radio" className="btn-check" name="btnradio" id="sempa" onClick={this.onValueChange} autoComplete="off" />
                                                <label className="btn btn-primary" htmlFor="sempa">{this.props.t("This week")}</label>

                                                <input type="radio" className="btn-check" name="btnradio" id="mespa" onClick={this.onValueChange} autoComplete="off" />
                                                <label className="btn btn-primary" htmlFor="mespa">{this.props.t("This month")}</label>

                                                <button
                                                    type="button"
                                                    className="btn btn-light mb-2"
                                                    onClick={this.toggle_modal}
                                                >
                                                    <i className="bx bx-filter  font-size-22 align-middle "></i>

                                                </button>
                                            </div>

                                        </Col>

                                    </div> */}
                                </Col>

                                <Modal isOpen={inv_modal} size="lg">
                                    <ModalHeader toggle={this.toggleInv}>
                                    </ModalHeader>
                                    <ModalBody>
                                        <Row className="text-center"  >
                                            {loading ? <Col xs="12">
                                                <div className="text-center my-3">
                                                    <Link to="#" className="text-success">
                                                        <i className="bx bx-hourglass bx-spin me-2" />
                                                        {this.props.t("Loading . . .")}
                                                    </Link>
                                                </div>
                                            </Col> :
                                                <Col lg="12" id="down">

                                                    <Card >

                                                        <CardBody>

                                                            <div className="print-container">
                                                                <div className="print-header">
                                                                    <header>
                                                                        <Row className="justify-items-center">
                                                                            {/* <Col className="col-sm-7 text-center text-sm-start mb-2  mb-sm-0"> <img id="logo" src={logo} width="90" height="30" title="Medios" alt="Medios"></img> </Col> */}
                                                                            <Col className=" text-center ">
                                                                                <h2 className="mb-0 ">{this.props.t("Invoice")}</h2>
                                                                                <p className="mb-0 ">{this.props.t("Invoice Number")} : <span className="fw-bold">{reservation.code}</span></p>
                                                                            </Col>
                                                                        </Row>

                                                                    </header>
                                                                </div>

                                                                <div className="inv-content">
                                                                    <Container fluid>


                                                                        <h5 className="text-start fw-bold mt-0 mb-2">{this.props.t("Billed to")} :</h5>

                                                                        <div >
                                                                            <table className="table rounded-table table-sm">
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td className="col-3 text-start">
                                                                                            <span className="fw-bold">{this.props.t("Name")} :</span>&nbsp;&nbsp;&nbsp;&nbsp;
                                                                                            {client_infos.first_name + " " + client_infos.last_name}
                                                                                        </td>
                                                                                        {/* <td className="col-3 text-start">
                                        <span className="fw-bold">{this.props.t("Date of Birth")}:</span>&nbsp;&nbsp;&nbsp;&nbsp;
                                        {client_infos.date_birth}
                                      </td>
 */}
                                                                                        {/* <td className="col-3 text-start">
          <span className="fw-bold">{this.props.t("Valid Till")}:</span><br />
          {client_infos.id_expiration_date}
        </td> */}
                                                                                        <td className="col-3 text-start">
                                                                                            <span className="fw-bold">{this.props.t("CIN/Passport Number")}:</span>&nbsp;&nbsp;&nbsp;&nbsp;
                                                                                            {client_infos.id_number}
                                                                                        </td>
                                                                                        <td className="col-3 text-start">
                                                                                            <span className="fw-bold">{this.props.t("Phone")}:</span>&nbsp;&nbsp;&nbsp;&nbsp;
                                                                                            {client_infos.mobile}
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        {/* <td className="col-4 text-start">
          <span className="fw-bold">{this.props.t("Driving License Number")}:</span><br />
          {client_infos.dl_number}
        </td> */}




                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td className="col-12 text-start" colSpan="3">
                                                                                            <span className="fw-bold">{this.props.t("Address")}:</span>&nbsp;&nbsp;&nbsp;&nbsp;
                                                                                            {client_infos.address + ", " + client_infos.city + ", " + client_infos.country}
                                                                                        </td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                        <h5 className="text-start fw-bold ">{this.props.t("Vehicle & Rental Details")} : </h5>
                                                                        <Row>
                                                                            <Col sm="7" className="text-sm-start">


                                                                                <table className="table  table-sm">
                                                                                    <tbody>
                                                                                        <tr>
                                                                                            <td className="col-4 text-start fw-bold">{this.props.t("Make & Model")}:</td>
                                                                                            <td className="col-8">{car_make + " " + car_model}</td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td className="text-start fw-bold">{this.props.t("Registration Number")}:</td>
                                                                                            <td>{reservation.plate_number}</td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td className="text-start fw-bold">{this.props.t("Rental Start Date")}:</td>
                                                                                            <td>{moment(reservation.pickup_date).format('DD-MM-YYYY')}</td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td className="text-start fw-bold">{this.props.t("Rental End Date")}:</td>
                                                                                            <td>{moment(reservation.return_date).format('DD-MM-YYYY')}</td>
                                                                                        </tr>

                                                                                    </tbody>
                                                                                </table>
                                                                            </Col>
                                                                            <Col sm="5" className="text-sm-end">
                                                                                <table className="table  table-sm">
                                                                                    <tbody>
                                                                                        <tr>
                                                                                            <td className="col-6 text-start fw-bold">{this.props.t("Invoice number")}:</td>
                                                                                            <td className="col-6">{reservation.code}</td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td className="text-start fw-bold">{this.props.t("Invoice Date")}:</td>
                                                                                            <td>{moment(new Date()).format('DD-MM-YYYY')}</td>
                                                                                        </tr>

                                                                                    </tbody>
                                                                                </table>

                                                                            </Col>
                                                                        </Row>





                                                                        <h5 className="text-start fw-bold ">{this.props.t("Charges & Fees")} : </h5>
                                                                        <div className="">
                                                                            <table className="table table-border  table-sm">
                                                                                <thead className="card-header">
                                                                                    <tr>
                                                                                        <td className="col-4 text-start fw-bold">{this.props.t("Description")}</td>
                                                                                        <td className="col-3 text-end fw-bold">{this.props.t("Unit Price (MAD)")}</td>
                                                                                        <td className="col-2 text-end fw-bold">{this.props.t("Unit")}</td>
                                                                                        <td className="col-2 text-end fw-bold">{this.props.t("Quantity")}</td>
                                                                                        <td className="col-3 text-end fw-bold">{this.props.t("Total (MAD)")}</td>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td className="col-4 text-start">{this.props.t("Net de location")} </td>
                                                                                        <td className="col-3 text-end">{reservation.amount / reservation.days}</td>
                                                                                        <td className="col-2 text-end">{this.props.t("Days")}</td>
                                                                                        <td className="col-2 text-end">{reservation.days}</td>
                                                                                        <td className="col-3 text-end">{reservation.amount}</td>
                                                                                    </tr>

                                                                                    <tr>
                                                                                        <td className="col-4 text-start">{this.props.t("Delivery fee")}<br />
                                                                                            {/* <span className="text-1">SUMMERFUN - 10% One Time Discount</span>*/}</td>
                                                                                        <td className="text-end">{reservation.frais_livraison}</td>
                                                                                        <td className="text-end">{this.props.t("-")}</td>
                                                                                        <td className="text-end">{1}</td>
                                                                                        <td className="text-end">{reservation.frais_livraison}</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td className="col-4 text-start">{this.props.t("Return fee")}<br />
                                                                                            {/* <span className="text-1">SUMMERFUN - 10% One Time Discount</span>*/}</td>
                                                                                        <td className="text-end">{reservation.frais_reprise}</td>
                                                                                        <td className="text-end">{this.props.t("-")}</td>
                                                                                        <td className="text-end">{1}</td>
                                                                                        <td className="text-end">{reservation.frais_reprise}</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td className="col-4 text-start">{this.props.t("Divers")}</td>
                                                                                        <td className="text-end">{reservation.frais_divers}</td>
                                                                                        <td className="text-end">{this.props.t("-")}</td>
                                                                                        <td className="text-end">{1}</td>
                                                                                        <td className="text-end">{reservation.frais_divers}</td>
                                                                                    </tr>
                                                                                </tbody>
                                                                                <tfoot className="card-footer">
                                                                                    <tr>
                                                                                        <td colSpan="4" className="text-end border-bottom-0"><strong>{this.props.t("Sub Total")}:</strong></td>
                                                                                        <td className="text-end border-bottom-0">{subtotal}</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td colSpan="4" className="text-end border-bottom-0"><strong>{this.props.t("TVA 20%")}:</strong></td>
                                                                                        <td className="text-end border-bottom-0">{tva}</td>
                                                                                    </tr>

                                                                                    <tr>
                                                                                        <td colSpan="4" className="text-end border-bottom-0 "><strong>{this.props.t("Total")}:</strong></td>
                                                                                        <td className="text-end border-bottom-0"><strong>{total}</strong></td>
                                                                                    </tr>
                                                                                </tfoot>

                                                                            </table>


                                                                        </div>
                                                                        <Row>
                                                                            <Col md="7">
                                                                                <h5 className="text-start fw-bold ">{this.props.t("Payment method")} : </h5>
                                                                                <Row>
                                                                                    <Col >
                                                                                        <Col className="d-flex align-items-center mt-2">
                                                                                            <div
                                                                                                style={{
                                                                                                    width: '15px',
                                                                                                    height: '15px',
                                                                                                    border: '1px solid #000', // Border only
                                                                                                    marginRight: '10px',
                                                                                                    borderRadius: '3px',
                                                                                                }}
                                                                                            ></div>
                                                                                            <span>{this.props.t("Cash")}</span>
                                                                                        </Col>
                                                                                    </Col>
                                                                                    <Col >
                                                                                        <Col className="d-flex align-items-center mt-2">
                                                                                            <div
                                                                                                style={{
                                                                                                    width: '15px',
                                                                                                    height: '15px',
                                                                                                    border: '1px solid #000', // Border only
                                                                                                    marginRight: '10px',
                                                                                                    borderRadius: '3px',
                                                                                                }}
                                                                                            ></div>
                                                                                            <span>{this.props.t("Bank Transfer")}</span>
                                                                                        </Col>
                                                                                    </Col>
                                                                                </Row>
                                                                                <Row>
                                                                                    <Col >
                                                                                        <Col className="d-flex align-items-center mt-2">
                                                                                            <div
                                                                                                style={{
                                                                                                    width: '15px',
                                                                                                    height: '15px',
                                                                                                    border: '1px solid #000', // Border only
                                                                                                    marginRight: '10px',
                                                                                                    borderRadius: '3px',
                                                                                                }}
                                                                                            ></div>
                                                                                            <span>{this.props.t("Credit/Debit Card")}</span>
                                                                                        </Col>
                                                                                    </Col>
                                                                                    <Col >
                                                                                        <Col className="d-flex align-items-center mt-2">
                                                                                            <div
                                                                                                style={{
                                                                                                    width: '15px',
                                                                                                    height: '15px',
                                                                                                    border: '1px solid #000', // Border only
                                                                                                    marginRight: '10px',
                                                                                                    borderRadius: '3px',
                                                                                                }}
                                                                                            ></div>
                                                                                            <span>{this.props.t("Other")}</span>
                                                                                        </Col>
                                                                                    </Col>
                                                                                </Row>


                                                                            </Col>
                                                                            <Col md="5" className="mt-3">
                                                                                <h5 className="text-start fw-bold mb-3">{this.props.t("Payment status")} : </h5>
                                                                                <Col >
                                                                                    <Col className="d-flex align-items-center mt-2">
                                                                                        <div
                                                                                            style={{
                                                                                                width: '15px',
                                                                                                height: '15px',
                                                                                                border: '1px solid #000', // Border only
                                                                                                marginRight: '10px',
                                                                                                borderRadius: '3px',
                                                                                            }}
                                                                                        ></div>
                                                                                        <span>{this.props.t("Paid")}</span>
                                                                                    </Col>
                                                                                </Col>
                                                                                <Col >
                                                                                    <Col className="d-flex align-items-center mt-2">
                                                                                        <div
                                                                                            style={{
                                                                                                width: '15px',
                                                                                                height: '15px',
                                                                                                border: '1px solid #000', // Border only
                                                                                                marginRight: '10px',
                                                                                                borderRadius: '3px',
                                                                                            }}
                                                                                        ></div>
                                                                                        <span>{this.props.t("Not paid")}</span>
                                                                                    </Col>
                                                                                </Col>
                                                                            </Col>
                                                                        </Row>
                                                                        {/* <Col className="d-flex align-items-center mb-2">
                                <div
                                  style={{
                                    width: '20px',
                                    height: '20px',
                                    border: '2px solid #000', // Border only
                                    marginRight: '10px',
                                  }}
                                ></div>
                                <span>kra kolo</span>
                              </Col> */}
                                                                    </Container>

                                                                </div>
                                                                <div className="print-footer">

                                                                    <p><strong>{agency.company_name}</strong><br />
                                                                        {agency.address} - {this.props.t("Email")} : {agency.email} <br />
                                                                        {this.props.t("Tél")}: {agency.phone} - {this.props.t("RC")} : {agency.rc} - {this.props.t("T.P")}: {agency.tp} - {this.props.t("ICE")}: {agency.ice} - {this.props.t("IF")}: {agency.id_fiscal}.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </CardBody>


                                                    </Card>
                                                    <div className="d-print-none mb-4">
                                                        <div className="float-center">
                                                            <Link
                                                                to="#"
                                                                onClick={this.printInvoice}
                                                                className="btn btn-success me-1"
                                                            >
                                                                {this.props.t("Print")}{" "}<i className="fa fa-print" />
                                                            </Link>{" "}
                                                            {/*  <Link
                          to="#"
                          onClick={generatePDF}
                          className="btn btn-primary me-1"
                        >
                          {this.props.t("Save as PDF")}{" "}<i className="fa fa-file-pdf" />
                        </Link>{" "} */}
                                                        </div>
                                                    </div>
                                                </Col>

                                            }


                                        </Row>
                                    </ModalBody>
                                </Modal>
                                <Modal
                                    isOpen={this.state.modal1}
                                    toggle={this.toggle_modal}
                                    id="event-modal"
                                >
                                    <ModalHeader toggle={this.toggle_modal} tag="h4">
                                        {this.props.t("Custom Period")}
                                    </ModalHeader>
                                    <ModalBody>
                                        <AvForm onValidSubmit={this.handleValidEventSubmit}>
                                            <Row form>
                                                <Col className="col-12 mb-3">

                                                    {/* <AvField
  name="title"
  label="Vehicles"
  type="select"
  onChange={this.handleSelectVehicle}
  validate={{
    required: { value: true },
  }}

>
  {vcList.map((e, key) => {

    return <option key={key} value={e.id}>{e.label}</option>;

  })}</AvField> */}
                                                </Col>
                                                <Col className="col-12 mb-3">
                                                    <AvField
                                                        type="select"
                                                        name="period"
                                                        label={this.props.t("Select Period")}
                                                        defaultValue={selectedPeriod}
                                                        validate={{
                                                            required: { value: true },
                                                        }}
                                                        onChange={this.handlePeriodChange}
                                                    >
                                                        <option value="day">{this.props.t("Day")}</option>
                                                        <option value="month">{this.props.t("Month")}</option>
                                                        <option value="year">{this.props.t("Year")}</option>
                                                    </AvField>
                                                </Col>

                                                {selectedPeriod === "day" ?
                                                    <div className="mb-3 mt-4 row">
                                                        <label
                                                            htmlFor="example-month-input"
                                                            className="col-md-2 col-form-label"
                                                        >
                                                            {this.props.t("Day")}
                                                        </label>

                                                        <div className="col">
                                                            <AvField
                                                                className="form-control text-sm-start"
                                                                name="date"
                                                                type="date"
                                                                onChange={this.dateChanged}
                                                                id="example-date-input"
                                                                validate={{
                                                                    required: { value: true },
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    : null}
                                                {selectedPeriod === "month" ?
                                                    <div className="mb-6 row mb-3">
                                                        <label
                                                            htmlFor="example-month-input"
                                                            className="col-md-2 col-form-label"
                                                        >
                                                            {this.props.t("Month")}
                                                        </label>
                                                        <div className="col">
                                                            <AvField
                                                                name="month"
                                                                className="form-control"
                                                                type="month"
                                                                onChange={this.monthChanged}
                                                                id="example-month-input"
                                                                validate={{
                                                                    required: { value: true },
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    : null}
                                                {selectedPeriod === "year" ?
                                                    <div className="mb-3 row mb-3">
                                                        <AvField
                                                            type="select"
                                                            name="year"
                                                            label={this.props.t("Select year")}
                                                            onChange={this.yearChanged}
                                                            defaultValue={currentYear}
                                                            validate={{
                                                                required: { value: true },
                                                            }}
                                                        >
                                                            <option value={currentYear}>{currentYear}</option>
                                                            <option value={currentYear - 1}>{currentYear - 1}</option>
                                                            <option value={currentYear - 2}>{currentYear - 2}</option>
                                                            <option value={currentYear - 3}>{currentYear - 3}</option>
                                                            <option value={currentYear - 4}>{currentYear - 4}</option>
                                                        </AvField>
                                                    </div>
                                                    : null}
                                            </Row>
                                            {/* <h5 className="text-center mt-4">
{this.state.selectedDate || this.state.selectedMonth}
</h5> */}
                                            <div className="modal-footer">
                                                <button
                                                    type="button"
                                                    className="btn btn-light"
                                                    data-dismiss="modal"
                                                    onClick={this.toggle_modal}
                                                >
                                                    {this.props.t("Close")}
                                                </button>

                                                <button
                                                    type="submit"
                                                    className="btn btn-success"
                                                >
                                                    {this.props.t("Confirm")}
                                                </button>
                                            </div>
                                        </AvForm>
                                    </ModalBody>


                                </Modal>

                            </Row>

                        </Row>
                        <Row>
                            <Col xs="12">
                                {/* <Card>
                                    <CardBody>
                                        <Row>


                                            <Col lg="12" className="text-center">
                                                <div className="text-lg-center mt-4 mt-lg-0">
                                                    <Row>
                                                        <Col >
                                                            <div>
                                                                <p className="text-muted text-truncate mb-2">
                                                                    {this.props.t("Reservations")}
                                                                </p>
                                                                <h5 className="mb-0">{tr}</h5>
                                                            </div>
                                                        </Col>

                                                        <Col >
                                                            <div>
                                                                <p className="text-muted text-truncate mb-2">
                                                                    {this.props.t("Total days")}
                                                                </p>
                                                                <h5 className="mb-0">{td}</h5>
                                                            </div>
                                                        </Col>
                                                        <Col >
                                                            <div>
                                                                <p className="text-muted text-truncate mb-2 ">
                                                                    {this.props.t("Total revenue")}
                                                                </p>
                                                                <h5 className="mb-0">{tre} MAD</h5>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Col>

                                        </Row>
                                    </CardBody>
                                </Card> */}
                                <Card>
                                    <CardBody>
                                        <PaginationProvider
                                            pagination={paginationFactory(/* {
                                                sizePerPage: 5} */)}
                                            keyField='id'
                                            columns={(this.state.ReservationColumns || [])}
                                            data={(rese || [])}
                                        >
                                            {({ paginationProps, paginationTableProps }) => (
                                                <ToolkitProvider
                                                    keyField="id"
                                                    data={rese}
                                                    columns={(this.state.ReservationColumns || [])}
                                                    bootstrap4
                                                    search
                                                >
                                                    {toolkitProps => (
                                                        <React.Fragment>
                                                            <Row className="mb-2" class="noPrint">
                                                                <Col sm="4">
                                                                    <div className="search-box me-2 mb-2 d-inline-block">
                                                                        <div className="position-relative">
                                                                            <SearchBar
                                                                                {...toolkitProps.searchProps}
                                                                            />
                                                                            <i className="bx bx-search-alt search-icon" />
                                                                        </div>
                                                                    </div>
                                                                </Col>

                                                                <Col sm="8" >

                                                                    <div className="text-sm-end" >
                                                                        <div className="btn-group me-2" role="group" aria-label="Basic radio toggle button group" onChange={this.onChangeValue}>
                                                                            <input type="radio" className="btn-check" name="btnradio" id="todo" onClick={this.onValueChange} autoComplete="off" defaultChecked />
                                                                            <label className="btn btn-primary" htmlFor="todo">{this.props.t("All")}</label>

                                                                            <input type="radio" className="btn-check" name="btnradio" id="hoy" onClick={this.onValueChange} autoComplete="off" />
                                                                            <label className="btn btn-primary" htmlFor="hoy">{this.props.t("Today")}</label>

                                                                            <input type="radio" className="btn-check" name="btnradio" id="sempa" onClick={this.onValueChange} autoComplete="off" />
                                                                            <label className="btn btn-primary" htmlFor="sempa">{this.props.t("This week")}</label>

                                                                            <input type="radio" className="btn-check" name="btnradio" id="mespa" onClick={this.onValueChange} autoComplete="off" />
                                                                            <label className="btn btn-primary" htmlFor="mespa">{this.props.t("This month")}</label>

                                                                            <button
                                                                                type="button"
                                                                                className="btn btn-light mb-2"
                                                                                onClick={this.toggle_modal}
                                                                            >
                                                                                <i className="bx bx-filter  font-size-22 align-middle "></i>

                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <div className="table-responsive">
                                                                <BootstrapTable

                                                                    {...toolkitProps.baseProps}
                                                                    {...paginationTableProps}
                                                                    responsive
                                                                    defaultSorted={defaultSorted}
                                                                    bordered={false}
                                                                    striped={false}
                                                                    noDataIndication={emptyDataMessage}
                                                                    classes={
                                                                        "table align-middle table-nowrap table-check"
                                                                    }
                                                                    headerWrapperClasses={"table-light"}
                                                                />



                                                                {this.state.isDelete ? (
                                                                    <SweetAlert
                                                                        title={this.props.t("Are you sure?")}
                                                                        warning
                                                                        showCancel
                                                                        confirmButtonText={this.props.t("Yes, delete it!")}
                                                                        confirmBtnBsStyle="success"
                                                                        cancelBtnBsStyle="danger"
                                                                        onConfirm={() =>
                                                                            this.handleDeleteReservation(vc)
                                                                        }
                                                                        onCancel={() =>
                                                                            this.setState({
                                                                                isDelete: false,
                                                                            })
                                                                        }
                                                                    >
                                                                        {this.props.t("You won't be able to revert this!")}
                                                                    </SweetAlert>
                                                                ) : null}
                                                                {this.state.no_data ? (
                                                                    <SweetAlert
                                                                        title={this.props.t("No data!")}
                                                                        warning
                                                                        onConfirm={() => this.setState({ no_data: false })}
                                                                    >
                                                                        {this.props.t("There is no data to export")}
                                                                    </SweetAlert>
                                                                ) : null}

                                                            </div>


                                                        </React.Fragment>
                                                    )}
                                                </ToolkitProvider>
                                            )}
                                        </PaginationProvider>


                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <Toaster
                    position="top-right"
                    reverseOrder={false}
                />
            </React.Fragment>
        )
    }
}

Documents.propTypes = {
    reservations: PropTypes.array,
    vehicles: PropTypes.array,
    indies: PropTypes.array,
    tariffs: PropTypes.array,
    onGetReservation: PropTypes.func,
    onAddNewReservation: PropTypes.func,
    onDeleteReservation: PropTypes.func,
    onUpdateReservation: PropTypes.func,
    onUpdateVehicle: PropTypes.func,
    onGetVehicle: PropTypes.func,
    onGetIndie: PropTypes.func,
    onGetTariff: PropTypes.func,
    className: PropTypes.any,
    t: PropTypes.any,
    i18n: PropTypes.any
}

const mapStateToProps = state => ({
    reservations: state.reservation.reservations,
    vehicles: state.vehicle.vehicles,
    indies: state.clients.indies,
    tariffs: state.rental.tariffs,
})

const mapDispatchToProps = dispatch => ({

    onGetVehicle: () => dispatch(getVehicle()),
    onGetIndie: () => dispatch(getIndie()),
    onGetTariff: () => dispatch(getTariff()),
    onUpdateVehicle: vehicle => dispatch(updateVehicle(vehicle)),
    onGetReservation: () => dispatch(getReservation()),
    onAddNewReservation: reservation => dispatch(addNewReservation(reservation)),
    onUpdateReservation: reservation => dispatch(updateReservation(reservation)),
    onDeleteReservation: reservation => dispatch(deleteReservation(reservation)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withTranslation()(Documents)))
