import {
  
  GET_SUPPLIER ,
  GET_SUPPLIER_FAIL,
  GET_SUPPLIER_SUCCESS,
  ADD_NEW_SUPPLIER,
  ADD_SUPPLIER_SUCCESS,
  ADD_SUPPLIER_FAIL,
  UPDATE_SUPPLIER,
  UPDATE_SUPPLIER_SUCCESS,
  UPDATE_SUPPLIER_FAIL,
  DELETE_SUPPLIER,
  DELETE_SUPPLIER_SUCCESS,
  DELETE_SUPPLIER_FAIL,
  
} from "./actionTypes"



export const getSupplier = () => ({
  type: GET_SUPPLIER,
})

export const getSupplierSuccess = supplier => ({
  type: GET_SUPPLIER_SUCCESS,
  payload: supplier,
})

export const getSupplierFail = error => ({
  type: GET_SUPPLIER_FAIL,
  payload: error,
})

export const addNewSupplier = supplier => ({
  type: ADD_NEW_SUPPLIER,
  payload: supplier,
})

export const addSupplierSuccess = supplier => ({
  type: ADD_SUPPLIER_SUCCESS,
  payload: supplier,
})

export const addSupplierFail = error => ({
  type: ADD_SUPPLIER_FAIL,
  payload: error,
})

export const updateSupplier = supplier => ({
  type: UPDATE_SUPPLIER,
  payload: supplier,
})

export const updateSupplierSuccess = supplier => ({
  type: UPDATE_SUPPLIER_SUCCESS,
  payload: supplier,
})

export const updateSupplierFail = error => ({
  type: UPDATE_SUPPLIER_FAIL,
  payload: error,
})

export const deleteSupplier = supplier => ({
  type: DELETE_SUPPLIER,
  payload: supplier,
})

export const deleteSupplierSuccess = supplier => ({
  type: DELETE_SUPPLIER_SUCCESS,
  payload: supplier,
})

export const deleteSupplierFail = error => ({
  type: DELETE_SUPPLIER_FAIL,
  payload: error,
})
