
/* SUPPLIER */
export const GET_SUPPLIER = "GET_SUPPLIER"
export const GET_SUPPLIER_SUCCESS = "GET_SUPPLIER_SUCCESS"
export const GET_SUPPLIER_FAIL = "GET_SUPPLIER_FAIL"

export const ADD_NEW_SUPPLIER = "ADD_NEW_SUPPLIER"
export const ADD_SUPPLIER_SUCCESS = "ADD_SUPPLIER_SUCCESS"
export const ADD_SUPPLIER_FAIL = "ADD_SUPPLIER_FAIL"

export const UPDATE_SUPPLIER = "UPDATE_SUPPLIER"
export const UPDATE_SUPPLIER_SUCCESS = "UPDATE_SUPPLIER_SUCCESS"
export const UPDATE_SUPPLIER_FAIL = "UPDATE_SUPPLIER_FAIL"

export const DELETE_SUPPLIER = "DELETE_SUPPLIER"
export const DELETE_SUPPLIER_SUCCESS = "DELETE_SUPPLIER_SUCCESS"
export const DELETE_SUPPLIER_FAIL = "DELETE_SUPPLIER_FAIL"

