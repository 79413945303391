import React, { Component } from "react"
import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';
import { AvForm, AvField } from "availity-reactstrap-validation"
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  CardTitle,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  Input
} from "reactstrap"
import { Link } from "react-router-dom"
import { connect } from "react-redux"

//import Charts
import StackedColumnChart from "./StackedColumnChart"

import modalimage1 from "../../assets/images/product/img-7.png"
import modalimage2 from "../../assets/images/product/img-4.png"

//import action
import { getChartsData } from "../../store/actions"
import Apaexlinecolumn from "../AllCharts/apex/apaexlinecolumn"
// Pages Components
import WelcomeComp from "./WelcomeComp"
import MonthlyEarning from "./MonthlyEarning"
import SocialSource from "./SocialSource"
import ActivityComp from "./ActivityComp"
import TopCities from "./TopCities"
import TodayPickups from "./Today_pickups"
import TodayReturns from "./Today_returns"

import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import "firebase/database"
import "firebase/storage"

import * as moment from 'moment';
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"
import classNames from "classnames";

class Dashboard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currentYear: new Date().getFullYear(),
      selectedYear: new Date().getFullYear(),
      rc: 0,
      rev: 0,
      res: [],
      fines: [],
      d: 0,
      repairs: [],
      modal: false,
      subscribemodal: false,
      chartSeries: [],
      periodType: "yearly"
    }
    this.handleYearChanged = this.handleYearChanged.bind(this)
    this.togglemodal.bind(this)
    this.togglesubscribemodal.bind(this)
  }
  handleYearChanged = (selectedVehicle, value) => {
    this.setState({
      selectedYear: value
    })
  }
  componentDidMount() {

    this.getReservation()
    this.getFine()
    this.getRepair()
    //const { onGetChartsData } = this.props
    //change subscribe modal to true in order to show subscription to email
    //setTimeout(() => this.setState({ subscribemodal: false }), 2000);
    //onGetChartsData("yearly");
  }
  getRepair = async () => {

    this.setState({ loading: true });

    // Unsubscribe previous listeners to prevent memory leaks
    if (this.unsubscribeRepairs) {
      this.unsubscribeRepairs();
    }

    this.unsubscribeRepairs = firebase.auth().onAuthStateChanged(async user => {
      if (user) {
        const userId = user.uid;
        const usersCollection = firebase.firestore().collection("users")
        const userDocRef = usersCollection.doc(userId);

        try {
          const userDoc = await userDocRef.get();
          if (userDoc.exists) {
            const userId = userDoc.data().id;
            const userRepairsCollectionRef = usersCollection.doc(userId).collection("repairs");

            this.unsubscribeRepairs = userRepairsCollectionRef.onSnapshot(snapshot => {
              let kra = []
              snapshot.docs.forEach(e => {
                kra.push(e.data())
              });
              this.setState({
                loading: false,
                repairs: kra,
              })
            })

            localStorage.setItem("authUser", JSON.stringify(user))
          } else {
            ////console.log("not user")
            localStorage.removeItem("authUser")
          }
        } catch (error) {
          console.error("Error fetching user document:", error);
          // Handle error
        }
      }

    })
  }

  getFine = async () => {
    this.setState({ loading: true });

    // Unsubscribe previous listeners to prevent memory leaks
    if (this.unsubscribeFines) {
      this.unsubscribeFines();
    }

    this.unsubscribeFines = firebase.auth().onAuthStateChanged(async user => {
      if (user) {
        const userId = user.uid;
        const usersCollection = firebase.firestore().collection("users")
        const userDocRef = usersCollection.doc(userId);

        try {
          const userDoc = await userDocRef.get();
          if (userDoc.exists) {
            const userId = userDoc.data().id;
            const userFinesCollectionRef = usersCollection.doc(userId).collection("fines");

            this.unsubscribeFines = userFinesCollectionRef.onSnapshot(snapshot => {
              let kra = []
              snapshot.docs.forEach(e => {
                kra.push(e.data())
              });
              this.setState({
                loading: false,
                fines: kra,
              })
            })

            localStorage.setItem("authUser", JSON.stringify(user))
          } else {
            ////console.log("not user")
            localStorage.removeItem("authUser")
          }
        } catch (error) {
          console.error("Error fetching user document:", error);
          // Handle error
        }
      }

    })


  }
  getReservation = async () => {
    this.setState({ loading: true });

    // Unsubscribe previous listeners to prevent memory leaks
    if (this.unsubscribeReservations) {
      this.unsubscribeReservations();
    }

    this.unsubscribeReservations = firebase.auth().onAuthStateChanged(async user => {
      if (user) {
        const userId = user.uid;
        const usersCollection = firebase.firestore().collection("users")
        const userDocRef = usersCollection.doc(userId);

        try {
          const userDoc = await userDocRef.get();
          if (userDoc.exists) {
            const userId = userDoc.data().id;
            const userReservationsCollectionRef = usersCollection.doc(userId).collection("reservations");

            this.unsubscribeReservations = userReservationsCollectionRef.onSnapshot(snapshot => {
              let reservations = [];
              snapshot.docs.forEach(doc => {
                reservations.push(doc.data());
              });

              this.setState({
                loading: false,
                res: reservations
              });
            });

            localStorage.setItem("authUser", JSON.stringify(user));
          } else {
            // User document not found
            localStorage.removeItem("authUser");
            this.setState({
              loading: false,
              res: []
            });
          }
        } catch (error) {
          console.error("Error fetching user document:", error);
          // Handle error
        }
      } else {
        localStorage.removeItem("authUser");
        // Clear the reservations if user is not authenticated
        this.setState({
          loading: false,
          res: []
        });
      }
    });
  }

  togglemodal = () => {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }))
  }

  togglesubscribemodal = () => {
    this.setState(prevState => ({
      subscribemodal: !prevState.subscribemodal,
    }))
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      //this.setState({ ...this.state, chartSeries: this.props.chartsData })
    }
  }

  render() {
    const yearData = [
      {
        name: this.props.t("Profit"),
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      {
        name: this.props.t("Revenue"),
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      {
        name: this.props.t("Expenses"),
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
    ]
    const { selectedYear, currentYear, rc, rev, d, res, fines, repairs } = this.state
    //console.log(selectedYear)
    var yearts = moment(selectedYear, 'YYYY').valueOf()
    //console.log(yearts)
    var tr = 0, dav = 0, tre = 0, days = 0, avg_duration = 0
    var r = []
    res.map(function (item) {
      if (moment(yearts).isSame(moment(item.pd), 'year')) {
        r.push(item)
        tre = tre + item.total_amount
        days = days + item.days
      }
    });
    tr = r.length
    dav = tr > 0 ? Math.floor(tre / days) : 0
    avg_duration = tr > 0 ? Math.floor(days / tr) : 0


    //console.log("Average duration ", avg_duration)
    var total = 0
    var totalD = 0
    for (var i in rev) {
      total += rev[i];
    }
    for (var i in d) {
      totalD += d[i];
    }
    var check = moment(new Date(), 'YYYY/MM/DD');

    var mo = check.format('M');
    var ye = check.format('YYYY');
    var month = parseInt(mo) - 1;
    var year = parseInt(selectedYear);

    ////console.log(new Date().getDate(), month, year)
    var a = moment(new Date(year, 0, 1));
    var b = moment(new Date(year + 1, 0, 1));
    ////console.log(a,b)
    var me = 0
    var mx = 0
    var mp = 0
    for (var m = moment(a); m.diff(b, 'months') < 0; m.add(1, 'months')) {

      var tm = moment(m);
      tm.add(1, 'months')

      res.forEach(e => {
        if(moment(m.valueOf()).isSame(moment(e.pd), 'month')){
          me = me + e.total_amount
        }
        /* if (e.pd >= m.unix() * 1000 && e.pd <= tm.unix() * 1000) {
          //  //console.log(m.format("YYYY-MM-DD"),e.code)
          me = me + e.total_amount
        } */
      });
      fines.forEach(f => {
        if(moment(m.valueOf()).isSame(moment(new Date(f.date).getTime()), 'month')){
          mx = mx + f.amount
        }
        /* if (new Date(f.date).getTime() >= m.unix() * 1000 && new Date(f.date).getTime() <= tm.unix() * 1000) {
          //  //console.log(m.format("YYYY-MM-DD"),e.code)
          mx = mx + f.amount
        } */
      });
      repairs.forEach(r => {
        if(moment(m.valueOf()).isSame(moment(r.sd), 'month')){
          mx = mx + r.amount
        }
        /* if (r.sd >= m.unix() * 1000 && r.sd <= tm.unix() * 1000) {
          //  //console.log(m.format("YYYY-MM-DD"),e.code)
          mx = mx + r.amount
        } */
      });

      yearData[1].data[m.format("M") - 1] = me
      yearData[2].data[m.format("M") - 1] = mx
      yearData[0].data[m.format("M") - 1] = me - mx
      /* //console.log(yearData[0].data[m.format("M")-1])
      //console.log(m.format("M"))
      //console.log(ma) */
      mx = 0
      me = 0
      mp = 0
    }
    ////console.log(yearData)
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>{this.props.t("Dashboard | Medios - Car Rental Management System")}</title>
          </MetaTags>
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs
              title={this.props.t("Dashboards")}
              breadcrumbItem={this.props.t("Dashboard")}
            />
            <Row className="mb-3 align-items-end justify-content-end">
              <Col md="6">
                <AvForm >
                  <div className="row align-items-end justify-content-end">
                    <label htmlFor="example-month-input" className="col-md-3 text-right">
                      {this.props.t("Select year")}
                    </label>
                    <Col md="4">
                      <AvField
                        className="form-control-md" // Increase the size of the AvField
                        type="select"
                        name="year"
                        onChange={this.handleYearChanged}
                        defaultValue={currentYear}
                        validate={{
                          required: { value: true },
                        }}
                      >
                        <option value={currentYear}>{currentYear}</option>
                        <option value={currentYear - 1}>{currentYear - 1}</option>
                        <option value={currentYear - 2}>{currentYear - 2}</option>
                        <option value={currentYear - 3}>{currentYear - 3}</option>
                        <option value={currentYear - 4}>{currentYear - 4}</option>
                      </AvField>
                    </Col>
                  </div>
                </AvForm>
              </Col>
            </Row>


            <Row>
              <Col xl="4">
                <WelcomeComp />
                <MonthlyEarning />
              </Col>
              <Col xl="8">
                <Row>
                  {/* Reports Render */}

                  <Col md="4" >
                    <Card className="mini-stats-wid .">
                      <CardBody>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <p className="text-muted fw-medium">
                            {this.props.t("Reservations")}
                            </p>
                            <h4 className="mb-0">{tr}</h4>
                          </div>
                          <Link to="/reservations">
                            <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                              <span className="avatar-title">
                                <i
                                  className={
                                    "bx bx-copy-alt font-size-24"
                                  }
                                />
                              </span>
                            </div>
                          </Link>
                        </div>

                      </CardBody>
                    </Card>
                  </Col>
                  <Col md="4" >
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <p className="text-muted fw-medium">
                            {this.props.t("Revenue")}
                            </p>
                            <h5 className="mb-0">{tre} DHs</h5>
                          </div>
                          <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                            <span className="avatar-title">
                              <i
                                className={
                                  "bx bx-archive-in font-size-24"
                                }
                              />
                            </span>
                          </div>
                        </div>

                      </CardBody>
                    </Card>
                  </Col>
                  <Col md="4" >
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <p className="text-muted fw-medium">
                              
                              {this.props.t("Average Duration")}
                            </p>
                            <h5 className="mb-0">{avg_duration || 0} {this.props.t("Days")}</h5>
                          </div>
                          <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                            <span className="avatar-title">
                              <i
                                className={
                                  "bx bx-purchase-tag-alt font-size-24"
                                }
                              />
                            </span>
                          </div>
                        </div>

                      </CardBody>
                    </Card>
                  </Col>
                </Row>

                <Card>
                  <CardBody>
                    <div className="d-sm-flex flex-wrap">
                      <CardTitle className="card-title mb-4 h4">
                        
                        {this.props.t("Earnings")}
                      </CardTitle>
                      <div className="ms-auto">
                        {selectedYear}
                        {/*  <ul className="nav nav-pills">
                          <li className="nav-item">
                            <Link
                              to="#"
                              className={classNames(
                                { "active": this.state.periodType === "weekly" },
                                "nav-link"
                              )}
                              onClick={() => {
                                this.setState({ ...this.state, periodType: "weekly" });
                                //this.props.onGetChartsData("weekly")
                              }}
                              id="one_month"
                            >
                              Week
                            </Link>{" "}
                          </li>
                          <li className="nav-item">
                            <Link
                              to="#"
                              className={classNames(
                                { "active": this.state.periodType === "monthly" },
                                "nav-link"
                              )}
                              onClick={() => {
                                this.setState({ ...this.state, periodType: "monthly" });
                                // this.props.onGetChartsData("monthly")
                              }}
                              id="one_month"
                            >
                              Month
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              to="#"
                              className={classNames(
                                { "active": this.state.periodType === "yearly" },
                                "nav-link"
                              )}
                              onClick={() => {
                                this.setState({ ...this.state, periodType: "yearly" });
                                // this.props.onGetChartsData("yearly")
                              }}
                              id="one_month"
                            >
                              Year
                            </Link>
                          </li>
                        </ul>*/}
                      </div>
                    </div>
                    <div className="clearfix" />
                    <Apaexlinecolumn chartSeries={yearData} />
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row>

              <Col xl="6">
                <ActivityComp />
              </Col>
              <Col xl="6">
                <TopCities year={selectedYear} />
              </Col>
            </Row>

            <Row>
              <Col lg="12">
                <TodayPickups />
              </Col>
            </Row>
            <Row>
              <Col lg="12">
                <TodayReturns />
              </Col>
            </Row>
          </Container>
        </div>

        <Modal
          isOpen={this.state.subscribemodal}
          role="dialog"
          autoFocus={true}
          data-toggle="modal"
          centered
          toggle={this.togglesubscribemodal}
        >
          <div className="modal-content">
            <div className="modal-header border-bottom-0">
              <button type="button" className="btn-close" onClick={() =>
                this.setState({ subscribemodal: false })
              } data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <div className="text-center mb-4">

                <div className="avatar-md mx-auto mb-4">
                  <div className="avatar-title bg-light  rounded-circle text-primary h1">
                    <i className="mdi mdi-email-open"></i>
                  </div>
                </div>

                <div className="row justify-content-center">
                  <div className="col-xl-10">
                    <h4 className="text-primary">Subscribe !</h4>
                    <p className="text-muted font-size-14 mb-4">Subscribe our newletter and get notification to stay update.</p>

                    <div className="input-group  rounded bg-light"  >
                      <Input type="email" className="form-control bg-transparent border-0" placeholder="Enter Email address" />
                      <Button color="primary" type="button" id="button-addon2">
                        <i className="bx bxs-paper-plane"></i>
                      </Button>

                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>

        {/* <Modal
          isOpen={this.state.modal}
          role="dialog"
          autoFocus={true}
          centered={true}
          className="exampleModal"
          tabindex="-1"
          toggle={this.togglemodal}
        >
          <div className="modal-content">
            <ModalHeader toggle={this.togglemodal}>Order Details</ModalHeader>
            <ModalBody>
              <p className="mb-2">
                Product id: <span className="text-primary">#SK2540</span>
              </p>
              <p className="mb-4">
                Billing Name:{" "}
                <span className="text-primary">Neal Matthews</span>
              </p>

              <div className="table-responsive">
                <Table className="table align-middle table-nowrap">
                  <thead>
                    <tr>
                      <th scope="col">Product</th>
                      <th scope="col">Product Name</th>
                      <th scope="col">Price</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">
                        <div>
                          <img src={modalimage1} alt="" className="avatar-sm" />
                        </div>
                      </th>
                      <td>
                        <div>
                          <h5 className="text-truncate font-size-14">
                            Solid Color T-Shirt
                          </h5>
                          <p className="text-muted mb-0">$ 225 x 1</p>
                        </div>
                      </td>
                      <td>$ 255</td>
                    </tr>
                    <tr>
                      <th scope="row">
                        <div>
                          <img src={modalimage2} alt="" className="avatar-sm" />
                        </div>
                      </th>
                      <td>
                        <div>
                          <h5 className="text-truncate font-size-14">
                            Hoodie (Blue)
                          </h5>
                          <p className="text-muted mb-0">$ 145 x 1</p>
                        </div>
                      </td>
                      <td>$ 145</td>
                    </tr>
                    <tr>
                      <td colSpan="2">
                        <h6 className="m-0 text-right">Sub Total:</h6>
                      </td>
                      <td>$ 400</td>
                    </tr>
                    <tr>
                      <td colSpan="2">
                        <h6 className="m-0 text-right">Shipping:</h6>
                      </td>
                      <td>Free</td>
                    </tr>
                    <tr>
                      <td colSpan="2">
                        <h6 className="m-0 text-right">Total:</h6>
                      </td>
                      <td>$ 400</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button
                type="button"
                color="secondary"
                onClick={this.togglemodal}
              >
                Close
              </Button>
            </ModalFooter>
          </div>
        </Modal> */}
      </React.Fragment >
    )
  }
}

Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  year: PropTypes.any,
  onGetChartsData: PropTypes.func
}

const mapStateToProps = ({ Dashboard }) => ({
  chartsData: Dashboard.chartsData,
})

const mapDispatchToProps = dispatch => ({
  onGetChartsData: (periodType) => dispatch(getChartsData(periodType)),
})

export default connect(
  mapStateToProps, mapDispatchToProps
)(withTranslation()(Dashboard))

