import {
  
  GET_REPAIR ,
  GET_REPAIR_FAIL,
  GET_REPAIR_SUCCESS,
  ADD_NEW_REPAIR,
  ADD_REPAIR_SUCCESS,
  ADD_REPAIR_FAIL,
  UPDATE_REPAIR,
  UPDATE_REPAIR_SUCCESS,
  UPDATE_REPAIR_FAIL,
  DELETE_REPAIR,
  DELETE_REPAIR_SUCCESS,
  DELETE_REPAIR_FAIL,
  
} from "./actionTypes"



export const getRepair = () => ({
  type: GET_REPAIR,
})

export const getRepairSuccess = repair => ({
  type: GET_REPAIR_SUCCESS,
  payload: repair,
})

export const getRepairFail = error => ({
  type: GET_REPAIR_FAIL,
  payload: error,
})

export const addNewRepair = repair => ({
  type: ADD_NEW_REPAIR,
  payload: repair,
})

export const addRepairSuccess = repair => ({
  type: ADD_REPAIR_SUCCESS,
  payload: repair,
})

export const addRepairFail = error => ({
  type: ADD_REPAIR_FAIL,
  payload: error,
})

export const updateRepair = repair => ({
  type: UPDATE_REPAIR,
  payload: repair,
})

export const updateRepairSuccess = repair => ({
  type: UPDATE_REPAIR_SUCCESS,
  payload: repair,
})

export const updateRepairFail = error => ({
  type: UPDATE_REPAIR_FAIL,
  payload: error,
})

export const deleteRepair = repair => ({
  type: DELETE_REPAIR,
  payload: repair,
})

export const deleteRepairSuccess = repair => ({
  type: DELETE_REPAIR_SUCCESS,
  payload: repair,
})

export const deleteRepairFail = error => ({
  type: DELETE_REPAIR_FAIL,
  payload: error,
})
