import { call, put, takeEvery } from "redux-saga/effects"

// Ecommerce Redux States
import {
  
  GET_INSSURANCE,
  ADD_NEW_INSSURANCE,
  DELETE_INSSURANCE,
  UPDATE_INSSURANCE,
  
} from "./actionTypes"
import {
  
  getInssuranceFail,
  getInssuranceSuccess,
  addInssuranceFail,
  addInssuranceSuccess,
  updateInssuranceSuccess,
  updateInssuranceFail,
  deleteInssuranceSuccess,
  deleteInssuranceFail,
  
} from "./actions"

//Include Both Helper File with needed methods
import {
 
  getInssurance,
  addNewInssurance,
  updateInssurance,
  deleteInssurance,
  
} from "helpers/fakebackend_helper"


function* fetchInssurance() {
  try {
    const response = yield call(getInssurance)
    yield put(getInssuranceSuccess(response))
  } catch (error) {
    yield put(getInssuranceFail(error))
  }
}

function* onAddNewInssurance({ payload: inssurance }) {
  try {
    const response = yield call(addNewInssurance, inssurance)
    yield put(addInssuranceSuccess(response))
  } catch (error) {

    yield put(addInssuranceFail(error))
  }
}

function* onUpdateInssurance({ payload: inssurance }) {
  try {
    const response = yield call(updateInssurance, inssurance)
    yield put(updateInssuranceSuccess(response))
  } catch (error) {
    yield put(updateInssuranceFail(error))
  }
}

function* onDeleteInssurance({ payload: inssurance }) {
  try {
    const response = yield call(deleteInssurance, inssurance)
    yield put(deleteInssuranceSuccess(response))
  } catch (error) {
    yield put(deleteInssuranceFail(error))
  }
}

function* inssuranceSaga() {
  
  yield takeEvery(GET_INSSURANCE, fetchInssurance)
  yield takeEvery(ADD_NEW_INSSURANCE, onAddNewInssurance)
  yield takeEvery(UPDATE_INSSURANCE, onUpdateInssurance)
  yield takeEvery(DELETE_INSSURANCE, onDeleteInssurance)
  
}

export default inssuranceSaga
