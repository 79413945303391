import {
  
  GET_TARIFF ,
  GET_TARIFF_FAIL,
  GET_TARIFF_SUCCESS,
  ADD_NEW_TARIFF,
  ADD_TARIFF_SUCCESS,
  ADD_TARIFF_FAIL,
  UPDATE_TARIFF,
  UPDATE_TARIFF_SUCCESS,
  UPDATE_TARIFF_FAIL,
  DELETE_TARIFF,
  DELETE_TARIFF_SUCCESS,
  DELETE_TARIFF_FAIL,
} from "./actionTypes"



export const getTariff = () => ({
  type: GET_TARIFF,
})

export const getTariffSuccess = tariff => ({
  type: GET_TARIFF_SUCCESS,
  payload: tariff,
})

export const getTariffFail = error => ({
  type: GET_TARIFF_FAIL,
  payload: error,
})

export const addNewTariff = tariff => ({
  type: ADD_NEW_TARIFF,
  payload: tariff,
})

export const addTariffSuccess = tariff => ({
  type: ADD_TARIFF_SUCCESS,
  payload: tariff,
})

export const addTariffFail = error => ({
  type: ADD_TARIFF_FAIL,
  payload: error,
})

export const updateTariff = tariff => ({
  type: UPDATE_TARIFF,
  payload: tariff,
})

export const updateTariffSuccess = tariff => ({
  type: UPDATE_TARIFF_SUCCESS,
  payload: tariff,
})

export const updateTariffFail = error => ({
  type: UPDATE_TARIFF_FAIL,
  payload: error,
})

export const deleteTariff = tariff => ({
  type: DELETE_TARIFF,
  payload: tariff,
})

export const deleteTariffSuccess = tariff => ({
  type: DELETE_TARIFF_SUCCESS,
  payload: tariff,
})

export const deleteTariffFail = error => ({
  type: DELETE_TARIFF_FAIL,
  payload: error,
})




