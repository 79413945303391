import { call, put, takeEvery } from "redux-saga/effects"

// Ecommerce Redux States
import {
  
  GET_STAFF,
  ADD_NEW_STAFF,
  DELETE_STAFF,
  UPDATE_STAFF,
  
} from "./actionTypes"
import {
  
  getStaffFail,
  getStaffSuccess,
  addStaffFail,
  addStaffSuccess,
  updateStaffSuccess,
  updateStaffFail,
  deleteStaffSuccess,
  deleteStaffFail,
  
} from "./actions"

//Include Both Helper File with needed methods
import {
 
  getStaff,
  addNewStaff,
  updateStaff,
  deleteStaff,
  
} from "helpers/fakebackend_helper"
import { getFirebaseBackend } from "helpers/firebase_helper"
const fireBaseBackend = getFirebaseBackend()

function* fetchStaff() {
  try {
    const response = yield call(fireBaseBackend.getStaff)
    yield put(getStaffSuccess(response))
  } catch (error) {
    yield put(getStaffFail(error))
  }
}

function* onAddNewStaff({ payload: staff }) {
  try {
    const response = yield call(fireBaseBackend.addNewStaff, staff)
    yield put(addStaffSuccess(response))
  } catch (error) {

    yield put(addStaffFail(error))
  }
}

function* onUpdateStaff({ payload: staff }) {
  try {
    const response = yield call(fireBaseBackend.updateStaff, staff)
    yield put(updateStaffSuccess(response))
  } catch (error) {
    yield put(updateStaffFail(error))
  }
}

function* onDeleteStaff({ payload: staff }) {
  try {
    const response = yield call(fireBaseBackend.deleteStaff, staff)
    yield put(deleteStaffSuccess(response))
  } catch (error) {
    yield put(deleteStaffFail(error))
  }
}

function* staffSaga() {
  
  yield takeEvery(GET_STAFF, fetchStaff)
  yield takeEvery(ADD_NEW_STAFF, onAddNewStaff)
  yield takeEvery(UPDATE_STAFF, onUpdateStaff)
  yield takeEvery(DELETE_STAFF, onDeleteStaff)
  
}

export default staffSaga
