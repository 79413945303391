import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import { Link } from "react-router-dom";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Container, Card, CardBody, Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap";
import SimpleBar from "simplebar-react";
import noRequests from "../../assets/images/error-img.png"

import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import "firebase/database"
import "firebase/storage"

import * as moment from 'moment';
import PropTypes from 'prop-types';
//i18n
import { withTranslation } from "react-i18next";

class Notifications extends Component {
  constructor(props) {
    super(props)
    this.state = {
      notifs: [],
      selectedOption: "",
      selectedDate: new Date(),
    }
    this.onValueChange = this.onValueChange.bind(this)
  }
  componentDidMount() {
    const { notifs } = this.state
    if (notifs && !notifs.length) {
      this.getNotifs()
    }
    this.setState({ notifs })
  }
  getNotifs = async () => {
    this.setState({ loading: true });

    // Unsubscribe previous listeners to prevent memory leaks
    if (this.unsubscribeNotifs) {
      this.unsubscribeNotifs();
    }

    this.unsubscribeNotifs = firebase.auth().onAuthStateChanged(async user => {
      if (user) {
        const userId = user.uid;
        const usersCollection = firebase.firestore().collection("users")
        const userDocRef = usersCollection.doc(userId);

        try {
          const userDoc = await userDocRef.get();
          if (userDoc.exists) {
            const userId = userDoc.data().id;
            const userNotifsCollectionRef = usersCollection.doc(userId).collection("notifications").orderBy("time", "desc");

            this.unsubscribeNotifs = userNotifsCollectionRef.onSnapshot(snapshot => {
              let n = [];
              snapshot.docs.forEach(doc => {
                n.push(doc.data());
              });

              this.setState({
                loading: false,
                notifs: n
              });
            });

            localStorage.setItem("authUser", JSON.stringify(user));
          } else {
            // User document not found
            localStorage.removeItem("authUser");
            
            this.setState({
                loading: false,
                notifs: []
              });
            
          }
        } catch (error) {
          console.error("Error fetching user document:", error);
          // Handle error
        }
      } else {
        localStorage.removeItem("authUser");
        // Clear the reservations if user is not authenticated
        this.setState({
                loading: false,
                notifs: []
              });
      }
    });
  }

  onValueChange(event) {
    const { notifs } = this.state
    this.setState({
      selectedOption: event.target.id
    });
    //console.log(event.target.id)


  }
  dateChanged = dt => {

    // //console.log(dt.target.value)
    this.setState({
      selectedOption: "dt",
      selectedDate: dt.target.value
    });
  }
  render() {
    const { notifs, selectedOption, selectedDate } = this.state

    let n = []
    let noti = []
    notifs.map(function (item) {
      //if (item.status === "new") {
      var span = ""
      var ic = ""
      var link = ""
      if (item.type === "PPError") {
        span = "avatar-title bg-danger rounded-circle font-size-16"
        ic = "bx bx-user"
        link = "/profile"
      }
      if (item.type === "RepairEsoon") {
        span = "avatar-title bg-warning rounded-circle font-size-16"
        ic = "bx bx-wrench"
        link = "/maintenance"
      }
      if (item.type === "RepairSsoon") {
        ic = "bx bx-wrench"
        span = "avatar-title bg-warning rounded-circle font-size-16"
        link = "/maintenance"
      }
      if (item.type === "ReservationPsoon") {
        span = "avatar-title bg-warning rounded-circle font-size-16"
        ic = "bx bx-car"
        link = "/reservations"
      }
      if (item.type === "ReservationRsoon") {
        ic = "bx bx-car"
        span = "avatar-title bg-warning rounded-circle font-size-16"
        link = "/reservations"
      }
      if (item.type === "RepairEnd") {
        ic = "bx bx-wrench"
        span = "avatar-title bg-danger rounded-circle font-size-16"
        link = "/maintenance"
      }
      if (item.type === "RepairStart") {
        ic = "bx bx-wrench"
        span = "avatar-title bg-danger rounded-circle font-size-16"
        link = "/maintenance"
      }
      if (item.type === "ReservationStart") {
        ic = "bx bx-car"
        span = "avatar-title bg-danger rounded-circle font-size-16"
        link = "/reservations"
      }
      if (item.type === "ReservationDone") {
        ic = "bx bx-car"
        span = "avatar-title bg-danger rounded-circle font-size-16"
        link = "/reservations"
      }
      if (item.type === "RequestArrived") {
        span = "avatar-title bg-success rounded-circle font-size-16"
        ic = "bx bx-message-square-check"
        link = "/booking_requests"
      }
      if (item.type === "RequestExpired") {
        span = "avatar-title bg-danger rounded-circle font-size-16"
        ic = "bx bx-message-square-check"
        link = "/booking_requests"
      }
      if (item.type === "RequestEsoon") {
        span = "avatar-title bg-warning rounded-circle font-size-16"
        ic = "bx bx-message-square-check"
        link = "/booking_requests"
      }

      if (item.type === "InssuranceSoon") {
        ic = "bx bx-paperclip"
        span = "avatar-title bg-warning rounded-circle font-size-16"
        link = "/inssurance"
      }
      if (item.type === "InssuranceExpired") {
        ic = "bx bx-paperclip"
        span = "avatar-title bg-danger rounded-circle font-size-16"
        link = "/inssurance"
      }
      if (item.type === "TaxSoon") {
        ic = "bx bx-money"
        span = "avatar-title bg-warning rounded-circle font-size-16"
        link = "/vignette"
      }
      if (item.type === "TaxExpired") {
        ic = "bx bx-money"
        span = "avatar-title bg-danger rounded-circle font-size-16"
        link = "/vignette"
      }
      if (item.type === "OilChangeSoon") {
        ic = "fas fa-oil-can"
        span = "avatar-title bg-warning rounded-circle font-size-16"
        link = "/maintenance"
      }
      if (item.type === "OilChangeToday") {
        ic = "fas fa-oil-can"
        span = "avatar-title bg-danger rounded-circle font-size-16"
        link = "/mantenance"
      }
      noti.push({ desc: item.desc,data: item.data, status: item.status, time: item.time, title: item.title, type: item.type, ic: ic, span: span, link: link })
      //}
    })
    /* n.sort(function(a,b){
      return b.time - a.time;
    }); */
    n = noti
    if (selectedOption === "today") {
      n = []
      noti.map(function (item) {
        var sd = moment(new Date()).format('MM-DD-YYYY')
        var ds = moment(item.time).format("MM-DD-YYYY");
        var t = moment(ds)
        var s = moment(sd)
        //console.log("sdddd", sd)
        //console.log("ttttt", ds)
        if (s.isSame(t, 'day')) {
          n.push(item)
        }

      });
    }
    if (selectedOption === "lw") {
      n = []
      noti.map(function (item) {
        if (moment(new Date().getTime()).diff(moment(item.time), 'days') <= 7) {
          n.push(item)
        }
      });

    }
    if (selectedOption === "lm") {
      n = []
      noti.map(function (item) {
        if (moment(new Date().getTime()).diff(moment(item.time), 'months') <= 1) {
          n.push(item)
        }
      });
    }
    if (selectedOption === "all") {
      n = []
      noti.map(function (item) {

        n.push(item)
      });
    }
    if (selectedOption === "dt") {
      n = []
      noti.map(function (item) {
        var sd = moment(selectedDate).format('MM-DD-YYYY')
        var ds = moment(item.time).format("MM-DD-YYYY");
        var t = moment(ds)
        var s = moment(sd)
        //console.log("sdddd", sd)
        //console.log("ttttt", ds)
        if (s.isSame(t, 'day')) {
          n.push(item)
        }

      });

    }
    const count = n.length

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>{this.props.t("Notifications | Medios - Car Rental Management System")}</title>
          </MetaTags>
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title={this.props.t("Medios")} breadcrumbItem={this.props.t("Notifications")} />

            <Row>
              <Col lg="12">
                <Row className="mb-2" >
                  <Col sm="4">
                    <div className="mb-3 row">

                      <div className="col-md-10">
                        <input
                          className="form-control"
                          type="date"
                          onChange={this.dateChanged}
                          id="example-date-input"
                        />
                      </div>
                    </div>
                  </Col>

                  <Col sm="8" >

                    <div className="text-sm-end" >
                      <Col >
                        <div className="btn-group " role="group" aria-label="Basic radio toggle button group" onChange={this.onChangeValue}>
                          <input type="radio" className="btn-check" name="btnradio" id="all" onClick={this.onValueChange} autoComplete="off" defaultChecked />
                          <label className="btn btn-primary" htmlFor="all">{this.props.t("All")}</label>

                          <input type="radio" className="btn-check" name="btnradio" id="today" onClick={this.onValueChange} autoComplete="off" />
                          <label className="btn btn-primary" htmlFor="today">{this.props.t("Today")}</label>

                          <input type="radio" className="btn-check" name="btnradio" id="lw" onClick={this.onValueChange} autoComplete="off" />
                          <label className="btn btn-primary" htmlFor="lw">{this.props.t("This week")}</label>

                          <input type="radio" className="btn-check" name="btnradio" id="lm" onClick={this.onValueChange} autoComplete="off" />
                          <label className="btn btn-primary" htmlFor="lm">{this.props.t("This month")}</label>


                        </div>

                      </Col>

                    </div>
                  </Col>
                </Row>
                <Card>
                  <CardBody>

                    {n.length == 0 ?
                      <div className="text-center">
                        <Row className="justify-content-center mt-5">
                          <Col sm="4">
                            <div className="maintenance-img">
                              <img
                                src={noRequests}
                                alt=""
                                className="img-fluid mx-auto d-block"
                              />
                            </div>
                          </Col>
                        </Row>
                        <h4 className="mt-5">{this.props.t("No notifications yet!")}</h4>
                        <p className="text-muted">
                          {this.props.t("You can find here the notifications")} <h5 className="text-primary"></h5>
                        </p>
                      </div>
                      : n.map((item, i) => (
                        <Link key={i} className="text-reset notification-item" to={item.link}>
                          <div className="media">

                            <div className="avatar-xs me-3">
                              <span className={item.span}>
                                <i className={item.ic} />
                              </span>
                            </div>

                            <div className="media-body">
                              <h6 className="mt-0 mb-1">
                                {this.props.t(item.title)}
                              </h6>
                              <div className="font-size-12 text-muted">
                                <p className="mb-1">
                                  {this.props.t(item.desc)||""}
                                </p>
                                <p className="mb-2">
                                  {item.data ||""}
                                </p>
                                {/* <button
                          onClick={() => this.notifConfirmed(item)}
                          className="btn btn-success btn-sm"
                        >
                          Confirm
                        </button>
                        {"         "}
                        <button
                          onClick={() => this.notifCancelled(item)}
                          className="btn btn-danger save-event btn-sm"
                        >
                          Cancel
                        </button> */}
                                <p className="mb-0">
                                  <i className="mdi mdi-clock-outline" />{" "}
                                  {moment(item.time).fromNow()}{" "}
                                </p>
                              </div>
                            </div>
                          </div>

                        </Link>
                      ))}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}
Notifications.propTypes = {
  t: PropTypes.any
}

export default withTranslation()(Notifications)

